import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { Address } from "@momenta/common/address";
import { DetailColumn } from "@momenta/common/companies/DetailColumn";
import { doesNotContainHashValidator } from "@momenta/common/validators/doesNotContainHashValidator";
import { ComponentVisible } from "@momenta/common/internationalisation";
import { CityLookupDropdown } from "@momenta/common/address/lookup/CityLookupDropdown";
import { stateOptionsList } from "@momenta/common/address/lookup/UsState";

interface EditAddressProps {
    values: { address?: Address };
    showErrors: boolean;
    onChange: (prop: string, value: string, valid: boolean) => void;
}

export const EditAddress: React.SFC<EditAddressProps> = ({ values, showErrors, onChange }) => {

    const getOnChange = (prop: string) => (value: string, valid: boolean) => onChange(prop, value, valid);

    const stateChange = () => (value: string, valid: boolean) => {
        if (value === values.address.state) {
            return;
        }
        onChange("address.city", "", false);
        return onChange("address.state", value, valid);
    };

    return (
        <Grid.Row>
            <Grid.Column computer={6} mobile={16} tablet={8}>
                <Input.Text
                    value={values.address && values.address.line1}
                    label="Line 1"
                    onChange={getOnChange("address.line1")}
                    autoComplete="address-line1"
                    disableAutocorrect
                    required
                    showErrors={showErrors}
                    validation={[doesNotContainHashValidator()]}
                />
            </Grid.Column>

            <Grid.Column computer={6} mobile={16} tablet={8}>
                <Input.Text
                    value={values.address && values.address.line2}
                    label="Line 2"
                    onChange={getOnChange("address.line2")}
                    autoComplete="address-line2"
                    disableAutocorrect
                    showErrors={showErrors}
                    validation={[doesNotContainHashValidator()]}
                />
            </Grid.Column>

            <Grid.Column computer={4} mobile={16} tablet={8}>
                <ComponentVisible keyName="stateAddressFieldEnabled">
                    <Input.Dropdown
                        label="State"
                        placeholder="Choose State"
                        value={values.address && values.address.state}
                        options={stateOptionsList}
                        showErrors={showErrors}
                        onChange={stateChange()}
                        required
                        search
                    />
                    <CityLookupDropdown
                        selectedState={values.address && values.address.state}
                        city={values.address && values.address.city}
                        onChange={getOnChange("address.city")}
                        showErrors={showErrors}
                        validation={[doesNotContainHashValidator()]}
                    />
                </ComponentVisible>
                <ComponentVisible keyName="stateAddressFieldNotEnabled">
                    <Input.Text
                        value={values.address && values.address.city}
                        label="City"
                        onChange={getOnChange("address.city")}
                        autoComplete="address-level2"
                        disableAutocorrect
                        showErrors={showErrors}
                        validation={[doesNotContainHashValidator()]}
                    />
                </ComponentVisible>
            </Grid.Column>

            <Grid.Column computer={6} mobile={16} tablet={8}>
                <Input.Text
                    value={values.address && values.address.county}
                    label="County"
                    onChange={getOnChange("address.county")}
                    autoComplete="address-level1"
                    disableAutocorrect
                    showErrors={showErrors}
                    validation={[doesNotContainHashValidator()]}
                />
            </Grid.Column>

            <Grid.Column computer={4} mobile={16} tablet={8}>
                <Input.Text
                    value={values.address && values.address.postcode}
                    label="Post Code / Zip Code"
                    onChange={getOnChange("address.postcode")}
                    autoComplete="postal-code"
                    disableAutocorrect
                    required
                    showErrors={showErrors}
                    validation={[doesNotContainHashValidator()]}
                />
            </Grid.Column>

            <Grid.Column computer={6} mobile={16} tablet={8}>
                <DetailColumn label="Country" value={values.address && values.address.country} />
            </Grid.Column>
        </Grid.Row>
    );
};
