import * as React from "react";
import { Button, Grid, Header, Message, Popup } from "semantic-ui-react";
import { wait } from "@momenta/common";
import { negativeTimesheetEntryOptions, Timesheet, TimesheetStateEnum, TimesheetView } from "@momenta/common/timesheets";
import { BonusTypeConfiguration, ExpenseTypeConfiguration, TimeTypeConfiguration } from "@momenta/common/hierarchicalConfiguration";
import { FixedActions } from "@momenta/common/fixedActions";

interface AdjustmentTimesheetViewProps {
    timesheet: Timesheet;
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    timeTypeConfigurations: TimeTypeConfiguration[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
    online: boolean;
    submit: (model: Timesheet, submit: boolean) => Promise<void>;
}

interface AdjustmentTimesheetViewState {
    offlineMessage: boolean;
    offlineSubmitMessage: boolean;
}

class AdjustmentTimesheetView extends React.Component<AdjustmentTimesheetViewProps, AdjustmentTimesheetViewState> {

    public state = {
        offlineMessage: false,
        offlineSubmitMessage: false
    };

    public render() {
        const props = this.props;
        const submitted = props.timesheet && props.timesheet.state >= TimesheetStateEnum.Submitted;
        const { online } = this.props;

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Message warning size="big">
                            <Header content="Adjustment Timesheet" textAlign="center" />
                        </Message>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={16}>
                    <Grid.Column width={16}>
                        <FixedActions className="timesheet-detail">

                            <TimesheetView
                                timesheet={props.timesheet}
                                timeTypeConfigurations={props.timeTypeConfigurations}
                                bonusTypeConfigurations={props.bonusTypeConfigurations}
                                expenseTypeConfigurations={props.expenseTypeConfigurations}
                                negativeTimesheetEntryOptions={negativeTimesheetEntryOptions}
                            />

                            <FixedActions.Bar className="timesheet-actions">
                                <Grid.Column width={16} textAlign="right">
                                    <Popup
                                        hideOnScroll
                                        open={this.state.offlineSubmitMessage}
                                        onOpen={this.submitMessageOpen}
                                        onClose={this.submitMessageClose}
                                        content="You cannot submit your timesheet while your are offline.  Please try again when you are online."
                                        trigger={<Button
                                            disabled={submitted}
                                            icon="send"
                                            content="Submit Adjustment"
                                            positive
                                            floated="right"
                                            className={online ? "" : "disabled clickable"}
                                            onClick={this.submitClick}
                                        />}
                                    />
                                </Grid.Column>
                            </FixedActions.Bar>
                        </FixedActions>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    private submitClick = async () => {

        if (this.props.online) {
            await this.props.submit(this.props.timesheet, true);
        } else {
            await this.submitMessageOpen();
        }
    }

    private submitMessageOpen = async () => {
        if (!this.props.online) {
            this.setState({ offlineSubmitMessage: true });
            await wait(5000);
            this.setState({ offlineSubmitMessage: false });
        }
    }

    private submitMessageClose = () => this.setState({ offlineSubmitMessage: false });
}

export { AdjustmentTimesheetView };
