import * as React from "react";
import { Container, Grid } from "semantic-ui-react";

export const LandingPageUk: React.FC = () => (
    <>
        <div className="associate-landing">
            <Container>
                <Grid>
                    <Grid.Column computer={10} mobile={16}>
                        <h1>
                            Make your next career
                            <br />
                            move a great one.
                        </h1>

                        <p>Welcome to Momenta People, our online candidate platform. Over 30 years we have built a network of highly skilled and talented
                        professionals, helping thousands of them secure roles within the Financial Services, Legal, Technology and Learning
                            & Development sectors.<br />
                        Becoming a Momenta Associate is easy and your online registration can be completed in minutes.<br /></p>

                        <p>If you are not yet registered with Momenta, click the Sign-Up button above. From here you will be able to:</p>

                        <ul className="bullets">
                            <li>Register your personal and contact details</li>
                            <li>Upload your current CV/Resume</li>
                            <li>Let us know your availability and pay expectations</li>
                            <li>Complete a "Fast-Track Onboarding" process, to improve your chances of securing a new role quickly</li>
                            <li>View vacancies and apply for new roles</li>
                        </ul>

                        <p>If you are an existing Momenta Associate, click the Sign-In button above to:</p>

                        <ul className="bullets">
                            <li>Update your personal or contact details</li>
                            <li>Upload your current CV/Resume</li>
                            <li>Complete your profile and improve your visibility to our skilled team of recruiters</li>
                            <li>View vacancies and apply for new roles</li>
                            <li>Complete your timesheet and view invoices (for Associates on contract)</li>
                        </ul>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
        <div className="landing-image" />
    </>
);
