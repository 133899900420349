import * as actions from "./actiontypes";
import { UmbrellaCompanyAction } from "./actions";
import { UmbrellaCompany } from "./model";

export function umbrellaCompanies(state: UmbrellaCompany[] = [], action: UmbrellaCompanyAction): UmbrellaCompany[] {
    switch (action.type) {
        case actions.LOAD_UMBRELLACOMPANIES_SUCCESS:
            return action.payload;
        case actions.SAVE_UMBRELLACOMPANY_SUCCESS:
        case actions.LOAD_UMBRELLACOMPANY_DETAIL_SUCCESS:
            return [
                ...state.filter(a => a.id !== action.payload.id),
                action.payload
            ];
        case actions.CREATE_UMBRELLACOMPANY_SUCCESS:
            return [...state, action.payload];
        case actions.DELETE_UMBRELLACOMPANY_SUCCESS:
            return [...state.filter(c => c.id !== action.payload)];
        default:
            return state;
    }
}
