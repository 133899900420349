import * as React from "react";
import { Button, ButtonProps, Table } from "semantic-ui-react";

import { ColumnDefinition, ValuesAndValid } from "./InlineEditTable";
import { InlineEditTableCell, SharedProps } from "./InlineEditTableCell";

export interface InlineEditTableRowProps<T> extends SharedProps<T> {
    keyProp: any;
    columns: ColumnDefinition<T>[];
    disabled: boolean;
    loading: boolean;
    onSaveClick: () => void;
    onEditClick: (key: any) => void;
    onDeleteClick: (key: any) => void;
    editDisabled?: boolean;
    deleteDisabled?: boolean;
    className?: string;
    updateStartEndDateValid?: (valid: boolean) => void;
}

export function InlineEditTableRow<T>(props: InlineEditTableRowProps<T>) {

    const {
        columns,
        disabled,
        loading,
        onSaveClick,
        onEditClick,
        onDeleteClick,
        keyProp,
        editDisabled,
        deleteDisabled,
        className,
        updateStartEndDateValid,
        ...sharedProps
    } = props;

    const { isEditing, row } = sharedProps;

    const key = row[keyProp];

    const editProps = (): ButtonProps => {

        if (disabled) {
            return { icon: "pencil", title: "Edit", primary: true, disabled: true };
        }

        if (isEditing) {
            return { icon: "save", color: "green", title: "Save", onClick: onSaveClick, loading };
        }

        return { icon: "pencil", title: "Edit", primary: true, onClick: () => onEditClick(key) };
    };

    const cancelProps = () => {

        if (disabled) {
            return { icon: "trash", disabled: true, title: "Delete" };
        }

        if (isEditing) {
            return { icon: "cancel", basic: true, title: "Cancel", onClick: () => onEditClick(key) };
        }

        return { icon: "trash", title: "Delete", onClick: () => onDeleteClick(key), loading };
    };

    const updatePropertyWithDateCheck = (prop: keyof T, value: any, valid: boolean, state?: ValuesAndValid<T>) => {
        sharedProps.updateProperty(prop, value, valid, state);
        updateStartEndDateValid(valid);
    };

    return (
        <Table.Row warning={isEditing} disabled={loading} className={className}>
            {columns.filter(col => !col.hidden).map((col, key2) => (<InlineEditTableCell
                key={key2}
                col={col}
                {...sharedProps}
                updateProperty={(col.isStartDate || col.isEndDate) ? updatePropertyWithDateCheck : sharedProps.updateProperty}
            />))}

            <Table.Cell collapsing textAlign="right">
                {!editDisabled && <Button {...editProps()} />}
                {!deleteDisabled && <Button {...cancelProps()} color="red" />}
            </Table.Cell>
        </Table.Row>
    );
}
