import * as React from "react";
import { Button, Card, Header } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { Timesheet } from "@momenta/common/timesheets";
import { TimesheetList } from "@momenta/common/timesheets";
import { localTextSelector } from "@momenta/common/internationalisation/selectors";
import { connect } from "react-redux";

interface TimesheetCardStateProps {
    invoicesHeader: string;
}

interface TimesheetCardProps {
    timesheets: Timesheet[];
}

const TimesheetCardUnconnected: React.FC<TimesheetCardProps & TimesheetCardStateProps> = (props) => {
    return (
        <Card className="dashboard-card timesheet-card">
            <Card.Content>
                <Header>
                    {props.invoicesHeader}
                </Header>
            </Card.Content>
            <Card.Content className="timesheet-list">
                <TimesheetList timesheets={props.timesheets.slice(0, 2)} />
            </Card.Content>
            <Card.Content extra>
                <Button floated="right" size="mini" content={`All ${props.invoicesHeader}`} as={Link} href="/timesheets" primary />
            </Card.Content>
        </Card>
    );
};

const mapStateToProps = (state: any): TimesheetCardStateProps => ({
    invoicesHeader: localTextSelector(state, "invoicesHeader")
});

export const TimesheetCard = connect(mapStateToProps)(TimesheetCardUnconnected);
