import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";
import { VatRate } from "@momenta/common/vatRates";
import { AuthState } from "reauthorize";

import { VatRatesApi } from "./VatRatesApi";
import { LOAD_VAT_RATES_SUCCESS } from "./actiontypes";

export type VatRateAction =
    ({ type: LOAD_VAT_RATES_SUCCESS } & Payload<VatRate[]>);

export const loadVatRatesSuccess = (payload: VatRate[]): VatRateAction => ({
    payload,
    type: LOAD_VAT_RATES_SUCCESS
});

export function loadVatRates() {
    return async (dispatch: any, getState: () => AuthState) => {
        if (!getState().currentUser.authenticated) {
            return;
        }
        const api = new VatRatesApi();
        const result = await loadAndTrack(dispatch, "loadClients", api.getAll());
        dispatch(loadVatRatesSuccess(result));
    };
}
