import * as React from "react";
import { Button } from "semantic-ui-react";
import { Modal } from "@momenta/common";

import { CreateForm } from "./CreateForm";
import { ColumnDefinition } from "./InlineEditTable";

interface CreateModalProps<T> {
    open: boolean;
    onClose?: () => void;
    save: (model: T) => Promise<void>;
    columns: ColumnDefinition<T>[];
    loading: boolean;
}

export class CreateModal<T> extends React.Component<CreateModalProps<T>> {

    private createForm: CreateForm<T>;

    public render() {

        const { open, onClose, columns, save, loading } = this.props;

        return (
            <Modal size="small" open={open}>
                <Modal.Header>Create</Modal.Header>
                <Modal.Content>
                    <CreateForm
                        save={save}
                        columns={columns}
                        ref={(instance: CreateForm<T>) => this.createForm = instance}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={loading} onClick={onClose} content="Cancel" icon="cancel" color="red" basic />
                    <Button disabled={loading} onClick={this.submit} content="Save" icon="save outline" color="green" />
                </Modal.Actions>
            </Modal>
        );
    }

    private submit = () => this.createForm.submit();
}
