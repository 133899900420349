import { connect } from "react-redux";
import { Dispatch } from "redux";
import { push } from "redux-little-router";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { basePathSelector } from "@momenta/common/referencing/selectors";
import { AppState, Reference } from "@momenta/common/referencing/model";
import { createReference } from "@momenta/common/referencing/actions";

import { CurrentUserState } from "../currentUser/model";

import { CreateReferenceModalProps, CreateReferenceModalUnconnected } from "./CreateReferenceModalUnconnected";

const mapStateToProps = (state: AppState & CurrentUserState & LoadingState, ownProps: any) => ({
    ...ownProps,
    open: state.router.pathname.startsWith("/referencing/create"),
    basePath: basePathSelector(state),
    loading: isRequestActive(state, "createReference"),
    start: state.router.query.start,
    end: state.router.query.end
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
    dispatchClose: (basePath: string) => dispatch(push(basePath)),
    dispatchCreate: (reference: Reference, basePath: string) => dispatch(createReference(reference, basePath))
});

const mergeProps = (propsFromState: any, propsFromDispatch: any): CreateReferenceModalProps => ({
    ...propsFromState,
    close: () => propsFromDispatch.dispatchClose(propsFromState.basePath),
    save: (reference: Reference) => propsFromDispatch.dispatchCreate(reference, propsFromState.basePath),
    buttonLabel: "Create",
    create: true
});

export const CreateReferenceModal = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateReferenceModalUnconnected);
