import { VatRate } from "@momenta/common/vatRates";

import { VatRateAction } from "./actions";
import { LOAD_VAT_RATES_SUCCESS } from "./actiontypes";

export function vatRates(state: VatRate[] = [], action: VatRateAction): VatRate[] {
    switch (action.type) {
        case LOAD_VAT_RATES_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
