import { ExperianAddressFormat } from "./lookup/model";
import { Address } from "./model";

export const mapExperianAddress = (address: ExperianAddressFormat) => {
    const mappedAddress: Address = {
        line1: address.addressLine1,
        line2: address.addressLine2,
        line3: address.addressLine3,
        city: address.locality,
        country: address.country,
        county: address.province,
        postcode: address.postalCode
    };

    return mappedAddress;
};
