import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

import { GridRowHeader } from "./GridRowHeader";

interface EditRelocationPreferencesProps {
    values: { willingToRelocate?: boolean };
    showErrors: boolean;
    onChange: (value: boolean, valid: boolean) => void;
}

export const EditRelocationPreferences: React.FC<EditRelocationPreferencesProps> = ({ values, showErrors, onChange }) => {

    return (
        <>
            <GridRowHeader text="Willing to relocate" />

            <Grid.Row>
                <Grid.Column>
                    <Input.Checkbox
                        label={values.willingToRelocate ? "Yes" : "No"}
                        value={values.willingToRelocate}
                        onChange={onChange}
                        showErrors={showErrors}
                        toggle
                    />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};
