import * as React from "react";
import { connect } from "react-redux";
import { isRequestActive, LoadingState } from "redux-request-loading";
import { PreferencesAndConsentDetailView } from "@momenta/common/preferencesAndConsent/PreferencesAndConsentDetailView";
import { Divider } from "semantic-ui-react";
import { TimezonePreference } from "@momenta/common/currentUser/TimezonePreference";

import { CurrentUser, CurrentUserState } from "../model";
import { loadCurrentUser, saveCurrentUser } from "../actions";
import { currentUserApi } from "../CurrentUserApi";

import { DataRightsRequestModal } from "./DataRightsRequestModal";

interface PreferencesAndConsentViewStateProps {
    currentUser: CurrentUser;
    loading: boolean;
}

interface PreferencesAndConsentDispatchProps {
    saveMarketingEmails: (id: number, receiveMarketingEmails: boolean) => Promise<void>;
    requestDataRights: (message: string) => Promise<void>;
    saveCurrentUser: (currentUser: CurrentUser) => Promise<void>;
}

export const PreferencesAndConsentViewUnconnected: React.SFC<PreferencesAndConsentViewStateProps & PreferencesAndConsentDispatchProps> =
    ({ currentUser, loading, saveMarketingEmails, requestDataRights, saveCurrentUser: save }) => {

        const saveTimezone = async (value: string) => {
            const user = { ...currentUser, timezone: value };
            await save(user);
        };

        // Neccessary because there is a type clash between the property "roles" on Associate and CurrentUser
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { roles, ...currentUserWithoutRoles } = currentUser;

        return (
            <>
                <TimezonePreference timezone={currentUser.timezone} save={saveTimezone} />

                <Divider />

                <PreferencesAndConsentDetailView
                    associate={currentUserWithoutRoles}
                    loading={loading}
                    saveMarketingEmails={saveMarketingEmails}
                    requestDataRights={requestDataRights}
                    dataRightsModal={<DataRightsRequestModal currentUser={currentUser} requestDataRights={requestDataRights} />}
                />
            </>
        );
    };

export const mapStateToProps = (state: CurrentUserState & LoadingState): PreferencesAndConsentViewStateProps => ({
    currentUser: state.currentUser,
    loading: isRequestActive(state, "loadCurrentUser")
});

export const mapDispatchToProps = (dispatch: any): PreferencesAndConsentDispatchProps => ({
    saveMarketingEmails: async (id: number, receiveMarketingEmails: boolean) => {
        await currentUserApi.saveMarketingEmails(id, receiveMarketingEmails);
        dispatch(loadCurrentUser());
    },
    requestDataRights: async (message: string) => currentUserApi.requestDataRights(message),
    saveCurrentUser: (currentUser: CurrentUser) => dispatch(saveCurrentUser(currentUser))
});

export const PreferencesAndConsentView = connect(mapStateToProps, mapDispatchToProps)(PreferencesAndConsentViewUnconnected);
