import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerForBrowser } from "redux-little-router";
import { reducer as loading } from "redux-request-loading";

import { routes } from "../routes";
import { AppState } from "../model";
import { syncConnectionWithStore } from "../connectionStatus";

import { middlewares } from "./middleware";
import { reducers } from "./reducers";

const {
    reducer,
    middleware,
    enhancer
} = routerForBrowser({
    routes
});

export const store = createStore<AppState, any, void, void>(
    combineReducers({
        ...reducers,
        loading,
        router: reducer,
    }),
    composeWithDevTools<any, any>(
        enhancer,
        applyMiddleware(...[...middlewares, middleware])
    )
);

syncConnectionWithStore(store);
