import { convertEnumToOptions, numberDropDownOptions, stringSort } from "@momenta/common";
import { LocalText } from "@momenta/common/internationalisation";
import { TimeMeasurementType } from "@momenta/common/model";
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "redux-little-router";
import { Button, Container, Form, Grid } from "semantic-ui-react";

import { saveCurrentUser } from "../currentUser/actions";

import { CurrentUser } from "../currentUser/model";

import { currentUserSelector } from "../currentUser/selector";

export const ApplicationSubmitted: React.FC = () => {

    const timeTypeOptions = convertEnumToOptions(TimeMeasurementType, k => +k)
        .filter(o => o.value > 10 && o.value < 40 && !isFinite(o.text))
        .sort(stringSort(r => r.text));

    const currentUserFromStore = useSelector(currentUserSelector);

    const [currentUser, setCurrentUser] = React.useState<CurrentUser>(currentUserFromStore);

    React.useEffect(() => setCurrentUser(currentUserFromStore), [currentUserFromStore]);

    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const getOnChange = (key: keyof CurrentUser) => {
        return (value: any, valid: boolean) => {
            if (valid) {
                setCurrentUser({ ...currentUser, [key]: value });
            }
        };
    };

    const onSubmit = async () => {
        setLoading(true);
        await dispatch(saveCurrentUser({ ...currentUser, externalApplicationComplete: true }));
        setLoading(false);
        if (currentUser.registrationComplete === false) {
            return dispatch(replace("/register"));
        }
        return dispatch(push("/"));
    };

    return (
        <Container text className="register">
            <h1>Your application has been submitted</h1>

            <Form noValidate loading={loading}>

                <LocalText keyName="applicationSubmittedText" />

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Input.Date
                                label="Available From"
                                onChange={getOnChange("availableFrom")}
                                value={currentUser.availableFrom}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row><Grid.Column width={3}><p><strong>Notice Period</strong></p></Grid.Column></Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Input.DropdownNumber
                                options={numberDropDownOptions(1, 12)}
                                value={currentUser.currentNoticePeriod}
                                onChange={getOnChange("currentNoticePeriod")}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Input.DropdownNumber
                                value={currentUser.currentNoticePeriodTimeType}
                                options={timeTypeOptions}
                                placeholder="Select Type"
                                onChange={getOnChange("currentNoticePeriodTimeType")}
                                dynamicOptions
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <p>Let us know about any upcoming holiday dates, so that we can plan start dates and training around you.</p>

                <Input.Textarea
                    label="Holidays"
                    value={currentUser.holiday}
                    onChange={getOnChange("holiday")}
                />

                <Button
                    icon="save outline"
                    color="green"
                    content="Save and Continue"
                    floated="right"
                    disabled={loading}
                    loading={loading}
                    onClick={onSubmit}
                />
            </Form>
        </Container>
    );
};
