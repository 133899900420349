import moment from "moment";
import { Reference, ReferenceConfiguration } from "@momenta/common/referencing/model";

import { REFERENCE_DURATION, REFERENCE_GAP, REFERENCE_QUANTITY } from "./messages";

export const validateReferences = (references: Reference[], referenceConfiguration: ReferenceConfiguration) => {

    const filteredReferences = references.filter(r => r.gap === undefined || r.gap === false);

    if (filteredReferences.length < 2) {
        return REFERENCE_QUANTITY(referenceConfiguration.referencingPeriod);
    }

    if (references.some(r => r.gap)) {
        return REFERENCE_GAP;
    }

    const totalCovered = getTotalMonthsCovered(filteredReferences, referenceConfiguration);

    if (totalCovered === false) {
        return REFERENCE_DURATION(referenceConfiguration.referencingPeriod);
    }

    return "";
};

export const getTotalMonthsCovered = (references: Reference[], referenceConfiguration: ReferenceConfiguration): boolean => {

    const start = references[references.length - 1].start;
    const contractStart = referenceConfiguration.contractStartDate || moment();
    const referenceStart = contractStart.clone().add(-referenceConfiguration.referencingPeriod, "months");
    const startGap = start && start.diff(referenceStart, "days");

    return startGap < referenceConfiguration.nonEmploymentPeriod;
};
