import * as React from "react";
import { connect } from "react-redux";
import { Header, Message } from "semantic-ui-react";
import { Location, push } from "redux-little-router";
import { LoadingState, makeAreRequestsActive } from "redux-request-loading";
import moment from "moment";
import { AppState, Timesheet, TimesheetStateEnum, TimesheetView } from "@momenta/common/timesheets";
import { BonusTypeConfiguration, ConfigurationState, ExpenseTypeConfiguration, TimeTypeConfiguration } from "@momenta/common/hierarchicalConfiguration";
import { toast } from "@momenta/common/toasts";

import { ConnectionState } from "../connectionStatus/model";
import { VatRate, vatRatesSelector, VatRatesState } from "../vat";

import { timesheetSelector } from "./selectors";
import { saveTimesheet, submitTimesheet } from "./actions";
import { timesheetStorage } from "./TimesheetStorage";
import { AdjustmentTimesheetView } from "./AdjustmentTimesheetView";
import { TimesheetEdit } from "./TimesheetEdit";

interface TimesheetDetailStateProps {
    timesheet: Timesheet;
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    timeTypeConfigurations: TimeTypeConfiguration[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
    online: boolean;
    loading: boolean;
    vatRates: VatRate[];
}

interface TimesheetDetailDispatchProps {
    save: (model: Timesheet, submit: boolean) => Promise<void>;
    saveToStorage: (model: Timesheet) => Promise<Timesheet>;
}

export const TimesheetDetailUnconnected: React.FC<TimesheetDetailStateProps & TimesheetDetailDispatchProps> =
({
    timesheet,
    expenseTypeConfigurations,
    timeTypeConfigurations,
    bonusTypeConfigurations,
    online,
    loading,
    save,
    saveToStorage,
    vatRates
}) => (
    <>
        {timesheet && timesheet.isAdjusted &&
            <Message warning size="small">
                <Header textAlign="center">Adjustment Reason</Header>
                <p>Reason: {timesheet.adjustmentReason}</p>
            </Message>
        }
        {timesheet && timesheet.state === TimesheetStateEnum.Rejected &&
            <Message error size="big">
                <Header textAlign="center" icon="close" content={`Timesheet Rejected by ${timesheet.approver}`} />
                <p>Reason: {timesheet.rejectionReason}</p>
            </Message>
        }

        {timesheet && timesheet.isAdjusted &&
            <Message success size="big">
                <Header content="Original Timesheet" textAlign="center" />
            </Message>
        }

        {timesheet && timesheet.isAdjusted
            ? <TimesheetView
                timesheet={timesheet.originalTimesheet}
                expenseTypeConfigurations={expenseTypeConfigurations}
                timeTypeConfigurations={timeTypeConfigurations}
                bonusTypeConfigurations={bonusTypeConfigurations}
            />
            : <TimesheetEdit
                timesheet={timesheet}
                expenseTypeConfigurations={expenseTypeConfigurations}
                timeTypeConfigurations={timeTypeConfigurations}
                bonusTypeConfigurations={bonusTypeConfigurations}
                online={online}
                loading={loading}
                save={save}
                saveToStorage={saveToStorage}
                vatRates={vatRates}
            />
        }

        {timesheet && timesheet.isAdjusted &&
            <AdjustmentTimesheetView
                timesheet={timesheet}
                online={online}
                bonusTypeConfigurations={bonusTypeConfigurations}
                expenseTypeConfigurations={expenseTypeConfigurations}
                timeTypeConfigurations={timeTypeConfigurations}
                submit={save}
            />
        }
    </>
);

const areRequestsActive = makeAreRequestsActive(["loadTimesheet", "loadConfiguration"]);

const mapStateToProps = (state: AppState & ConfigurationState & ConnectionState & LoadingState & VatRatesState) => ({
    timesheet: timesheetSelector(state),
    timeTypeConfigurations: state.timeTypeConfiguration,
    expenseTypeConfigurations: state.expenseTypeConfiguration,
    bonusTypeConfigurations: state.bonusTypeConfiguration,
    online: state.connection.online,
    loading: areRequestsActive(state),
    previous: state.router.previous,
    vatRates: vatRatesSelector(state)
});

const mapDispatchToProps = (dispatch: any) => ({
    save: async (model: Timesheet, submit: boolean, previous: Location) => {
        if (submit) {
            await dispatch(submitTimesheet({ ...model, state: TimesheetStateEnum.Submitted, dateSubmitted: moment() }));
            dispatch(push(previous || "/timesheets"));
            toast.success("Timesheet submitted");
        } else {
            await dispatch(saveTimesheet(model));
        }
    },
    saveToStorage: (timesheet: Timesheet) => timesheetStorage.save(timesheet)
});

const mergeProps = (
    { previous, ...propsFromState }: ReturnType<typeof mapStateToProps>,
    { save, saveToStorage }: ReturnType<typeof mapDispatchToProps>
): TimesheetDetailStateProps & TimesheetDetailDispatchProps => ({
    ...propsFromState,
    saveToStorage,
    save: (model, submit) => save(model, submit, previous)
});

export const TimesheetDetail = connect(mapStateToProps, mapDispatchToProps, mergeProps)(TimesheetDetailUnconnected);
