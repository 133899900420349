import * as React from "react";
import { Grid } from "semantic-ui-react";

import { Company } from "../companies";

import { AddressColumn } from "./AddressColumn";
import { DetailColumn } from "./DetailColumn";

interface CompanyDetailProps {
    company: Company;
}

export const CompanyDetail: React.FC<CompanyDetailProps> = ({ company }) => (
    <Grid>
        <Grid.Row>
            <Grid.Column width={16}><h1>Company Details</h1></Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <AddressColumn width={4} label="Company Address" address={company && company.address && company.address} />
            <DetailColumn width={4} label="Company Name" value={company && company.name} />
            <DetailColumn width={4} label="Account Number" value={company && company.accountNumber} />
            <DetailColumn width={4} label="Sort Code" value={company && company.sortCode} />
        </Grid.Row>
        <Grid.Row>
            <DetailColumn width={4} label="Company Number" value={company && company.registrationNumber} />
            <DetailColumn width={4} label="VAT Number" value={company && company.vatDetails && company.vatDetails.vatNumber} />
            {company && company.umbrella === false &&
                <DetailColumn
                    width={4}
                    label="Registered For VAT"
                    value={
                        company &&
                        company.vatDetails &&
                        company.vatDetails.dateRegistered &&
                        company.vatDetails.dateRegistered.format("L")
                    }
                />
            }
            <DetailColumn width={4} label="Company Type" value={company && company.umbrella ? "Umbrella" : "LTD"} />
        </Grid.Row>
        <Grid.Row>
            {company && company.umbrella === false &&
                <DetailColumn width={4} label="Finance Id" value={company && company.financeId} />
            }
            <DetailColumn width={4} label="Managing Director" value={company && company.managingDirector} />
            <DetailColumn width={4} label="Managing Director Email" value={company && company.managingDirectorEmail} />
        </Grid.Row>
    </Grid>
);
