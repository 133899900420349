import moment from "moment";
import axios from "axios";

import { Reference, ReferenceConfiguration, ReferenceType } from "./model";

export class ReferenceApi {

    public static parseModel(model: Reference, detail: boolean = false): Reference {
        return {
            ...model,
            start: model.start && moment(model.start),
            end: model.end && moment(model.end),
            referenceSentDate: model.referenceSentDate && moment(model.referenceSentDate),
            referenceReceivedDate: model.referenceReceivedDate && moment(model.referenceReceivedDate),
            detail
        };
    }

    private readonly apiUrl = "/api/reference";

    public async getAll(): Promise<Reference[]> {
        const response = await axios.get(this.apiUrl);
        const model = response.data as Reference[];
        return model.map(m => ReferenceApi.parseModel(m));
    }

    public async get(referenceId: number): Promise<Reference> {
        const response = await axios.get(`${this.apiUrl}/${referenceId}`);
        const model = response.data as Reference;
        return ReferenceApi.parseModel(model, true);
    }

    public async create(reference: Reference): Promise<Reference> {
        const url = this.apiTypeIdentifier(reference);
        const response = await axios.post(url, reference);
        const model = response.data as Reference;
        return ReferenceApi.parseModel(model);
    }

    public async update(reference: Reference): Promise<Reference> {
        const url = this.apiTypeIdentifier(reference);
        const response = await axios.put(`${url}/${reference.id}`, reference);
        const model = response.data as Reference;
        return ReferenceApi.parseModel(model);
    }

    public async delete(id: number): Promise<boolean> {
        const response = await axios.delete(`${this.apiUrl}/${id}`);
        return response.data as boolean;
    }

    public async submit(referenceIds: number[]): Promise<boolean> {
        const response = await axios.post(`${this.apiUrl}`, referenceIds);
        return response.data as boolean;
    }

    public async getReferenceConfiguration(): Promise<ReferenceConfiguration> {
        const response = await axios.get("/api/referencingconfiguration");
        return this.parseReferenceConfigurationModel(response.data as ReferenceConfiguration);
    }

    private parseReferenceConfigurationModel = (config: ReferenceConfiguration) => {
        return {
            ...config,
            contractStartDate: config.contractStartDate && moment(config.contractStartDate)
        };
    }

    private apiTypeIdentifier(reference: Reference) {
        switch (reference.referenceType) {
            case ReferenceType.Academic:
                return "/api/academicreference";
            case ReferenceType.Employment:
                return "/api/employmentreference";
            case ReferenceType.Gap:
                return "/api/gapreference";
            default:
                throw new Error("should have reference type set");
        }
    }
}
