import axios, { AxiosStatic } from "axios";
import moment from "moment";
import { PageResult } from "@momenta/common";

import { MomentaDocumentVettingData, ProofDocumentVettingData, DocumentVettingData } from "../vetting/model";

export class VettingItemApi {
    private axios: AxiosStatic;

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async loadAllProofDocument(): Promise<PageResult<ProofDocumentVettingData>> {
        const response = await this.axios.get("/api/proofdocumentvettingdata");
        const { items, count } = response.data as PageResult<ProofDocumentVettingData>;
        return {
            items: items.map(this.parseProofDocument),
            count
        };
    }

    public async loadAllMomentaDocument(): Promise<PageResult<MomentaDocumentVettingData>> {
        const response = await this.axios.get("/api/momentadocumentvettingdata");
        const { items, count } = response.data as PageResult<MomentaDocumentVettingData>;
        return {
            items: items.map(this.parseMomentaDocument),
            count
        };
    }

    public async saveProofDocument(vettingItemId: number, model: ProofDocumentVettingData): Promise<ProofDocumentVettingData> {
        const response = await this.axios.put(`/api/proofdocumentvettingdata/${vettingItemId}`, model);
        return this.parseProofDocument(response.data as ProofDocumentVettingData);
    }

    public async saveMomentaDocument(vettingItemId: number, model: MomentaDocumentVettingData): Promise<MomentaDocumentVettingData> {
        const response = await this.axios.put(`/api/momentadocumentvettingdata/${vettingItemId}`, model);
        return this.parseMomentaDocument(response.data as MomentaDocumentVettingData);
    }

    public async createFastTrackVettingItems(): Promise<{}> {
        const response = await this.axios.post("/api/momentadocumentvettingdata/CreateFastTrackVettingItems");
        return response;
    }

    public parseProofDocument(model: ProofDocumentVettingData): ProofDocumentVettingData {

        let created;
        let updated;
        let expiryDate;
        let vettingItem;

        if (model.vettingItem != null) {

            if (model.vettingItem.created !== undefined) {
                created = moment(model.vettingItem.created);
                updated = moment(model.vettingItem.updated);
                expiryDate = moment(model.vettingItem.expiryDate);
            }

            vettingItem = {
                ...model.vettingItem,
                created,
                updated,
                expiryDate
            };
        }

        return {
            ...model,
            dateSeen: moment(model.dateSeen),
            documentProvidedDate: moment(model.documentProvidedDate),
            vettingItem
        };
    }

    public parseMomentaDocument(model: MomentaDocumentVettingData): MomentaDocumentVettingData {

        let created;
        let updated;
        let expiryDate;
        let vettingItem;

        if (model.vettingItem != null) {

            if (model.vettingItem.created !== undefined) {
                created = moment(model.vettingItem.created);
                updated = moment(model.vettingItem.updated);
                expiryDate = moment(model.vettingItem.expiryDate);
            }

            vettingItem = {
                ...model.vettingItem,
                created,
                updated,
                expiryDate
            };
        }

        return {
            ...model,
            documentProvidedDate: moment(model.documentProvidedDate),
            vettingItem
        };
    }
}

export const vettingItemApi = new VettingItemApi(axios);
