import * as React from "react";
import { Button, Form, Grid, Header, Icon, Popup, Message } from "semantic-ui-react";
import { debounce } from "@neworbit/simpleui-utils";
import {
    getFormattedMessage,
    isNotNullOrUndefined,
    LoadingWrapper,
    wait
} from "@momenta/common";
import { validateObject } from "@momenta/common/validateObject";
import { Bonus, BonusesEdit } from "@momenta/common/bonuses";
import { Expense, ExpensesEdit } from "@momenta/common/expenses";
import { Timesheet, TimesheetDay, TimesheetDaysEdit, timesheetEntryOptions, TimesheetStateEnum } from "@momenta/common/timesheets";
import { BonusTypeConfiguration, ExpenseTypeConfiguration, TimeTypeConfiguration } from "@momenta/common/hierarchicalConfiguration";
import { FixedActions } from "@momenta/common/fixedActions";
import { ValidationState } from "@momenta/common/validationState";
import { getTimesheetDateRange } from "@momenta/common/timesheets/getEndDateTimesheet";

import { VatRate } from "../vat";

interface TimesheetEditProps {
    timesheet: Timesheet;
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    timeTypeConfigurations: TimeTypeConfiguration[];
    bonusTypeConfigurations: BonusTypeConfiguration[];
    online: boolean;
    loading: boolean;
    save: (model: Timesheet, submit: boolean) => Promise<void>;
    saveToStorage: (model: Timesheet) => Promise<Timesheet>;
    vatRates: VatRate[];
}

interface TimesheetEditState {
    timesheet: Timesheet;
    saving: boolean;
    saved: boolean;
    offlineMessage: boolean;
    offlineSubmitMessage: boolean;
    valid: ValidationState;
    showErrors: boolean;
    submitting: boolean;
    loading: boolean;
    savingExpense: boolean;
}

class TimesheetEdit extends React.Component<TimesheetEditProps, TimesheetEditState> {

    public static getDerivedStateFromProps({ timesheet, loading }: TimesheetEditProps, previous: TimesheetEditState) {

        const newTimesheet = previous.timesheet && previous.timesheet.timesheetDays && previous.timesheet.timesheetDays.length
            ? {
                ...previous.timesheet,
                state: timesheet && timesheet.state,
                expenses: previous.timesheet.expenses && previous.timesheet.expenses.map(e => {
                    const savedExpense = timesheet.expenses.filter(x => x.correlationId === e.correlationId)[0];
                    const id = savedExpense ? savedExpense.id : e.id;

                    return {
                        ...e,
                        id,
                        previousReceiptExpense: savedExpense && savedExpense.previousReceiptExpense
                    };
                })
            }
            : timesheet;

        return {
            ...previous,
            timesheet: newTimesheet,
            loading
        };
    }

    private saveDebounced: (submit?: boolean) => Promise<void>;
    constructor(props: TimesheetEditProps) {
        super(props);

        this.state = {
            timesheet: props.timesheet,
            saving: false,
            saved: false,
            offlineMessage: false,
            offlineSubmitMessage: false,
            valid: {},
            showErrors: false,
            submitting: false,
            loading: false,
            savingExpense: false
        };

        this.updateDays = this.updateDays.bind(this);
        this.submitClick = this.submitClick.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onOfflineMessageOpen = this.onOfflineMessageOpen.bind(this);
        this.onOfflineMessageClose = this.onOfflineMessageClose.bind(this);
        this.submitMessageOpen = this.submitMessageOpen.bind(this);
        this.saveDebounced = debounce(this.save as any, 1500).bind(this);
    }

    public render() {
        const { saving } = this.state;
        const timesheet = this.props.timesheet?.timesheetEntryDenied ? this.props.timesheet : this.state.timesheet;
        const submitted = timesheet && timesheet.state >= TimesheetStateEnum.Submitted;
        const disableEditing = submitted || this.props.timesheet?.timesheetEntryDenied;
        const isNotAdjustment = timesheet && !timesheet.isAdjusted;
        const { online } = this.props;
        const submitDisabled = disableEditing || saving || this.state.loading;

        return (
            <LoadingWrapper loading={(this.state.loading) || this.state.submitting}>
                {timesheet !== undefined && (
                    <FixedActions className="timesheet-detail">
                        <h1>Timesheet for {timesheet && getTimesheetDateRange(timesheet)}</h1>
                        <>{getFormattedMessage(timesheet.invoicePeriod.candidate.role.project.timesheetInformation)}</>
                        <Form noValidate>
                            <TimesheetDaysEdit
                                timesheetDays={timesheet && timesheet.timesheetDays}
                                timeTypeConfigurations={this.props.timeTypeConfigurations}
                                projectTimeType={timesheet.invoicePeriod.candidate.role.project.timeEntryType}
                                daysUpdated={this.updateDays}
                                submitted={disableEditing}
                                showErrors={this.state.showErrors}
                                timesheetDaysValid={this.state.valid.timesheetDays as ValidationState}
                                timesheetEntryOptions={timesheetEntryOptions}
                                isAdjustment={timesheet.isAdjusted}
                                workedHoursRequired={timesheet.invoicePeriod.candidate.role.project.workedHoursRequired}
                            />

                            {this.props.bonusTypeConfigurations.length !== 0 && timesheet && (
                                <BonusesEdit
                                    bonuses={timesheet.bonuses}
                                    bonusesValid={this.state.valid.bonuses as ValidationState}
                                    bonusTypeConfigurations={this.props.bonusTypeConfigurations}
                                    bonusesUpdated={this.updateBonuses}
                                    submitted={disableEditing}
                                    showErrors={this.state.showErrors}
                                    isAdjustment={timesheet.isAdjusted}
                                    timesheetStart={timesheet.start}
                                />
                            )}

                            {this.props.expenseTypeConfigurations.length !== 0 && (
                                <ExpensesEdit
                                    timesheetId={timesheet.id}
                                    start={timesheet.start}
                                    end={timesheet.end}
                                    expenses={timesheet.expenses}
                                    expensesValid={this.state.valid.expenses as ValidationState}
                                    expenseTypeConfigurations={this.props.expenseTypeConfigurations}
                                    expensesChanged={this.onExpensesChanged}
                                    submitted={disableEditing}
                                    online={online}
                                    showErrors={this.state.showErrors}
                                    saving={this.state.saving || this.state.loading}
                                    isAdjustment={timesheet.isAdjusted}
                                    vatRates={this.props.vatRates}
                                    isInvoiced={isNotNullOrUndefined(timesheet.invoicePeriod.associateInvoiceBatchId)}
                                    timesheetState={timesheet.state}
                                />
                            )}
                            {isNotAdjustment &&
                                <FixedActions.Bar className="timesheet-actions">
                                    <Grid.Column width={8} verticalAlign="middle">
                                        {this.state.saving &&
                                            <Header as="h4">Saving <Icon loading name="spinner" /></Header>
                                        }

                                        {this.state.saving === false &&
                                            <Message warning visible>
                                                <Icon name="warning sign" />Only Submit once you have completed a full week
                                            </Message>
                                        }
                                        {this.state.saved && this.props.online &&
                                            <Header as="h4" color="green">Saved <Icon name="check" /></Header>
                                        }
                                        {(this.state.saved || this.state.offlineMessage) && !this.props.online &&
                                            <Popup
                                                hideOnScroll
                                                open={this.state.offlineMessage}
                                                onOpen={this.onOfflineMessageOpen}
                                                onClose={this.onOfflineMessageClose}
                                                trigger={<Header disabled as="h4">Saved offline <Icon name="info" /></Header>}
                                                content={"Your timesheet is saved to your device when you're offline.  " +
                                                    "You'll need to open the app whilst online to sync with our server"
                                                }
                                            />
                                        }
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Popup
                                            hideOnScroll
                                            open={this.state.offlineSubmitMessage}
                                            onOpen={this.submitMessageOpen}
                                            onClose={this.submitMessageClose}
                                            content="You cannot submit your timesheet while your are offline.  Please try again when you are online."
                                            trigger={<Button
                                                disabled={submitDisabled}
                                                loading={saving}
                                                icon="send"
                                                content="Submit"
                                                color="green"
                                                floated="right"
                                                className={online ? "" : "disabled clickable"}
                                                onClick={this.submitClick}
                                            />}
                                        />
                                    </Grid.Column>
                                </FixedActions.Bar>
                            }
                        </Form>
                    </FixedActions>
                )}
            </LoadingWrapper>
        );
    }

    private async submitClick() {

        if (!this.isValid()) {
            this.setState({ showErrors: true });
            return;
        }

        if (this.props.online) {
            this.setState({ saving: true, submitting: true });
            await this.saveDebounced(true);
        } else {
            await this.submitMessageOpen();
        }
    }

    private async submitMessageOpen() {
        if (!this.props.online) {
            this.setState({ offlineSubmitMessage: true });
            await wait(5000);
            this.setState({ offlineSubmitMessage: false });
        }
    }

    private submitMessageClose = () => this.setState({ offlineSubmitMessage: false });

    private async onOfflineMessageOpen() {
        this.setState({ offlineMessage: true });
        await wait(5000);
        this.setState({ offlineMessage: false });
    }

    private onOfflineMessageClose = () => this.setState({ offlineMessage: false });

    private async updateDays(timesheetDays: TimesheetDay[], timesheetDayValid: ValidationState) {
        await this.setState(current => {
            return {
                timesheet: {
                    ...current.timesheet,
                    timesheetDays,
                    synced: false
                },
                valid: {
                    ...current.valid,
                    timesheetDays: timesheetDayValid
                },
                saved: false,
                saving: current.timesheet.state < TimesheetStateEnum.Submitted
            };
        }, this.handleSave);
    }

    private updateBonuses = (bonuses: Bonus[], bonusesValid: ValidationState) => {
        this.setState(current => {
            return {
                timesheet: {
                    ...current.timesheet,
                    bonuses
                },
                valid: {
                    ...current.valid,
                    bonuses: bonusesValid
                },
                saved: false,
                saving: current.timesheet.state < TimesheetStateEnum.Submitted
            };
        }, this.handleSave);
    }

    private onExpensesChanged = (expenses: Expense[], expensesValid: ValidationState, receiptUpdated: boolean) => {
        this.setState(current => ({
            timesheet: {
                ...current.timesheet,
                expenses,
                synced: false
            },
            valid: {
                ...current.valid,
                expenses: expensesValid
            },
            saved: false,
            saving: current.timesheet.state < TimesheetStateEnum.Submitted,
        }), () => this.handleSave(false, receiptUpdated));
    }

    private async save(submit: boolean = false) {
        const promises: Promise<any>[] = [];

        if (!this.isValid() || this.state.timesheet.timesheetEntryDenied) {
            this.setState({
                showErrors: true,
                saving: false
            });
            return;
        }

        if (this.props.online) {
            promises.push(this.props.save(this.state.timesheet, submit));
        }

        if (!submit) {
            promises.push(this.props.saveToStorage(this.state.timesheet));
        }

        await Promise.all(promises);

        if (!submit) {
            this.setState({ saving: false, saved: true });
            await wait(5000);
            this.setState({ saved: false });
        }
    }

    private handleSave(shouldDebounce: boolean = true, receiptUpdated: boolean = false) {
        if (this.state.timesheet.state >= TimesheetStateEnum.Submitted && receiptUpdated === false) {
            return;
        }

        if (shouldDebounce) {

            this.saveDebounced();
        } else {
            this.save();
        }
    }

    private isValid(): boolean {
        return validateObject(this.state.valid);
    }
}

export {
    TimesheetEdit
};
