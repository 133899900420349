import axios from "axios";
import moment from "moment";
import { VatRate } from "@momenta/common/vatRates";

export class VatRatesApi {

    private readonly apiUrl = "/api/vatrates";

    public async getAll(): Promise<VatRate[]> {
        const response = await axios.get<VatRate[]>(this.apiUrl);
        return response.data.map(this.parseData);
    }

    private parseData(vatRate: VatRate): VatRate {
        return {
            ...vatRate,
            start: moment(vatRate.start),
            end: vatRate.end ? moment(vatRate.end) : vatRate.end
        };
    }
}
