import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { ComponentVisible } from "@momenta/common/internationalisation";

import { CurrentUser } from "./model";
import { GridRowHeader } from "./GridRowHeader";

interface EmergencyContactEditProps {
    values: CurrentUser;
    showErrors: boolean;
    onChange: (prop: string, value: string, valid: boolean) => void;
}

const EmergencyContactEdit: React.FC<EmergencyContactEditProps> = ({ values, showErrors, onChange }) => {

    const onRelationshipChange = (value: string, valid: boolean) => { onChange("emergencyContact.relationship", value, valid); };
    const onEmergencyContactForenameChange = (value: string, valid: boolean) => { onChange("emergencyContact.forename", value, valid); };
    const onEmergencyContactSurnameChange = (value: string, valid: boolean) => { onChange("emergencyContact.surname", value, valid); };
    const onEmergencyContactNumberChange = (value: string, valid: boolean) => { onChange("emergencyContact.contactNumber", value, valid); };
    const onEmergencyContactAddressChange = (value: string, valid: boolean) => { onChange("emergencyContact.address", value, valid); };

    return (
        <>
            <GridRowHeader text="Emergency Contact Details" />

            <Grid.Row>
                <Grid.Column computer={6} mobile={16} tablet={8}>
                    <Input.Text
                        value={values.emergencyContact && values.emergencyContact.relationship}
                        label="Relationship"
                        showErrors={showErrors}
                        onChange={onRelationshipChange}
                    />
                </Grid.Column>

                <Grid.Column computer={5} mobile={16} tablet={8}>
                    <Input.Text
                        value={values.emergencyContact && values.emergencyContact.forename}
                        label="Forename"
                        showErrors={showErrors}
                        onChange={onEmergencyContactForenameChange}
                    />
                </Grid.Column>

                <Grid.Column computer={5} mobile={16} tablet={8}>
                    <Input.Text
                        value={values.emergencyContact && values.emergencyContact.surname}
                        label="Surname"
                        showErrors={showErrors}
                        onChange={onEmergencyContactSurnameChange}
                    />
                </Grid.Column>

                <Grid.Column computer={5} mobile={16} tablet={8}>
                    <Input.PhoneNumber
                        value={values.emergencyContact && values.emergencyContact.contactNumber}
                        label="Phone Number"
                        showErrors={showErrors}
                        onChange={onEmergencyContactNumberChange}
                    />
                </Grid.Column>

                <ComponentVisible keyName="emergencyContactAddress">
                    <Grid.Column computer={5} mobile={16} tablet={8}>
                        <Input.Text
                            value={values.emergencyContact && values.emergencyContact.address}
                            label="Address"
                            showErrors={showErrors}
                            onChange={onEmergencyContactAddressChange}
                        />
                    </Grid.Column>
                </ComponentVisible>

            </Grid.Row>
        </>
    );
};

export {
    EmergencyContactEdit
} ;
