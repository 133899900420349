import { FileUpload } from "@momenta/common";
import { AssociateQualification } from "@momenta/common/qualifications/model";
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnDefinition, InlineEditTable } from "src/App/People/src/inlineEdit/InlineEditTable";

import { createAssociateQualification, deleteAssociateQualification, loadAllAssociateQualifications, updateAssociateQualification } from "./actions";
import { associateQualificationsSelector } from "./selector";

const CodeTable = InlineEditTable as new() => InlineEditTable<AssociateQualification>;

const getFileChange = (onChange: any) => { return (file: string, valid: boolean) => { onChange(file, valid); }; };

const columns: ColumnDefinition<AssociateQualification>[] = [
    {
        label: "Type",
        property: "type",
        render: (value: string) => value,
        edit: (value: string, onChange, label, showErrors) => (
            <Input.Text
                value={value}
                onChange={onChange}
                label={label}
                showErrors={showErrors}
                required
            />

        )
    },
    {
        label: "Institution",
        property: "institution",
        render: (value: string) => value,
        edit: (value: string, onChange, label, showErrors) => (
            <Input.Text
                value={value}
                onChange={onChange}
                label={label}
                showErrors={showErrors}
                required
            />
        )
    },
    {
        label: "File",
        property: "file",
        render: (value: string) => { return (<a href={`api/qualification/download/${value}`} target="blank">{value}</a> ); },
        edit: (value: string, onChange, label, showErrors) => (
            <FileUpload
                value={value}
                apiUrl={"/api/qualification/upload"}
                fileTypes={[".pdf", ".doc", ".docx", ".xml", ".rtf", ".txt"]}
                onFileChange={getFileChange(onChange)}
                showErrors={showErrors}
                required
            />
        ),
    }
];

export const AssociateQualificationsCodeTable = () => {

    const data = useSelector(associateQualificationsSelector);

    const dispatch = useDispatch();

    const allowEdit = (qualification: AssociateQualification) => qualification.verified === false;

    const createQualification = async (model: AssociateQualification) => {
        await dispatch(createAssociateQualification(model));
    };

    const deleteQualification = async (id: number) => {
        await dispatch(deleteAssociateQualification(id));
    };

    const editQualification = async (model: AssociateQualification) => {
        await dispatch(updateAssociateQualification(model));
        await dispatch(loadAllAssociateQualifications);
    };

    const subheading = `Please upload evidence of any tertiary education and/or professional 
    qualifications you have listed on your CV/Resume. This can be certificates or, if you are studying, enrolment letters, transcripts etc.`;

    return (
        <CodeTable
            title="Certificates and Qualifications"
            subheading={subheading}
            columns={columns}
            data={data}
            keyProp="id"
            onCreate={createQualification}
            onEdit={editQualification}
            onDelete={deleteQualification}
            canDelete={allowEdit}
            canEdit={allowEdit}
            buttonLabel="New"
        />
    );
};
