import { Expense } from "@momenta/common/expenses";

import { AssociateQualification } from "@momenta/common/qualifications/model";

import * as actions from "./actionTypes";
import { QualificationActions } from "./actions";

export function associateQualifications(state: AssociateQualification[] = [], action: QualificationActions): Expense[] {
    switch (action.type) {
        case actions.LOAD_QUALIFICATIONS_SUCCESS:
            return action.payload;
        case actions.SAVE_QUALIFICATION_SUCCESS:
            return [...state.filter(a => a.id !== action.payload.id), action.payload];
        case actions.DELETE_QUALIFICATION_SUCCESS:
            return state.filter(a => a.id !== action.payload);
        default:
            return state;
    }
}
