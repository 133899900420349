import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { ValidationResult } from "not-valid";
import { componentVisible, ComponentVisible, localTextSelector } from "@momenta/common/internationalisation";
import { compose } from "recompose";
import { connect } from "react-redux";
import { DetailColumn } from "@momenta/common/companies/DetailColumn";

import { AppState } from "../model";

import { CurrentUser } from "./model";
import { GridRowHeader } from "./GridRowHeader";
import { onContractSelector } from "./selector";

interface BankDetailsEditProps {
    values: CurrentUser;
    showErrors: boolean;
    onChange: (prop: string, value: string, valid: boolean) => void;
}

interface BankDetailsEditStateProps {
    onContract: boolean;
    bankCodeName: string;
    bankCodeValidator: ((value: string) => ValidationResult)[];
    accountNumberValidator: ((value: string) => ValidationResult)[];
}

const BankDetailsEditUnconnected: React.FC<BankDetailsEditProps & BankDetailsEditStateProps> = (
    {   values,
        showErrors,
        onChange,
        onContract,
        bankCodeName,
        bankCodeValidator,
        accountNumberValidator
    }) => {

    const updateProperty = (property: string) => {
        return (value: string, valid: boolean) => onChange(property, value, valid);
    };

    return (
        <>
            <GridRowHeader text="Bank Details" />
            <Grid.Row>
                <Grid.Column computer={6} mobile={16} tablet={16}>
                    <ComponentVisible keyName="showBankAccountName" >
                        {onContract === true
                            ? <DetailColumn label="Bank Account Name" value={values.bankDetails && values.bankDetails.bankAccountName} />
                            : <Input.Text
                                value={values.bankDetails && values.bankDetails.bankAccountName}
                                label="Bank Account Name"
                                onChange={updateProperty("bankDetails.bankAccountName")}
                                disableAutocorrect
                                showErrors={showErrors}
                            />
                        }
                    </ComponentVisible>
                    <ComponentVisible keyName="showBankName" >
                        {onContract === true
                            ? <DetailColumn label="Bank Name" value={values.bankDetails && values.bankDetails.bankAccountName} />
                            : <Input.Text
                                value={values.bankDetails && values.bankDetails.bankName}
                                label="Bank Name"
                                onChange={updateProperty("bankDetails.bankName")}
                                disableAutocorrect
                                showErrors={showErrors}
                            />
                        }
                    </ComponentVisible>
                </Grid.Column>
                <Grid.Column computer={5} mobile={16} tablet={8}>
                    {onContract === true
                        ? <DetailColumn label={bankCodeName} value={values.bankDetails && values.bankDetails.bsbCode} />
                        : <Input.Text
                            value={values.bankDetails && values.bankDetails.bsbCode}
                            showErrors={showErrors}
                            label={bankCodeName}
                            onChange={updateProperty("bankDetails.bsbCode")}
                            validation={bankCodeValidator}
                        />
                    }
                </Grid.Column>
                <Grid.Column computer={5} mobile={16} tablet={8}>
                    {onContract === true
                        ? <DetailColumn label="Account Number" value={values.bankDetails && values.bankDetails.accountNumber} />
                        : <Input.Text
                            value={values.bankDetails && values.bankDetails.accountNumber}
                            showErrors={showErrors}
                            label="Account Number"
                            onChange={updateProperty("bankDetails.accountNumber")}
                            validation={accountNumberValidator}
                        />
                    }
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column computer={6} mobile={16} tablet={16}>
                    <ComponentVisible keyName="showBranchName" >
                        {onContract === true
                            ? <DetailColumn label="Bank Account Name" value={values.bankDetails && values.bankDetails.bankAccountName} />
                            : <Input.Text
                                value={values.bankDetails && values.bankDetails.branch}
                                label="Branch"
                                onChange={updateProperty("bankDetails.branch")}
                                disableAutocorrect
                                showErrors={showErrors}
                            />
                        }
                    </ComponentVisible>
                </Grid.Column>
            </Grid.Row>
        </>
    );
};

const mapStateToProps = (state: AppState): BankDetailsEditStateProps => ({
    onContract: onContractSelector(state),
    bankCodeName: localTextSelector(state, "bankCodeName"),
    bankCodeValidator: localTextSelector(state, "bankCodeValidator"),
    accountNumberValidator: localTextSelector(state, "accountNumberValidator")
});

export const BankDetailsEdit = compose<BankDetailsEditProps & BankDetailsEditStateProps, BankDetailsEditProps>(
    componentVisible("showBankDetails"),
    connect(mapStateToProps)
)(BankDetailsEditUnconnected);
