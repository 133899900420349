import * as React from "react";
import { ReferenceConfiguration } from "@momenta/common/referencing/model";
import { Message } from "semantic-ui-react";
import { localTextSelector, RegionState } from "@momenta/common/internationalisation";
import { connect } from "react-redux";

interface ReferencingHeaderStateProps {
    content: (referenceConfiguration: ReferenceConfiguration) => JSX.Element;
}

interface ReferencingHeaderOwnProps {
    referenceConfiguration: ReferenceConfiguration;
}

type ReferencingHeaderProps = ReferencingHeaderOwnProps & ReferencingHeaderStateProps;

interface ReferencingHeaderState {
    visible: boolean;
}

export class ReferencingHeaderUnconnected extends React.Component<ReferencingHeaderProps, ReferencingHeaderState> {

    public state: ReferencingHeaderState = {
        visible: true
    };

    public render() {
        const { referenceConfiguration, content } = this.props;
        const { visible } = this.state;

        return (
            visible && <Message info content={content(referenceConfiguration)} onDismiss={this.handleDismiss} />
        );
    }

    private handleDismiss = () => {
        this.setState({ visible: false });
    }
}

const mapStateToProps = (state: RegionState): ReferencingHeaderStateProps => ({
    content: localTextSelector(state, "referencingHeaderMessage")
});

export const ReferencingHeader = connect(mapStateToProps)(ReferencingHeaderUnconnected);
