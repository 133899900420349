import * as React from "react";
import { Grid, Header } from "semantic-ui-react";

interface GridRowHeaderProps {
    text: string;
    as?: string;
    dividing?: boolean;
}

export const GridRowHeader: React.FC<GridRowHeaderProps> = ({ text, as = "h3", dividing = true }) => (
    <Grid.Row>
        <Grid.Column>
            <Header as={as} dividing={dividing}>{text}</Header>
        </Grid.Column>
    </Grid.Row>
);
