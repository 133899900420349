import * as React from "react";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface AsyncResolveProps {
    reload?: () => Promise<void>;
    loading?: boolean;
}

type ResolveProps<T, TOwnProps extends {}> = {
    [P in keyof T]?: (props?: TOwnProps) => Promise<T[P]>;
};

interface Resolver<T, TProps> {
    key: string;
    resolve: (props: Readonly<React.PropsWithChildren<TProps>>) => Promise<T>;
}

type AsyncResolveWrapperState<T> = {
    [P in keyof T]: T[P];
} & {
    loading: boolean,
    loadIteration: number
};

export const asyncResolve = <T extends {} | AsyncResolveProps, TOwnProps>(resolveProps: ResolveProps<T, TOwnProps>, initialProps: Partial<T> = {}) => {

    return <TProps extends T>(Component: React.ComponentClass<TProps> | React.StatelessComponent<TProps>) => {

        return class AsyncResolveWrapper extends React.PureComponent<Omit<TProps, keyof T>, AsyncResolveWrapperState<T>> {

            public state = {
                ...(initialProps as any),
                loadIteration: 0
            };

            public async componentDidMount() {
                await this.load();
            }

            public render() {

                return (
                    <Component
                        {...this.state}
                        {...this.props}
                        reload={this.load}
                        key={this.state.loadIteration}
                    />
                );
            }

            private async resolve<TValue>(resolver: Resolver<TValue, Omit<TProps, keyof T>>) {

                const result = await resolver.resolve(this.props);

                this.setState(previous => ({
                    ...(previous as any),
                    [resolver.key]: result
                }));
            }

            private load = async () => {
                const resolvers = Object.keys(resolveProps).map(key => ({ key, resolve: resolveProps[key] }));

                this.setState(previous => ({ ...(previous as any), loading: true }));

                const promises = resolvers.map(this.resolve.bind(this));
                await Promise.all(promises);

                this.setState(previous => ({ ...(previous as any), loading: false, loadIteration: previous.loadIteration + 1 }));
            }
        };
    };
};
