import axios from "axios";

import { AddressLookupResponseModel, ExperianAddressFormat, ExperianAddressResponse } from "./model";

export class AddressLookupApi {
    private readonly apiUrl = "/api/address";

    public async search(query: string, country: string = "gbr", take: string = "50") {
        const queryString = `search?query=${query}&country=${country}&take=${take}`;
        const response = await axios.get<AddressLookupResponseModel>(`${this.apiUrl}/lookup`, { params: { queryString } });
        return response.data.results;
    }

    public async usCityLookup(state: string, query: string) {
        const response = await axios.get<string[]>(`${this.apiUrl}/uscitylookup/${state}`, { params: { query } });
        return response.data;
    }

    public async format(queryString: string) {
        if (!queryString) {
            return null;
        }

        const splitFormat = queryString.split("/").reverse()[0];
        const response = await axios.get<ExperianAddressResponse>(`${this.apiUrl}/lookup`, { params: { queryString: splitFormat } });
        const result = this.flattenAddress(response.data.address);
        return result;
    }

    private flattenAddress(address: any): ExperianAddressFormat {
        return address.reduce((value: any, current: any) => ({ ...current, ...value }), {});
    }
}
