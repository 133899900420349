export const LOAD_COMPANIES_SUCCESS = "LOAD_COMPANIES_SUCCESS";
export type LOAD_COMPANIES_SUCCESS = typeof LOAD_COMPANIES_SUCCESS;

export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export type CREATE_COMPANY_SUCCESS = typeof CREATE_COMPANY_SUCCESS;

export const SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS";
export type SAVE_COMPANY_SUCCESS = typeof SAVE_COMPANY_SUCCESS;

export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export type DELETE_COMPANY_SUCCESS = typeof DELETE_COMPANY_SUCCESS;

export const VERIFY_COMPANY_SUCCESS = "VERIFY_COMPANY_SUCCESS";
export type VERIFY_COMPANY_SUCCESS = typeof VERIFY_COMPANY_SUCCESS;
