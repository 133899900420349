import * as React from "react";
import { Card, Icon } from "semantic-ui-react";
import { Reference, ReferenceType } from "@momenta/common/referencing/model";
import { ConfirmModal } from "@momenta/common";
import { Link } from "redux-little-router";

interface ReferenceItemProps {
    reference: Reference;
    deleteReference: (referenceId: number) => void;
}

interface ReferenceItemState {
    open: boolean;
}

export class ReferenceItem extends React.Component<ReferenceItemProps, ReferenceItemState> {

    public state: ReferenceItemState = {
        open: false
    };

    public render() {
        const { reference } = this.props;
        const { open } = this.state;

        const end = reference.end ? reference.end.format("ll") : "To Present";
        const header = `${reference.start.format("ll")} - ${end}`;

        return (
            <>
                <Card fluid>
                    <Card.Content as={Link} href={`/referencing/${reference.id}/edit`}>
                        {reference.submitted === false &&
                            <Icon className="delete-reference" name="close" color="grey" onClick={this.onOpenModal} />
                        }
                        <Card.Header>{header}</Card.Header>
                        <Card.Meta>{ReferenceType[reference.referenceType]}</Card.Meta>
                        <Card.Description>{reference && reference.summary}</Card.Description>
                    </Card.Content>
                </Card>

                <ConfirmModal
                    title="Delete Reference"
                    open={open}
                    onCancel={this.onCancelModal}
                    onConfirm={this.deleteReference}
                    content="Are you sure you want to delete this reference?"
                />
            </>
        );
    }

    private onOpenModal = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        this.setState({ open: true });
    }

    private onCancelModal = () => this.setState({ open: false });

    private deleteReference = async () => {
        this.setState({ open: false });
        await this.props.deleteReference(this.props.reference.id);
    }
}
