import { loadAndTrack } from "redux-request-loading";
import { AuthState } from "reauthorize";
import { loadInvoicePeriodsSuccess, loadInvoicePeriodSuccess } from "@momenta/common/invoicePeriods/actions";
import { AppState } from "@momenta/common/invoicePeriods/model";
import { loadPaginationTotalSuccess } from "@momenta/common/pagination";

import InvoicePeriodApi from "./InvoicePeriodApi";

export function loadInvoicePeriods() {
    return async (dispatch: any, getState: () => AppState & AuthState) => {
        if (!getState().currentUser.authenticated) {
            return;
        }

        const pageNumber = +(getState().router.query.page || 1);
        const { items, count } = await loadAndTrack(dispatch, "loadInvoicePeriod", InvoicePeriodApi.getAll(pageNumber));
        dispatch(loadInvoicePeriodsSuccess(items));
        dispatch(loadPaginationTotalSuccess(count));
    };
}

export function loadInvoicePeriod({ invoicePeriodId }: { invoicePeriodId: string | number }) {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "loadInvoicePeriod", InvoicePeriodApi.get(+invoicePeriodId));
        dispatch(loadInvoicePeriodSuccess(result));
    };
}

export function loadInvoicePeriodToPrint({ invoicePeriodId }: { invoicePeriodId: string | number }) {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "loadInvoicePeriod", InvoicePeriodApi.getInvoiceToPrint(+invoicePeriodId));
        dispatch(loadInvoicePeriodSuccess(result));
    };
}
