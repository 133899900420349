import { isNullOrUndefined } from "util";

import * as React from "react";
import { Button, Container, Divider, Form } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import moment from "moment";
import { toast } from "@momenta/common/toasts";
import { dateSortDesc, FileUpload } from "@momenta/common";
import { Result, ValidationFunction } from "not-valid";
import { getCurrentTimeZone } from "@momenta/common/utils/getCurrentTimeZone";
import { ComponentVisible, localTextSelector, RegionState } from "@momenta/common/internationalisation";
import { stateOptionsList } from "@momenta/common/address/lookup/UsState";
import { CityLookupDropdown } from "@momenta/common/address/lookup/CityLookupDropdown";
import { doesNotContainHashValidator } from "@momenta/common/validators/doesNotContainHashValidator";

import { CurrentUser, CurrentUserState, filenameRegex } from "../currentUser/model";
import { saveCurrentUser } from "../currentUser/actions";

export interface RegisterProps {
    currentUser: CurrentUser;
    phoneNumberValidation: ValidationFunction<string>;
}

interface RegisterUser extends CurrentUser {
    cvChanged: boolean;
}

export interface RegisterState extends FormState<RegisterUser> {
}

export class RegisterUnconnected extends FormBaseComponent<RegisterUser, RegisterProps, RegisterState> {
    constructor(props: RegisterProps & SaveDispatchProps<CurrentUser>) {
        super(props);

        this.state = {
            values: {
                ...props.currentUser,
                newCv: this.getCurrentCv(props),
                cvChanged: false,
                registrationComplete: true
            },
            valid: {}
        };
    }

    public render() {
        const { loading } = this.state;
        const cvFileTypes = [".pdf", ".doc", ".docx"];

        return (
            <Container text className="register">
                <h1>Complete Registration</h1>

                <Form onSubmit={this.handleSubmit} loading={this.state.loading} noValidate>

                    <FileUpload
                        label="CV/Resume"
                        value={this.state.values.newCv}
                        apiUrl={"/api/cv"}
                        fileTypes={cvFileTypes}
                        onFileChange={this.handleFileChange}
                        required
                        showErrors={this.state.showErrors}
                    />

                    <Input.PhoneNumber
                        value={this.state.values.mobileNumber}
                        label="Mobile Number"
                        onChange={this.getUpdate("mobileNumber")}
                        showErrors={this.state.showErrors}
                        validation={[this.props.phoneNumberValidation]}
                        required
                    />

                    <ComponentVisible keyName="stateAddressFieldEnabled">
                        <Input.Dropdown
                            label="State"
                            placeholder="Choose State"
                            value={this.state.values.address && this.state.values.address.state}
                            options={stateOptionsList}
                            showErrors={this.state.showErrors}
                            onChange={this.handleStateChange}
                            required
                            search
                        />
                        <CityLookupDropdown
                            selectedState={this.state.values.address && this.state.values.address.state}
                            city={this.state.values.address && this.state.values.address.city}
                            onChange={this.handleCityChange}
                            showErrors={this.state.showErrors}
                            validation={[doesNotContainHashValidator()]}
                        />
                    </ComponentVisible>

                    <Divider />

                    <p>
                        By clicking below you agree to the
                        <a
                            href="https://momentagroup.com/website-terms-and-conditions/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="register-link"> Terms and Conditions </a>
                        applicable to our service and acknowledge that your personal data will be used in accordance with our
                        <a
                            href="https://momentagroup.com/privacy-and-cookie-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="register-link"> Privacy Policy </a>
                        and either our
                        <a
                            href="https://momentagroup.com/contractor-privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="register-link"> Contractor Privacy Policy </a>
                        or
                        <a
                            href="https://momentagroup.com/candidate-fair-processing-notice-for-potential-employees/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="register-link"> Candidate Fair Processing Notice for Potential Employees. </a>
                        We may send you emails and communications about jobs and career related topics.
                        Your preferences can be changed at any time by logging into your associate profile once you have registered.
                    </p>

                    <Input.Checkbox
                        value={this.state.values.agreedToTerms}
                        onChange={this.handleAgreedToTermsChange}
                        showErrors={this.state.showErrors}
                        validation={[this.agreeToTermsValidation]}
                        label="I agree to the terms and conditions"
                    />

                    <Button
                        type="submit"
                        icon="save outline"
                        color="green"
                        content="Save"
                        floated="right"
                        disabled={loading}
                        loading={loading}
                    />
                </Form>
            </Container>
        );
    }

    public UNSAFE_componentWillReceiveProps(props: RegisterProps & SaveDispatchProps<RegisterUser>) {
        this.setState({
            values: {
                ...props.currentUser,
                newCv: this.getCurrentCv(props),
                cvChanged: false,
                registrationComplete: true
            }
        });
    }

    public agreeToTermsValidation(value: boolean) {
        if (!value) {
            return Result.Fail("You must agree to Terms and Conditions");
        }

        return Result.Pass;
    }

    private getCurrentCv = (props: RegisterProps & SaveDispatchProps<RegisterUser>) => {
        const cv = props.currentUser.cvs && props.currentUser.cvs
            .sort(dateSortDesc(c => c.created))
            .filter(c => !c.momentaFormatted)[0];

        if (isNullOrUndefined(cv)) {
            return null;
        }

        return cv.blobName.match(filenameRegex)[1];
    }

    private handleFileChange = async (value: string, valid: boolean) => {

        if (value != null && value === this.state.values.newCv) {
            return;
        }

        await this.updateProperty("newCv", value, valid);

        if (valid && value) {
            toast.success("CV/Resume uploaded successfully");
            this.setState(previous => ({ values: { ...previous.values, cvChanged: true } }));
        }
    }

    private handleStateChange = (value: string, valid: boolean) => {
        if (value !== null && value === this.state.values.address.state) {
            return;
        }

        this.updateProperty("address", { ...this.state.values.address, state: value }, valid);

        if (valid && value) {
            this.setState(previous => ({ values: { ...previous.values, address: {
                ...previous.values.address,
                state: value,
                city: ""
            } } }));
        }
    }

    private handleCityChange = (value: string, valid: boolean) => {
        if (value !== null && value === this.state.values.address.city) {
            return;
        }

        this.updateProperty("address", { ...this.state.values.address, city: value }, valid);

        if (valid && value) {
            this.setState(previous => ({ values: { ...previous.values, address: {
                ...previous.values.address,
                city: value
            } } }));
        }
    }

    private handleAgreedToTermsChange = (value: boolean, valid: boolean) => {
        this.updateProperty("agreedToTerms", value, valid);
        this.updateProperty("receiveMarketingEmails", value, valid);
    }
}

const mapStateToProps = (state: CurrentUserState & RegionState): RegisterProps => ({
    currentUser: state.currentUser,
    phoneNumberValidation: localTextSelector(state, "phoneNumberValidation")
});

const mapDispatchToProps = (dispatch: any): SaveDispatchProps<CurrentUser> => ({
    save: async (currentUser: RegisterUser) => {

        let user = { ...currentUser, registered: moment() };

        if (user.cvChanged === false) {
            user = { ...user, newCv: null };
        }

        const timezone = getCurrentTimeZone();
        user = { ...user, timezone };

        await dispatch(saveCurrentUser(user));

        if (currentUser.registrationRoleId !== null && currentUser.registrationRoleId !== undefined) {
            toast.success("Thank you for submitting your application");
        }

        if (user.externalApplicationComplete === false) {
            dispatch(push("/application-submitted"));
            return;
        }

        dispatch(push("/"));
    }
});

export const Register = connect(mapStateToProps, mapDispatchToProps)(RegisterUnconnected);
