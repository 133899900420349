import * as React from "react";
import { LoadingWrapper, Modal } from "@momenta/common";
import { Button, Form } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

import { CurrentUser } from "../model";

interface SendEmailModalStateProps {
    open: boolean;
    onCloseModal: () => void;
    requestCompanyChange: (message: string, subject?: string) => Promise<void>;
    currentUser: CurrentUser;
}

interface SendEmailState {
    message: string;
    loading: boolean;
}

const subject = "MP2 - Change of contracting entity";

export class ChangeCompanyModal extends React.Component<SendEmailModalStateProps, SendEmailState> {

    public state: SendEmailState = {
        message: "Dear Momenta\n\n"
            + "This email is to notify you of my intention to change the entity through which I contract with yourselves.\n\n"
            + "I am requesting to transfer from (Current Contracting Entity) to (New Contracting Entity) "
            + "and would like to transfer the contract entity from (DD/MM/YYYY)\n\n"
            + "If there is anything further you require, please do not hesitate to contact me.\n\n"
            + "Kind regards,\n\n"
            + `${this.props.currentUser.forename} ${this.props.currentUser.surname}\n\n`
            + `${this.props.currentUser.email}`,
        loading: false
    };

    public render() {
        const { loading } = this.state;

        return (
            <Modal
                size="large"
                open={this.props.open}
            >
                <Modal.Header>Change Company</Modal.Header>
                <Modal.Content>
                    <LoadingWrapper loading={this.state.loading}>
                        <Form noValidate>
                            <Input.Text
                                value={subject}
                                label="Subject"
                                readOnly
                            />

                            <Input.Textarea
                                value={this.state.message}
                                label="Message"
                                onChange={this.onHandleMessageChange}
                                required
                                rows={8}
                            />
                        </Form>
                    </LoadingWrapper>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon="cancel" basic content="Cancel" negative onClick={this.props.onCloseModal} disabled={loading} />
                    <Button icon="send" content="Send" positive onClick={this.onSendClick} loading={loading} disabled={loading} />
                </Modal.Actions>
            </Modal>
        );
    }

    private onHandleMessageChange = (value: string) => this.setState({ message: value });

    private onSendClick = async () => {
        this.setState({ loading: true });

        await this.props.requestCompanyChange(this.state.message, subject);

        this.setState({ loading: false });
    }
}

export default ChangeCompanyModal;
