import { isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from "../typeUtils";

import { Address } from "./model";

export const addressProvided = (address: Address): boolean => {
    return isNotNullOrUndefined(address)
        && (
            isNotNullUndefinedOrEmpty(address.line1)
            || isNotNullUndefinedOrEmpty(address.line2)
            || isNotNullUndefinedOrEmpty(address.line3)
            || isNotNullUndefinedOrEmpty(address.city)
            || isNotNullUndefinedOrEmpty(address.county)
            || isNotNullUndefinedOrEmpty(address.postcode)
        );
};
