import * as React from "react";
import moment = require("moment");
import { DropdownItemProps, Form } from "semantic-ui-react";
import { SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { getDateInputFormat } from "@momenta/common/dateFormatting/getDateInputFormat";
import { convertEnumToOptions } from "@momenta/common";
import { createValidator } from "not-valid";

import { stringSort } from "../sorting";

import { GapReason, GapReference, ReferenceType } from "./model";
import { CreateReference, ReferenceOwnProps, ReferenceProps } from "./CreateReference";

export class CreateGapReference extends CreateReference<GapReference> {

    constructor(props: ReferenceProps & ReferenceOwnProps<GapReference> & SaveDispatchProps<GapReference>) {
        super(props);
        this.state = {
            values: props.reference || this.defaultState(),
            valid: {}
        };
    }

    private gapEndsAfterItStarts() {
        return this.state.values.end >= this.state.values.start;
    }

    public render() {
        const { values: { gapReason, start, end, current, otherGapReason, submitted } } = this.state;
        const { disabled } = this.props;

        const gapReasonOptions = this.getOptionsWithOtherAsLast();

        const readOnly = submitted && disabled;

        const futureDateCheck = createValidator<moment.Moment>(
            () => this.gapEndsAfterItStarts(),
            "Gap end date must be after the start date"
        );

        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Date
                    value={start}
                    label="Start Date"
                    onChange={this.getUpdate("start")}
                    format={getDateInputFormat()}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    required
                />
                {current === false && <Input.Date
                    value={end}
                    label="End Date"
                    onChange={this.getUpdate("end")}
                    format={getDateInputFormat()}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    validation={[futureDateCheck]}
                    required
                />}
                <Input.Checkbox
                    value={current}
                    label="To Present"
                    onChange={this.onCurrentChange}
                    readOnly={readOnly}
                />
                <Input.DropdownNumber
                    value={gapReason}
                    onChange={readOnly === false && this.onGapReasonChange}
                    label="Reason for Gap"
                    options={gapReasonOptions}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    dynamicOptions
                    required
                />
                {gapReason === GapReason.Other &&
                    <Input.Textarea
                        value={otherGapReason}
                        label="Other Reason"
                        showErrors={this.state.showErrors}
                        onChange={this.getUpdate("otherGapReason")}
                        readOnly={readOnly}
                        required
                    />
                }
            </Form>
        );
    }

    public onCurrentChange = (value: any) => {

        if (this.state.values.current === value) {
            return;
        }

        this.setState(prevState => ({
            values: {
                ...prevState.values,
                current: value,
                end: value ? null : prevState.values.end
            },
            valid: {
                ...prevState.valid,
                end: value ? true : prevState.valid.end
            }
        }));
    }

    private onGapReasonChange = (value: number, valid: boolean) => {

        this.setState(prevState => ({
            values: {
                ...prevState.values,
                gapReason: value,
                otherGapReason: value !== GapReason.Other ? null : prevState.values.otherGapReason
            },
            valid: {
                ...prevState.valid,
                gapReason: valid,
                otherGapReason: value !== GapReason.Other ? true : prevState.valid.otherGapReason
            }
        }));
    }

    private getOptionsWithOtherAsLast = (): DropdownItemProps[] => {
        const options = convertEnumToOptions(GapReason, k => +k)
            .filter(o => o.value !== GapReason.Unknown && o.value !== GapReason.Other && !isFinite(o.text))
            .sort(stringSort(r => r.text));

        options.push({ text: "Other", value: GapReason.Other });

        return options;
    }

    private defaultState(): GapReference {

        const { start, end } = this.props;
        const reference = this.getDefaultReferenceState(ReferenceType.Gap);
        const startDate = start && start.isValid() ? start : null;
        const endDate = end && end.isValid() ? end : null;

        return {
            ...reference,
            start: startDate,
            end: endDate,
            gapReason: undefined,
            otherGapReason: ""
        };
    }
}
