export type UsStateCode =
    "AL" |
    "AK" |
    "AZ" |
    "AR" |
    "CA" |
    "CO" |
    "CT" |
    "DE" |
    "DC" |
    "FL" |
    "GA" |
    "HI" |
    "ID" |
    "IL" |
    "IN" |
    "IA" |
    "KS" |
    "KY" |
    "LA" |
    "ME" |
    "MD" |
    "MA" |
    "MI" |
    "MN" |
    "MS" |
    "MO" |
    "MT" |
    "NE" |
    "NV" |
    "NH" |
    "NJ" |
    "NM" |
    "NY" |
    "NC" |
    "ND" |
    "OH" |
    "OK" |
    "OR" |
    "PA" |
    "RI" |
    "SC" |
    "SD" |
    "TN" |
    "TX" |
    "UT" |
    "VT" |
    "VA" |
    "WA" |
    "WV" |
    "WI" |
    "WY" |
    "PR";

export const UsStateNames = {
    Alabama: "Alabama",
    Alaska: "Alaska",
    Arizona: "Arizona",
    Arkansas: "Arkansas",
    California: "California",
    Colorado: "Colorado",
    Connecticut: "Connecticut",
    Delaware: "Delaware",
    DistrictOfColumbia: "District Of Columbia",
    Florida: "Florida",
    Georgia: "Georgia",
    Hawaii: "Hawaii",
    Idaho: "Idaho",
    Illinois: "Illinois",
    Indiana: "Indiana",
    Iowa: "Iowa",
    Kansas: "Kansas",
    Kentucky: "Kentucky",
    Louisiana: "Louisiana",
    Maine: "Maine",
    Maryland: "Maryland",
    Massachusetts: "Massachusetts",
    Michigan: "Michigan",
    Minnesota: "Minnesota",
    Mississippi: "Mississippi",
    Missouri: "Missouri",
    Montana: "Montana",
    Nebraska: "Nebraska",
    Nevada: "Nevada",
    NewHampshire: "New Hampshire",
    NewJersey: "New Jersey",
    NewMexico: "New Mexico",
    NewYork: "New York",
    NorthCarolina: "North Carolina",
    NorthDakota: "North Dakota",
    Ohio: "Ohio",
    Oklahoma: "Oklahoma",
    Oregon: "Oregon",
    Pennsylvania: "Pennsylvania",
    PuertoRico: "Puerto Rico",
    RhodeIsland: "Rhode Island",
    SouthCarolina: "South Carolina",
    SouthDakota: "South Dakota",
    Tennessee: "Tennessee",
    Texas: "Texas",
    Utah: "Utah",
    Vermont: "Vermont",
    Virginia: "Virginia",
    Washington: "Washington",
    WestVirginia: "West Virginia",
    Wisconsin: "Wisconsin",
    Wyoming: "Wyoming",
};

export const UsStateLookup = {
    AL: UsStateNames.Alabama,
    AK: UsStateNames.Alaska,
    AR: UsStateNames.Arkansas,
    AZ: UsStateNames.Arizona,
    CA: UsStateNames.California,
    CO: UsStateNames.Colorado,
    CT: UsStateNames.Connecticut,
    DC: UsStateNames.DistrictOfColumbia,
    DE: UsStateNames.Delaware,
    FL: UsStateNames.Florida,
    GA: UsStateNames.Georgia,
    HI: UsStateNames.Hawaii,
    IA: UsStateNames.Iowa,
    ID: UsStateNames.Idaho,
    IL: UsStateNames.Illinois,
    IN: UsStateNames.Indiana,
    KS: UsStateNames.Kansas,
    KY: UsStateNames.Kentucky,
    LA: UsStateNames.Louisiana,
    MA: UsStateNames.Massachusetts,
    MD: UsStateNames.Maryland,
    ME: UsStateNames.Maine,
    MI: UsStateNames.Michigan,
    MN: UsStateNames.Minnesota,
    MO: UsStateNames.Missouri,
    MS: UsStateNames.Mississippi,
    MT: UsStateNames.Montana,
    NC: UsStateNames.NorthCarolina,
    ND: UsStateNames.NorthDakota,
    NE: UsStateNames.Nebraska,
    NH: UsStateNames.NewHampshire,
    NJ: UsStateNames.NewJersey,
    NM: UsStateNames.NewMexico,
    NV: UsStateNames.Nevada,
    NY: UsStateNames.NewYork,
    OK: UsStateNames.Oklahoma,
    OH: UsStateNames.Ohio,
    OR: UsStateNames.Oregon,
    PA: UsStateNames.Pennsylvania,
    PR: UsStateNames.PuertoRico,
    RI: UsStateNames.RhodeIsland,
    SC: UsStateNames.SouthCarolina,
    SD: UsStateNames.SouthDakota,
    TN: UsStateNames.Tennessee,
    TX: UsStateNames.Texas,
    UT: UsStateNames.Utah,
    VA: UsStateNames.Virginia,
    VT: UsStateNames.Vermont,
    WA: UsStateNames.Washington,
    WI: UsStateNames.Wisconsin,
    WV: UsStateNames.WestVirginia,
    WY: UsStateNames.Wyoming,
};

export interface StateOption {
    text: string;
    value: UsStateCode;
};

export const stateOptionsList: StateOption[] = [
    { text: UsStateNames.Alabama, value: "AL" },
    { text: UsStateNames.Alaska, value: "AK" },
    { text: UsStateNames.Arizona, value: "AZ" },
    { text: UsStateNames.Arkansas, value: "AR" },
    { text: UsStateNames.California, value: "CA" },
    { text: UsStateNames.Colorado, value: "CO" },
    { text: UsStateNames.Connecticut, value: "CT" },
    { text: UsStateNames.Delaware, value: "DE" },
    { text: UsStateNames.DistrictOfColumbia, value: "DC" },
    { text: UsStateNames.Florida, value: "FL" },
    { text: UsStateNames.Georgia, value: "GA" },
    { text: UsStateNames.Hawaii, value: "HI" },
    { text: UsStateNames.Idaho, value: "ID" },
    { text: UsStateNames.Illinois, value: "IL" },
    { text: UsStateNames.Indiana, value: "IN" },
    { text: UsStateNames.Iowa, value: "IA" },
    { text: UsStateNames.Kansas, value: "KS" },
    { text: UsStateNames.Kentucky, value: "KY" },
    { text: UsStateNames.Louisiana, value: "LA" },
    { text: UsStateNames.Maine, value: "ME" },
    { text: UsStateNames.Maryland, value: "MD" },
    { text: UsStateNames.Massachusetts, value: "MA" },
    { text: UsStateNames.Michigan, value: "MI" },
    { text: UsStateNames.Minnesota, value: "MN" },
    { text: UsStateNames.Mississippi, value: "MS" },
    { text: UsStateNames.Missouri, value: "MO" },
    { text: UsStateNames.Montana, value: "MT" },
    { text: UsStateNames.Nebraska, value: "NE" },
    { text: UsStateNames.Nevada, value: "NV" },
    { text: UsStateNames.NewHampshire, value: "NH" },
    { text: UsStateNames.NewJersey, value: "NJ" },
    { text: UsStateNames.NewMexico, value: "NM" },
    { text: UsStateNames.NewYork, value: "NY" },
    { text: UsStateNames.NorthCarolina, value: "NC" },
    { text: UsStateNames.NorthDakota, value: "ND" },
    { text: UsStateNames.Ohio, value: "OH" },
    { text: UsStateNames.Oklahoma, value: "OK" },
    { text: UsStateNames.Oregon, value: "OR" },
    { text: UsStateNames.Pennsylvania, value: "PA" },
    { text: UsStateNames.PuertoRico, value: "PR" },
    { text: UsStateNames.RhodeIsland, value: "RI" },
    { text: UsStateNames.SouthCarolina, value: "SC" },
    { text: UsStateNames.SouthDakota, value: "SD" },
    { text: UsStateNames.Tennessee, value: "TN" },
    { text: UsStateNames.Texas, value: "TX" },
    { text: UsStateNames.Utah, value: "UT" },
    { text: UsStateNames.Vermont, value: "VT" },
    { text: UsStateNames.Virginia, value: "VA" },
    { text: UsStateNames.Washington, value: "WA" },
    { text: UsStateNames.WestVirginia, value: "WV" },
    { text: UsStateNames.Wisconsin, value: "WI" },
    { text: UsStateNames.Wyoming, value: "WY" },
];
