import * as React from "react";
import { TimesheetDay } from "@momenta/common/timesheets";
import { Table } from "semantic-ui-react";
import { dateSortAsc } from "@momenta/common/sorting";

import { TimesheetDayPrint } from "./TimesheetDayPrint";

interface HoursWorkedPrintProps {
    timesheetDays: TimesheetDay[];
}

export const HoursWorkedPrint: React.FC<HoursWorkedPrintProps> = ({ timesheetDays }) => {

    const timeSheetDaysOrdered = timesheetDays?.sort(dateSortAsc(t => t.date));

    const hasNoEntries = timesheetDays.some(t => t.timesheetEntries.length) === false;

    return (
        <Table color="teal" size="small">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Time</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {hasNoEntries &&
                    <Table.Row>
                        <Table.Cell content="No timesheet entries" colSpan={8} textAlign="center" disabled />
                    </Table.Row>
                }
                {timeSheetDaysOrdered?.map(timesheetDay => (
                    <TimesheetDayPrint
                        key={timesheetDay.id}
                        timesheetDay={timesheetDay}
                    />
                ))}
            </Table.Body>

        </Table>
    );
};
