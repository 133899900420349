import * as React from "react";
import { Button, Icon, Label, List } from "semantic-ui-react";

import { ExpenseTypeConfiguration } from "../hierarchicalConfiguration";
import { TimesheetStateEnum } from "../timesheets";
import { LocalText } from "../internationalisation/LocalText";
import { Currency } from "../internationalisation";

import { getExpenseNetAndVat } from "./getExpenseNetAndVat";
import { Expense, ReceiptState } from "./model";

export interface ExpenseRowProps {
    expense: Expense;
    onDeleteClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onEditClick: () => void;
    submitted: boolean;
    expenseValid: boolean;
    showErrors: boolean;
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    canAlwaysEditReceipts?: boolean;
    timesheetState?: number;
    isInvoiced?: boolean;
    disableEdit: boolean;
}

export const ExpenseRow: React.SFC<ExpenseRowProps> = ({
    expense,
    onDeleteClick,
    onEditClick,
    submitted,
    expenseValid,
    showErrors,
    expenseTypeConfigurations,
    canAlwaysEditReceipts,
    timesheetState,
    isInvoiced,
    disableEdit
}) => {
    let receiptFile: string = null;

    if (expense.receiptFile) {
        receiptFile = expense.receiptFile;
    } else if (expense.previousReceipt && expense.previousReceiptExpense) {
        receiptFile = expense.previousReceiptExpense.receiptFile;
    }

    const invalidExpense = showErrors && expenseValid === false;

    const [net, vat] = getExpenseNetAndVat(expense, expenseTypeConfigurations);

    const timesheetSubmittedOrApproved = timesheetState === TimesheetStateEnum.Submitted || timesheetState === TimesheetStateEnum.Approved;
    const invoicedOrAlreadySubmitted = isInvoiced || receiptFile != null && timesheetSubmittedOrApproved;

    const disabled = disableEdit || invoicedOrAlreadySubmitted && !canAlwaysEditReceipts && expense.receiptState !== ReceiptState.Rejected;

    return (
        <List.Item className="expense-row">
            <List.Content floated="right">
                <Button icon="edit" onClick={onEditClick} primary disabled={disabled} />
                <Button as="a" icon="file text" href={`/api/receipt/${receiptFile}`} target="_blank" disabled={receiptFile === null} />
                <Button icon="trash" onClick={onDeleteClick} color="red" disabled={submitted} />
            </List.Content>
            <List.Content floated="right" verticalAlign="middle" className="expense-amount">
                <>
                    <strong>Net</strong> <Currency value={net} />
                    <br /><strong><LocalText keyName="taxAbbreviation" /></strong> <Currency value={vat} />
                </>
            </List.Content>
            {invalidExpense &&
                <List.Content floated="right">
                    <Icon name="exclamation" color="red" size="large" />
                </List.Content>
            }

            <List.Content verticalAlign="middle">
                <List.Header>
                    {expense.description}
                    {expense.receiptState === ReceiptState.Rejected &&
                        <Label content="Receipt Rejected" icon="warning" color="red" size="tiny" className="rejected-receipt" />
                    }
                </List.Header>
                <List.Description>
                    {expense.date.format("LL")}
                </List.Description>
            </List.Content>
        </List.Item>
    );
};
