import { loadAndTrack } from "redux-request-loading";
import { CandidateWithdrawModel } from "src/App/People/src/candidates/model";

import { candidateApi } from "./candidateApi";

export function withdrawCandidate(candidateId: number, model: CandidateWithdrawModel) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "withdrawCandidate", candidateApi.withdrawCandidate(candidateId, model));
    };
}
