import * as React from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";

export interface DetailColumnProps {
    label: string;
    value: React.ReactNode;
    width?: SemanticWIDTHS;
}

export const DetailColumn: React.SFC<DetailColumnProps> = ({ width, label, value }) => (
    <Grid.Column width={width}>
        <strong>{label}</strong>
        <p>{value || "-"}</p>
    </Grid.Column>
);
