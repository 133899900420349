import * as React from "react";
import { Header } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { toast } from "@momenta/common/toasts";
import { StandardTimezoneOptions } from "@momenta/common/currentUser/model";

interface TimezonePreferenceProps {
    timezone: string;
    save: (value: string) => Promise<void>;
}

interface TimezonePreferenceState {
    saving: boolean;
}

const options = Object.keys(StandardTimezoneOptions).map(k => ({ text: k, value: StandardTimezoneOptions[k] }));

export class TimezonePreference extends React.Component<TimezonePreferenceProps, TimezonePreferenceState> {

    public state = {
        saving: false
    };

    public render() {
        const { timezone } = this.props;

        return (
            <>
                <Header dividing>Timezone Preference</Header>

                <Input.Dropdown
                    value={timezone}
                    label="Timezone"
                    options={options}
                    onChange={this.onTimezoneChange}
                    disabled={this.state.saving}
                />
            </>
        );
    }

    private onTimezoneChange = async (value: string) => {

        if (value === this.props.timezone) {
            return;
        }

        this.setState({ saving: true });

        try {
            await this.props.save(value);
            toast.success("Updated timezone preference");
        } finally {
            this.setState({ saving: false });
        }
    }
}
