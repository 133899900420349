import * as React from "react";
import { connect } from "react-redux";
import {
    Button,
    Container,
    Divider,
    DropdownItemProps,
    Form,
    Grid,
    Header,
    List,
    Message,
    Segment
} from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { CandidateState, LoadingWrapper, stringSort } from "@momenta/common";
import { UmbrellaCompany } from "@momenta/common/umbrellaCompany";
import { Company } from "@momenta/common/companies";
import { toast } from "@momenta/common/toasts";
import { areRequestsActive } from "redux-request-loading";
import { DismissableMessage } from "@momenta/common/dismissableMessage";

import { createCompany, loadCompanies, saveCompany } from "../company/actions";
import { associateCompaniesSelector } from "../company/selectors";
import { AppState } from "../model";

import { CurrentUser } from "./model";
import { loadCurrentUser, requestCompanyChange, saveCurrentUser } from "./actions";
import { AddCompanyModal } from "./companies/AddCompanyModal";
import ChangeCompanyModal from "./companies/ChangeCompanyModal";
import { AssociateCompanies } from "./AssociateCompanies";
import { companyGuidanceMessage } from "./companies/CompanyGuidanceMessage";

export interface EditFinanceProps {
    currentUser: CurrentUser;
    companies: Company[];
    umbrellaCompanies: UmbrellaCompany[];
    loading: boolean;
}

export interface EditFinanceDispatchProps {
    createNewCompany: (company: Company, currentUser: CurrentUser) => Promise<void>;
    saveSelectedCompany: (selectedCompanyId: number, currentUser: CurrentUser) => Promise<void>;
    saveCompany: (company: Company) => Promise<void>;
    requestCompanyChange: (message: string, subject?: string) => Promise<void>;
}

interface EditFinanceState {
    openAddCompanyModal: boolean;
    openSendEmailModal: boolean;
    selectedCompanyId: number;
}

export class EditFinance extends React.Component<EditFinanceProps & EditFinanceDispatchProps, EditFinanceState> {
    public state: EditFinanceState = {
        openAddCompanyModal: false,
        openSendEmailModal: false,
        selectedCompanyId: this.props.currentUser.selectedCompanyId,
    };

    public UNSAFE_componentWillReceiveProps(props: EditFinanceProps) {
        this.setState({ selectedCompanyId: props.currentUser.selectedCompanyId });
    }

    public render() {
        const { companies, umbrellaCompanies, currentUser, loading } = this.props;
        const { selectedCompanyId, openAddCompanyModal, openSendEmailModal } = this.state;

        const companyOptions: DropdownItemProps[] = companies
            .filter(c => !c.umbrella)
            .map(c => ({ text: c.name, value: c.id }))
            .sort(stringSort(c => c.text));

        const umbrellaCompanyOptions: DropdownItemProps[] = umbrellaCompanies
            .filter(u => u.showOnAssociate)
            .map(u => ({ text: u.name, value: u.id }))
            .sort(stringSort(c => c.text));

        companyOptions.unshift({ text: "My Companies", value: -1, disabled: true, className: "dropdown-header" });
        umbrellaCompanyOptions.unshift({ text: "Umbrella Companies", value: -2, disabled: true, className: "dropdown-header" });

        const allCompanyOptions = (companyOptions.concat(umbrellaCompanyOptions));

        const associateOnContract = currentUser.contracts
            && currentUser.contracts.filter(c => c.state === CandidateState["On Contract"]).length > 0;

        return (
            <LoadingWrapper loading={loading}>
                <Container className="edit-finance">
                    <Header dividing>Company Details</Header>

                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <DismissableMessage
                                    message={companyGuidanceMessage()}
                                    keyName="company-guidance"
                                    placeholder="Show company guidance information"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={13} mobile={16} verticalAlign="bottom">
                                <Form noValidate>
                                    <Input.DropdownNumber
                                        value={selectedCompanyId}
                                        label="Select Company"
                                        options={allCompanyOptions}
                                        onChange={this.handleSelectedCompany}
                                        disabled={associateOnContract}
                                        dynamicOptions
                                    />
                                </Form>
                            </Grid.Column>

                            <Grid.Column computer={3} mobile={16} verticalAlign="bottom">
                                <Button fluid icon="save" content="Save" positive onClick={this.onSaveSelectedCompany} disabled={associateOnContract} />
                            </Grid.Column>
                        </Grid.Row>

                        {associateOnContract &&
                            <Grid.Row>
                                <Grid.Column computer={16} mobile={16}>
                                    <Message
                                        content={this.renderOnContractMessage()}
                                        error
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        }

                        <Grid.Row>
                            <Grid.Column computer={16} mobile={16} textAlign="right">
                                <Button floated="right" icon="add" content="Add New Company" basic positive onClick={this.openAddCompanyModal} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />

                    {companies &&
                        <AssociateCompanies
                            companies={companies}
                            currentUser={currentUser}
                            save={this.props.saveCompany}
                        />
                    }

                    {openAddCompanyModal && <AddCompanyModal
                        open={openAddCompanyModal}
                        save={this.saveCompany}
                        onCloseModal={this.closeAddCompanyModal}
                        companyOnContract={false}
                    />}

                    <ChangeCompanyModal
                        open={openSendEmailModal}
                        onCloseModal={this.closeSendEmailModal}
                        requestCompanyChange={this.requestCompanyChange}
                        currentUser={currentUser}
                        key={currentUser.id}
                    />
                </Container>
            </LoadingWrapper>
        );
    }

    private openAddCompanyModal = () => this.setState({ openAddCompanyModal: true });
    private closeAddCompanyModal = () => this.setState({ openAddCompanyModal: false });

    private openSendEmailModal = () => this.setState({ openSendEmailModal: true });
    private closeSendEmailModal = () => this.setState({ openSendEmailModal: false });

    private saveCompany = async (company: Company) => {
        await this.props.createNewCompany(company, this.props.currentUser);
        this.closeAddCompanyModal();
    }

    private requestCompanyChange = async (message: string, subject?: string) => {
        await this.props.requestCompanyChange(message, subject);
        this.closeSendEmailModal();
    }

    private handleSelectedCompany = (value: number) => {
        this.setState({ selectedCompanyId: value });
    }

    private onSaveSelectedCompany = () => {
        this.props.saveSelectedCompany(this.state.selectedCompanyId, this.props.currentUser);
    }

    private renderOnContractMessage = () => (
        <Segment basic clearing>
            <p>
                As you are currently on contract please refer to the change of Contracting Entity Guide which can be found
                <a
                    href="https://momentagroup.com/wp-content/uploads/contract-entity-guide.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={"contract-guide-link"}> here </a>
                which details the Momenta rules regarding these changes.
            </p>

            <List bulleted>
                <List.Item>
                    If you are changing from/to an Umbrella company please click the
                    <strong> Email Momenta button</strong> and provide the appropriate details.
                </List.Item>

                <List.Item>
                    If you are changing to a Limited company you have previously used please click the
                    <strong> Email Momenta button</strong> and provide the appropriate details.
                </List.Item>

                <List.Item>
                    If you have setup a new Limited company please click the <strong>Add New Company button </strong>
                    to provide us with all the details and documentation, then click the <strong>Email Momenta button </strong>
                    to advise us of these changes accordingly.
                </List.Item>
            </List>

            <Grid>
                <Grid.Row>
                    <Grid.Column computer={12} mobile={16} tablet={16}>
                        <strong>PLEASE NOTE:  Any changes made by you <u>will not</u> take place until Momenta have confirmed back to you accordingly.</strong>
                    </Grid.Column>
                    <Grid.Column computer={4} mobile={16} tablet={16} textAlign="right">
                        <Button icon="send" content="Email Momenta" primary onClick={this.openSendEmailModal} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

const mapStateToProps = (state: AppState): EditFinanceProps => ({
    currentUser: state.currentUser,
    companies: associateCompaniesSelector(state),
    umbrellaCompanies: state.umbrellaCompanies,
    loading: areRequestsActive(state, ["loadUmbrellaCompanies", "loadCompanies"])
});

const mapDispatchToProps = (dispatch: any): EditFinanceDispatchProps => ({
    createNewCompany: async (company: Company, currentUser: CurrentUser) => {
        await dispatch(createCompany({ ...company, associateId: currentUser.id }));
        await dispatch(loadCompanies());
        toast.success("Created new company");
    },
    saveSelectedCompany: async (selectedCompanyId: number, currentUser: CurrentUser) => {
        await dispatch(saveCurrentUser({ ...currentUser, selectedCompanyId }));
        await dispatch(loadCurrentUser());
        toast.success("Selected company saved");
    },
    saveCompany: async (company: Company) => {
        await dispatch(saveCompany(company));

        await Promise.all([
            dispatch(loadCompanies()),
            dispatch(loadCurrentUser())
        ]);

        toast.success("Company saved");
    },
    requestCompanyChange: async (message: string, subject?: string) => {
        await dispatch(requestCompanyChange(message, subject));
        toast.success("Email sent to Momenta");
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFinance);
