import * as React from "react";
import { Button } from "semantic-ui-react";

import { Address } from "./model";
import { mapExperianAddress } from "./mapExperianAddress";
import { ExperianAddressFormat } from "./lookup/model";
import { AddressLookup } from "./lookup/AddressLookup";
import { allowedCountryCodes } from "./allowedCountryCodes";
import { addressProvided } from "./addressProvided";

interface AddressProps {
    values: { address?: Address };
    showErrors: boolean;
    onChange: (prop: string, value: string, valid: boolean) => void;
}

interface AddressLookupAndAddressProps extends AddressProps {
    onLookup: (address: Address, valid: boolean) => void;
    addressEdit: React.ComponentType<AddressProps>;
}

export const AddressLookupAndAddress: React.FC<AddressLookupAndAddressProps> = ({
    onLookup,
    onChange,
    addressEdit,
    ...addressProps
}) => {

    const [showFields, setShowFields] = React.useState<boolean>(addressProvided(addressProps.values.address));

    const updateAddress = (value: ExperianAddressFormat, valid: boolean) => {
        const address = mapExperianAddress(value);
        onLookup(address, valid);
        setShowFields(true);
    };

    const changeAddressVisibility = (newState: boolean) => (event: React.MouseEvent) => {
        event.preventDefault();
        setShowFields(newState);
    };

    const AddressEdit = addressEdit;

    return (
        <>
            {showFields === false &&
                <>
                    <AddressLookup
                        showCountrySelect
                        allowedCountryCodes={allowedCountryCodes}
                        updateAddress={updateAddress}
                        onChange={onChange}
                    />

                    <Button content={"Enter Address Manually" } onClick={changeAddressVisibility(true)} />
                </>
            }

            {showFields && (
                <>
                    <Button content={"Search For Address" } onClick={changeAddressVisibility(false)} />

                    <AddressEdit
                        onChange={onChange}
                        {...addressProps}
                    />
                </>
            )}
        </>
    );
};
