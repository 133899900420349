import * as React from "react";
import { Grid, Header } from "semantic-ui-react";
import { AppState } from "src/App/People/src/model";
import { localTextSelector } from "@momenta/common/internationalisation";
import { connect } from "react-redux";

import { MomentaTeamInfoCard } from "./MomentaTeamInfoCard";

interface MomentaTeamsProps {
    financePage: React.ReactNode;
    resourcingPage: React.ReactNode;
    vettingPage: React.ReactNode;
    clientServicesPage: React.ReactNode;
    showClientServices: boolean;
}

export const MomentaTeamsUnconnected: React.FC<MomentaTeamsProps> = ({ financePage, resourcingPage, vettingPage, clientServicesPage, showClientServices }) => {

    return (
        <>
            <Header as="h1" dividing>The Momenta Teams</Header>
            <Header as="h3">
                Whether you have just made an application with Momenta or are already
                working with us, we are on hand to help. Our teams below can assist you with any queries you might have.
            </Header>
            <Grid padded>
                <Grid.Row>
                    <MomentaTeamInfoCard title="Resourcing" body={resourcingPage} image="/resourcing-banner.jpg" />
                    <MomentaTeamInfoCard title="Vetting & Compliance" body={vettingPage} image="/vetting-and-compliance-banner.jpg" />
                </Grid.Row>
                <Grid.Row>
                    { showClientServices && <MomentaTeamInfoCard title="Client Services" body={clientServicesPage} image="/client-services-banner.jpeg" /> }
                    <MomentaTeamInfoCard title="Finance" body={financePage} image="/finance-banner.jpg" />
                </Grid.Row>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: AppState): MomentaTeamsProps => ({
    financePage: localTextSelector(state, "financeInfo"),
    resourcingPage: localTextSelector(state, "resourcingInfo"),
    vettingPage: localTextSelector(state, "vettingInfo"),
    clientServicesPage: localTextSelector(state, "clientServicesInfo"),
    showClientServices: localTextSelector(state, "showClientServicesCard")
});

export const MomentaTeams = connect(mapStateToProps)(MomentaTeamsUnconnected);
