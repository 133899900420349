import * as React from "react";
import { componentVisible } from "@momenta/common/internationalisation";
import { compose } from "recompose";
import { YesNoOption } from "@momenta/common/YesNoOption";
import { Grid } from "semantic-ui-react";
import { DetailColumn } from "@momenta/common/companies/DetailColumn";

import { CurrentUser } from "../model";

interface TaxDetailQuestionsProps {
    values: CurrentUser;
    onChange: (prop: string, valid: boolean) => (value: boolean) => void;
    onContract: boolean;
}

const TaxDetailQuestionsUnconnected: React.FunctionComponent<TaxDetailQuestionsProps> = ({ values, onChange, onContract }) => {
    const onAustralianResidentChange = (value: boolean) => { onChange("taxDetails.australianResident", true)(value); };
    const onWorkingHolidayMakerChange = (value: boolean) => { onChange("taxDetails.workingHolidayMaker", true)(value); };
    const onClaimTaxFreeThresholdChange = (value: boolean) => { onChange("taxDetails.claimTaxFreeThreshold", true)(value); };
    const onStudentDebtChange = (value: boolean) => { onChange("taxDetails.studentDebt", true)(value); };
    const onSupplementDebtChange = (value: boolean) => { onChange("taxDetails.supplementDebt", true)(value); };

    const australianResidentValue = values.taxDetails && values.taxDetails.australianResident;
    const workingHolidayMakerValue = values.taxDetails && values.taxDetails.workingHolidayMaker;
    const claimTaxFreeThresholdValue = values.taxDetails && values.taxDetails.claimTaxFreeThreshold;
    const studentDebtValue = values.taxDetails && values.taxDetails.studentDebt;
    const supplementDebtValue = values.taxDetails && values.taxDetails.supplementDebt;

    const australianResidentLabel = "Are you an Australian Resident for tax purposes?";
    const workingHolidayMakerLabel = "Are you a Working Holiday Maker?";
    const claimTaxFreeThresholdLabel = "Do you want to claim the tax-free threshold from this payer?";
    const studentDebtLabel = "Do you have a Higher Education Loan Program (HELP), Student Start-Up Load or Trade Support Loan debt?";
    const supplementDebtLabel = "Do you have a Financial Supplement Debt?";

    return (
        <>
            <Grid.Row>
                <Grid.Column computer={5} mobile={16} tablet={8}>
                    {onContract === true
                        ? <DetailColumn label={australianResidentLabel} value={australianResidentValue ? "Yes" : "No"} />
                        : <YesNoOption
                            label={australianResidentLabel}
                            propValue={australianResidentValue}
                            onChange={onAustralianResidentChange}
                        />
                    }
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column computer={5} mobile={16} tablet={8}>
                    {onContract === true
                        ? <DetailColumn label={workingHolidayMakerLabel} value={workingHolidayMakerValue ? "Yes" : "No"} />
                        : <YesNoOption
                            label={workingHolidayMakerLabel}
                            propValue={workingHolidayMakerValue}
                            onChange={onWorkingHolidayMakerChange}
                        />
                    }
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column computer={5} mobile={16} tablet={8}>
                    {onContract === true
                        ? <DetailColumn label={claimTaxFreeThresholdLabel} value={claimTaxFreeThresholdValue ? "Yes" : "No"} />
                        : <YesNoOption
                            label={claimTaxFreeThresholdLabel}
                            propValue={claimTaxFreeThresholdValue}
                            onChange={onClaimTaxFreeThresholdChange}
                        />
                    }
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column computer={5} mobile={16} tablet={8}>
                    {onContract === true
                        ? <DetailColumn label={studentDebtLabel} value={studentDebtValue ? "Yes" : "No"} />
                        : <YesNoOption
                            label={studentDebtLabel}
                            propValue={studentDebtValue}
                            onChange={onStudentDebtChange}
                        />
                    }
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column computer={5} mobile={16} tablet={8}>
                    {onContract === true
                        ? <DetailColumn label={supplementDebtLabel} value={supplementDebtValue ? "Yes" : "No"} />
                        : <YesNoOption
                            label={supplementDebtLabel}
                            propValue={supplementDebtValue}
                            onChange={onSupplementDebtChange}
                        />
                    }
                </Grid.Column>
            </Grid.Row>
            <p>
                Please click
                <a
                    href="https://www.ato.gov.au/uploadedFiles/Content/IND/Downloads/TFN_declaration_form_N3092.pdf"
                    target="_blank"
                    rel="noopener noreferrer"> here </a>
                to download a Tax file number declaration
            </p>
        </>
    );
};

export const TaxDetailQuestions = compose<TaxDetailQuestionsProps, TaxDetailQuestionsProps>(
    componentVisible("australiaTaxForm"),
)(TaxDetailQuestionsUnconnected);
