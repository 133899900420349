import * as React from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";

import { UsStateLookup } from "@momenta/common/address/lookup/UsState";

import { Address } from "../address/model";
import { ComponentVisible } from "../internationalisation";

export interface AddressColumnProps {
    label: string;
    address: Address;
    width?: SemanticWIDTHS;
}

export const AddressColumn: React.SFC<AddressColumnProps> = ({ label, address, width }) => (
    <Grid.Column width={width}>
        <strong>{label}</strong>
        {address ?
            (
                <>
                    <p>{address.line1}</p>
                    <p>{address.line2}</p>
                    <p>{address.city}</p>
                    <p>{address.county}</p>
                    <ComponentVisible keyName="stateAddressFieldEnabled">
                        <p>{UsStateLookup[address.state]}</p>
                    </ComponentVisible>
                    <p>{address.postcode}</p>
                    <p>{address.country}</p>
                </>
            )
            : <p>-</p>
        }
    </Grid.Column>
);
