import * as React from "react";
import { debounce } from "@neworbit/simpleui-utils";
import { Form, Message, Search, SearchProps, SearchResultData, Dropdown, DropdownProps } from "semantic-ui-react";
import defaultProps from "recompose/defaultProps";

import { AddressLookupApi } from "./AddressLookupApi";

import { countryOptions, countryOptionsList } from "./CountryOptions";
import { AddressLookupModel, ExperianAddressFormat } from "./model";
import { CountryCode } from "./CountryCodes";

export interface AddressLookupProps {
    showCountrySelect?: boolean;
    allowedCountryCodes: CountryCode[];
    updateAddress: (value: ExperianAddressFormat, valid: boolean) => void;
    onChange: (prop: string, value: string, valid: boolean) => void;
}

export interface AddressLookupDispatchProps {
    lookupAddress: (postcode: string, country: string) => Promise<AddressLookupModel[]>;
    formatAddress: (queryString: string) => Promise<ExperianAddressFormat>;
}

interface AddressLookupState {
    selectedCountry: number;
    loading: boolean;
    results: any;
    value: string;
}

const inputProps = {
    input: { autoComplete: "new-password" }
};

export class AddressLookupUnconnected extends React.Component<AddressLookupProps & AddressLookupDispatchProps, AddressLookupState> {
    public state: AddressLookupState = {
        selectedCountry: undefined,
        loading: false,
        results: null,
        value: undefined
    };

    public render() {
        const { showCountrySelect, allowedCountryCodes } = this.props;
        const { value, results, selectedCountry, loading } = this.state;
        const countryCode = selectedCountry !== undefined ? countryOptionsList[selectedCountry].value : undefined;
        const allowedLookupCountries = allowedCountryCodes.some(country => country === countryCode);
        const showSupportMessage = selectedCountry != null && allowedLookupCountries === false;

        return (
            <>
                {showCountrySelect && (<Form.Field>
                    <label>Select country</label>
                    <Dropdown
                        options={countryOptions}
                        value={selectedCountry}
                        onChange={this.onCountryChange}
                        searchInput={inputProps.input}
                        search
                        selection
                    />
                </Form.Field>)}

                {showSupportMessage && (
                    <Message info content="The selected country is not supported by address lookup, please enter address details manually below." />
                )}

                {allowedLookupCountries && (
                    <Form.Field>
                        <label>Address</label>
                        <Search
                            fluid
                            loading={loading}
                            onResultSelect={this.onAddressSelect}
                            onSearchChange={this.searchDebounced}
                            results={results}
                            value={value}
                            input={inputProps}
                        />
                    </Form.Field>
                )}
            </>
        );
    }

    private onCountryChange = (event: any, { value }: DropdownProps) => {
        const countryName = countryOptionsList[+value].text;
        this.updateCountry(countryName);
        this.setState({ selectedCountry: +value });
    }

    private onAddressSelect = async (event: React.MouseEvent<HTMLElement>, data: SearchResultData) => {
        this.setState({ loading: true });

        const address = await this.props.formatAddress(data.result.value);
        this.updateAddress(address, true);

        this.setState({ loading: false });
    }

    private updateAddress(address: ExperianAddressFormat, valid: boolean) {
        this.props.updateAddress(address, valid);
    }

    private updateCountry(country: string) {
        this.props.onChange("address.country", country, true);
    }

    private searchAddress = async (event: React.MouseEvent<HTMLElement>, { value }: SearchProps) => {
        this.setState({ loading: true });

        const countryCode = countryOptionsList[this.state.selectedCountry].value;

        const addresses = await this.props.lookupAddress(value, countryCode);
        const addressOptions = addresses.map(address => ({
            title: address.suggestion,
            value: address.format
        }));

        this.setState({ loading: false, results: addressOptions });
    }

    private searchDebounced = debounce(this.searchAddress, 300);
}

const addressLookupApi = new AddressLookupApi();

export const AddressLookup = defaultProps({
    lookupAddress: addressLookupApi.search.bind(addressLookupApi),
    formatAddress: addressLookupApi.format.bind(addressLookupApi)
})(AddressLookupUnconnected);
