export const StandardTimezoneOptions = {
    "Australian Eastern Standard Time": "E. Australia Standard Time",
    "Australian Central Standard Time": "Cen. Australia Standard Time",
    "Australian Western Standard Time": "W. Australia Standard Time",
    "Greenwich Mean Time": "GMT Standard Time",
    "India Standard Time": "India Standard Time",
    "USA Pacific Standard Time": "Pacific Standard Time",
    "USA Mountain Standard Time": "Mountain Standard Time",
    "USA Central Standard Time": "Central Standard Time",
    "USA Eastern Standard Time": "Eastern Standard Time"
};
