import * as React from "react";
import { Expense } from "@momenta/common/expenses/model";
import { Table } from "semantic-ui-react";
import { LocalText } from "@momenta/common/internationalisation/LocalText";
import { dateSortAsc } from "@momenta/common/sorting";

import { ExpenseRowPrint } from "./ExpenseRowPrint";

interface ExpensesPrintProps {
    expenses: Expense[];
}

export const ExpensesPrint: React.FC<ExpensesPrintProps> = ({ expenses }) => {

    const expensesOrdered = expenses.sort(dateSortAsc(e => e.date));

    return (
        <Table color="teal" size="small">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Category</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Net</Table.HeaderCell>
                    <Table.HeaderCell><LocalText keyName="taxAbbreviation" /></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {expenses.length === 0 &&
                    <Table.Row>
                        <Table.Cell content="No expenses" colSpan={8} textAlign="center" disabled />
                    </Table.Row>
                }
                {expensesOrdered?.map(expense => (
                    <ExpenseRowPrint key={expense.id} expense={expense} />
                ))}
            </Table.Body>
        </Table>
    );
};
