import * as React from "react";
import { Country } from "@momenta/common/model";
import { connect } from "react-redux";

import { LandingPageUk } from "./LandingPageUk";
import { LandingPageAus } from "./LandingPageAus";
import { LandingPageDefault } from "./LandingPageDefault";
import { AppState } from "./model";

interface LandingStateProps {
    region: Country;
}

export const LandingUnconnected: React.SFC<LandingStateProps> = ({ region }) => {

    switch (region) {
        case Country["United Kingdom"]:
            return <LandingPageUk />;
        case Country.Australia:
            return <LandingPageAus />;
        default:
            return <LandingPageDefault />;
    }
};

const mapStateToProps = (state: AppState): LandingStateProps => ({
    region: state.region
});

export const Landing = connect(mapStateToProps)(LandingUnconnected);
