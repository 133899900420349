import axios from "axios";

import { makeRequest, PageResult } from "../odata";

import { UmbrellaCompanyCreateModel, UmbrellaCompanyDetailModel, UmbrellaCompanyEditModel, UmbrellaCompanyListModel } from "./model";

class UmbrellaCompanyApi {

    private readonly pageSize = 20;
    private readonly apiUrl = "/api/umbrellacompany";

    public async getAll(pageNumber: number = 1, loadAll?: boolean): Promise<PageResult<UmbrellaCompanyListModel>> {

        const skip = (pageNumber - 1) * this.pageSize;
        const request = makeRequest(this.apiUrl, {
            skip,
            top: this.pageSize,
            count: true
        });

        const response = loadAll
            ? await axios.get(this.apiUrl)
            : await axios.get(request);

        const { items, count } = response.data as PageResult<UmbrellaCompanyListModel>;
        return {
            items: items.map(this.parseModel),
            count
        };
    }

    public async detail(id: number): Promise<UmbrellaCompanyDetailModel> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const model = response.data as UmbrellaCompanyDetailModel;
        return this.parseModel(model);
    }

    public async save(umbrellaCompany: UmbrellaCompanyEditModel): Promise<UmbrellaCompanyDetailModel> {
        const response = await axios.put(`${this.apiUrl}/${umbrellaCompany.id}`, umbrellaCompany);
        const model = response.data as UmbrellaCompanyDetailModel;
        return this.parseModel(model);
    }

    public async create(umbrellaCompany: UmbrellaCompanyCreateModel): Promise<UmbrellaCompanyDetailModel> {

        const response = await axios.post(this.apiUrl, umbrellaCompany);
        const model = response.data as UmbrellaCompanyDetailModel;
        return this.parseModel(model);
    }

    public delete(id: number): Promise<{}> {
        return axios.delete(`${this.apiUrl}/${id}`);
    }

    public parseModel(model: UmbrellaCompanyDetailModel): UmbrellaCompanyDetailModel {
        return {
            ...model
        };
    }
}

export default new UmbrellaCompanyApi();
