import * as React from "react";
import { Grid } from "semantic-ui-react";
import { AddressLookupAndAddress, Address } from "@momenta/common/address";
import { ComponentVisible } from "@momenta/common/internationalisation";

import { GridRowHeader } from "./GridRowHeader";
import { EditAddress } from "./EditAddress";

export interface AddressesEditProps {
    values: { address?: Address, temporaryAddress?: Address };
    valid: { address?: boolean };
    showErrors: boolean;
    onChange: (prop: string, value: string | Address, valid: boolean) => void;
}

export const AddressesEdit: React.FC<AddressesEditProps> = ({ values, valid, showErrors, onChange }) => {
    const getOnAddressLookup = (propName: string) => (address: Address, addressValid: boolean) =>
        onChange(propName, address, addressValid);

    const getOnAddressManualChange = (propName: string) => (addressEditPropName: string, value: string, isValid: boolean) => {
        const addressPartName = addressEditPropName.split(".")[1];
        onChange(`${propName}.${addressPartName}`, value, isValid);
    };

    return (
        <>
            <Grid.Row>
                <Grid.Column width={16}>
                    <AddressLookupAndAddress
                        values={values}
                        showErrors={showErrors}
                        addressEdit={EditAddress}
                        onChange={getOnAddressManualChange("address")}
                        onLookup={getOnAddressLookup("address")}
                    />

                    {showErrors && valid.address === false &&
                        <div className="ui red pointing above basic label">
                            <p>Please provide address details</p>
                        </div>}
                </Grid.Column>
            </Grid.Row>

            <ComponentVisible keyName="temporaryAddressEnabled">
                <GridRowHeader text="Temporary Address" />
                <Grid.Row>
                    <Grid.Column width={16}>
                        <AddressLookupAndAddress
                            values={{ address: values.temporaryAddress }}
                            showErrors={showErrors}
                            addressEdit={EditAddress}
                            onChange={getOnAddressManualChange("temporaryAddress")}
                            onLookup={getOnAddressLookup("temporaryAddress")}
                        />
                    </Grid.Column>
                </Grid.Row>
            </ComponentVisible>
        </>
    );
};
