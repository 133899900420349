import * as React from "react";
import { createSelector } from "reselect";
import { Company, CompanyCard as CompanyCardCommon } from "@momenta/common/companies";

import { CurrentUser } from "../model";

import { AddCompanyModal } from "./AddCompanyModal";

interface CompanyCardProps {
    company: Company;
    isSelectedCompany: boolean;
    saveCompany: (company: Company) => Promise<void>;
    currentUser: CurrentUser;
}

interface CompanyCardState {
    open: boolean;
}

const contractSelector = (props: CompanyCardProps) => props.currentUser.contracts;
const contractCompaniesSelector = createSelector(
    contractSelector,
    contracts => contracts.map(c => c.companyId)
);

const companyIdSelector = (props: CompanyCardProps) => props.company.id;

const companyOnContractSelector = createSelector(
    contractCompaniesSelector,
    companyIdSelector,
    (companies, id) => companies.filter(c => c === id).length > 0
);

export class CompanyCard extends React.Component<CompanyCardProps, CompanyCardState> {

    public state: CompanyCardState = {
        open: false
    };

    public render() {
        const { company, isSelectedCompany } = this.props;
        const companyOnContract = companyOnContractSelector(this.props);

        return (
            <>
                <CompanyCardCommon
                    company={company}
                    isSelectedCompany={isSelectedCompany}
                    openModal={this.handleClick}
                />

                {this.state.open && <AddCompanyModal
                    company={company}
                    save={this.save}
                    open={this.state.open}
                    onCloseModal={this.closeModal}
                    companyOnContract={companyOnContract}
                />}
            </>
        );
    }

    private save = async (company: Company) => {
        this.props.saveCompany(company);

        this.closeModal();
    }

    private handleClick = () => {
        if (this.props.company.umbrella === false) {
            this.openModal();
        }
    }

    private openModal = () => {
        this.setState({ open: true });
    }

    private closeModal = () => {
        this.setState({ open: false });
    }
}
