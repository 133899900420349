export function validate<T, K extends keyof T>(obj: T, keys: K[]): boolean {

    if (!obj) {
        return false;
    }

    for (const key of keys) {

        if (obj[key] === null || obj[key] === undefined) {
            return false;
        }

        if (typeof (obj[key]) === "string" && validateString(obj[key])) {
            continue;
        }

        if (typeof (obj[key]) === "number" && validateNumber(obj[key])) {
            continue;
        }

        return false;
    }
    return true;
}

export function validateNumber(x: any): boolean {
    return isFinite(x);
}

export function validateString(x: any): boolean {
    return x !== undefined && x !== null && x.trim().length > 0;
}
