import { loadInvoicePeriod, loadInvoicePeriods, loadInvoicePeriodToPrint } from "./actions";

const printRoute  = {
    "/print": {
        title: "Print Invoice Period Timesheets",
        resolve: [loadInvoicePeriodToPrint],
    }
};

export const routes = {
    "/invoicePeriod": {
        "authorize": true,
        "/:invoicePeriodId": {
            title: "Invoice Period Details",
            resolve: [loadInvoicePeriods, loadInvoicePeriod],
            ignoreParentResolve: true,
            ...printRoute,
        }
    }
};
