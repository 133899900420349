import { stringSort } from "@momenta/common";
import * as React from "react";
import { Button, Divider } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import Modal from "@momenta/common/Modal";
import moment from "moment";
import { convertEnumToOptions } from "@momenta/common/convertEnumToOptions";
import { Reference, ReferenceType } from "@momenta/common/referencing/model";
import { ReferenceDispatchProps, ReferenceProps } from "@momenta/common/referencing/CreateReference";
import { ReferenceTypeComponent } from "@momenta/common/referencing/ReferenceTypeComponent";

export interface UpdateReferenceGapProps {
    updateReferenceGap: () => Promise<void>;
}

interface CreateReferenceModalStateProps {
    reference?: Reference;
    loading: boolean;
    create: boolean;
    buttonLabel: string;
    start?: string;
    end?: string;
}

interface CreateReferenceModalState {
    loaded: boolean;
    loading: boolean;
    referenceType: number;
}

export type CreateReferenceModalProps = ReferenceProps & CreateReferenceModalStateProps & ReferenceDispatchProps & UpdateReferenceGapProps;

export class CreateReferenceModalUnconnected extends React.Component<CreateReferenceModalProps, CreateReferenceModalState> {

    public static getDerivedStateFromProps(props: CreateReferenceModalProps, state: CreateReferenceModalState) {

        if (state.loading === true && props.loading === false) {
            return {
                loaded: true,
                loading: false
            };
        }

        if (state.loaded === false && props.loading === true) {
            return {
                loading: true
            };
        }

        return null;
    }

    public state: CreateReferenceModalState = {
        loading: false,
        loaded: false,
        referenceType: 0
    };

    private referenceTypeComponent: ReferenceTypeComponent;

    public render() {
        const { open, reference, loading, buttonLabel, create, start, end } = this.props;
        const { loaded, referenceType } = this.state;
        const referenceStart = moment(start);
        const referenceEnd = moment(end);

        const referenceTypes = convertEnumToOptions(ReferenceType, k => +k)
            .filter(o => o.value !== 0 && !isFinite(o.text))
            .sort(stringSort(r => r.text));

        const header = referenceType ? `Create ${ReferenceType[referenceType]} Reference` : "Create Reference";
        const detail = loaded && reference && reference.detail;
        const setInstance = (instance: any) => { this.referenceTypeComponent = instance; };

        return (
            <Modal
                open={open}
                onClose={this.close}
                closeIcon
            >
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    {create && <Input.DropdownNumber
                        value={referenceType}
                        label="Select Reference Type"
                        options={referenceTypes}
                        onChange={this.handleReferenceTypeChange}
                        dynamicOptions
                        required
                    />}

                    {create && referenceType !== 0 && <Divider />}

                    {(detail || create) &&
                        <ReferenceTypeComponent
                            reference={reference}
                            start={referenceStart}
                            end={referenceEnd}
                            referenceType={(reference && reference.referenceType) || this.state.referenceType}
                            save={this.props.save}
                            ref={setInstance}
                            disabled={true}
                            associateView
                        />
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon="cancel" content="Cancel" onClick={this.close} disabled={loading} loading={loading} negative />
                    <Button icon="send" content={buttonLabel} onClick={this.submit} disabled={loading} loading={loading} positive />
                </Modal.Actions>
            </Modal>
        );
    }

    private handleReferenceTypeChange = (referenceType: number) => {
        this.setState({ ...this.state, referenceType });
    }

    private submit = async () => {
        const valid = await this.referenceTypeComponent.submit();

        if (valid) {
            await this.props.updateReferenceGap();
            this.close();
        }
    }

    private close = () => {
        this.setState({
            loading: false,
            loaded: false,
            referenceType: 0
        }, () => this.props.close());
    }
}
