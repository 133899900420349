import localForage from "localforage";
import moment from "moment";
import { Timesheet } from "@momenta/common/timesheets";

export class TimesheetStorage {

    private readonly storagePrefix = "timesheets";

    public async save(timesheet: Timesheet): Promise<Timesheet> {

        const key = this.getKey(timesheet.id);

        const model = this.sanitizeModel(timesheet);

        return localForage.setItem(key, model);
    }

    public async load(id: number): Promise<Timesheet> {
        const key = this.getKey(id);
        return localForage.getItem<Timesheet>(key);
    }

    private getKey(id: number) {
        return `${this.storagePrefix}_${id}`;
    }

    private sanitizeModel(timesheet: Timesheet): any {
        const sanitizedOriginalTimesheet = timesheet.originalTimesheet && this.sanitizeModel(timesheet.originalTimesheet);

        return {
            ...timesheet,
            updated: moment().toJSON(),
            start: timesheet.start.toJSON(),
            end: timesheet.end.toJSON(),
            expenses: timesheet.expenses.map(e => ({
                ...e,
                date: e.date.toJSON()
            })),
            timesheetDays: timesheet.timesheetDays.map(d => ({
                ...d,
                date: d.date.toJSON()
            })),
            dateSubmitted: timesheet.dateSubmitted ? timesheet.dateSubmitted.toJSON() : timesheet.dateSubmitted,
            dateApproved: timesheet.dateApproved ? timesheet.dateApproved.toJSON() : timesheet.dateApproved,
            dateRejected: timesheet.dateRejected ? timesheet.dateRejected.toJSON() : timesheet.dateRejected,
            originalTimesheet: sanitizedOriginalTimesheet,
            invoicePeriod: timesheet.invoicePeriod
                ? {
                    ...timesheet.invoicePeriod,
                    start: timesheet.invoicePeriod.start && timesheet.invoicePeriod.start.toJSON(),
                    end: timesheet.invoicePeriod.end && timesheet.invoicePeriod.end.toJSON()
                }
                : timesheet.invoicePeriod
        };
    }
}

export const timesheetStorage = new TimesheetStorage();
