import axios, { AxiosStatic } from "axios";
import moment from "moment";
import { ValidVat } from "@momenta/common/vatDetails";
import { parseCompany } from "@momenta/common/companies";

import { CurrentUser } from "./model";

export class CurrentUserApi {

    private readonly apiUrl = "/api/CurrentUser";
    private axios: AxiosStatic;

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async load(): Promise<CurrentUser> {
        const response = await this.axios.get(this.apiUrl);
        const model = response.data as CurrentUser;
        return this.parseModel(model);
    }

    public async save(profile: CurrentUser): Promise<CurrentUser> {
        const response = await this.axios.put(`${this.apiUrl}`, profile);
        const model = response.data as CurrentUser;
        return this.parseModel(model);
    }

    public async requestCompanyChange(message: string, subject?: string) {
        await this.axios.post("/api/email", { subject, message, type: "CompanyChange" });
    }

    public async vatCompanyNameVerified(vatNumber: string, companyName: string) {
        const response = await this.axios.post(`${this.apiUrl}/vatCompanyNameVerified`, { vatNumber, companyName });
        return response.data as ValidVat;
    }

    public async saveMarketingEmails(id: number, receiveMarketingEmails: boolean) {
        await this.axios.put(`/api/marketingemail/${id}`, { receiveMarketingEmails });
    }

    public async requestDataRights(message: string) {
        await this.axios.post("/api/email", { type: "DataRightsRequest", message });
    }

    private parseModel(model: CurrentUser): CurrentUser {
        return {
            ...model,
            cvs: model.cvs.map(cv => ({
                ...cv,
                created: moment(cv.created)
            })),
            previousIdentities: model.previousIdentities.map(identity => ({
                ...identity,
                lastUsed: moment(identity.lastUsed)
            })),
            selectedCompany: parseCompany(model.selectedCompany),
            visaExpiryDate: moment(model.visaExpiryDate),
            contracts: model.contracts.map(contract => ({
                ...contract,
                contractStart: contract.contractStart && moment(contract.contractStart),
                contractEnd: contract.contractEnd && moment(contract.contractEnd),
                created: contract.created && moment(contract.created)
            })),
            candidacies: model.candidacies.map(candidate => ({
                ...candidate,
                created: candidate.created && moment(candidate.created)
            })),
            dateOfBirth: model.dateOfBirth && moment(model.dateOfBirth),
            availableFrom: model.availableFrom && moment(model.availableFrom)
        };
    }
}

const currentUserApi = new CurrentUserApi(axios);

export { currentUserApi };
