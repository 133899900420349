import { FixedActions } from "@momenta/common/fixedActions/FixedActions";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Grid } from "semantic-ui-react";

import { validateObject } from "@momenta/common/validateObject";

import { saveCurrentUser } from "../currentUser/actions";

import { BankDetailsEdit } from "../currentUser/BankDetailsEdit";
import { GridRowHeader } from "../currentUser/GridRowHeader";
import { CurrentUser } from "../currentUser/model";
import { currentUserSelector } from "../currentUser/selector";
import { SuperannuationDetailsEdit } from "../currentUser/SuperannuationDetailsEdit";
import { TaxDetailsEdit } from "../currentUser/TaxDetailsEdit";

export const ViewFinanceInformation: React.FC<{}> = () => {

    const currentUser = useSelector(currentUserSelector);

    const [values, setValues] = React.useState<CurrentUser>(currentUser);
    const [valid, setValid] = React.useState<{[key in keyof(CurrentUser)]: boolean}>();
    const [showErrors, setShowErrors] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const dispatch = useDispatch();

    const onChange = (prop: string, value: any, isValid: boolean) => {
        setValues({ ...values, [prop]: value });
        setValid({ ...valid, [prop]: isValid });
    };

    const onNestedChange = (prop: string, value: any, isValid: boolean) => {
        const props = prop.split(".");

        setValues({ ...values, [props[0]]: { ...values[props[0]], [props[1]]: value } });
        setValid({ ...valid, [prop]: isValid });
    };

    const onYesNoChange = (prop: string, isValid: boolean) => {
        const props = prop.split(".");
        return (value: boolean) => {
            setValues({ ...values, [props[0]]: { ...values[props[0]], [props[1]]: value } });
            setValid({ ...valid, [prop]: isValid });
        };
    };

    const onSubmit = () => {
        setShowErrors(true);
        if ( validateObject(valid) === false) {
            return;
        }
        setLoading(true);
        dispatch(saveCurrentUser(values));
        setLoading(false);
    };

    React.useEffect(() => setValues(currentUser), [currentUser]);

    return (
        <FixedActions>
            <Form onSubmit={onSubmit} noValidate>
                <Grid>
                    <GridRowHeader text="Finance Information" as="h1" />
                    <BankDetailsEdit
                        values={values}
                        showErrors={showErrors}
                        onChange={onNestedChange}
                    />

                    <SuperannuationDetailsEdit
                        values={values}
                        showErrors={showErrors}
                        model={values}
                        onChange={onNestedChange}
                    />

                    <TaxDetailsEdit
                        values={values}
                        showErrors={showErrors}
                        model={values}
                        onChange={onChange}
                        onNestedChange={onNestedChange}
                        onYesNoChange={onYesNoChange}
                    />
                    <FixedActions.Bar>
                        <Grid.Column>
                            <Button type="submit" icon="save outline" color="green" content="Save" floated="right" loading={loading} disabled={loading} />
                        </Grid.Column>
                    </FixedActions.Bar>
                </Grid>
            </Form>

        </FixedActions>
    );
};
