import * as React from "react";
import moment from "moment";
import { CreateAcademicReference, CreateEmploymentReference } from "@momenta/common/referencing";
import { AcademicReference, EmploymentReference, GapReference, Reference, ReferenceType } from "@momenta/common/referencing/model";
import { CreateGapReference } from "@momenta/common/referencing/CreateGapReference";

import { ReferenceProps } from "./CreateReference";

interface ReferenceTypeComponentProps extends ReferenceProps {
    reference: Reference;
    referenceType: number;
    start?: moment.Moment;
    end?: moment.Moment;
    showRefereeEmail?: boolean;
    showRefereePhoneNumber?: boolean;
    disabled?: boolean;
    save: (reference: Reference) => Promise<void>;
    onSendEmailClick?: (e: any) => void;
}

export class ReferenceTypeComponent extends React.Component<ReferenceTypeComponentProps> {

    private referenceForm: any;

    public render() {

        const { referenceType, reference } = this.props;
        const updatedKey = reference && reference.updated?.valueOf();
        const updateReference = (instance: any) => { this.referenceForm = instance; };

        return (
            <>
                {referenceType === ReferenceType.Academic &&
                    <CreateAcademicReference
                        {...this.props}
                        key={updatedKey}
                        ref={updateReference}
                        reference={reference as AcademicReference}
                    />
                }
                {referenceType === ReferenceType.Employment &&
                    <CreateEmploymentReference
                        {...this.props}
                        key={updatedKey}
                        ref={updateReference}
                        reference={reference as EmploymentReference}
                    />
                }
                {referenceType === ReferenceType.Gap &&
                    <CreateGapReference
                        key={updatedKey}
                        start={this.props.start}
                        end={this.props.end}
                        save={this.props.save}
                        ref={updateReference}
                        reference={reference as GapReference}
                        disabled={this.props.disabled}
                    />
                }
            </>
        );
    }

    public submit = () => this.referenceForm.submit();
}
