import { Modal } from "@momenta/common";
import { ComponentVisible } from "@momenta/common/internationalisation";

import { Input } from "@neworbit/simpleui-input";

import * as React from "react";
import { Button } from "semantic-ui-react";

export interface WelcomeMessageModalProps {
    open: boolean;
    onClose: (welcomeMessageViewed: boolean) => void;
}

export const WelcomeMessageModal: React.FC<WelcomeMessageModalProps> = ({ open, onClose }) => {
    const [loading, setLoading] = React.useState(false);
    const [welcomeMessageViewed, setWelcomeMessagedViewed] = React.useState(true);

    const closeModal = () => {
        setLoading(true);
        onClose(welcomeMessageViewed);
        setLoading(false);
    };

    return (
        <Modal
            size="small"
            open={open}
        >
            <Modal.Header>Welcome to Momenta</Modal.Header>
            <Modal.Content>
                <p>Thank you for taking the time to register your details with us and beginning your journey as a Momenta Associate.
                    You are now part of one of the most professional and high quality contractor networks across the world.
                </p>
                <p>
                    In order to increase your visibility to our internal recruitment teams and client partners, we now ask you to complete
                    your Associate Profile as fully as possible. This includes providing us with details of your:
                </p>
                <ul className="bullets">
                    <li>Availability/Notice Period</li>
                    <li>Day rate or salary expectations</li>
                    <ComponentVisible keyName="showAcademicBullet">
                        <li>Academic & employment history</li>
                    </ComponentVisible>
                </ul>
                <p>
                    By doing this now you will make yourself stand out from the crowd and help our teams closely match you to your ideal role.
                    Candidates with a complete profile are more likely to secure a new role quickly, sometimes within a just a few days.
                </p>
                <p>
                    If you are eager to start your new role as soon as possible, click the ‘Vacancies’ button in your Associate Profile to see
                    what we are recruiting for now.
                </p>
                <p>
                    Welcome to Momenta! We look forward to working with you.
                </p>

                <Input.Checkbox
                    label="Don't show me this message again"
                    value={welcomeMessageViewed}
                    onChange={setWelcomeMessagedViewed}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    icon="arrow right"
                    content="Continue to Dashboard"
                    labelPosition="right"
                    color="teal"
                    onClick={closeModal}
                    loading={loading}
                    disabled={loading} />
            </Modal.Actions>
        </Modal>
    );
};

