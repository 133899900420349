import * as React from "react";
import * as  moment from "moment";
import { Button, Grid, Header, List, Segment } from "semantic-ui-react";
import { ValidationState } from "@momenta/common/validationState";

import { BonusTypeConfiguration } from "../hierarchicalConfiguration";
import { Currency } from "../internationalisation";

import { BonusEntriesEdit } from "./BonusEntriesEdit";
import { Bonus } from "./model";

interface BonusesEditProps {
    bonuses: Bonus[];
    bonusesValid: ValidationState;
    bonusTypeConfigurations: BonusTypeConfiguration[];
    bonusesUpdated: (bonuses: Bonus[], bonusesValid: ValidationState) => void;
    submitted: boolean;
    showErrors: boolean;
    isAdjustment: boolean;
    timesheetStart: moment.Moment;
}

export class BonusesEdit extends React.Component<BonusesEditProps, {}> {
    constructor(props: BonusesEditProps) {
        super(props);

        this.addBonus = this.addBonus.bind(this);
    }

    public render() {
        const { bonuses, bonusesValid } = this.props;
        const bonusTotals = bonuses.map(b => b.amount).reduce((previous, current) => previous + current, 0);
        const allConfigsAssigned = this.allConfigsAssigned();

        const getOnDelete = (index: number) => { return () => this.onDelete(index); };
        const getOnChange = (index: number) => { return (newBonus: Bonus, valid: ValidationState) => this.onChange(index, newBonus, valid); };

        return (
            <>
                <Header attached="top">
                    <h2>Bonuses</h2>
                </Header>
                <Segment attached>
                    <List divided className="bonus-list">
                        {bonuses.length === 0 &&
                            <p className="disabled-text">You currently have no bonuses listed for this month</p>
                        }
                        {(bonuses || []).map((bonus, index) =>
                            (<BonusEntriesEdit
                                key={index}
                                bonusValid={(bonusesValid && bonusesValid[index] || {}) as ValidationState}
                                bonusTypeConfigurations={this.filterConfigurations(index)}
                                bonus={bonus}
                                onDelete={getOnDelete(index)}
                                onChange={getOnChange(index)}
                                submitted={this.props.submitted}
                                showErrors={this.props.showErrors}
                                isAdjustment={this.props.isAdjustment}
                                timesheetStart={this.props.timesheetStart}
                            />)
                        )}
                    </List>
                </Segment>
                <Segment attached="bottom">
                    <Grid>
                        <Grid.Column width={8} verticalAlign="middle">
                            <b>Total value of bonuses: <Currency value={bonusTotals} /></b>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            {!allConfigsAssigned && <Button
                                basic
                                floated="right"
                                content="Add"
                                icon="add"
                                onClick={this.addBonus}
                                color="green"
                                disabled={this.props.submitted}
                            />}
                        </Grid.Column>
                    </Grid>
                </Segment>
            </>
        );
    }

    private filterConfigurations = (index: number): BonusTypeConfiguration[] => {
        const otherConfigs = this.props.bonuses.filter((b, i) => i !== index).map((b) => b.bonusTypeId);
        const filteredConfigs = this.props.bonusTypeConfigurations.filter(t => !otherConfigs.some(typeId => typeId === t.id));
        return filteredConfigs;
    }

    private allConfigsAssigned = (): boolean => {
        const assignedConfigs = this.props.bonuses.map(b => b.bonusTypeId);
        return this.props.bonusTypeConfigurations.every(t => assignedConfigs.some(typeId => typeId === t.id));
    }

    private addBonus(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();

        const bonuses = [
            ...this.props.bonuses,
            {
                id: 0,
                bonusTypeId: undefined,
                bonusType: undefined,
                amount: 0,
            }
        ];

        const key = this.props.bonuses.length;

        const valid = {
            ...this.props.bonusesValid,
            [key]: {
                bonusTypeId: false,
                amount: true
            }
        };

        this.props.bonusesUpdated(bonuses, valid);
    }

    private onChange(index: number, bonus: Bonus, valid: ValidationState) {
        const bonuses = [...this.props.bonuses];
        const bonusesValid = { ...this.props.bonusesValid };
        bonuses.splice(index, 1, bonus);
        bonusesValid[index] = valid;
        this.props.bonusesUpdated(bonuses, bonusesValid);
    }

    private onDelete(index: number) {
        const bonuses = [...this.props.bonuses];
        const bonusesValid = { ...this.props.bonusesValid };
        bonuses.splice(index, 1);
        delete bonusesValid[index];
        this.props.bonusesUpdated(bonuses, bonusesValid);
    }
}
