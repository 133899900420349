/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { PreviousIdentity } from "src/App/People/src/associate/model";
import { Table, Button } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import moment from "moment";

interface PreviousIdentityCreateProps{
    onCreate: (newIdentity: PreviousIdentity) => void;
}

const PreviousIdentityCreate: React.FC<PreviousIdentityCreateProps> = ({ onCreate }) => {

    const blankIdentity: PreviousIdentity = {
        id: 0,
        forename: "",
        middleName: "",
        surname: "",
        lastUsed: undefined
    };

    const [newIdentity, editNewIdentity] = React.useState(blankIdentity);

    const resetNewIdentity = () => {
        editNewIdentity({ ...newIdentity, forename: "", surname: "" });
    };

    const addIdentity = () => {
        event.preventDefault();
        onCreate(newIdentity);
        resetNewIdentity();
    };

    const identityHasBeenProvided = (): boolean => {
        return newIdentity.forename && newIdentity.surname && newIdentity.lastUsed ? true : false;
    };

    const updateForename = (value: string) => {
        editNewIdentity({ ...newIdentity, forename: value });
    };

    const updateMiddleName = (value: string) => {
        editNewIdentity({ ...newIdentity, middleName: value });
    };

    const updateSurname = (value: string) => {
        editNewIdentity({ ...newIdentity, surname: value });
    };

    const updateLastUsed = (value: moment.Moment) => {
        editNewIdentity({ ...newIdentity, lastUsed: value });
    };

    return (
        <Table.Row>
            <Table.Cell>
                <Input.Text
                    value={newIdentity.forename}
                    placeholder="Forename"
                    label="Forename"
                    onChange={updateForename}
                />
            </Table.Cell>
            <Table.Cell>
                <Input.Text
                    value={newIdentity.middleName}
                    label="Middle Name"
                    placeholder="Middle name"
                    onChange={updateMiddleName}
                />
            </Table.Cell>
            <Table.Cell>
                <Input.Text
                    value={newIdentity.surname}
                    placeholder="Surname"
                    label="Surname"
                    onChange={updateSurname}
                />
            </Table.Cell>
            <Table.Cell>
                <Input.Date
                    value={newIdentity.lastUsed}
                    placeholder="Last Used"
                    label="Last Used"
                    onChange={updateLastUsed}
                />
            </Table.Cell>
            <Table.Cell>
                <Button
                    icon="plus"
                    onClick={addIdentity}
                    color="green"
                    disabled={identityHasBeenProvided() === false}
                />
            </Table.Cell>
        </Table.Row>
    );
};

export {
    PreviousIdentityCreate
};
