import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { Result, ValidationFunction } from "not-valid";
import { Button } from "semantic-ui-react";
import { Modal } from "@momenta/common";
import { convertEnumToOptions, WithdrawCandidateReasons } from "@momenta/common";

export interface WithdrawProps {
    open: boolean;
    saving: boolean;
    modalOpen: (open: boolean) => void;
    withdraw: (reason: number) => Promise<void>;
    message?: string;
}

interface WithdrawState {
    reason: number;
    reasonValid: boolean;
    changingRole: boolean;
}

export const WithdrawModal: React.FC<WithdrawProps> = ({ open, saving, modalOpen, withdraw, message }) => {

    const [state, setState] = React.useState<WithdrawState>({ reason: undefined, reasonValid: false, changingRole: false });

    const openModal = (openValue: boolean) => () => modalOpen(openValue);

    const options = () => convertEnumToOptions(WithdrawCandidateReasons).filter(o => o.value !== "0" && Number(o.value) >= 500);

    const selectReason = (reason: number, reasonValid: boolean) => {
        setState({ reason, reasonValid, changingRole: false });
    };

    const withdrawCandidate = () => { if (state.reasonValid) { withdraw(state.reason); } };

    const categoryValidation: ValidationFunction<number> = value => value !== undefined && value > 0 ? Result.Pass : Result.Fail("Category is required");

    const modalMessage = message ? message : "Are you sure you want to withdraw from this role?";

    return (
        <>
            <Modal
                open={open}
                closeOnEscape={false}
                closeOnRootNodeClick={false}
            >
                <Modal.Header>
                    {modalMessage}
                </Modal.Header>
                <Modal.Content>
                    <p>Please give a reason why:</p>
                    <Input.DropdownNumber
                        value={state.reason}
                        onChange={selectReason}
                        validation={[categoryValidation]}
                        placeholder="Select your reason"
                        options={options()}
                    />
                </Modal.Content>
                <Modal.Actions disabled={saving}>
                    <Button onClick={openModal(false)} negative>No</Button>
                    <Button onClick={withdrawCandidate} positive labelPosition="right" icon="checkmark" content="Yes" />
                </Modal.Actions>
            </Modal>
        </>
    );
};
