import * as React from "react";
import { Button, Form } from "semantic-ui-react";
import { Modal } from "@momenta/common";
import { Input } from "@neworbit/simpleui-input";
import { toast } from "@momenta/common/toasts";

import { CurrentUser } from "../model";

interface DataRightsRequestModalState {
    open: boolean;
    message: string;
    loading: boolean;
}

interface DataRightsRequestModalProps {
    currentUser: CurrentUser;
    requestDataRights: (message: string) => Promise<void>;
}

export class DataRightsRequestModal extends React.Component<DataRightsRequestModalProps, DataRightsRequestModalState> {
    public state: DataRightsRequestModalState = {
        open: false,
        loading: false,
        message: "\n\n\nRegards,\n\n"
            + `${this.props.currentUser.forename} ${this.props.currentUser.surname}\n\n`
            + `${this.props.currentUser.email}`,
    };

    public render() {
        const { loading } = this.state;

        return (
            <>
                <Button content="Request" onClick={this.openModal} primary fluid />

                <Modal open={this.state.open} onClose={this.closeModal} closeIcon>
                    <Modal.Content>
                        <Form noValidate>
                            <Input.Textarea
                                value={this.state.message}
                                label="Message"
                                onChange={this.handleMessageChange}
                                required
                                autoHeight
                            />
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeModal} content="Cancel" icon="close" disabled={loading} negative basic />
                        <Button onClick={this.sendRequestDataRights} content="Send" icon="send" disabled={loading} loading={loading} positive />
                    </Modal.Actions>
                </Modal>
            </>
        );
    }

    private openModal = () => this.setState({ open: true });
    private closeModal = () => this.setState({ open: false });

    private handleMessageChange = (value: string) => this.setState({ message: value });

    private sendRequestDataRights = async () => {
        this.setState({ loading: true });
        await this.props.requestDataRights(this.state.message);
        this.setState({ loading: false });

        this.closeModal();

        toast.success("Email sent");
    }
}
