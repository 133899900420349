export const CREATE_ACADEMIC_REFERENCE_SUCCESS = "CREATE_ACADEMIC_REFERENCE_SUCCESS";
export type CREATE_ACADEMIC_REFERENCE_SUCCESS = typeof CREATE_ACADEMIC_REFERENCE_SUCCESS;

export const LOAD_REFERENCES_SUCCESS = "LOAD_REFERENCES_SUCCESS";
export type LOAD_REFERENCES_SUCCESS = typeof LOAD_REFERENCES_SUCCESS;

export const LOAD_REFERENCE_SUCCESS = "LOAD_REFERENCE_SUCCESS";
export type LOAD_REFERENCE_SUCCESS = typeof LOAD_REFERENCE_SUCCESS;

export const DELETE_REFERENCE_SUCCESS = "DELETE_REFERENCE_SUCCESS";
export type DELETE_REFERENCE_SUCCESS = typeof DELETE_REFERENCE_SUCCESS;
