import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Card, Container, Grid } from "semantic-ui-react";
import { Timesheet, TimesheetState } from "@momenta/common/timesheets";
import { GlobalConfigState } from "@momenta/common/globalConfig/model";
import { localTextSelector } from "@momenta/common/internationalisation/selectors";
import { RegionState } from "@momenta/common/internationalisation";

import { ApplicationsAndContractsCard } from "./dashboard/ContractsCard";
import { CurrentUser, CurrentUserState, } from "./currentUser/model";
import { TimesheetCard } from "./timesheets/TimesheetCard";
import {
    BackgroundScreeningCard,
    EmploymentHistoryCard,
    FinanceDetailsCard,
    PersonalInformationCard,
    PreferencesAndConsentCard,
    VacanciesCard,
    MomentaTeamsCard
} from "./dashboard";
import { MomentaDocumentVettingData, ProofDocumentVettingData, VettingDataState } from "./vetting/model";

import { WelcomeMessageModal } from "./WelcomeMessageModal";
import { saveCurrentUser } from "./currentUser/actions";

import { FinanceInformationCard } from "./dashboard/FinanceInformationCard";

interface DashboardStateProps {
    profile: CurrentUser;
    timesheets: Timesheet[];
    proofDocuments: ProofDocumentVettingData[];
    momentaDocuments: MomentaDocumentVettingData[];
    referAFriendText: string;
    referAFriendLink: string;
    showReferAFriend: boolean;
    showReferAFriendTextLink: boolean;
    showApplicationsAndContracts: boolean;
    showMomentaTeamsCard: boolean;
    showFinanceInformationCard: boolean;
}

export const DashboardUnconnected: React.FC<DashboardStateProps> = ({
    profile,
    timesheets,
    proofDocuments,
    momentaDocuments,
    referAFriendText,
    referAFriendLink,
    showReferAFriendTextLink,
    showReferAFriend,
    showApplicationsAndContracts,
    showMomentaTeamsCard,
    showFinanceInformationCard
}) => {
    const contracts = profile.contracts || [];
    const noDocumentsUploaded = proofDocuments.filter(p => p.document === null);
    const noMomentaUploaded = momentaDocuments.filter(m => m.document === null);
    const noDocumentsRequested = proofDocuments.length + momentaDocuments.length === 0;
    const documentsNotUploaded = noDocumentsUploaded.length + noMomentaUploaded.length;

    const dispatch = useDispatch();

    const applicationsAndContractsCardVisible = showApplicationsAndContracts && (profile.contracts?.length > 0 || profile?.candidacies?.length > 0);

    const getReferAFriendText = () => {
        if (showReferAFriendTextLink) {
            return (
                <a href={referAFriendLink} target="_blank" rel="noopener noreferrer">
                    {referAFriendText}
                </a>
            );
        } else {
            return referAFriendText;
        }
    };

    const [welcomeMessageOpen, setWelcomeMessageOpen] = React.useState<boolean>(true);

    const saveWelcomeMessageViewed = (welcomeMessageViewed: boolean) => {
        setWelcomeMessageOpen(false);
        if (welcomeMessageViewed) {
            dispatch(saveCurrentUser({ ...profile, welcomeMessageViewed }));
        }
    };

    return (
        <>
            <div className="associate-landing">
                <Grid>
                    <Container>
                        {showReferAFriend && (
                            <>
                                <div className="dashboard-message">
                                    {getReferAFriendText()}
                                </div>
                            </>
                        )}

                        <Card.Group itemsPerRow={3} doubling stackable>

                            {contracts.length > 0 && <TimesheetCard timesheets={timesheets} />}
                            <WelcomeMessageModal
                                open={profile.welcomeMessageViewed === false && welcomeMessageOpen}
                                onClose={saveWelcomeMessageViewed}
                            />
                            <PersonalInformationCard />
                            <EmploymentHistoryCard />
                            <BackgroundScreeningCard noDocumentsRequested={noDocumentsRequested} documentsNotUploaded={documentsNotUploaded} />
                            <FinanceDetailsCard />
                            { showFinanceInformationCard &&
                                <FinanceInformationCard />
                            }
                            <PreferencesAndConsentCard />
                            <VacanciesCard />
                            { applicationsAndContractsCardVisible &&
                                <ApplicationsAndContractsCard />
                            }
                            { showMomentaTeamsCard &&
                                <MomentaTeamsCard />
                            }
                        </Card.Group>
                    </Container>
                </Grid>
            </div>
        </>
    );
};

const mapStateToProps = (state: TimesheetState & CurrentUserState & VettingDataState & GlobalConfigState & RegionState): DashboardStateProps => {
    return {
        profile: state.currentUser,
        timesheets: state.timesheets,
        proofDocuments: state.proofDocuments,
        momentaDocuments: state.momentaDocuments,
        referAFriendText: localTextSelector(state, "Refer a friend"),
        referAFriendLink: localTextSelector(state, "Refer a friend link"),
        showReferAFriend: localTextSelector(state, "Show refer a friend text") === "True",
        showReferAFriendTextLink: localTextSelector(state, "Show refer a friend text link") === "True",
        showApplicationsAndContracts: localTextSelector(state, "showAssociateApplicationsAndContractsCard"),
        showMomentaTeamsCard: localTextSelector(state, "showMomentaTeamsCard"),
        showFinanceInformationCard: localTextSelector(state, "showFinanceInformationCard")
    };
};

export const Dashboard = connect(mapStateToProps)(DashboardUnconnected);
