import * as React from "react";
import { connect } from "react-redux";
import { Card, Icon, Message } from "semantic-ui-react";
import { isNullOrUndefined } from "@momenta/common";
import { FinanceDocument, FinanceDocumentTypeEnum } from "@momenta/common/financeDocuments";
import { VatDetails } from "@momenta/common/vatDetails";
import { componentVisible } from "@momenta/common/internationalisation/componentVisibleHOC";

import { CurrentUser, CurrentUserState } from "../currentUser/model";

import { DashboardCard } from "./DashboardCard";
import { validate } from "./validators";

interface FinanceDetailsCardProps {
    currentUser: CurrentUser;
}

const validateCompanyDocumentation = (financeDocuments: FinanceDocument[], documentType: FinanceDocumentTypeEnum) => {
    if (isNullOrUndefined(financeDocuments)) {
        return false;
    }
    return financeDocuments.some(d => d.removed === false && d.documentType === documentType);
};

const validateVatDetails = (vatDetails: VatDetails, financeDocuments: FinanceDocument[]) => {
    return validate(vatDetails, ["vatNumber"])
        && validateCompanyDocumentation(financeDocuments, FinanceDocumentTypeEnum["Vat Registration Document"]);
};

const isFinanceDetailsComplete = (props: FinanceDetailsCardProps) => {
    const selectedCompany = props.currentUser.selectedCompany;

    if (isNullOrUndefined(selectedCompany)) {
        return false;
    }

    if (selectedCompany.umbrella) {
        return true;
    }

    const companyValidationResult = validate(selectedCompany, ["name", "registrationNumber", "sortCode", "accountNumber"])
        && validate(selectedCompany.address, ["line1", "postcode"])
        && validateCompanyDocumentation(selectedCompany.financeDocuments, FinanceDocumentTypeEnum["Company Registration Document"])
        && validateCompanyDocumentation(selectedCompany.financeDocuments, FinanceDocumentTypeEnum["Pipl Insurance Document"]);

    const vatDetails = selectedCompany.vatDetails;
    const vatRegistered = vatDetails && vatDetails.vatRegistered;
    const vatValidationResult = validateVatDetails(vatDetails, selectedCompany.financeDocuments);

    return vatRegistered
        ? companyValidationResult && vatValidationResult
        : companyValidationResult;
};

export const FinanceDetailsCardUnconnected: React.SFC<FinanceDetailsCardProps> = (props) => {

    const isComplete = isFinanceDetailsComplete(props);
    const color = !isComplete ? "red" : "green";

    const complete = () => {
        if (isComplete) {
            return <p>Finance details complete <Icon name="checkmark" color="green" /></p>;
        }

        return (
            <Message negative>
                Your finance details are incomplete.
                <br />
                Please complete your finance details in order to be paid accordingly.
            </Message>
        );
    };

    return (
        <DashboardCard
            color={color}
            href="/finance"
            svg="finance"
            title="Finance Details"
        >
            <Card.Content textAlign="center">
                <Card.Meta>
                    {complete()}
                </Card.Meta>
            </Card.Content>
        </DashboardCard>
    );
};

const mapStateToProps = (state: CurrentUserState): FinanceDetailsCardProps => ({
    currentUser: state.currentUser
});

const FinanceDetailsCardConnected = connect(mapStateToProps)(FinanceDetailsCardUnconnected);
export const FinanceDetailsCard = componentVisible("showFinanceTile")(FinanceDetailsCardConnected);
