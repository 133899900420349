import * as React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Result, validate, ValidationFunction } from "not-valid";
import { Input } from "@neworbit/simpleui-input";
import { Button, DropdownItemProps, DropdownProps, Form, Grid, Label, Message } from "semantic-ui-react";
import { EditProps as SharedEditProps, FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { debounce } from "@neworbit/simpleui-utils";

import { FileUpload } from "../FileUpload";
import { ExpenseTypeConfiguration } from "../hierarchicalConfiguration";
import { positiveNumberValidator } from "../validators/positiveNumberValidator";
import { ExpenseVatValidationProps, expenseVatValidator } from "../validators/expenseVatValidator";
import { VatRate } from "../vatRates";
import { LocalText } from "../internationalisation/LocalText";
import { localTextSelector, RegionState } from "../internationalisation";
import { dateConfigOptionSelector } from "../timesheets/dateConfigOptionSelector";
import { truncateGuid } from "..";

import { Expense, ExpenseInputTypeEnum, ReceiptState } from "./model";

interface AddExpenseOwnProps extends SharedEditProps<Expense> {
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    expenses: Expense[];
    startDate: moment.Moment;
    endDate: moment.Moment;
    cancel: () => void;
    online: boolean;
    isAdjustment: boolean;
    submitted: boolean;
    vatRates: VatRate[];
    debounce?: number;
}

interface AddExpenseStateProps {
    taxAbbreviation: string;
}

type AddExpenseProps = AddExpenseOwnProps & AddExpenseStateProps;

export interface AddExpenseState extends FormState<Expense> {
    netValid: boolean;
    expenseErrors: string[];
    isQuantity: boolean;
    uploadingFile: boolean;
}

export function getDateOptions(startDate: moment.Moment, endDate: moment.Moment) {
    const options = [];

    const days = endDate ? endDate.startOf("day").diff(startDate.startOf("day"), "days") + 1 : 7;

    for (let i = 0; i < days; i++) {
        const date = startDate.startOf("day").clone().add(i, "days");
        options.push({
            value: i,
            text: date.format("L"),
            date
        });
    }
    return options;
}

export class AddExpenseUnconnected extends FormBaseComponent<Expense, AddExpenseProps, AddExpenseState> {

    private dateOptions: DropdownItemProps[];

    private expenseVatValidatorDebounced: ValidationFunction<ExpenseVatValidationProps>;

    constructor(props: AddExpenseProps & SaveDispatchProps<Expense>) {

        super(props);

        this.state = {
            values: {
                ...props.model
            },
            valid: {
                net: true,
                vat: true
            },
            netValid: true,
            isQuantity: false,
            expenseErrors: [],
            uploadingFile: false
        };

        this.dateOptions = getDateOptions(props.startDate, props.endDate);
        this.onDateChange = this.onDateChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.getDailyLimit = this.getDailyLimit.bind(this);
        this.getExpenseTypeOptionValue = this.getExpenseTypeOptionValue.bind(this);
        this.expenseVatValidatorDebounced = debounce(expenseVatValidator, props.debounce || 500);
    }

    public render() {
        const dateOption = this.dateOptions.filter(o => this.state.values.date.isSame(o.date))[0] || this.dateOptions[0];
        const dateValue = dateOption.value;
        const expensesWithReceiptOption = this.props.expenses
            .filter(e => e.receiptFile)
            .filter(e => e.id !== this.state.values.id)
            .map((e, index) => ({ key: index, text: `${e.description} - ${e.date.format("LL")}`, value: e.id }));

        const expenseValidation = [];

        if (!this.props.isAdjustment) {
            expenseValidation.push(positiveNumberValidator);
        }

        const onUpdatePreviousReceiptExpenseId = this.getUpdate("previousReceiptExpenseId");
        const onUpdateReceiptFile = this.getUpdate("receiptFile");
        const onUpdateDescription = this.getUpdate("description");
        const onUpdateAmount = this.getUpdate("amount");

        const currentExpenseType = this.state.values.expenseTypeId ?
            this.props.expenseTypeConfigurations.filter(e => e.id === this.state.values.expenseTypeId)[0] : undefined;

        const receiptRequired = currentExpenseType ? currentExpenseType.receiptRequired : false;

        const categories = dateConfigOptionSelector({
            config: this.props.expenseTypeConfigurations,
            date: this.state.values.date
        });

        const setUploading = (uploading: boolean) => {
            this.setState({ uploadingFile: uploading });
        };

        const receipt = () => {

            if (this.state.values.previousReceipt) {
                return (
                    <Input.DropdownNumber
                        label="Expense with"
                        options={expensesWithReceiptOption}
                        value={this.state.values.previousReceiptExpenseId}
                        showErrors={this.state.showErrors}
                        onChange={onUpdatePreviousReceiptExpenseId}
                        dynamicOptions
                        required
                    />
                );
            }

            return (
                <>
                    <FileUpload
                        label="Receipt"
                        apiUrl="/api/receipt"
                        placeholder="Upload receipt..."
                        value={this.state.values.receiptFile}
                        filename={truncateGuid(this.state.values.receiptFile)}
                        onFileChange={onUpdateReceiptFile}
                        disabled={!this.props.online}
                        fileTypes={["image/*"]}
                        setUploading={setUploading}
                    />
                    {!this.props.online &&
                        <Message info icon="info" size="tiny" content="You cannot upload a receipt while you're offline" />
                    }
                </>
            );
        };

        return (
            <Grid className="nomargintop">
                <Grid.Row >
                    <Grid.Column textAlign="center">
                        {this.props.model.receiptState === ReceiptState.Rejected &&
                            <Message visible error content={`Receipt Rejection Reason: ${this.props.model.receiptRejectionReason}`} />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} computer={3}>
                        <Form.Select
                            fluid
                            label="Date"
                            value={dateValue}
                            options={this.dateOptions}
                            placeholder="Date"
                            onChange={this.onDateChange}
                            disabled={this.props.submitted}
                        />
                    </Grid.Column>

                    <Grid.Column mobile={16} computer={7}>
                        <Input.Text
                            value={this.state.values.description}
                            onChange={onUpdateDescription}
                            showErrors={this.state.showErrors}
                            label="Description"
                            placeholder="Reference for this expense"
                            disabled={this.props.submitted}
                            required
                        />
                    </Grid.Column>

                    <Grid.Column mobile={16} computer={6}>
                        {receiptRequired && receipt()}
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>

                    <Grid.Column mobile={16} computer={7}>
                        <Input.DropdownNumber
                            value={this.state.values.expenseTypeId}
                            onChange={this.onExpenseTypeConfigurationChange}
                            options={categories}
                            showErrors={this.state.showErrors}
                            placeholder="Choose Category"
                            label="Category"
                            disabled={this.props.submitted}
                            dynamicOptions
                            required
                        />
                    </Grid.Column>
                    {!this.state.isQuantity &&
                        <>
                            <Grid.Column mobile={16} computer={2}>
                                <Input.Number
                                    value={this.state.values.net}
                                    showErrors={this.state.showErrors}
                                    onChange={this.updateNet}
                                    label={<><LocalText keyName="currencySymbol" /> Net</>}
                                    placeholder="00.00"
                                    validation={expenseValidation}
                                    disabled={this.props.submitted}
                                    required
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} computer={2}>
                                <Input.Number
                                    value={this.state.values.vat}
                                    showErrors={this.state.showErrors}
                                    onChange={this.updateVat}
                                    label={<><LocalText keyName="currencySymbol" /> <LocalText keyName="taxAbbreviation" /></>}
                                    placeholder="00.00"
                                    validation={expenseValidation}
                                    disabled={this.props.submitted}
                                    required
                                />
                            </Grid.Column>
                        </>
                    }
                    {this.state.isQuantity &&
                        <Grid.Column mobile={16} computer={4}>
                            <Input.Number
                                value={this.state.values.amount}
                                showErrors={this.state.showErrors}
                                onChange={onUpdateAmount}
                                label="Quantity"
                                placeholder="00.00"
                                disabled={this.props.submitted}
                            />
                        </Grid.Column>
                    }

                    { receiptRequired &&
                        <Grid.Column mobile={16} computer={5}>
                            <Input.Checkbox
                                label="Link to previous receipt"
                                value={this.state.values.previousReceipt}
                                onChange={this.onUpdatePreviousReceipt}
                            />
                        </Grid.Column>
                    }

                </Grid.Row>
                {this.state.netValid === false &&
                    <Grid.Row>
                        <Grid.Column mobile={16} computer={16}>
                            <Label basic color="red">Net cannot exceed limit of {this.getDailyLimit()}</Label>
                        </Grid.Column>
                    </Grid.Row>
                }
                {this.state.expenseErrors.length > 0 &&
                    <Grid.Row>
                        <Grid.Column mobile={16} computer={16}>
                            <Label basic color="red">{this.state.expenseErrors}</Label>
                        </Grid.Column>
                    </Grid.Row>
                }
                <Grid.Row>
                    <Grid.Column mobile={16} computer={16}>
                        <Button.Group floated="right">
                            <Button onClick={this.onCancel} content="Cancel" icon="cancel" color="red" basic />
                            <Button onClick={this.onSave} disabled={this.state.uploadingFile}  content="Save" icon="save outline" color="green" />
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    public UNSAFE_componentWillReceiveProps(props: AddExpenseProps & SaveDispatchProps<Expense>) {
        this.dateOptions = getDateOptions(props.startDate, props.endDate);
    }

    public componentWillMount() {
        this.getExpenseTypeOptionValue();
    }

    public shouldComponentUpdate(nextProps: AddExpenseProps & SaveDispatchProps<Expense>, nextState: AddExpenseState) {
        return this.props !== nextProps || this.state !== nextState;
    }

    private onExpenseTypeConfigurationChange = async (value: number, valid: boolean) => {
        await this.updateExpenseType(value, valid);

        const selectedConfig = this.props.expenseTypeConfigurations.filter(c => c.id === value)[0];
        const isQuantity = selectedConfig && selectedConfig.type.expenseInputType === ExpenseInputTypeEnum.Quantity;

        this.setState(prevState => ({
            isQuantity,
            values: {
                ...prevState.values,
                amount: isQuantity ? prevState.values.amount : null
            }
        }));
    }

    private getExpenseTypeOptionValue() {
        const configs = this.props.expenseTypeConfigurations || [];
        const expense = this.state.values;

        if (configs.some(e => e.id === expense.expenseTypeId)) {
            return;
        }

        if (expense.expenseType) {
            const expenseType = configs.filter(e => e.typeId === expense.expenseType.typeId)[0];

            if (expenseType) {
                this.setState(current => ({
                    values: {
                        ...current.values,
                        expenseTypeId: expenseType.id,
                        expenseType
                    }
                }));
            }
        }
    }

    private onSave() {
        if (this.state.expenseErrors.length > 0 || !this.valid()) {
            this.setState({
                showErrors: true
            });
            return;
        }

        this.setState(current => ({
            values: {
                ...current.values,
                receiptState: ReceiptState.Pending,
            }
        }), () => this.props.save(this.state.values));
    }

    private updateExpenseType = async (value: any, valid?: boolean) => {
        this.updateProperty("expenseTypeId", value, valid);

        await this.updateNet(this.state.values.net, this.state.valid.net);
    }

    private updateNet = async (value: any, valid?: boolean) => {

        let netValid = true;

        if (this.state.values.expenseTypeId !== null) {
            netValid = this.validateNumber(value) && value <= this.getDailyLimit();
        }

        this.updateProperty("net", value, valid);

        const errors = await validate<ExpenseVatValidationProps>(
            [this.expenseVatValidatorDebounced],
            {
                vatRates: this.props.vatRates,
                net: value,
                vat: this.state.values.vat,
                date: this.state.values.date,
                taxAbbreviation: this.props.taxAbbreviation
            });

        this.setState(current => ({
            values: current.values,
            netValid,
            valid: {
                ...current.valid,
                net: valid && netValid
            },
            expenseErrors: errors
        }));

        await this.updateVat(this.state.values.vat, true);
    }

    private updateVat = async (value: any, valid?: boolean) => {
        this.updateProperty("vat", value, valid);

        const errors = await validate<ExpenseVatValidationProps>(
            [this.expenseVatValidatorDebounced],
            {
                vatRates: this.props.vatRates,
                net: this.state.values.net,
                vat: value,
                date: this.state.values.date,
                taxAbbreviation: this.props.taxAbbreviation
            });

        this.setState(current => ({
            values: current.values,
            valid: {
                ...current.valid,
                vat: valid && errors && errors.length === 0
            },
            expenseErrors: errors
        }));
    }

    private onUpdatePreviousReceipt = async (value: boolean, valid?: boolean) => {

        if (value === false) {
            this.setState(previous => ({
                valid: { ...previous.valid, previousReceiptExpenseId: true },
                values: { ... previous.values, previousReceiptExpenseId: null }
            }));
        }

        this.updateProperty("previousReceipt", value, valid);
    }

    private validateNumber = (value: number) => {
        return this.props.isAdjustment || positiveNumberValidator(value) === Result.Pass;
    }

    private onCancel(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();
        this.props.cancel();
    }

    private onDateChange(event: any, props: DropdownProps) {
        const option = this.dateOptions[+props.value];
        this.updateProperty("date", option.date, true);
    }

    private getDailyLimit = () => {
        const expenseTypes = this.props.expenseTypeConfigurations.filter(e => e.id === this.state.values.expenseTypeId);

        if (expenseTypes.length === 0) {
            return undefined;
        }

        return expenseTypes[0].dailyLimit;
    }
}

const mapStateToProps = (state: RegionState): AddExpenseStateProps => ({
    taxAbbreviation: localTextSelector(state, "taxAbbreviation")
});

export const AddExpense = connect(mapStateToProps)(AddExpenseUnconnected);
