import * as React from "react";
import { Button, Icon, List } from "semantic-ui-react";
import { FileUpload } from "@momenta/common";

import { DocumentVettingData } from "../../vetting/model";

interface DocumentUploadProps {
    vettingData: DocumentVettingData;
    controllerName: string;
    saveDocument: (vettingData: DocumentVettingData) => Promise<void>;
}

interface DocumentUploadState {
    hasDocument: boolean;
    vettingData: DocumentVettingData;
}

export class DocumentUpload extends React.Component<DocumentUploadProps, DocumentUploadState> {

    constructor(props: DocumentUploadProps) {
        super(props);

        this.state = {
            hasDocument: false,
            vettingData: props.vettingData
        };

        this.onSave = this.onSave.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    public render() {
        const vettingData = this.props.vettingData;

        const onFileChange = (value: string ) => { this.onFileChange(value); };

        return (
            <div className="document-upload">
                {vettingData.document === null &&
                    <List.Content floated="right">
                        <Button positive icon="save outline" onClick={this.onSave} disabled={this.state.vettingData.document === null} />
                    </List.Content>
                }

                <List.Content floated="right" className="upload-button" verticalAlign="middle">

                    {vettingData.document === null &&
                        <FileUpload
                            value={this.state.vettingData.document}
                            apiUrl={`/api/${this.props.controllerName}/${vettingData.id}/document`}
                            hideTextInput
                            uploadText=""
                            noClear
                            onFileChange={onFileChange}
                        />
                    }

                    {vettingData.document !== null && <Icon name="check" color="green" />}
                </List.Content>
            </div>
        );
    }

    private onFileChange(value: string) {
        this.setState(current => ({ vettingData: { ...current.vettingData, document: value } }));
    }

    private async onSave() {
        await this.props.saveDocument(this.state.vettingData);
    }
}

export default DocumentUpload;
