import { Payload } from "@neworbit/redux-helpers";

import * as actions from "./actionTypes";

export type ConnectionStatusAction =
    ({ type: actions.STATUS_CHANGED } & Payload<boolean>);

export function connectionStatusChanged(payload: boolean): ConnectionStatusAction {
    return {
        payload,
        type: actions.STATUS_CHANGED
    };
}
