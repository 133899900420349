import moment from "moment";
import { State as RouterState } from "redux-little-router";

export interface Reference {
    id: number;
    start?: moment.Moment;
    end?: moment.Moment;
    current: boolean;
    referenceType: ReferenceType;
    associateId: number;
    submitted: boolean;
    detail: boolean;
    gap: boolean;
    summary: string;
    referenceSent?: boolean;
    referenceSentDate?: moment.Moment;
    referenceSentBy?: string;
    referenceReceived?: boolean;
    referenceReceivedDate?: moment.Moment;
    referenceReceivedBy?: string;
    refereeEmail?: string;
    updated?: moment.Moment;
    refereePhoneNumber?: string;
    contactRefereeConsent?: boolean;
    validated?: boolean;
}

export interface AcademicReference extends Reference {
    institutionName: string;
    courseTitle: string;
    qualificationType: QualificationType;
    qualificationTypeOther: string;
    grade: string;
}

export interface EmploymentReference extends Reference {
    employer: string;
    directEmployment: boolean;
    agencyName: string;
    employmentAddress: string;
    jobTitle: string;
    employmentType: EmploymentStatusType;
    leavingReason: string;
    referenceIssues: string;
}

export interface GapReference extends Reference {
    gapReason: number;
    otherGapReason: string;
}

export interface ReferenceState {
    references: Reference[];
}

export interface ReferenceConfiguration {
    contractStartDate: moment.Moment;
    nonEmploymentPeriod: number;
    referencingPeriod: number;
}

export enum EmploymentStatusType {
    Unknown = 0,
    Employed = 10,
    SelfEmployed = 20,
    Voluntary = 30,
    Contractor = 40
}

export const EmploymentStatusTypeOptions = {
    0: "Unknown",
    10: "Employed",
    20: "Self-Employed",
    30: "Voluntary",
    40: "Contractor"
};

export enum ReferenceType {
    Unknown = 0,
    Employment = 10,
    Academic = 20,
    Gap = 30
}

export const ReferenceTypeOptions = {
    0: "Unknown",
    10: "Employment",
    20: "Academic",
    30: "Gap"
};

export enum QualificationType {
    Unknown = 0,
    BSc = 10,
    BA = 20,
    MA = 30,
    Other = 40
}

export enum GapReason {
    "Unknown" = 0,
    "Maternity Leave" = 10,
    "Travel" = 20,
    "Seeking Employment" = 30,
    "Career Break" = 40,
    "Redundancy" = 50,
    "Medical Reasons" = 60,
    "Caring For Others" = 70,
    "Lifestyle Projects" = 80,
    "Other" = 90
}

export type AppState = RouterState & {
    references: Reference[]
};
