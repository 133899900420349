import localForage from "localforage";

export class DismissableMessageStorage {
    private readonly storagePrefix = "dismissable-message";

    public async save(name: string, showMessage: boolean): Promise<any> {
        const key = this.getKey(name);
        await localForage.setItem(key, showMessage);
    }

    public async load(name: string): Promise<boolean> {
        const key = this.getKey(name);
        return localForage.getItem(key);
    }

    private getKey(name: string) {
        return `${this.storagePrefix}_${name}`;
    }
}
