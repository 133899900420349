import * as React from "react";
import { Bonus } from "@momenta/common/bonuses";
import { Table } from "semantic-ui-react";
import { Currency } from "@momenta/common/internationalisation/Currency";

interface BonusRowPrintProps {
    bonus: Bonus;
}

export const BonusRowPrint: React.FC<BonusRowPrintProps> = ({ bonus }) => {
    return (
        <Table.Row key={bonus.id}>
            <Table.Cell >{bonus?.bonusType?.type?.name}</Table.Cell>
            <Table.Cell ><Currency value={bonus?.amount} /></Table.Cell>
        </Table.Row>
    );
};
