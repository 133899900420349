import { loadAndTrack } from "redux-request-loading";
import { loadPaginationTotalSuccess } from "@momenta/common/pagination";
import { AppState, loadUmbrellaCompaniesSuccess } from "@momenta/common/umbrellaCompany";

import UmbrellaCompanyApi from "./umbrellaCompanyApi";

export function loadUmbrellaCompanies(loadAll: boolean = false) {
    return async (dispatch: any, getState: () => AppState) => {
        const pageNumber = +(getState().router.query.page || 1);
        const { items, count } = await loadAndTrack(dispatch, "loadUmbrellaCompanies", UmbrellaCompanyApi.getAll(pageNumber, loadAll));
        dispatch(loadUmbrellaCompaniesSuccess(items));
        dispatch(loadPaginationTotalSuccess(count));
    };
}
