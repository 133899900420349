import * as React from "react";
import { Checkbox, CheckboxProps, Form } from "semantic-ui-react";
import { SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { CreateReference, ReferenceOwnProps, ReferenceProps } from "@momenta/common/referencing/CreateReference";
import { EmploymentReference, EmploymentStatusTypeOptions, ReferenceType } from "@momenta/common/referencing/model";
import { getDateInputFormat } from "@momenta/common/dateFormatting/getDateInputFormat";
import { createValidator } from "not-valid";
import moment = require("moment");

import { YesNoOption } from "../YesNoOption";
import { isNotNullOrUndefined } from "..";

import { RefereeEmail } from "./RefereeEmail";
import { RefereePhoneNumber } from "./RefereePhoneNumber";

export class CreateEmploymentReference extends CreateReference<EmploymentReference> {
    constructor(props: ReferenceProps & ReferenceOwnProps<EmploymentReference> & SaveDispatchProps<EmploymentReference>) {
        super(props);
        this.state = {
            values: props.reference || this.defaultState(),
            valid: {
                contactRefereeConsent: isNotNullOrUndefined(this.props.reference?.contactRefereeConsent) || this.props.associateView !== true
            }
        };
    }

    private employmentEndsAfterItStarts() {
        return this.state.values.end >= this.state.values.start;
    }

    public render() {
        const {
            values: {
                employer,
                start,
                current,
                end,
                jobTitle,
                directEmployment,
                agencyName,
                employmentAddress,
                employmentType,
                leavingReason,
                referenceIssues,
                refereeEmail,
                submitted,
                refereePhoneNumber,
                contactRefereeConsent
            }
        } = this.state;

        const employmentStatusTypeOptions = Object.keys(EmploymentStatusTypeOptions)
            .filter(k => +k !== 0)
            .map(k => ({ text: EmploymentStatusTypeOptions[k], value: +k }));
        const readOnly = this.props.disabled === true && submitted;

        const futureDateCheck = createValidator<moment.Moment>(
            () => this.employmentEndsAfterItStarts(),
            "Reference end date must be after the start date"
        );

        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Text
                    value={employer}
                    label="Employer/End Client"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("employer")}
                    readOnly={readOnly}
                    required
                />
                <Form.Field>
                    <label>Please provide detail of how you were employed</label>
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label="Direct Employment"
                        name="directEmploymentGroup"
                        value={"true"}
                        checked={directEmployment === true}
                        onChange={this.onDirectEmploymentChange}
                        readOnly={readOnly}
                        radio
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        label="Supplied via Agency"
                        name="directEmploymentGroup"
                        value={"false"}
                        checked={directEmployment === false}
                        onChange={this.onDirectEmploymentChange}
                        readOnly={readOnly}
                        radio
                    />
                </Form.Field>
                {directEmployment === false && <Input.Text
                    value={agencyName}
                    label="Agency Name"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("agencyName")}
                    readOnly={readOnly}
                    required
                />}
                <Input.Date
                    value={start}
                    label="Start Date"
                    onChange={this.getUpdate("start")}
                    format={getDateInputFormat()}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    required
                />
                {current === false && <Input.Date
                    value={end}
                    label="End Date"
                    onChange={this.getUpdate("end")}
                    format={getDateInputFormat()}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    validation={[futureDateCheck]}
                    required
                />}
                <Input.Checkbox
                    value={current}
                    label="To Present"
                    onChange={this.onCurrentChange}
                    readOnly={readOnly}
                />
                <Input.Textarea
                    value={employmentAddress}
                    label="Please provide the address of your place of work"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("employmentAddress")}
                    readOnly={readOnly}
                    required
                />
                <Input.Text
                    value={jobTitle}
                    label="Job Title"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("jobTitle")}
                    readOnly={readOnly}
                    required
                />
                <Input.DropdownNumber
                    value={employmentType}
                    onChange={readOnly === false && this.getUpdate("employmentType")}
                    label="What is the nature of your employment status?"
                    options={employmentStatusTypeOptions}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    dynamicOptions
                    required
                />
                <Input.Text
                    value={leavingReason}
                    label="Reason for leaving"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("leavingReason")}
                    readOnly={readOnly}
                    required
                />
                <Input.Text
                    value={referenceIssues}
                    label="Please provide details if you are aware of any issues we may encounter when trying to obtain a reference"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("referenceIssues")}
                    readOnly={readOnly}
                />
                {this.props.showRefereeEmail &&
                    <RefereeEmail
                        refereeEmail={refereeEmail}
                        showErrors={this.state.showErrors}
                        disabled={this.props.disabled}
                        onChange={this.getUpdate("refereeEmail")}
                        onSendEmailClick={this.props.onSendEmailClick}
                    />
                }
                {this.props.showRefereePhoneNumber &&
                    <RefereePhoneNumber
                        refereePhoneNumber={refereePhoneNumber}
                        showErrors={this.state.showErrors}
                        onChange={this.getUpdate("refereePhoneNumber")}
                    />
                }
                {this.props.associateView &&
                    <YesNoOption
                        label="Do we have permission to contact this employer for a reference?"
                        propValue={contactRefereeConsent}
                        onChange={this.getYesNoChange("contactRefereeConsent")}
                        showErrors={this.state.showErrors}
                        required={this.props.associateView}
                    />
                }
            </Form>
        );
    }

    public onCurrentChange = (value: any) => {
        if (this.state.values.current === value) {
            return;
        }

        this.setState(prevState => ({
            values: {
                ...prevState.values,
                current: value,
                end: value ? null : prevState.values.end
            },
            valid: {
                ...prevState.valid,
                end: value ? true : prevState.valid.end
            }
        }));
    }

    public onDirectEmploymentChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        const directEmployment = data.value === "true";
        this.setState(prevState => ({
            values: {
                ...prevState.values,
                directEmployment
            },
            valid: {
                ...prevState.valid,
                agencyName: directEmployment
            }
        }));
    }

    private getYesNoChange(key: keyof EmploymentReference) {
        return (value: boolean) => this.updateProperty(key, value, true);
    }

    private defaultState(): EmploymentReference {

        const { start, end } = this.props;
        const reference = this.getDefaultReferenceState(ReferenceType.Employment);
        const startDate = start && start.isValid() ? start : null;
        const endDate = end && end.isValid() ? end : null;

        return {
            ...reference,
            start: startDate,
            end: endDate,
            employer: "",
            directEmployment: false,
            agencyName: "",
            employmentAddress: "",
            jobTitle: "",
            employmentType: undefined,
            leavingReason: "",
            referenceIssues: "",
        };
    }
}
