import { State as RouterState } from "redux-little-router";
import { createSelector } from "reselect";
import { ReferenceVettingData } from "src/App/People/src/vetting/model";

import { createEmptyState } from "../utils/createEmptyState";

import { AppState, Reference, ReferenceState } from "./model";

export const allReferencesSelector = (state: RouterState & ReferenceState) => state.references;

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("referencing") + "referencing".length);

export const routeIdSelector = (state: RouterState) => {
    return +state.router.params.referenceId;
};

export const referenceIdSelector = (state: any, props: { vettingData?: ReferenceVettingData }) =>
    props.vettingData && props.vettingData.referenceId;

export const vettingReferenceSelector = createSelector(
    allReferencesSelector,
    referenceIdSelector,
    (references, referenceId) => references.filter(r => r.id === referenceId)[0]
);

export const referenceSelector = createSelector(
    allReferencesSelector,
    routeIdSelector,
    (references: Reference[], id: number) => {
        return references.filter(r => r.id === id)[0] || createEmptyState<Reference>();
    }
);
