import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";
import { replace } from "redux-little-router";
import { saveRegion } from "@momenta/common/internationalisation";
import { State as RouterState } from "redux-little-router";

import * as actions from "./actionTypes";
import { currentUserApi } from "./CurrentUserApi";
import { CurrentUser } from "./model";

export type ProfileAction =
    ({ type: actions.LOAD_CURRENT_USER_SUCCESS } & Payload<CurrentUser>)
    | ({ type: actions.UNAUTHENTICATED });

export function loadCurrentUserSuccess(payload: CurrentUser): ProfileAction {
    return {
        payload,
        type: actions.LOAD_CURRENT_USER_SUCCESS
    };
}

export function unauthenticatedUser(): ProfileAction {
    return {
        type: actions.UNAUTHENTICATED
    };
}

export function loadCurrentUser() {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "loadCurrentUser", currentUserApi.load());
        await dispatch(loadCurrentUserSuccess(result));
        await dispatch(saveRegion((result.region)));
    };
}

export function saveCurrentUser(model: CurrentUser) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "saveCurrentUser", currentUserApi.save(model));
        dispatch(loadCurrentUser());
    };
}

export function requestCompanyChange(message: string, subject?: string) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "requestCompanyChange", currentUserApi.requestCompanyChange(message, subject));
    };
}

export function unauthenticated() {
    return async (dispatch: any, getState: () => RouterState) => {
        const isViewingJobLink = getState().router.pathname.startsWith("/jobLink");
        if (isViewingJobLink === false) {
            await dispatch(unauthenticatedUser());
            dispatch(replace("/"));
        }
    };
}
