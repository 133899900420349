/* eslint-disable max-classes-per-file */
import moment from "moment";
import { VettingItemState } from "@momenta/common";

export interface VettingItem {
    readonly id: number;
    readonly state: VettingItemState;
    readonly vettingType: VettingTypeModel;
    readonly associateId: number;
    readonly associate: AssociateModel;
    readonly ragStatus: number;
    readonly updated: moment.Moment;
    readonly created: moment.Moment;
    readonly validated: boolean;
    readonly validatedById: number;
    readonly validatedBy: UserModel;
    readonly dateValidated: moment.Moment;
    readonly expiryDate?: moment.Moment;
    readonly noExpiry: boolean;
}

export interface VettingTypeModel {
    id: number;
    name?: string;
    description?: string;
    type?: number;
    documentType?: number;
    document?: string;
    originalToBeSeen?: boolean;
    fastTrack?: boolean;
}

export interface AssociateModel {
    id: number;
}

export interface UserModel {
    id: number;
    emailAddress: string;
    forename: string;
    surname: string;
}

export interface VettingData {
    id: number;
    vettingItem: VettingItem;
}

export interface DocumentVettingData extends VettingData {
    document: string;
    documentProvidedDate?: moment.Moment;
}

export interface ProofDocumentVettingData extends DocumentVettingData {
    originalSeen: boolean;
    seenBy: string;
    dateSeen?: moment.Moment;
}

export interface MomentaDocumentVettingData extends DocumentVettingData {
}

export type VettingDataState = {
    proofDocuments: ProofDocumentVettingData[],
    momentaDocuments: MomentaDocumentVettingData[]
};

export const VettingItemHeaderState = {
    0: "Unknown",
    10: "Waiting (Associate)",
    20: "Ready to Start",
    30: "In Progress",
    40: "Waiting (External)",
    50: "On Hold",
    60: "Awaiting Completion",
    70: "QC Complete",
    80: "Complete",
    90: "Closed"
};

export const RagStatus = {
    0: "Unknown",
    10: "Incomplete (Red)",
    20: "Complete - Issue (Orange)",
    30: "Complete - Clear (Green)"
};

export const VettingTypeOption = {
    0: "Unknown",
    10: "General",
    20: "Document"
};

export enum VettingDocumentTypeEnum {
    "Unknown" = 0,
    "Original Document" = 10,
    "Momenta Document" = 20,
    "Proof Document" = 30
}

export const VettingDocumentType = {
    0: "Unknown",
    10: "Original Document",
    20: "Momenta Document",
    30: "Proof Document"
};

export class VettingItemBuilder {

    private id: number;
    private state: VettingItemState;
    private vettingType: VettingTypeModel;
    private candidateId: number;
    private candidate: AssociateModel;
    private ragStatus: number;
    private updated: moment.Moment;
    private created: moment.Moment;
    private validated: boolean;
    private validatedById: number;
    private validatedBy: UserModel;
    private dateValidated: moment.Moment;
    private expiryDate: moment.Moment;
    private noExpiry: boolean;

    constructor() {
        this.id = 0;
        this.state = 0;
        this.vettingType = undefined;
        this.candidateId = 0;
        this.candidate = undefined;
        this.ragStatus = 0;
        this.updated = undefined;
        this.created = undefined;
        this.validated = false;
        this.validatedById = 0;
        this.validatedBy = undefined;
        this.dateValidated = undefined;
        this.expiryDate = undefined;
        this.noExpiry = false;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withState(state: VettingItemState) {
        this.state = state;
        return this;
    }

    public withVettingType(vettingType: VettingTypeModel) {
        this.vettingType = vettingType;
        return this;
    }

    public withCandidateId(candidateId: number) {
        this.candidateId = candidateId;
        return this;
    }

    public withCandidate(candidate: AssociateModel) {
        this.candidate = candidate;
        return this;
    }

    public withRagStatus(ragStatus: number) {
        this.ragStatus = ragStatus;
        return this;
    }

    public withUpdated(updated: moment.Moment) {
        this.updated = updated;
        return this;
    }

    public withCreated(created: moment.Moment) {
        this.created = created;
        return this;
    }

    public withValidated(validated: boolean) {
        this.validated = validated;
        return this;
    }

    public withValidatedById(validatedById: number) {
        this.validatedById = validatedById;
        return this;
    }

    public withValidatedBy(validatedBy: UserModel) {
        this.validatedBy = validatedBy;
        return this;
    }

    public withDateValidated(dateValidated: moment.Moment) {
        this.dateValidated = dateValidated;
        return this;
    }

    public build(): VettingItem {
        return {
            id: this.id,
            state: this.state,
            vettingType: this.vettingType,
            associateId: this.candidateId,
            associate: this.candidate,
            ragStatus: this.ragStatus,
            updated: this.updated,
            created: this.created,
            validated: this.validated,
            validatedById: this.validatedById,
            validatedBy: this.validatedBy,
            dateValidated: this.dateValidated,
            expiryDate: this.expiryDate,
            noExpiry: this.noExpiry
        };
    }
}

export class ProofDocumentVettingDataBuilder {
    private id: number;
    private vettingItem: VettingItem;
    private document: string;
    private documentProvidedDate?: moment.Moment;
    private originalSeen: boolean;
    private seenBy: string;
    private dateSeen?: moment.Moment;

    constructor() {
        this.id = 0;
        this.vettingItem = undefined;
        this.document = "";
        this.documentProvidedDate = undefined;
        this.originalSeen = false;
        this.seenBy = "";
        this.dateSeen = undefined;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withVettingItem(vettingItem: VettingItem) {
        this.vettingItem = vettingItem;
        return this;
    }

    public withDocument(document: string) {
        this.document = document;
        return this;
    }

    public withDocumentProvidedDate(documentProvidedDate: moment.Moment) {
        this.documentProvidedDate = documentProvidedDate;
        return this;
    }

    public withOriginalSeen(originalSeen: boolean) {
        this.originalSeen = originalSeen;
        return this;
    }

    public withSeenBy(seenBy: string) {
        this.seenBy = seenBy;
        return this;
    }

    public withDateSeen(dateSeen: moment.Moment) {
        this.dateSeen = dateSeen;
        return this;
    }

    public build(): ProofDocumentVettingData {
        return {
            id: this.id,
            vettingItem: this.vettingItem,
            document: this.document,
            documentProvidedDate: this.documentProvidedDate,
            originalSeen: this.originalSeen,
            seenBy: this.seenBy,
            dateSeen: this.dateSeen
        };
    }
}

export class DocumentVettingDataBuilder {
    private id: number;
    private vettingItem: VettingItem;
    private document: string;
    private documentProvidedDate?: moment.Moment;

    constructor() {
        this.id = 0;
        this.vettingItem = undefined;
        this.document = "";
        this.documentProvidedDate = undefined;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withVettingItem(vettingItem: VettingItem) {
        this.vettingItem = vettingItem;
        return this;
    }

    public withDocument(document: string) {
        this.document = document;
        return this;
    }

    public withDocumentProvidedDate(documentProvidedDate: moment.Moment) {
        this.documentProvidedDate = documentProvidedDate;
        return this;
    }

    public build(): MomentaDocumentVettingData {
        return {
            id: this.id,
            vettingItem: this.vettingItem,
            document: this.document,
            documentProvidedDate: this.documentProvidedDate,
        };
    }
}

export class VettingTypeBuilder {
    private id: number;
    private name: string;
    private description: string;
    private type: number;
    private documentType: number;
    private document: string;
    private originalToBeSeen: boolean;
    private fastTrack: boolean;

    constructor() {
        this.id = 0;
        this.name = "";
        this.description = "";
        this.type = 0;
        this.documentType = 0;
        this.document = "";
        this.originalToBeSeen = false;
        this.fastTrack = false;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withName(name: string) {
        this.name = name;
        return this;
    }

    public withDescription(description: string) {
        this.description = description;
        return this;
    }

    public withType(type: number) {
        this.type = type;
        return this;
    }

    public withDocumentType(documentType: number) {
        this.documentType = documentType;
        return this;
    }

    public withDocument(document: string) {
        this.document = document;
        return this;
    }

    public withOriginalToBeSeen(originalToBeSeen: boolean) {
        this.originalToBeSeen = originalToBeSeen;
        return this;
    }

    public withFastTrack(fastTrack: boolean) {
        this.fastTrack = fastTrack;
        return this;
    }

    public build(): VettingTypeModel {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            type: this.type,
            documentType: this.documentType,
            document: this.document,
            originalToBeSeen: this.originalToBeSeen,
            fastTrack: this.fastTrack
        };
    }
}
