import * as React from "react";
import { Link } from "redux-little-router";
import { Button, Label, List } from "semantic-ui-react";

import { DocumentVettingData, VettingDocumentType } from "../../vetting/model";

import { DocumentUpload } from "./DocumentUpload";

interface DocumentListProps {
    vettingDatas: DocumentVettingData[];
    controllerName: string;
    saveDocument: (vettingData: DocumentVettingData) => Promise<void>;
}

const DocumentList: React.FC<DocumentListProps> = ({ vettingDatas, controllerName, saveDocument }) => {

    const documentType = (document: DocumentVettingData): string => {
        return `${VettingDocumentType[document.vettingItem.vettingType.documentType]}`;
    };

    return (
        <List divided relaxed verticalAlign="middle" className="document-list">
            {vettingDatas.map(vettingData => (
                <List.Item key={vettingData.id}>

                    <DocumentUpload vettingData={vettingData} controllerName={controllerName} saveDocument={saveDocument} />

                    {documentType(vettingData) === "Momenta Document" &&
                        <List.Content floated="right">
                            <Link href={`/api/document/${vettingData.vettingItem.vettingType.document}`} target="_blank">
                                <Button icon="download" primary />
                            </Link>
                        </List.Content>
                    }

                    <List.Content floated="right">
                        {vettingData.vettingItem.vettingType.originalToBeSeen &&
                            <Label content="Original to be provided" icon="warning" color="yellow" size="tiny" className="original-to-be-provided" />
                        }
                    </List.Content>

                    <List.Content verticalAlign="middle" floated="left">
                        <List.Header>
                            {`${vettingData.vettingItem.vettingType.name}`}
                        </List.Header>
                        <List.Description>
                            {vettingData.vettingItem.vettingType.description && `${vettingData.vettingItem.vettingType.description}`}
                        </List.Description>
                        <List.Description className="document-created-date">
                            Created {vettingData.vettingItem.created.fromNow()}
                        </List.Description>
                    </List.Content>
                </List.Item>
            ))}
        </List>
    );
};

export { DocumentList };
