import { loadGlobalConfig } from "@momenta/common/globalConfig/actions";

import { routes as currentUser } from "./currentUser/routes";
import { routes as timesheet } from "./timesheets";
import { routes as momentaTeams } from "./momentaTeams/routes";
import { loadInvoicePeriods, routes as invoicePeriods } from "./invoicePeriods";
import { loadAllMomentaDocumentVettingData, loadAllProofDocumentVettingData } from "./vetting/actions";
import { routes as referencing } from "./referencing";
import { loadVatRates } from "./vat";
import { loadTopTwoTimesheets } from "./timesheets/actions";
import { routes as contracts } from "./contracts/routes";
import { routes as jobLink } from "./jobLink/routes";
import { routes as financeInformation } from "./financeInformation/routes";

export const routes: any = {
    "/": {
        title: "Home",
        resolve: [
            loadTopTwoTimesheets(),
            loadAllProofDocumentVettingData,
            loadAllMomentaDocumentVettingData,
            loadInvoicePeriods,
            loadVatRates,
            loadGlobalConfig
        ],
        authorize: false,
    },
    "/register": {
        title: "Register",
        authorize: true,
    },
    "/application-submitted": {
        title: "Register",
        authorize: true,
    },
    "/right-to-work": {
        title: "Right to Work in US",
        authorize: false,
    },
    ...currentUser,
    ...timesheet,
    ...invoicePeriods,
    ...referencing,
    ...contracts,
    ...momentaTeams,
    ...jobLink,
    ...financeInformation
};
