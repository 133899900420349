import { Payload } from "@neworbit/redux-helpers";

import * as actions from "./actiontypes";
import * as models from "./model";

export type UmbrellaCompanyAction =
    ({ type: actions.LOAD_UMBRELLACOMPANIES_SUCCESS } & Payload<models.UmbrellaCompanyListModel[]>)
    | ({ type: actions.CREATE_UMBRELLACOMPANY_SUCCESS } & Payload<models.UmbrellaCompanyDetailModel>)
    | ({ type: actions.LOAD_UMBRELLACOMPANY_DETAIL_SUCCESS } & Payload<models.UmbrellaCompanyDetailModel>)
    | ({ type: actions.SAVE_UMBRELLACOMPANY_SUCCESS } & Payload<models.UmbrellaCompanyDetailModel>)
    | ({ type: actions.DELETE_UMBRELLACOMPANY_SUCCESS } & Payload<number>);

export const loadUmbrellaCompaniesSuccess = (payload: models.UmbrellaCompanyListModel[]): UmbrellaCompanyAction => ({
    payload,
    type: actions.LOAD_UMBRELLACOMPANIES_SUCCESS
});

export const createUmbrellaCompanySuccess = (payload: models.UmbrellaCompanyDetailModel): UmbrellaCompanyAction => ({
    payload,
    type: actions.CREATE_UMBRELLACOMPANY_SUCCESS
});

export const saveUmbrellaCompanySuccess = (payload: models.UmbrellaCompanyDetailModel): UmbrellaCompanyAction => ({
    payload,
    type: actions.SAVE_UMBRELLACOMPANY_SUCCESS
});

export const deleteUmbrellaCompanySuccess = (id: number): UmbrellaCompanyAction => ({
    payload: id,
    type: actions.DELETE_UMBRELLACOMPANY_SUCCESS
});

export const loadUmbrellaCompanyDetailSuccess = (umbrellaCompany: models.UmbrellaCompanyDetailModel) => ({
    payload: umbrellaCompany,
    type: actions.LOAD_UMBRELLACOMPANY_DETAIL_SUCCESS
});
