import * as React from "react";
import { Card, Header, Icon } from "semantic-ui-react";
import { isNotNullOrUndefined } from "@momenta/common";
import { Company } from "@momenta/common/companies/model";

import { UmbrellaCompany } from "../umbrellaCompany";

interface CompanyCardProps {
    company: Company | UmbrellaCompany;
    isSelectedCompany: boolean;
    basePath?: string;
    setSelectedCompany?: (companyId: number) => Promise<void>;
    openModal: () => void;
}

export const CompanyCardDetail: React.FC<CompanyCardProps> = ({ company, isSelectedCompany, setSelectedCompany, openModal }) => {
    const color = isSelectedCompany ? "yellow" : "grey";

    const onIconClick = React.useCallback(() => {
        if (setSelectedCompany && !isSelectedCompany) {
            setSelectedCompany(company.id);
        }
    }, [setSelectedCompany, company, isSelectedCompany]);

    return (
        <>
            <Card.Content textAlign="center">
                <Header textAlign="center">
                    <Icon name="star" title="Selected company" color={color} onClick={onIconClick} />
                    {company.name}
                </Header>
            </Card.Content>
            <Card.Content textAlign="center" onClick={openModal}>
                {company.address &&
                    <Card.Meta>
                        {[
                            company.address.line1,
                            company.address.line2,
                            company.address.city,
                            company.address.county,
                            company.address.postcode
                        ].filter(s => isNotNullOrUndefined(s) && s.length > 0)
                            .map((s, index) => (<React.Fragment key={index}> {s} <br /> </React.Fragment>))}
                    </Card.Meta>
                }
            </Card.Content>
        </>
    );
};
