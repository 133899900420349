import * as React from "react";
import { Fragment } from "redux-little-router";

import { Invoices } from "./Invoices";
import { TimesheetDetail } from "./TimesheetDetail";

export const Timesheets: React.FC = () => (
    <>
        <div>
            <Fragment forRoute="/:timesheetId">
                <TimesheetDetail />
            </Fragment>
            <Fragment forRoute="/">
                <Invoices />
            </Fragment>
        </div>
    </>
);
