import { CdnImage } from "@momenta/common";
import * as React from "react";
import { Card, Header } from "semantic-ui-react";

const ApplicationsAndContractsCard: React.FC<{}> = () => {

    return (
        <Card as="a" href={"/contracts"} className="dashboard-card">
            <Card.Content textAlign="center">
                <CdnImage src="/applications-and-contracts.png" />
                <Header textAlign="center">Applications and Contracts</Header>
            </Card.Content>
        </Card>
    );
};

export {
    ApplicationsAndContractsCard
};
