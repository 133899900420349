import * as React from "react";

import { DashboardCard } from "./DashboardCard";

const FinanceInformationCard: React.FC<{}> = () => {
    return (
        <DashboardCard
            href="/finance-information"
            svg="finance"
            title="Finance Information"
        />);
};

export {
    FinanceInformationCard
};
