import { Payload } from "@neworbit/redux-helpers";

import { Company } from "../companies";

import * as actions from "./actiontypes";

export type CompanyAction =
      ({ type: actions.LOAD_COMPANIES_SUCCESS } & Payload<Company[]>)
    | ({ type: actions.CREATE_COMPANY_SUCCESS } & Payload<Company>)
    | ({ type: actions.SAVE_COMPANY_SUCCESS } & Payload<Company>)
    | ({ type: actions.DELETE_COMPANY_SUCCESS } & Payload<number>)
    | ({ type: actions.VERIFY_COMPANY_SUCCESS } & Payload<number>);

export const loadCompaniesSuccess = (payload: Company[]): CompanyAction => ({
    payload,
    type: actions.LOAD_COMPANIES_SUCCESS
});

export const createCompanySuccess = (payload: Company): CompanyAction => ({
    payload,
    type: actions.CREATE_COMPANY_SUCCESS
});

export const saveCompanySuccess = (payload: Company): CompanyAction => ({
    payload,
    type: actions.SAVE_COMPANY_SUCCESS
});

export const deleteCompanySuccess = (id: number): CompanyAction => ({
    payload: id,
    type: actions.DELETE_COMPANY_SUCCESS
});

export const verifyCompanySuccess = (id: number) => ({
    payload: id,
    type: actions.VERIFY_COMPANY_SUCCESS
});
