import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

import { componentVisible } from "@momenta/common/internationalisation";

import { convertEnumToOptions } from "@momenta/common";

import { BloodGroup } from "@momenta/common/associate/bloodGroups";

interface BloodGroupEditProps {
    values: { bloodGroup?: BloodGroup };
    required?: boolean;
    showErrors: boolean;
    onChange: (value: string, valid: boolean) => void;
}

const BloodGroupEditAlwaysVisible: React.FC<BloodGroupEditProps> = ({ values, showErrors, onChange, required }) => {
    const options = convertEnumToOptions(BloodGroup, key => key).filter(o => !isFinite(o.text));

    return (
        <Grid.Row>
            <Grid.Column>
                <Input.Dropdown
                    value={values.bloodGroup?.toString()}
                    options={options}
                    label="Blood Group"
                    showErrors={showErrors}
                    onChange={onChange}
                    required={required}
                />
            </Grid.Column>
        </Grid.Row>
    );
};

export const BloodGroupEdit = componentVisible("bloodGroupEnabled")(BloodGroupEditAlwaysVisible);
