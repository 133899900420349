import * as React from "react";
import { Bonus } from "@momenta/common/bonuses";
import { Table } from "semantic-ui-react";

import { BonusRowPrint } from "./BonusRowPrint";

interface BonusesPrintProps {
    bonuses: Bonus[];
}

export const BonusesPrint: React.FC<BonusesPrintProps> = ({ bonuses }) => {

    return (
        <Table color="teal" size="small">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Bonus Type</Table.HeaderCell>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {bonuses.length === 0 &&
                    <Table.Row>
                        <Table.Cell content="No bonuses" colSpan={8} textAlign="center" disabled />
                    </Table.Row>
                }
                {bonuses?.map(bonus => (
                    <BonusRowPrint key={bonus.id} bonus={bonus} />
                ))}
            </Table.Body>
        </Table>
    );
};
