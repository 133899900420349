import * as React from "react";
import { Button, Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

interface RefereeEmailProps {
    refereeEmail: string;
    disabled?: boolean;
    showErrors?: boolean;
    onChange: (value: string, valid: boolean) => void;
    onSendEmailClick: (e: any) => void;

}

export const RefereeEmail: React.FC<RefereeEmailProps> = ({ refereeEmail, disabled, showErrors, onChange, onSendEmailClick }) => {

    const colWidth = onSendEmailClick ? 13 : 16;

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={colWidth}>
                    <Input.Email
                        value={refereeEmail}
                        label="Referee Email"
                        showErrors={showErrors}
                        onChange={onChange}
                    />
                </Grid.Column>
                {onSendEmailClick &&
                    <Grid.Column width={3} verticalAlign="bottom">
                        <Button onClick={onSendEmailClick} content="Send Email" fluid floated="right" positive disabled={disabled} />
                    </Grid.Column>
                }
            </Grid.Row>
        </Grid>
    );
};
