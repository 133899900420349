export const LOAD_PROOF_DOCUMENTS_SUCCESS = "LOAD_PROOF_DOCUMENTS_SUCCESS";
export type LOAD_PROOF_DOCUMENTS_SUCCESS = typeof LOAD_PROOF_DOCUMENTS_SUCCESS;

export const LOAD_MOMENTA_DOCUMENTS_SUCCESS = "LOAD_MOMENTA_DOCUMENTS_SUCCESS";
export type LOAD_MOMENTA_DOCUMENTS_SUCCESS = typeof LOAD_MOMENTA_DOCUMENTS_SUCCESS;

export const SAVE_PROOF_DOCUMENT_SUCCESS = "SAVE_PROOF_DOCUMENT_SUCCESS";
export type SAVE_PROOF_DOCUMENT_SUCCESS = typeof SAVE_PROOF_DOCUMENT_SUCCESS;

export const SAVE_MOMENTA_DOCUMENT_SUCCESS = "SAVE_MOMENTA_DOCUMENT_SUCCESS";
export type SAVE_MOMENTA_DOCUMENT_SUCCESS = typeof SAVE_MOMENTA_DOCUMENT_SUCCESS;
