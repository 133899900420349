import * as React from "react";
import { Container, Header } from "semantic-ui-react";
import { getTimesheetDateRange } from "@momenta/common/timesheets/getEndDateTimesheet";
import { Timesheet } from "@momenta/common/timesheets";

import { ExpensesPrint } from "./ExpensesPrint";
import { BonusesPrint } from "./BonusesPrint";
import { HoursWorkedPrint } from "./HoursWorkedPrint";

export interface TimesheetPrintProps {
    timesheet: Timesheet;
}

export const TimesheetPrint: React.FC<TimesheetPrintProps> = ({ timesheet }) => {

    return (
        <Container text className="invoice-details">
            <Header as="h2" textAlign="center">Timesheet for {getTimesheetDateRange(timesheet)}</Header>

            <HoursWorkedPrint timesheetDays={timesheet?.timesheetDays} />

            <Header as="h3" textAlign="left">Bonuses</Header>

            <BonusesPrint bonuses={timesheet?.bonuses} />

            <Header as="h3" textAlign="left">Expenses</Header>

            <ExpensesPrint expenses={timesheet?.expenses} />

        </Container>
    );
};
