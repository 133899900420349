import * as React from "react";
import { Container, Grid } from "semantic-ui-react";

export const LandingPageDefault: React.FC = () => (
    <>
        <div className="associate-landing">
            <Container>
                <Grid>
                    <Grid.Column computer={10} mobile={16}>
                        <h1>
                            Make your next career
                            <br />
                            move a great one.
                        </h1>

                        <p>Welcome to the <span className="secondary-colour">Momenta People</span> platform,
                        our online ecosystem of contingent professionals.<br />
                        Create your profile here to begin your journey with us. By signing up you can:</p>

                        <ul className="bullets">
                            <li>Upload your CV/resume or personal details</li>
                            <li>Apply for new roles</li>
                            <li>Be contacted when there are opportunities that match your profile</li>
                            <li>Complete required background and compliance checks online for any of your ongoing role applications with us.</li>
                        </ul>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
        <div className="landing-image" />
    </>
);
