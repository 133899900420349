import * as React from "react";
import { Container, Grid, Segment } from "semantic-ui-react";

export interface FixedActionBarProps {
    className?: string;
}

const FixedActionBar: React.FC<FixedActionBarProps> = ({ className, children }) => (
    <Segment clearing raised className={`fixed-action-bar ${className}`}>
        <Container as={Grid}>
            {children}
        </Container>
    </Segment>
);

export {
    FixedActionBar
};
