import { createSelector } from "reselect";
import { State as RouterState } from "redux-little-router";
import { InvoicePeriod, InvoicePeriodState } from "@momenta/common/invoicePeriods";

export const invoicePeriodsSelector = (state: InvoicePeriodState & RouterState) => state.invoicePeriods;
export const invoicePeriodIdSelector = (state: InvoicePeriodState & RouterState) => +state.router.params.invoicePeriodId;

export const invoicePeriodSelector = createSelector(
    invoicePeriodsSelector,
    invoicePeriodIdSelector,
    (invoicePeriods: InvoicePeriod[], invoicePeriodId: number) => invoicePeriods.filter(i => i.id === invoicePeriodId)[0]
);
