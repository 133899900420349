import * as React from "react";
import { Card } from "semantic-ui-react";
import { Link } from "redux-little-router";

import { Reference } from "./model";

interface GapReferenceItemProps {
    reference: Reference;
}

export class GapReferenceItem extends React.Component<GapReferenceItemProps, {}> {

    public render() {
        const { reference } = this.props;

        const header = `${reference.start.format("ll")} - ${reference.end ? reference.end.format("ll") : "Now"}`;

        return (
            <div className="gap-referencing-item">
                <Card fluid>
                    <Card.Content as={Link} href={`/referencing/create?start=${reference.start.toJSON()}&end=${reference.end.toJSON()}`}>
                        <Card.Header>{header}</Card.Header>
                        <Card.Description>There is a gap in your referencing history, please fill in the details for this gap reference.</Card.Description>
                    </Card.Content>
                </Card>
            </div>
        );
    }
}
