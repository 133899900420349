import * as React from "react";
import { Container, Divider } from "semantic-ui-react";

import { dateSortAsc } from "@momenta/common/sorting";

import { InvoicePeriod } from "../model";

import { TimesheetPrint } from "./TimesheetPrint";

export interface InvoicePrintStateProps {
    invoicePeriod: InvoicePeriod;
}

export const InvoicePeriodPrintUnconnected: React.FC<InvoicePrintStateProps> = ({ invoicePeriod }) => {

    const timesheetsOrdered = invoicePeriod?.timesheets?.sort(dateSortAsc(t => t.start));

    return (
        <Container text className="invoice-details">
            { timesheetsOrdered?.map(timesheet => (
                <>
                    <TimesheetPrint timesheet={timesheet} />
                    <Divider />
                </>
            ))}
        </Container>
    );
};
