import { Grid, Icon } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { ComponentVisible } from "@momenta/common/internationalisation/ComponentVisible";
import { FileUpload, isNullOrUndefined, truncateGuid } from "@momenta/common";
import * as React from "react";
import { localTextSelector } from "@momenta/common/internationalisation/selectors";
import { useSelector } from "react-redux";
import { DetailColumn } from "@momenta/common/companies/DetailColumn";
import { validAlphanumeric } from "not-valid/bin/validators/valid-alphanumeric";
import { validLength } from "not-valid/bin/validators/valid-length";
import { validNumber } from "@momenta/common/validators/validNumberValidator";

import { GridRowHeader } from "./GridRowHeader";
import { TaxDetailQuestions } from "./tax/TaxDetails";
import { CurrentUser } from "./model";
import { onContractSelector } from "./selector";

export interface TaxDetailsEditProps {
    model: CurrentUser;
    values: CurrentUser;
    showErrors: boolean;
    onChange: (prop: string, value: string, valid: boolean) => void;
    onNestedChange: (prop: string, value: string, valid: boolean) => void;
    onYesNoChange: (prop: string, valid: boolean) => (value: boolean) => void;
}

export const TaxDetailsEdit: React.FC<TaxDetailsEditProps> =
    ({ model, values, showErrors, onChange, onYesNoChange, onNestedChange }) => {

        const onNiNumberChange = (value: string, valid: boolean) => { onChange("niNumber", value, valid); };
        const onTaxFormChange = (value: string, valid: boolean) => { onChange("taxForm", value, valid); };

        const getUpdate = (field: string) => {
            return (value: any, valid: boolean) => { onNestedChange(field, value, valid); };
        };

        const taxDetails = values?.taxDetails;

        const universalAccountNumber = taxDetails?.universalAccountNumber;
        const employeeStateInsuranceCorporationNumber = taxDetails?.employeeStateInsuranceCorporationNumber;
        const providentFundAccountNumber = taxDetails?.providentFundAccountNumber;

        const onContract = useSelector(onContractSelector);
        const nationalInsuranceText = useSelector((state: any) => localTextSelector(state, "nationalInsuranceText"));
        const taxNumberValidation = useSelector((state: any) => localTextSelector(state, "taxNumberValidation"));

        return (
            <>
                <ComponentVisible keyName="showNationalInsuranceNumber">
                    <GridRowHeader text="Tax Details" />
                    <Grid.Row>
                        <Grid.Column computer={5} mobile={16} tablet={8}>
                            {onContract === true
                                ? <DetailColumn label={nationalInsuranceText} value={values.niNumber} />
                                : <Input.Text
                                    key={nationalInsuranceText}
                                    value={values.niNumber}
                                    label={nationalInsuranceText}
                                    showErrors={showErrors}
                                    onChange={onNiNumberChange}
                                    validation={[taxNumberValidation]}
                                />}
                        </Grid.Column>
                    </Grid.Row>
                </ComponentVisible>

                <ComponentVisible keyName="indiaTaxForm">
                    <Grid.Row>
                        <Grid.Column computer={5} mobile={16} tablet={8}>
                            <Input.Text
                                value={universalAccountNumber}
                                label={"UAN Number"}
                                showErrors={showErrors}
                                onChange={getUpdate("taxDetails.universalAccountNumber")}
                                validation={[validLength({ min: 12, max: 12 }), validNumber()]}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column computer={5} mobile={16} tablet={8}>
                            <Input.Text
                                value={employeeStateInsuranceCorporationNumber}
                                label={"ESIC Number"}
                                showErrors={showErrors}
                                onChange={getUpdate("taxDetails.employeeStateInsuranceCorporationNumber")}
                                validation={[validLength({ min: 10, max: 10 }), validNumber()]}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column computer={5} mobile={16} tablet={8}>
                            <Input.Text
                                value={providentFundAccountNumber}
                                label={"PF Number"}
                                showErrors={showErrors}
                                onChange={getUpdate("taxDetails.providentFundAccountNumber")}
                                validation={[validAlphanumeric(), validLength({ min: 22, max: 22 })]}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </ComponentVisible>

                <ComponentVisible keyName="australiaTaxForm">
                    <TaxDetailQuestions
                        values={values}
                        onChange={onYesNoChange}
                        onContract={onContract}
                    />

                    <Grid.Row>
                        <Grid.Column computer={5} mobile={16} tablet={8}>
                            {isNullOrUndefined(model.taxForm) && onContract === false
                                ? <FileUpload
                                    label="Tax Form"
                                    apiUrl="/api/document"
                                    placeholder="Tax Form"
                                    value={values.taxForm}
                                    filename={values.taxForm && truncateGuid(values.taxForm)}
                                    onFileChange={onTaxFormChange}
                                    showErrors={showErrors}
                                />
                                :
                                <div>
                                    <br />
                                    <strong>Tax Form</strong>
                                    <Icon fitted name="check circle" color="green" size="large" className="float-right" title="Tax Form" />
                                </div>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </ComponentVisible>
            </>
        );
    };
