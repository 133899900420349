import { loadReference, loadReferences } from "@momenta/common/referencing/actions";

import { loadAllAssociateQualifications } from "../qualifications/actions";

export const routes = {
    "/referencing": {
        "title": "Academic/Employment History",
        "authorize": true,
        "resolve": [loadReferences, loadAllAssociateQualifications],
        "ignoreParentResolve": true,
        "/create": {
            title: "Create Reference"
        },
        "/:referenceId": {
            "/edit": {
                title: "Edit reference",
                resolve: loadReference
            }
        }
    }
};
