import { initializeCurrentLocation, replace } from "redux-little-router";
import { loadAndTrack } from "redux-request-loading";
import { saveRegionSuccess } from "@momenta/common/internationalisation/actions";

import { AppState } from "../model";
import { loadCurrentUserSuccess, unauthenticatedUser } from "../currentUser/actions";
import { currentUserApi } from "../currentUser/CurrentUserApi";

const initialise = async (dispatch: any, getState: () => AppState) => {
    try {
        const result = await loadAndTrack(dispatch, "loadCurrentUser", currentUserApi.load());
        await dispatch(loadCurrentUserSuccess(result));
        await dispatch(saveRegionSuccess(result.region));

        const state = getState();

        if (state.currentUser.registrationComplete === false || state.currentUser.agreedToTerms === false) {
            dispatch(replace("/register"));
            return;
        }

        if (state.currentUser.externalApplicationComplete === false) {
            dispatch(replace("/application-submitted"));
            return;
        }
    } catch {
        await dispatch(unauthenticatedUser());
    } finally {

        const initialLocation = getState().router;

        if (initialLocation) {
            dispatch(initializeCurrentLocation(initialLocation));
        }
    }
};

export function initialiseLocation() {
    return (dispatch: any, getState: () => AppState) => initialise(dispatch, getState);
}
