import { connect } from "react-redux";
import { State as RouterState } from "redux-little-router";
import { InvoicePeriodState } from "@momenta/common/invoicePeriods";
import { AuthState } from "reauthorize";
import { InvoicePrintStateProps } from "@momenta/common/invoicePeriods/print/InvoicePeriodPrintUnconnected";
import { invoicePeriodSelector } from "src/App/Umbrella/src/invoicePeriods/selectors";
import { InvoicePeriodPrintUnconnected } from "@momenta/common/invoicePeriods/print/InvoicePeriodPrintUnconnected";
import { ConfigurationState } from "src/App/People/src/hierarchicalConfiguration";
import { TimeTypeState } from "src/App/People/src/timeType";

const mapStateToProps = (state: InvoicePeriodState & RouterState & AuthState & ConfigurationState & TimeTypeState): InvoicePrintStateProps => ({
    invoicePeriod: invoicePeriodSelector(state),
});

export const InvoicePeriodPrint = connect(mapStateToProps)(InvoicePeriodPrintUnconnected);
