import * as React from "react";
import moment from "moment";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { getConfig } from "@momenta/common/config/getConfig";
import { AppInsights } from "applicationinsights-js";
import AppInsightsLogging from "appinsights-usage";
import "react-toastify/dist/ReactToastify.css";
import { enrichLogsWithUser } from "@momenta/common/logging/appInsightsUserLogEnricher";

import "../../../../node_modules/semantic-ui-less/semantic.less";

import "./style/index.scss";
import registerServiceWorker from "./registerServiceWorker";
import { App } from "./app";
import { initialiseLocation, initialiseRegion, store } from "./startup";

const setupAppInsights = async () => {

    const configuration = await getConfig();
    const instrumentationKey = configuration.ApplicationInsightsInstrumentationKey;
    const options = { instrumentationKey };
    AppInsights.downloadAndSetup(options);
    AppInsightsLogging.init(options);
};

setupAppInsights();

const locale = (window.navigator as any).userLanguage || window.navigator.language;
moment.locale(locale);

store.dispatch(initialiseLocation());
store.dispatch(initialiseRegion());
enrichLogsWithUser(store);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("approot")
);

registerServiceWorker();
