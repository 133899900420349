import * as React from "react";
import { Card, Icon, Message } from "semantic-ui-react";

import { useSelector } from "react-redux";
import { localTextSelector, RegionState } from "@momenta/common/internationalisation";

import { DashboardCard } from "./DashboardCard";

interface BackgroundScreeningCardProps {
    documentsNotUploaded: number;
    noDocumentsRequested: boolean;
}

export const BackgroundScreeningCard: React.FC<BackgroundScreeningCardProps> = ({ documentsNotUploaded, noDocumentsRequested }) => {
    const titleText = useSelector((state: RegionState) => localTextSelector(state, "backgroundScreeningDocuments"));

    const complete = () => {

        if (noDocumentsRequested) {
            return <></>;
        }

        if (documentsNotUploaded === 0) {
            return <p>All documents complete <Icon name="checkmark" color="green" /></p>;
        }

        return (
            <Message negative>
                {documentsNotUploaded} document{documentsNotUploaded > 1 ? "s" : ""} awaiting completion
            </Message>
        );
    };

    const isDocumentsComplete = documentsNotUploaded !== 0 ? "red" : "green";

    return (
        <DashboardCard
            color={isDocumentsComplete}
            href="/documents"
            svg="background-screening"
            title={titleText}
        >
            <Card.Content textAlign="center">
                <Card.Meta>
                    {complete()}
                </Card.Meta>
            </Card.Content>
        </DashboardCard>
    );
};
