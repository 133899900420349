import * as React from "react";
import { TimesheetDay } from "@momenta/common/timesheets";

import { TimesheetEntryPrint } from "./TimesheetEntryPrint";

interface TimesheetDayPrintProps {
    timesheetDay: TimesheetDay;
}

export const TimesheetDayPrint: React.FC<TimesheetDayPrintProps> = ({ timesheetDay }) => {

    const numberOfEntries = timesheetDay?.timesheetEntries?.length;

    const firstEntry = numberOfEntries > 0 ? timesheetDay?.timesheetEntries[0] : undefined;

    return (
        <>
            {firstEntry &&
                <TimesheetEntryPrint
                    timesheetEntry={firstEntry}
                    timesheetDay={timesheetDay?.date}
                    timesheetEntryCount={numberOfEntries}
                    showdate />
            }
            {numberOfEntries > 1 && timesheetDay?.timesheetEntries?.slice(1).map(timeEntry => (
                <TimesheetEntryPrint
                    key={timeEntry.id}
                    timesheetEntry={timeEntry}
                />
            ))}

        </>
    );
};
