import { Store } from "redux";

import { AppState } from "../model";

import { connectionStatusChanged } from "./actions";

export function syncConnectionWithStore(store: Store<AppState>) {
    store.dispatch(connectionStatusChanged(navigator.onLine));

    window.addEventListener("online", () => store.dispatch(connectionStatusChanged(true)));
    window.addEventListener("offline", () => store.dispatch(connectionStatusChanged(false)));
}
