import { loadAndTrack } from "redux-request-loading";
import { Payload } from "@neworbit/redux-helpers";
import { ConfigTypeName, HierarchicalConfiguration } from "@momenta/common/hierarchicalConfiguration";
import { Timesheet } from "@momenta/common/timesheets/model";

import { hierarchicalConfigurationApi } from "./HierarchicalConfigurationApi";
import * as actions from "./actiontypes";

export type HierarchicalConfigurationAction<T extends HierarchicalConfiguration> =
      ({ type: actions.LOAD_HIERARCHICAL_CONFIGURATION_SUCCESS } & { configType: ConfigTypeName } & Payload<T[]>);

export const loadConfigurationSuccess = <T extends HierarchicalConfiguration>(
    configType: ConfigTypeName,
    payload: T[]
): HierarchicalConfigurationAction<T> => ({
        payload,
        configType,
        type: actions.LOAD_HIERARCHICAL_CONFIGURATION_SUCCESS
    });

export function loadConfiguration<T extends HierarchicalConfiguration>(type: ConfigTypeName) {
    return ({ timesheetId }: { timesheetId: number }) => {
        return async (dispatch: any) => {
            const result = await loadAndTrack(dispatch, "loadConfiguration", hierarchicalConfigurationApi.get<T>(type, timesheetId));
            dispatch(loadConfigurationSuccess<T>(type, result.items));
        };
    };
}

export function loadTimesheetsConfiguration<T extends HierarchicalConfiguration>(type: ConfigTypeName, timesheets: Timesheet[]) {
    return async (dispatch: any) => {
        const loadConfigurations = async (timesheet: Timesheet) => {
            const results = await loadAndTrack(dispatch, "loadConfiguration", hierarchicalConfigurationApi.get<T>(type, timesheet.id));
            dispatch(loadConfigurationSuccess<T>(type, results.items));
        };

        const promises = timesheets.map(loadConfigurations);
        await Promise.all(promises);
    };
}
