import * as actions from "./actiontypes";
import { CompanyAction } from "./actions";
import { Company } from "./model";

export function companies(state: Company[] = [], action: CompanyAction): Company[] {
    switch (action.type) {
        case actions.LOAD_COMPANIES_SUCCESS:
            return action.payload;
        case actions.SAVE_COMPANY_SUCCESS:
            return state.map(c => company(c, action));
        case actions.CREATE_COMPANY_SUCCESS:
            return [...state, action.payload];
        case actions.DELETE_COMPANY_SUCCESS:
            return [...state.filter(c => c.id !== action.payload)];
        default:
            return state;
    }
}

function company(state: Company, action: CompanyAction): Company {
    switch (action.type) {
        case actions.SAVE_COMPANY_SUCCESS:
            if (action.payload.id === state.id) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
}
