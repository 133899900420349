import { connect } from "react-redux";
import { Action } from "redux";
import { push } from "redux-little-router";
import { basePathSelector, referenceSelector } from "@momenta/common/referencing/selectors";
import { AppState, Reference } from "@momenta/common/referencing/model";
import { updateReference } from "@momenta/common/referencing/actions";
import { ThunkDispatch } from "redux-thunk";
import { areRequestsActive, LoadingState } from "redux-request-loading";

import { CurrentUserState } from "../currentUser/model";

import { CreateReferenceModalProps, CreateReferenceModalUnconnected } from "./CreateReferenceModalUnconnected";

const mapStateToProps = (state: AppState & CurrentUserState & LoadingState, ownProps: any) => ({
    ...ownProps,
    open: true,
    basePath: basePathSelector(state),
    reference: referenceSelector(state),
    loading: areRequestsActive(state, ["loadReference", "updateReference"])
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>) => ({
    dispatchClose: (basePath: string) => dispatch(push(basePath)),
    dispatchUpdate: (reference: Reference, basePath: string) => dispatch(updateReference(reference, basePath))
});

const mergeProps = (
    { basePath, ...propsFromState }: ReturnType<typeof mapStateToProps>,
    propsFromDispatch: ReturnType<typeof mapDispatchToProps>
): CreateReferenceModalProps => ({
    ...propsFromState,
    close: () => propsFromDispatch.dispatchClose(basePath),
    save: (reference: Reference) => propsFromDispatch.dispatchUpdate(reference, basePath),
    buttonLabel: "Save",
    create: false
});

export const EditReferenceModal = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateReferenceModalUnconnected);
