import moment from "moment";
import { FormBaseComponent, SaveDispatchProps } from "@neworbit/simpleui-forms";

import { Reference } from "./model";

export interface ReferenceOwnProps<T extends Reference = Reference> {
    reference?: T;
    showRefereeEmail?: boolean;
    showRefereePhoneNumber?: boolean;
    disabled?: boolean;
    onSendEmailClick?: (e: any) => void;
}

export interface ReferenceProps {
    open?: boolean;
    start?: moment.Moment;
    end?: moment.Moment;
    associateView?: boolean;
}

export interface ReferenceDispatchProps extends SaveDispatchProps<Reference> {
    close?: () => void;
}

export abstract class CreateReference<T extends Reference = Reference> extends FormBaseComponent<T, ReferenceProps & ReferenceOwnProps<T>> {

    public submit = async (): Promise<boolean> => {
        await this.handleSubmit({ preventDefault: (): void => undefined } as any);
        return this.valid();
    }

    protected getDefaultReferenceState(referenceType: number): Reference {
        return {
            id: 0,
            associateId: 0,
            start: null,
            end: null,
            current: false,
            referenceType,
            submitted: false,
            detail: false,
            gap: false,
            summary: ""
        };
    }
}
