import * as React from "react";

export const companyGuidanceMessage = () => (
    <>
        <p>
            To work as an associate of Momenta, you will be required to advise, ideally a week in advance of your intended commencement date,
            the details of the selected contracting entity through which you will operate. The options are that you contract through either:
        </p>

        <b>A Registered Limited Company</b>
        <p>If you will be operating through a registered Limited Company, please select + Add New Company</p>

        <p>
            <b>Note:</b> Once you have entered your Limited Company Registration number your Limited Company details will be looked up on Companies
            house and the Company name, registration date and address will be pre-populated.
        </p>

        <p>
            You will then be required to enter your Limited Company
            Business bank details and select whether your Company is VAT registered (if your company is VAT registered you will be required to enter
            your VAT Number and Registration date)
        </p>

        <p>
            Documents will also need to be uploaded for your Limited Company – Certificate of Incorporation,
            PIPL Insurance and VAT Registration Certificate (if applicable)
        </p>

        <b>An Approved Umbrella Company</b>

        <p>
            If you will be operating through one of Momenta’s approved Umbrella Companies, please select the umbrella company
            you have chosen to work through from the Company drop down list and press save.
        </p>

        <p>
            <b>
                Once you have commenced your contract you must work through this contracting entity for at least the first invoicing period (4 weeks),
                therefore please ensure you are comfortable with all the terms.
            </b>
        </p>

        Further information regarding Starting out as a Contractor can be found on our website
        <a href="http://www.momentagroup.com/about/how-to-contract/" target="_blank" rel="noopener noreferrer" className="contract-guide-link"> here</a>
    </>
);
