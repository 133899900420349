import { UmbrellaCompanyDetailModel, UmbrellaCompanyListModel } from "@momenta/common/umbrellaCompany";
import { makeRequest, PageResult } from "@momenta/common";
import axios from "axios";

class UmbrellaCompanyApi {

    private readonly pageSize = 20;
    private readonly apiUrl = "/api/umbrellacompany";

    public async getAll(pageNumber: number = 1, loadAll?: boolean): Promise<PageResult<UmbrellaCompanyListModel>> {

        const skip = (pageNumber - 1) * this.pageSize;
        const request = makeRequest(this.apiUrl, {
            skip,
            top: this.pageSize,
            count: true
        });

        const response = loadAll
            ? await axios.get(this.apiUrl)
            : await axios.get(request);

        const { items, count } = response.data as PageResult<UmbrellaCompanyListModel>;
        return {
            items: items.map(this.parseModel),
            count
        };
    }

    public parseModel(model: UmbrellaCompanyDetailModel): UmbrellaCompanyDetailModel {
        return {
            ...model
        };
    }
}

export default new UmbrellaCompanyApi();
