import { connect } from "react-redux";
import { State as RouterState } from "redux-little-router";
import { InvoiceDetailStateProps, InvoiceDetailsUnconnected, InvoicePeriodState } from "@momenta/common/invoicePeriods";
import { AuthState } from "reauthorize";

import { invoicePeriodSelector } from "../invoicePeriods";

const mapStateToProps = (state: InvoicePeriodState & RouterState & AuthState): InvoiceDetailStateProps => ({
    invoicePeriod: invoicePeriodSelector(state),
    path: state.router.pathname,
    canEdit: false
});

export const InvoiceDetails = connect(mapStateToProps)(InvoiceDetailsUnconnected);
