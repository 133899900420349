import { createEmptyState } from "@momenta/common/utils/createEmptyState";

import * as actions from "./actionTypes";
import { ProfileAction } from "./actions";
import { CurrentUser } from "./model";

/* eslint-disable @typescript-eslint/consistent-type-assertions */
export function currentUser(state: CurrentUser = createEmptyState<CurrentUser>(), action: ProfileAction): CurrentUser {
    switch (action.type) {
        case actions.LOAD_CURRENT_USER_SUCCESS:
            return {
                ...action.payload,
                authenticated: true
            };
        case actions.UNAUTHENTICATED:
            return {
                authenticated: false
            } as CurrentUser;
        default:
            return state;
    }
}
