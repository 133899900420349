import * as React from "react";
import { connect } from "react-redux";
import { InvoicePeriod, InvoicePeriodState, InvoicesUnconnected } from "@momenta/common/invoicePeriods";
import { PaginationState } from "@momenta/common/pagination";
import { localTextSelector } from "@momenta/common/internationalisation/selectors";
import { RegionState } from "@momenta/common/internationalisation/model";

import { isRequestActive } from "redux-request-loading/dist/selectors";

import { CurrentUserState } from "../currentUser/model";
import { Candidate } from "../../../People/src/candidates";
import { AppState } from "../model";

interface TimesheetsStateProps {
    contracts: Candidate[];
    invoicePeriods: InvoicePeriod[];
    invoicesHeader: string;
    total: number;
    loading: boolean;
}

export const InvoiceUnconnected: React.SFC<TimesheetsStateProps> = (props) => (
    <>
        <h1>{props.invoicesHeader}</h1>

        <InvoicesUnconnected  {...props} allowPrinting />
    </>
);

const mapStateToProps = (state: AppState & CurrentUserState & InvoicePeriodState & PaginationState & RegionState
): TimesheetsStateProps => ({
    contracts: state.currentUser.contracts || [],
    invoicePeriods: state.invoicePeriods || [],
    invoicesHeader: localTextSelector(state, "invoicesHeader"),
    total: state.pagination.total,
    loading: isRequestActive(state, "loadInvoicePeriods")
});

export const Invoices = connect(mapStateToProps)(InvoiceUnconnected);
