/* eslint-disable max-classes-per-file */
import { User } from "reauthorize";
import moment from "moment";
import { Company } from "@momenta/common/companies";
import { Country, TimeMeasurementType } from "@momenta/common/model";

import { PreviousIdentity } from "src/App/People/src/associate/model";

import { BloodGroup } from "@momenta/common/associate/bloodGroups";

import { Candidate } from "../../../People/src/candidates";

export const filenameRegex = "^\\d+-\\d+-(.*)";

export interface CurrentUser extends User {
    id: number;
    title: string;
    forename: string;
    middleName: string;
    surname: string;
    dateOfBirth: moment.Moment;
    bloodGroup?: BloodGroup;
    email: string;
    address: Address;
    linkedInAddress: string;
    temporaryAddress: Address;
    candidacies: Candidate[];
    contracts: Candidate[];
    newCv: string;
    registrationComplete: boolean;
    registered: moment.Moment;
    mobileNumber: string;
    homeNumber: string;
    niNumber: string;
    nationality: string;
    cvs: Cv[];
    emergencyContact: EmergencyContactDetails;
    visaRequired: boolean;
    visaExpiryDate?: moment.Moment;
    selectedCompany: Company;
    selectedCompanyId: number;
    companies: Company[];
    registrationRoleId: number;
    authenticated: boolean;
    roles: string[];
    agreedToTerms: boolean;
    receiveMarketingEmails: boolean;
    archived: boolean;
    referencingComplete?: boolean;
    timezone?: string;
    bankDetails?: BankDetail;
    superannuationDetails?: SuperannuationDetail;
    taxForm?: string;
    region?: Country;
    taxDetails?: TaxDetail;
    updated: moment.Moment;
    willingToRelocate: boolean;
    hasPreviousIdentities: boolean;
    previousIdentities: PreviousIdentity[];
    welcomeMessageViewed: boolean;
    externalApplicationComplete?: boolean;
    currentNoticePeriod?: number;
    currentNoticePeriodTimeType?: TimeMeasurementType;
    holiday?: string;
    availableFrom: moment.Moment;
    expectedRate: number;
    provideFastTrackInformation: boolean;
}

export interface TaxDetail {
    id?: number;
    australianResident?: boolean;
    workingHolidayMaker?: boolean;
    claimTaxFreeThreshold?: boolean;
    studentDebt?: boolean;
    supplementDebt?: boolean;
    universalAccountNumber?: string;
    employeeStateInsuranceCorporationNumber?: string;
    providentFundAccountNumber?: string;
}

export interface BankDetail {
    bankAccountName?: string;
    bsbCode?: string;
    accountNumber?: string;
    bankName?: string;
    branch?: string;
}

export interface SuperannuationDetail {
    fundName?: string;
    usiCode?: string;
    membershipName?: string;
    membershipNumber?: string;
    superForm?: string;
}

export interface Address {
    id?: number;
    line1?: string;
    line2?: string;
    line3?: string;
    city?: string;
    county?: string;
    postcode?: string;
    country?: string;
    state?: string;
}

export interface Cv {
    id: number;
    associateId: number;
    blobName: string;
    momentaFormatted: boolean;
    created: moment.Moment;
}

export interface EmergencyContactDetails {
    forename?: string;
    surname?: string;
    contactNumber?: string;
    relationship?: string;
    address?: string;
}

export type CurrentUserState = {
    currentUser: CurrentUser
};

export class AddressBuilder {
    private id: number;
    private line1: string;
    private line2: string;
    private line3: string;
    private city: string;
    private county: string;
    private postcode: string;
    private country: string;

    public withId(id: number): AddressBuilder {
        this.id = id;
        return this;
    }

    public withLine1(line1: string): AddressBuilder {
        this.line1 = line1;
        return this;
    }

    public withLine2(line2: string): AddressBuilder {
        this.line2 = line2;
        return this;
    }

    public withLine3(line3: string): AddressBuilder {
        this.line3 = line3;
        return this;
    }

    public withCity(city: string): AddressBuilder {
        this.city = city;
        return this;
    }

    public withCounty(county: string): AddressBuilder {
        this.county = county;
        return this;
    }

    public withPostcode(postcode: string): AddressBuilder {
        this.postcode = postcode;
        return this;
    }

    public withCountry(country: string): AddressBuilder {
        this.country = country;
        return this;
    }

    public build(): Address {
        return {
            id: this.id,
            line1: this.line1,
            line2: this.line2,
            line3: this.line3,
            city: this.city,
            county: this.county,
            postcode: this.postcode,
            country: this.country
        };
    }
}

export class CurrentUserBuilder {
    private id: number;
    private title: string;
    private forename: string;
    private middleName: string;
    private surname: string;
    private dateOfBirth: moment.Moment;
    private bloodGroup: BloodGroup;
    private email: string;
    private address: Address;
    private temporaryAddress: Address;
    private candidacies: Candidate[];
    private contracts: Candidate[];
    private newCv: string;
    private registrationComplete: boolean;
    private registered: moment.Moment;
    private mobileNumber: string;
    private homeNumber: string;
    private niNumber: string;
    private nationality: string;
    private cvs: Cv[];
    private emergencyContact: EmergencyContactDetails;
    private visaRequired: boolean;
    private visaExpiryDate?: moment.Moment;
    private registrationRoleId?: number;
    private authenticated: boolean;
    private roles: string[];
    private selectedCompany: Company;
    private selectedCompanyId: number;
    private companies: Company[];
    private agreedToTerms: boolean;
    private receiveMarketingEmails: boolean;
    private archived: boolean;
    private updated: moment.Moment;
    private willingToRelocate: boolean;
    private previousIdentities: PreviousIdentity[];
    private hasPreviousIdentities: boolean;
    private linkedInAddress: string;
    private welcomeMessageViewed: boolean;
    private currentNoticePeriod?: number;
    private currentNoticePeriodTimeType?: TimeMeasurementType;
    private holiday?: string;
    private availableFrom: moment.Moment;
    private expectedRate: number;
    private provideFastTrackInformation: boolean;

    constructor() {
        this.id = 0;
        this.title = "";
        this.forename = "";
        this.middleName = "";
        this.surname = "";
        this.dateOfBirth = moment();
        this.email = "";
        this.address = {
            id: 0,
            line1: "",
            line2: "",
            line3: "",
            city: "",
            county: "",
            postcode: "",
            country: ""
        };
        this.temporaryAddress = {
            id: 0,
            line1: "",
            line2: "",
            line3: "",
            city: "",
            county: "",
            postcode: "",
            country: ""
        };
        this.candidacies = undefined;
        this.contracts = undefined;
        this.newCv = "";
        this.registrationComplete = false;
        this.registered = moment();
        this.mobileNumber = "";
        this.homeNumber = "";
        this.authenticated = false;
        this.roles = [];
        this.homeNumber = "";
        this.niNumber = "";
        this.nationality = "";
        this.cvs = undefined;
        this.emergencyContact = undefined;
        this.visaRequired = false;
        this.visaExpiryDate = moment();
        this.title = "";
        this.selectedCompany = {
            id: 0,
            accountNumber: "",
            address: null,
            name: "",
            registrationNumber: "",
            sortCode: "",
            umbrella: false,
            piplInsuranceDocument: undefined,
            registrationDocument: undefined,
            vatRegistrationDocument: undefined,
            vatDetails: undefined,
            associateId: 0,
            generateInvoice: false,
        };
        this.selectedCompanyId = 0;
        this.registrationRoleId = undefined;
        this.companies = undefined;
        this.agreedToTerms = false;
        this.receiveMarketingEmails = false;
        this.archived = false;
        this.updated = moment();
        this.willingToRelocate = false;
        this.previousIdentities = [];
        this.hasPreviousIdentities = false;
        this.linkedInAddress = "";
        this.welcomeMessageViewed = false;
        this.availableFrom = undefined;
        this.currentNoticePeriod = 0;
        this.currentNoticePeriodTimeType = TimeMeasurementType.Unknown;
        this.holiday = "";
        this.expectedRate = 0;
        this.provideFastTrackInformation = false;
    }

    public withId(id: number): CurrentUserBuilder {
        this.id = id;
        return this;
    }

    public withTitle(title: string): CurrentUserBuilder {
        this.title = title;
        return this;
    }

    public withForename(forename: string): CurrentUserBuilder {
        this.forename = forename;
        return this;
    }

    public withMiddleName(middleName: string): CurrentUserBuilder {
        this.middleName = middleName;
        return this;
    }

    public withSurname(surname: string): CurrentUserBuilder {
        this.surname = surname;
        return this;
    }

    public withDateOfBirth(dateOfBirth: moment.Moment): CurrentUserBuilder {
        this.dateOfBirth = dateOfBirth;
        return this;
    }

    public withEmail(email: string): CurrentUserBuilder {
        this.email = email;
        return this;
    }

    public withAddress(address: Address): CurrentUserBuilder {
        this.address = address;
        return this;
    }

    public withTemporaryAddress(temporaryAddress: Address): CurrentUserBuilder {
        this.temporaryAddress = temporaryAddress;
        return this;
    }

    public withCandidacies(candidacies: Candidate[]): CurrentUserBuilder {
        this.candidacies = candidacies;
        return this;
    }

    public withContracts(contracts: Candidate[]): CurrentUserBuilder {
        this.contracts = contracts;
        return this;
    }

    public withNewCv(newCv: string): CurrentUserBuilder {
        this.newCv = newCv;
        return this;
    }

    public withRegistrationComplete(registrationComplete: boolean): CurrentUserBuilder {
        this.registrationComplete = registrationComplete;
        return this;
    }

    public withRegistered(registered: moment.Moment): CurrentUserBuilder {
        this.registered = registered;
        return this;
    }

    public withMobileNumber(mobileNumber: string): CurrentUserBuilder {
        this.mobileNumber = mobileNumber;
        return this;
    }

    public withHomeNumber(homeNumber: string): CurrentUserBuilder {
        this.homeNumber = homeNumber;
        return this;
    }

    public withNiNumber(niNumber: string): CurrentUserBuilder {
        this.niNumber = niNumber;
        return this;
    }

    public withNationality(nationality: string): CurrentUserBuilder {
        this.nationality = nationality;
        return this;
    }

    public withCvs(cvs: Cv[]): CurrentUserBuilder {
        this.cvs = cvs;
        return this;
    }

    public withVisaRequired(visaRequired: boolean): CurrentUserBuilder {
        this.visaRequired = visaRequired;
        return this;
    }

    public withVisaExpiryDate(visaExpiryDate: moment.Moment): CurrentUserBuilder {
        this.visaExpiryDate = visaExpiryDate;
        return this;
    }

    public withAuthenticated(authenticated: boolean): CurrentUserBuilder {
        this.authenticated = authenticated;
        return this;
    }

    public withRoles(roles: string[]): CurrentUserBuilder {
        this.roles = roles;
        return this;
    }

    public withSelectedCompany(selectedCompany: Company): CurrentUserBuilder {
        this.selectedCompany = selectedCompany;
        return this;
    }

    public withSelectedCompanyId(selectedCompanyId: number): CurrentUserBuilder {
        this.selectedCompanyId = selectedCompanyId;
        return this;
    }

    public withCompanies(companies: Company[]): CurrentUserBuilder {
        this.companies = companies;
        return this;
    }

    public withRegistrationRoleId(registrationRoleId: number): CurrentUserBuilder {
        this.registrationRoleId = registrationRoleId;
        return this;
    }

    public withAgreedToTerms(agreedToTerms: boolean): CurrentUserBuilder {
        this.agreedToTerms = agreedToTerms;
        return this;
    }

    public withReceiveMarketingEmails(receiveMarketingEmails: boolean): CurrentUserBuilder {
        this.receiveMarketingEmails = receiveMarketingEmails;
        return this;
    }

    public withArchived(archived: boolean) {
        this.archived = archived;
        return this;
    }

    public withEmergencyContact(emergencyContact: EmergencyContactDetails): CurrentUserBuilder {
        this.emergencyContact = emergencyContact;
        return this;
    }

    public withUpdated(updated: moment.Moment): CurrentUserBuilder {
        this.updated = updated;
        return this;
    }

    public withWillingToRelocated(willingToRelocate: boolean): CurrentUserBuilder {
        this.willingToRelocate = willingToRelocate;
        return this;
    }

    public withPreviousIdentities(previousIdentities: PreviousIdentity[]) {
        this.previousIdentities = previousIdentities;
        return this;
    }

    public withHasPreviousIdentities(hasPreviousIdentities: boolean) {
        this.hasPreviousIdentities = hasPreviousIdentities;
        return this;
    }

    public withLinkedInAddress(linkedInAddress: string) {
        this.linkedInAddress = linkedInAddress;
        return this;
    }

    public withWelcomeMessageViewed(welcomeMessageViewed: boolean) {
        this.welcomeMessageViewed = welcomeMessageViewed;
        return this;
    }

    public withAvailableFrom(availableFrom: moment.Moment) {
        this.availableFrom = availableFrom;
        return this;
    }

    public withNoticePeriod(currentNoticePeriod: number) {
        this.currentNoticePeriod = currentNoticePeriod;
        return this;
    }

    public withNoticePeriodTimeType(currentNoticePeriodTimeType: TimeMeasurementType) {
        this.currentNoticePeriodTimeType = currentNoticePeriodTimeType;
        return this;
    }

    public withHoliday(holiday: string) {
        this.holiday = holiday;
        return this;
    }

    public withExpectedRate(expectedRate: number) {
        this.expectedRate = expectedRate;
        return this;
    }

    public withProvideFastTrackInformation(provideFastTrackInformation: boolean): CurrentUserBuilder {
        this.provideFastTrackInformation = provideFastTrackInformation;
        return this;
    }

    public build(): CurrentUser {
        return {
            id: this.id,
            provideFastTrackInformation: this.provideFastTrackInformation,
            title: this.title,
            forename: this.forename,
            middleName: this.middleName,
            surname: this.surname,
            dateOfBirth: this.dateOfBirth,
            bloodGroup: this.bloodGroup,
            email: this.email,
            address: this.address,
            temporaryAddress: this.temporaryAddress,
            candidacies: this.candidacies,
            contracts: this.contracts,
            newCv: this.newCv,
            registrationComplete: this.registrationComplete,
            registered: this.registered,
            mobileNumber: this.mobileNumber,
            homeNumber: this.homeNumber,
            authenticated: this.authenticated,
            roles: this.roles,
            niNumber: this.niNumber,
            nationality: this.nationality,
            cvs: this.cvs,
            emergencyContact: this.emergencyContact,
            visaRequired: this.visaRequired,
            visaExpiryDate: this.visaExpiryDate,
            registrationRoleId: this.registrationRoleId,
            selectedCompany: this.selectedCompany,
            selectedCompanyId: this.selectedCompanyId,
            companies: this.companies,
            agreedToTerms: this.agreedToTerms,
            receiveMarketingEmails: this.receiveMarketingEmails,
            archived: this.archived,
            updated: this.updated,
            willingToRelocate: this.willingToRelocate,
            previousIdentities: this.previousIdentities,
            hasPreviousIdentities: this.hasPreviousIdentities,
            linkedInAddress: this.linkedInAddress,
            welcomeMessageViewed: this.welcomeMessageViewed,
            availableFrom: this.availableFrom,
            currentNoticePeriod: this.currentNoticePeriod,
            currentNoticePeriodTimeType: this.currentNoticePeriodTimeType,
            holiday: this.holiday,
            expectedRate: this.expectedRate
        };
    }
}
