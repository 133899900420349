import * as actions from "./actionTypes";
import { ConnectionStatusAction } from "./actions";
import { ConnectionStatus } from "./model";

const initialState = {
    online: false
};

export function connectionReducer(state: ConnectionStatus = initialState, action: ConnectionStatusAction) {
    if (action.type === actions.STATUS_CHANGED) {
        return {
            ...state,
            online: action.payload
        };
    }

    return state;
}
