import { Company, parseCompany } from "@momenta/common/companies";
import axios from "axios";
import { makeRequest, PageResult } from "@momenta/common";
import { FinanceDocument } from "@momenta/common/financeDocuments";
import { parseFinanceDocument } from "@momenta/common/financeDocuments/model";

class CompanyApi {

    private readonly apiUrl = "/api/company";

    public async getAll(): Promise<PageResult<Company>> {
        const response = await axios.get(`${this.apiUrl}`);
        const { items, count } = response.data as PageResult<Company>;
        return {
            items: items.map(company => parseCompany(company)),
            count
        };
    }

    public async detail(id: number): Promise<Company> {
        const response = await axios.get(`${this.apiUrl}/${id}`);
        const result = response.data as Company;
        return parseCompany(result);
    }

    public async save(company: Company): Promise<Company> {
        const response = await axios.put(`${this.apiUrl}/${company.id}`, company);
        const result = response.data as Company;
        return parseCompany(result);
    }

    public async create(company: Company): Promise<Company> {
        const response = await axios.post(this.apiUrl, company);
        return response.data as Company;
    }

    public delete(id: number): Promise<{}> {
        return axios.delete(`${this.apiUrl}/${id}`);
    }

    public verify(id: number): Promise<{}> {
        return axios.put(`${this.apiUrl}/${id}/verify`);
    }

    public async getActiveFinanceDocuments(companyId: number): Promise<FinanceDocument[]> {
        const request = makeRequest(`${this.apiUrl}/${companyId}/financeDocuments`, {
            filter: `Removed eq false and CompanyId eq ${companyId}`
        });

        const response = await axios.get<PageResult<FinanceDocument>>(request);
        const { items } = response.data;
        return items.map(parseFinanceDocument);
    }
}

export default new CompanyApi();
