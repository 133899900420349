import { Result } from "not-valid";

export function positiveNumberValidator(value: number) {

    if (isFinite(value) && value < 0) {
        return Result.Fail("Please enter a positive value");
    }

    return Result.Pass;
}
