import * as React from "react";
import { Form } from "semantic-ui-react";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { createEmptyState } from "@momenta/common/utils/createEmptyState";

import { ColumnDefinition, EditComponent } from "./InlineEditTable";

interface CreateFormProps<T> extends SaveDispatchProps<T> {
    columns: ColumnDefinition<T>[];
}

interface CreateFormState<T> extends FormState<T> {
    loading: boolean;
}

export class CreateForm<T> extends FormBaseComponent<T, CreateFormProps<T>, CreateFormState<T>> {

    constructor(props: CreateFormProps<T>) {
        super(props);

        const values = createEmptyState<T>();

        for (const column of props.columns) {
            values[column.property] = column.default;
        }

        this.state = {
            values,
            valid: {},
            loading: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    public render() {
        const { columns } = this.props;

        return (
            <Form onSubmit={this.onSubmit} loading={this.state.loading} noValidate>
                {columns.filter(col => !col.hidden).map(this.renderColumn)}
            </Form>
        );
    }

    public submit() {
        this.onSubmit({ preventDefault: (): void => undefined } as any);
    }

    private updateDateValidation() {
        const { columns } = this.props;
        const startDate = columns.find(c => c.isStartDate);

        const endDate = columns.find(c => c.isEndDate);

        const startDateValue = startDate && this.state.values[startDate.property];

        const endDateValue = endDate && this.state.values[endDate.property];

        const isValid = (startDateValue && endDateValue) ? endDateValue > startDateValue : true;

        this.setState({
            valid: {
                ...this.state.valid,
                [startDate.property]: isValid,
                [endDate.property]: isValid
            }
        });
    }

    private renderColumn = (column: ColumnDefinition<T>, key: number) => {

        const shouldRender = column.create !== false && (column.create !== undefined || column.edit !== false);

        const render = column.create !== false && column.create !== undefined
            ? column.create
            : column.edit as EditComponent<T>;

        const onChangeWithDateCheck = (value: any, valid: boolean) => {
            this.updateProperty(column.property, value, valid);
            this.updateDateValidation();
        };

        return shouldRender && (
            <div key={key}>
                {render(
                    this.state.values[column.property],
                    (column.isStartDate || column.isEndDate) ? onChangeWithDateCheck : this.getUpdate(column.property),
                    column.label,
                    this.state.showErrors,
                    () => this.state.values as any
                )}
            </div>
        );
    }

    private async onSubmit(event: any) {
        this.setState({ loading: true });
        await this.handleSubmit(event);
        this.setState({ loading: false });
    }
}
