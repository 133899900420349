import { Company } from "@momenta/common/companies";
import moment from "moment";

import { Associate } from "../../People/src/associate";

export interface FinanceDocument extends DocumentBase {
    readonly documentType: FinanceDocumentTypeEnum;
    readonly companyId: number;
    readonly company: Company;
    readonly removed: boolean;
    readonly removedDate: moment.Moment;
    readonly expiryDate?: moment.Moment;
}

export interface FinanceDocumentState {
    financeDocuments: FinanceDocument[];
}

export const parseFinanceDocument = (model: FinanceDocument) => {
    return {
        ...model,
        removedDate: moment(model.removedDate)
    };
};

export interface DocumentBase {
    readonly id: number;
    readonly filename: string;
    readonly path: string;
    readonly dateTime: moment.Moment;
    readonly associateId: number;
    readonly associate?: Associate;
    readonly candidateId?: number;
    readonly vettingId?: number;
}

export enum FinanceDocumentTypeEnum {
    "Unknown" = 0,
    "Company Registration Document" = 10,
    "Pipl Insurance Document" = 20,
    "Vat Registration Document" = 30,
    "Vat Deregistration Document" = 40
}

export const FinanceDocumentType = {
    0: "Unknown",
    10: "Company Registration Document",
    20: "Pipl Insurance Document",
    30: "Vat Registration Document"
};

export class FinanceDocumentBuilder {

    private id: number;
    private documentType: FinanceDocumentTypeEnum;
    private companyId: number;
    private company: Company;
    private removed: boolean;
    private removedDate: moment.Moment;
    private expiryDate?: moment.Moment;
    private filename: string;
    private path: string;
    private dateTime: moment.Moment;
    private associateId: number;
    private associate: Associate;

    constructor() {
        this.id = 0;
        this.documentType = 0;
        this.companyId = 0;
        this.company = null;
        this.removed = false;
        this.removedDate = moment();
        this.expiryDate = moment();
        this.filename = "";
        this.path = "";
        this.dateTime = moment();
        this.associateId = 0;
        this.associate = null;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withDocumentType(documentType: FinanceDocumentTypeEnum) {
        this.documentType = documentType;
        return this;
    }

    public withCompany(company: Company) {
        this.company = company;
        return this;
    }

    public withCompanyId(companyId: number) {
        this.companyId = companyId;
        return this;
    }

    public withRemoved(removed: boolean) {
        this.removed = removed;
        return this;
    }

    public withRemovedDate(removedDate: moment.Moment) {
        this.removedDate = removedDate;
        return this;
    }

    public withExpiryDate(expiryDate?: moment.Moment) {
        this.expiryDate = expiryDate;
        return this;
    }

    public withDateTime(dateTime: moment.Moment) {
        this.dateTime = dateTime;
        return this;
    }

    public withAssociateId(associateId: number) {
        this.associateId = associateId;
        return this;
    }

    public withAssociate(associate: Associate) {
        this.associate = associate;
        return this;
    }

    public withFilename(filename: string) {
        this.filename = filename;
        return this;
    }

    public withPath(path: string) {
        this.path = path;
        return this;
    }

    public build(): FinanceDocument {
        return {
            id: this.id,
            documentType: this.documentType,
            company: this.company,
            companyId: this.companyId,
            removed: this.removed,
            removedDate: this.removedDate,
            expiryDate: this.expiryDate,
            filename: this.filename,
            path: this.path,
            dateTime: this.dateTime,
            associateId: this.associateId,
            associate: this.associate
        };
    }
}
