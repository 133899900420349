import { Timesheet } from "@momenta/common/timesheets";

import * as actions from "./actionTypes";
import { TimesheetAction } from "./actions";

export function timesheets(state: Timesheet[] = [], action: TimesheetAction): Timesheet[] {
    switch (action.type) {
        case actions.LOAD_TIMESHEETS_SUCCESS:
            return action.payload;
        case actions.LOAD_TIMESHEET_SUCCESS:
            if (state.filter(t => t.id === action.payload.id).length > 0) {
                return state.map(t =>  timesheet(t, action));
            }
            return [...state, action.payload];
        default:
            return state;
    }
}

export function timesheet(state: Timesheet, action: TimesheetAction): Timesheet {
    switch (action.type) {
        case actions.LOAD_TIMESHEET_SUCCESS:
            if (state.id === action.payload.id) {
                return action.payload;
            }
            return state;
        default:
            return state;
    }
}
