import { CdnImage } from "@momenta/common";
import * as React from "react";
import { Card, Divider, Grid } from "semantic-ui-react";

export interface MomentaTeamInfoCardProps {
    image: string;
    title: string;
    body: React.ReactNode;
}

export const MomentaTeamInfoCard: React.FC<MomentaTeamInfoCardProps> = ({ image, body, title }) => {
    return (
        <Grid.Column computer={8} mobile={16} >
            <Card fluid className="momenta-team-info-card">
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={1} ></Grid.Column>
                        <Grid.Column computer={1} className="momenta-team-title">{title}</Grid.Column>
                        <Grid.Column computer={9} ></Grid.Column>
                        <Grid.Column computer={4} textAlign="right" ><CdnImage size="tiny" src={"/momenta-logo.png"} /></Grid.Column>
                        <Grid.Column computer={1} ></Grid.Column>
                    </Grid.Row>
                </Grid>
                <CdnImage src={image} />
                <Card.Content>
                    <h3 className="momenta-team-link">www.momentagroup.com</h3>
                    <Divider />
                    {body}
                </Card.Content>
            </Card>
        </Grid.Column>
    );
};
