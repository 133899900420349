import * as React from "react";

import { connect, useSelector } from "react-redux";
import { Link } from "redux-little-router";
import { Button, Container, Grid } from "semantic-ui-react";

import { RegionState } from "@momenta/common/internationalisation";
import { Country } from "@momenta/common/model";
import { getQuerySelector } from "@momenta/common/odata";

import { CurrentUserState } from "../currentUser/model";

import { jobIdSelector } from "./JobIdSelector";

interface JobLinkProps {
    isUsa: boolean;
    isAuthenticated: boolean;
}

export const JobLinkUnconnected: React.FC<JobLinkProps> = ({ isUsa, isAuthenticated }) => {
    const name = useSelector(getQuerySelector("name"));
    const jobId = useSelector(jobIdSelector);
    // eslint-disable-next-line max-len
    let representationText = "By submitting this application, you agree to be represented by Momenta for this role and give consent for your CV/Resume and details to be shared with client hiring managers.";

    const jobLink = `/apply/${jobId}?name=${name}`;

    let jobLinkButton = <a href={jobLink}><Button className="momenta-blue-button" content="Yes"></Button></a>;

    if (isUsa && isAuthenticated === false) {
        jobLinkButton = <Button className="momenta-blue-button" content="Yes" as={Link} href={`/right-to-work?jobLink=${jobId}&jobName=${name}`} />;
        // eslint-disable-next-line max-len
        representationText = "By submitting this application, you agree to be represented by Momenta.  We will be in touch with you prior to sending your CV or sharing any contact details with any of our client hiring managers.  We truly appreciate your interest in this role!";
    }

    return (
        <div className="job-link">
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={10} mobile={16}>

                            <strong> Please note you are now leaving the site and being taken to the site of Momenta
                            whereby you can continue the application process.</strong>

                            <h3 className="momenta-blue-text">Momenta Representation Agreement</h3>

                            <p>{representationText}</p>

                            <p>Are you happy to proceed?  {jobLinkButton}</p>
                            <br />
                            <p>Your application is almost complete</p>

                            <p>Completing your application with Momenta should only take a few minutes and will be managed
                            on Momenta People, our cutting edge online candidate platform.
                            </p>
                            <ul>
                                <li>If you are not yet registered with Momenta, confirm you are happy to proceed above and follow the simple steps.</li>
                                <li>If you already have an account with us, confirm you are happy to proceed above to submit your application</li>
                            </ul>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <div className="job-link-image" />
        </div>
    );
};

const mapStateToProps = (state: RegionState & CurrentUserState): JobLinkProps => {
    return {
        isUsa: state.region === Country.Usa,
        isAuthenticated: state.currentUser.authenticated,
    };
};

export const JobLink = connect(mapStateToProps)(JobLinkUnconnected);
