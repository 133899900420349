import { dateSortDesc } from "@momenta/common";
import * as React from "react";
import { Action } from "redux";
import { Button, Divider, Grid, Message, Popup } from "semantic-ui-react";
import { Fragment, Link } from "redux-little-router";
import { ThunkDispatch } from "redux-thunk";
import { FixedActions } from "@momenta/common/fixedActions";
import { asyncResolve, AsyncResolveProps } from "@momenta/common/asyncResolve";
import { connect } from "react-redux";
import { compose } from "recompose";
import { gapReferencingCalculation } from "@momenta/common/referencing/gapReferencingCalculation";
import { allReferencesSelector } from "@momenta/common/referencing/selectors";
import { deleteReferences, loadReferences, submitReferences } from "@momenta/common/referencing/actions";
import { ReferenceApi } from "@momenta/common/referencing/ReferenceApi";
import { Reference, ReferenceConfiguration } from "@momenta/common/referencing/model";
import { GapReferenceItem } from "@momenta/common/referencing/GapReferencingItem";
import { toast } from "@momenta/common/toasts";

import { AssociateQualification } from "@momenta/common/qualifications/model";

import { CurrentUser } from "../currentUser/model";
import { saveCurrentUser, loadCurrentUser } from "../currentUser/actions";

import { currentUserSelector } from "../currentUser/selector";

import { associateQualificationsSelector } from "../qualifications/selector";

import { AssociateQualificationsCodeTable } from "../qualifications/codeTable";

import { CreateReferenceModal } from "./CreateReferenceModal";
import { ReferenceItem } from "./ReferenceItem";
import { validateReferences } from "./validateReferences";
import { EditReferenceModal } from "./EditReferenceModal";
import { ReferencingHeader } from "./ReferencingHeader";

export interface AsyncProps extends AsyncResolveProps {
    referenceConfiguration: ReferenceConfiguration;
}

export interface ReferencingState {
    error: string;
    open: boolean;
    node: any;
    submitting: boolean;
}

export interface ReferencingStateProps {
    references: Reference[];
    currentUser: CurrentUser;
    qualifications: AssociateQualification[];
}

export interface ReferencingDispatchProps {
    deleteReference: (referenceId: number) => Promise<void>;
    submitReference: (referenceIds: number[]) => Promise<void>;
    updateCurrentUser: (currentUser: CurrentUser) => Promise<void>;
}

const timeoutLength = 5000;

export class ReferencingUnconnected extends React.Component<ReferencingStateProps & ReferencingDispatchProps & AsyncProps, ReferencingState> {

    public state: ReferencingState = {
        error: "",
        open: false,
        node: undefined,
        submitting: false
    };

    private timeoutId: any;

    public render() {
        const { deleteReference, referenceConfiguration } = this.props;
        const { error, open, node } = this.state;

        return (
            <FixedActions className="referencing-detail">
                <h1>Academic/Employment History</h1>
                <ReferencingHeader referenceConfiguration={referenceConfiguration} />

                {this.getAllReferences()
                    .sort(dateSortDesc(d => d.start))
                    .map((reference, index) => {
                        return reference.gap
                            ? <GapReferenceItem key={index} reference={reference} />
                            : <ReferenceItem key={index} reference={reference} deleteReference={deleteReference} />;
                    })}

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16} textAlign="right">

                            <Button
                                icon="add"
                                content="Add Reference"
                                color="green"
                                floated="right"
                                as={Link}
                                href={"/referencing/create"}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <CreateReferenceModal updateReferenceGap={this.updateReferencingGap} associateView={true} />
                        <Fragment forRoute="/:referenceId/edit">
                            <EditReferenceModal updateReferenceGap={this.updateReferencingGap} associateView={true} />
                        </Fragment>
                    </Grid.Row>

                    <Divider />
                </Grid>

                <AssociateQualificationsCodeTable />

                <FixedActions.Bar className="referencing-actions">
                    <Popup
                        context={node}
                        content={<Message negative><p>{error}</p></Message>}
                        open={open}
                        onClose={this.handleClose}
                        position="top right"
                        wide="very"
                    />
                    <Grid.Column mobile={6} computer={2} floated="right">
                        <div ref={this.handleRef}>
                            <Button
                                icon="send"
                                content="Submit"
                                color="green"
                                onClick={this.submitClick}
                                disabled={this.props.references.every(r => r.submitted === true)}
                            />
                        </div>
                    </Grid.Column>
                </FixedActions.Bar>
            </FixedActions>
        );
    }

    private handleRef = (node: any) => {
        this.setState({ node });
    }

    private updateReferencingGap = async () => {
        const validationResult = validateReferences(this.getAllReferences(), this.props.referenceConfiguration);
        if (validationResult.length > 0) {
            await this.updateReferencingComplete(false);
            return;
        }

        await this.updateReferencingComplete(true);
    }

    private submitClick = async () => {

        const validationResult = validateReferences(this.getAllReferences(), this.props.referenceConfiguration);
        if (validationResult.length > 0) {
            this.showErrors(validationResult);
            await this.updateReferencingComplete(false);
            return;
        }

        const referenceIds = this.props.references.filter(r => r.submitted === false).map(r => r.id);

        this.setState({ submitting: true });
        await this.props.submitReference(referenceIds);
        await this.updateReferencingComplete(true);
        this.setState({ submitting: false });
    }

    private showErrors = (error: string) => {
        this.setState({ open: true, error });

        this.timeoutId = setTimeout(() => {
            this.setState({ open: false });
        }, timeoutLength);
    }

    private handleClose = () => {
        this.setState({ open: false });
        clearTimeout(this.timeoutId);
    }

    private getAllReferences = () => {
        const { references, referenceConfiguration } = this.props;

        const nonGapReferences = references.sort(dateSortDesc(d => d.start));
        const gapReferences = nonGapReferences.length > 0 && referenceConfiguration
            ? gapReferencingCalculation(nonGapReferences, referenceConfiguration) as Reference[]
            : [];

        const allReferences = gapReferences.concat(nonGapReferences);
        return allReferences;
    }

    private updateReferencingComplete = (referencingComplete: boolean) => {

        this.props.updateCurrentUser({
            ...this.props.currentUser,
            referencingComplete
        });

    }
}

const mapStateToProps = (state: any): ReferencingStateProps => ({
    references: allReferencesSelector(state),
    currentUser: currentUserSelector(state),
    qualifications: associateQualificationsSelector(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, Action>): ReferencingDispatchProps => ({
    deleteReference: async (referenceId: number) => {
        await dispatch(deleteReferences(referenceId));
        toast.success("Reference deleted");
    },
    submitReference: async (referenceIds: number[]) => {
        await dispatch(submitReferences(referenceIds));
        toast.success(`${referenceIds.length} Reference${referenceIds.length > 1 ? "s" : ""} submitted`);
        await dispatch(loadReferences());
        await dispatch(loadCurrentUser());
    },
    updateCurrentUser: (currentUser: CurrentUser) => dispatch(saveCurrentUser(currentUser))
});

const referenceApi = new ReferenceApi();

export const Referencing = compose(
    connect(mapStateToProps, mapDispatchToProps),
    asyncResolve({ referenceConfiguration: () => referenceApi.getReferenceConfiguration() })
)(ReferencingUnconnected);
