import * as React from "react";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

interface RefereePhoneNumberProps {
    refereePhoneNumber: string;
    disabled?: boolean;
    showErrors?: boolean;
    onChange: (value: string, valid: boolean) => void;
}

export const RefereePhoneNumber: React.FC<RefereePhoneNumberProps> = ({ refereePhoneNumber, showErrors, onChange }) => {

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Input.PhoneNumber
                        value={refereePhoneNumber}
                        label="Referee Phone Number"
                        showErrors={showErrors}
                        onChange={onChange}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
