import * as React from "react";
import moment from "moment";
import { Button, Grid, Header, List, Segment } from "semantic-ui-react";
import { ValidationState } from "@momenta/common/validationState";

import { ExpenseTypeConfiguration } from "../hierarchicalConfiguration";
import { VatRate } from "../vatRates";
import { Currency, LocalText } from "../internationalisation";

import { Expense, ExpenseInputTypeEnum } from "./model";
import { ExpenseRow } from "./ExpenseRow";
import { AddExpense } from "./AddExpense";

interface ExpensesEditProps {
    timesheetId: number;
    start: moment.Moment;
    end?: moment.Moment;
    expenses: Expense[];
    expenseTypeConfigurations: ExpenseTypeConfiguration[];
    expensesChanged: (expenses: Expense[], expenseValid: ValidationState, receiptUpdated: boolean) => void;
    expensesValid: ValidationState;
    submitted: boolean;
    online: boolean;
    showErrors: boolean;
    saving: boolean;
    isAdjustment: boolean;
    canAlwaysEditReceipts?: boolean;
    vatRates: VatRate[];
    isInvoiced?: boolean;
    timesheetState?: number;
}

interface ExpensesEditState {
    savedExpenses: number[];
    editingExpense?: number;
    deletingExpense?: number;
    expenses: Expense[];
}

const getArrayOfNumbers = (length: number) => Array.from(Array(length).keys());

export class ExpensesEdit extends React.Component<ExpensesEditProps, ExpensesEditState> {

    constructor(props: ExpensesEditProps) {
        super(props);

        this.state = {
            expenses: props.expenses,
            savedExpenses: getArrayOfNumbers(props.expenses.length),
            editingExpense: undefined
        };

        this.saveExpense = this.saveExpense.bind(this);
        this.addExpense = this.addExpense.bind(this);
        this.deleteExpense = this.deleteExpense.bind(this);
        this.totalExpenses = this.totalExpenses.bind(this);
        this.totalVat = this.totalVat.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    public render() {

        const getHandleDelete = (index: number) => { return (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.deleteExpense(event, index); };
        const getOnEditClick = (index: number) => { return () => this.onEditClick(index); };
        const getOnCancel = (index: number) => { return () => this.onCancel(index); };
        const getHandleSave = (index: number) => { return (model: Expense) => this.saveExpense(index, model); };

        return (
            <>
                <Header attached="top">
                    <h2>Expenses</h2>
                </Header>
                <Segment attached>

                    <List divided>
                        {this.state.expenses.length === 0 &&
                            <p className="disabled-text">You currently have no expenses listed for this month</p>
                        }

                        {this.state.expenses.map((expense, index) => this.state.editingExpense === index
                            ? (
                                <List.Item key={index}>
                                    <List.Content>
                                        <AddExpense
                                            startDate={this.props.start}
                                            endDate={this.props.end}
                                            model={expense}
                                            save={getHandleSave(index)}
                                            expenseTypeConfigurations={this.props.expenseTypeConfigurations}
                                            cancel={getOnCancel(index)}
                                            expenses={this.props.expenses}
                                            online={this.props.online}
                                            isAdjustment={this.props.isAdjustment}
                                            submitted={this.props.submitted}
                                            vatRates={this.props.vatRates}
                                        />
                                    </List.Content>
                                </List.Item>
                            )
                            : (<ExpenseRow
                                key={index}
                                expense={expense}
                                onEditClick={getOnEditClick(index)}
                                onDeleteClick={getHandleDelete(index)}
                                submitted={this.props.submitted || this.state.deletingExpense === index}
                                expenseValid={(this.props.expensesValid && this.props.expensesValid[index]) as boolean}
                                showErrors={this.props.showErrors}
                                expenseTypeConfigurations={this.props.expenseTypeConfigurations}
                                canAlwaysEditReceipts={this.props.canAlwaysEditReceipts}
                                isInvoiced={this.props.isInvoiced}
                                timesheetState={this.props.timesheetState}
                                disableEdit={this.state.editingExpense && this.state.editingExpense !== index}
                            />)
                        )}
                    </List>
                </Segment>
                <Segment attached="bottom">
                    <Grid>
                        <Grid.Column computer={11} mobile={9} verticalAlign="middle" className="total-row">
                            <span><b>Total expenses this week: </b></span>
                            <span><b>Net</b> <Currency value={this.totalExpenses()} /></span>&nbsp;
                            <span><b><LocalText keyName="taxAbbreviation" /></b> <Currency value={this.totalVat()} /></span>
                        </Grid.Column>
                        <Grid.Column computer={5} mobile={7} floated="right">
                            <Button
                                basic
                                floated="right"
                                disabled={this.props.submitted || this.state.editingExpense !== undefined || this.props.saving}
                                content="Add"
                                icon="add"
                                color="green"
                                onClick={this.addExpense}
                                loading={this.props.saving}
                            />
                        </Grid.Column>
                    </Grid>
                </Segment>
            </>
        );
    }

    public UNSAFE_componentWillReceiveProps(props: ExpensesEditProps) {
        this.setState(previous => {

            let expenses = props.expenses;

            if (previous.deletingExpense !== undefined && props.saving) {
                expenses = previous.expenses;
            }

            if (previous.deletingExpense === undefined && previous.expenses.length > props.expenses.length) {
                expenses = [...props.expenses, previous.expenses[previous.expenses.length - 1]];
            }

            return {
                expenses,
                deletingExpense: previous.deletingExpense !== undefined && props.saving ? previous.deletingExpense : undefined
            };
        });
    }

    private async saveExpense(index: number, expense: Expense) {

        const previousExpense = this.state.expenses.filter(e => e.id === expense.id)[0];
        const receiptChanged = previousExpense
            && (previousExpense.receiptFile !== expense.receiptFile || previousExpense.previousReceiptExpenseId !== expense.previousReceiptExpenseId);

        this.setState(current => {

            const expenses = [...current.expenses];
            expenses.splice(index, 1, expense);

            const newState: ExpensesEditState = {
                expenses,
                savedExpenses: [...current.savedExpenses.filter(e => e !== index), index],
                editingExpense: undefined
            };
            return newState;
        }, () => this.props.expensesChanged(this.state.expenses, { ...this.props.expensesValid, [index]: true }, receiptChanged));
    }

    private addExpense(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();

        this.setState(current => {

            const expenses = [
                ...current.expenses,
                {
                    id: 0,
                    expenseTypeId: null,
                    expenseType: null,
                    timesheetId: this.props.timesheetId,
                    date: this.props.start,
                    description: "",
                    net: 0,
                    vat: 0,
                    receiptFile: null,
                    previousReceipt: false,
                    previousReceiptExpenseId: null,
                    correlationId: moment().valueOf(),
                    amount: undefined
                }
            ];

            return {
                expenses,
                editingExpense: expenses.length - 1
            };
        });
    }

    private onEditClick(index: number) {
        this.setState({
            editingExpense: index
        });
    }

    private onCancel(index: number) {

        this.setState(current => {

            const expenses = [...current.expenses];

            if (current.savedExpenses.indexOf(index) === -1) {
                expenses.splice(index, 1);
            }

            return {
                expenses,
                editingExpense: undefined
            };
        });
    }

    private deleteExpense(event: React.MouseEvent<HTMLButtonElement>, index: number) {
        event.stopPropagation();
        event.preventDefault();

        this.setState(current => ({
            ...current,
            editingExpense: undefined,
            deletingExpense: index
        }),
        () => {
            const expenses = [...this.state.expenses];
            expenses.splice(index, 1);
            this.props.expensesChanged(expenses, { ...this.props.expensesValid }, false);
        });
    }

    private totalExpenses() {
        let sum = 0;
        for (const expense of this.state.expenses) {
            const expenseConfig = this.props.expenseTypeConfigurations.filter(c => c.id === expense.expenseTypeId)[0];
            const isQuantity = expenseConfig && expenseConfig.type.expenseInputType === ExpenseInputTypeEnum.Quantity;
            sum += isQuantity ? expense.amount * expenseConfig.rate : expense.net;
        }

        return sum;
    }

    private totalVat = () => this.state.expenses.reduce((sum, expense) => sum + expense.vat, 0);
}
