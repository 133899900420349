import { Company, loadCompaniesSuccess } from "@momenta/common/companies";
import { loadAndTrack } from "redux-request-loading";
import { createCompanySuccess, deleteCompanySuccess, saveCompanySuccess } from "@momenta/common/companies/actions";

import CompanyApi from "./companyApi";

export function createCompany(company: Company) {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "createCompany", CompanyApi.create(company));
        dispatch(createCompanySuccess(result));
    };
}

export function saveCompany(company: Company) {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "saveCompany", CompanyApi.save(company));
        dispatch(saveCompanySuccess(result));
    };
}

export function deleteCompany(id: number) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "deleteCompany", CompanyApi.delete(id));
        dispatch(deleteCompanySuccess(id));
    };
}

export function loadCompanies() {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "loadCompanies", CompanyApi.getAll());
        dispatch(loadCompaniesSuccess(result.items));
    };
}
