import * as React from "react";
import { PreviousIdentity } from "src/App/People/src/associate/model";
import { Table, Button } from "semantic-ui-react";

interface PreviousIdentityEditProps{
    previousIdentity: PreviousIdentity;
    onDelete: (identity: PreviousIdentity) => void;
}

const PreviousIdentityEdit: React.FC<PreviousIdentityEditProps> = ({ previousIdentity, onDelete }) => {

    const { id, forename, middleName, surname, lastUsed } = previousIdentity;

    const deleteIdentity = () => {
        event.preventDefault();
        onDelete(previousIdentity);
    };

    return (
        <Table.Row key={id}>
            <Table.Cell>{forename}</Table.Cell>
            <Table.Cell>{middleName}</Table.Cell>
            <Table.Cell>{surname}</Table.Cell>
            <Table.Cell>{lastUsed.format("L")}</Table.Cell>
            <Table.Cell>
                <Button
                    icon="trash alternate outline"
                    onClick={deleteIdentity}
                    color="red"
                />
            </Table.Cell>
        </Table.Row>
    );
};

export {
    PreviousIdentityEdit
};
