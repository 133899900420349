import * as actions from "./actiontypes";
import { FinanceDocumentAction } from "./actions";
import { FinanceDocument } from "./model";

export function financeDocuments(state: FinanceDocument[] = [], action: FinanceDocumentAction): FinanceDocument[] {
    switch (action.type) {
        case actions.LOAD_FINANCE_DOCUMENTS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
