import {
    loadAllMomentaDocumentVettingData,
    loadAllProofDocumentVettingData
} from "../vetting/actions";

import { loadUmbrellaCompanies } from "../umbrellaCompany";
import { loadCompanies } from "../company/actions";

export const routes = {
    "/profile": {
        title: "Profile",
        authorize: true
    },
    "/finance": {
        title: "Finance Details",
        authorize: true,
        resolve: [() => loadUmbrellaCompanies(true), loadCompanies]
    },
    "/documents": {
        title: "Documents",
        authorize: true,
        resolve: [loadAllProofDocumentVettingData, loadAllMomentaDocumentVettingData]
    },
    "/preferences": {
        title: "Preferences and Consent",
        authorize: true
    },
};
