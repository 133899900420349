import * as React from "react";
import { PreviousIdentity } from "src/App/People/src/associate/model";
import { Table, Header, Grid } from "semantic-ui-react";

import { Input } from "@neworbit/simpleui-input";

import { GridRowHeader } from "src/App/Associate/src/currentUser/GridRowHeader";

import { PreviousIdentityEdit } from "./PreviousIdentityEdit";
import { PreviousIdentityCreate } from "./PreviousIdentityCreate";

export interface PreviousIdentitiesEditProps {
    hasPreviousIdentities: boolean;
    onHasPreviousIdentitiesChange: any;
    previousIdentities: PreviousIdentity[];
    onChange: any;
    message?: string;
}

const PreviousIdentitiesEdit: React.FC<PreviousIdentitiesEditProps> = (
    { previousIdentities, onChange, hasPreviousIdentities, onHasPreviousIdentitiesChange, message }) => {

    const [editFormValues, changeEditFormValues] = React.useState(previousIdentities);

    const identitiesMatch = (identity: PreviousIdentity, currentIdentity: PreviousIdentity) => {
        if (identity.id === currentIdentity.id &&
            identity.forename === currentIdentity.forename &&
            identity.surname === currentIdentity.surname &&
            identity.lastUsed === currentIdentity.lastUsed) {
            return true;
        }
        return false;
    };

    const deletePreviousIdentity = (identity: PreviousIdentity) => {
        const updatedIdentities = editFormValues.filter(i => identitiesMatch(i, identity) === false);
        changeEditFormValues(updatedIdentities);
    };

    const addPreviousIdentity = (newIdentity: PreviousIdentity) => {
        const updatedIdentities = [...editFormValues, newIdentity];
        changeEditFormValues(updatedIdentities);
    };

    React.useEffect(() => {
        if (editFormValues !== undefined) {
            onChange(editFormValues);
        }
    }, [editFormValues]);

    const label = message ? message : "Do you have any previous names (including maiden names) to declare?";

    return (
        <>
            <GridRowHeader text="Previous Names" />
            <Header content={label} size="small"></Header>
            <Grid.Row>
                <Grid.Column>
                    <Input.Checkbox
                        label={hasPreviousIdentities ? "Yes" : "No"}
                        value={hasPreviousIdentities}
                        onChange={onHasPreviousIdentitiesChange}
                        toggle
                    />
                </Grid.Column>
            </Grid.Row>

            {hasPreviousIdentities &&
                <Table singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Forename</Table.HeaderCell>
                            <Table.HeaderCell>Middle Name</Table.HeaderCell>
                            <Table.HeaderCell>Surname</Table.HeaderCell>
                            <Table.HeaderCell>Last Used</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {editFormValues && editFormValues.map((i: PreviousIdentity) => (
                            <PreviousIdentityEdit
                                key={i.id}
                                previousIdentity={i}
                                onDelete={deletePreviousIdentity}
                            />
                        ))}
                    </Table.Body>
                    <PreviousIdentityCreate
                        onCreate={addPreviousIdentity}
                    />
                </Table>
            }
        </>
    );

};

export {
    PreviousIdentitiesEdit
};
