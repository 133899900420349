import React from "react";
import { ValidationFunction } from "not-valid";

import { debounce } from "@neworbit/simpleui-utils";
import { Input } from "@neworbit/simpleui-input";

import { Form, DropdownOnSearchChangeData, DropdownProps, DropdownItemProps } from "semantic-ui-react";

import { AddressLookupApi } from "./AddressLookupApi";

interface CityLookupDropdownProps {
    selectedState?: string;
    city?: string;
    onChange?: (city: string, valid: boolean) => void;
    showErrors: boolean;
    validation?: ValidationFunction<string>[];
}

const addressLookupApi = new AddressLookupApi();
const OTHER = "Other";

const customSearch = function(options: DropdownItemProps[]) {
    return options;
};

export const CityLookupDropdown: React.FC<CityLookupDropdownProps> = ({ selectedState, city, onChange, showErrors, validation }) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [cities, setCities] = React.useState<string[]>([]);
    const [query, setQuery] = React.useState<string>(city);
    const [selectedCity, setSelectedCity] = React.useState(city);

    const isOther = selectedCity === OTHER;

    React.useEffect(() => {
        (async () => {
            if (!selectedState) {
                return;
            }
            setLoading(true);
            const cityList = await addressLookupApi.usCityLookup(selectedState, query);
            cityList.push(OTHER);
            setCities(cityList);
            if (!!city && !cityList.includes(city)) {
                setSelectedCity(OTHER);
            }
            setLoading(false);
        })();
    }, [selectedState, query, city]);

    React.useEffect(() => {
        if (!city) {
            setQuery("");
        }
    }, [selectedState, city]);

    const searchCity = (_event: React.SyntheticEvent<HTMLElement>, { searchQuery }: DropdownOnSearchChangeData,) => {
        setQuery(searchQuery);
    };

    const cityChange = (_e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
        const c = value as string;
        setSelectedCity(c);
        if (c === OTHER) {
            onChange("", false);
        } else {
            onChange(c, true);
        }
    };

    const customCityChange = (customCity: string) => {
        onChange(customCity, !!customCity);
    };

    const searchDebounced = debounce(searchCity, 300);

    return (
        <>
            <div className="field-wrapper">
                <Form.Dropdown
                    label="City"
                    options={cities.map(c => ({ text: c, value: c }))}
                    onSearchChange={searchDebounced}
                    loading={loading}
                    noResultsMessage={loading ? "Searching..." : "No results"}
                    search={customSearch}
                    value={selectedCity}
                    onChange={cityChange}
                    placeholder="Choose City"
                    error={showErrors}
                    selection
                    required
                    fluid
                />
            </div>
            {isOther && <>
                <div className="field-wrapper">
                    <Input.Text
                        value={city}
                        label="Other City"
                        onChange={customCityChange}
                        showErrors={showErrors}
                        validation={validation}
                        required
                    />
                </div>
            </>}
        </>
    );
};
