import axios, { AxiosStatic } from "axios";
import { CandidateWithdrawModel } from "src/App/People/src/candidates/model";

export class CandidateApi {

    private apiUrl = "/api/candidate";
    private axios: AxiosStatic;
    private readonly pageSize = 20;

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async withdrawCandidate(candidateId: number, model: CandidateWithdrawModel) {
        await this.axios.put(`${this.apiUrl}/${candidateId}/withdraw`, model);
    }
}

export const candidateApi =  new CandidateApi(axios);
