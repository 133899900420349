import * as React from "react";
import { connect } from "react-redux";
import { LoadingState } from "redux-request-loading";
import { Progress } from "semantic-ui-react";
import { debounce } from "@neworbit/simpleui-utils";

interface LoadingBarProps {
    active: boolean;
    progress: number;
}

interface LoadingBarState {
    active: boolean;
    progress: number;
}

class LoadingBarUnconnected extends React.Component<LoadingBarProps, LoadingBarState> {

    private setActiveDebounced: (active: boolean) => void;

    constructor(props: LoadingBarProps) {
        super(props);

        this.state = {
            active: false,
            progress: 0
        };

        this.setActiveDebounced = debounce(this.setActive, 500).bind(this);
    }

    public render() {
        const { active, progress } = this.state;

        return active && (
            <Progress
                percent={progress}
                size="tiny"
                className="loading-bar"
            />
        );
    }

    public UNSAFE_componentWillReceiveProps({ active, progress }: LoadingBarProps) {

        this.setState(current => ({ progress, active: active || current.active }), () => {

            if (!active) {
                this.setActiveDebounced(active);
            }
        });

    }

    private setActive = (active: boolean) => {
        this.setState({ active });
    }
}

const mapStateToProps = ({ loading }: LoadingState): LoadingBarProps => ({
    active: loading.active,
    progress: loading.progress
});

const LoadingBar = connect(mapStateToProps)(LoadingBarUnconnected);
export {
    LoadingBar
};
