import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";
import { AuthState } from "reauthorize";

import * as actions from "../vetting/actionTypes";
import { MomentaDocumentVettingData, ProofDocumentVettingData } from "../vetting/model";

import { vettingItemApi } from "./VettingItemApi";

export type VettingAction =
    ({ type: actions.LOAD_PROOF_DOCUMENTS_SUCCESS } & Payload<ProofDocumentVettingData[]>
        | { type: actions.LOAD_MOMENTA_DOCUMENTS_SUCCESS } & Payload<MomentaDocumentVettingData[]>
        | { type: actions.SAVE_PROOF_DOCUMENT_SUCCESS } & Payload<ProofDocumentVettingData>
        | { type: actions.SAVE_MOMENTA_DOCUMENT_SUCCESS } & Payload<MomentaDocumentVettingData>);

export const loadProofDocumentsSuccess = (payload: ProofDocumentVettingData[]): VettingAction => ({
    type: actions.LOAD_PROOF_DOCUMENTS_SUCCESS,
    payload
});

export const loadMomentDocumentsSuccess = (payload: MomentaDocumentVettingData[]): VettingAction => ({
    type: actions.LOAD_MOMENTA_DOCUMENTS_SUCCESS,
    payload
});

export const saveProofDocumentSuccess = (payload: ProofDocumentVettingData): VettingAction => ({
    type: actions.SAVE_PROOF_DOCUMENT_SUCCESS,
    payload
});

export const saveMomentaDocumentSuccess = (payload: MomentaDocumentVettingData): VettingAction => ({
    type: actions.SAVE_MOMENTA_DOCUMENT_SUCCESS,
    payload
});

export function loadAllProofDocumentVettingData() {
    return async (dispatch: any, getState: () => AuthState) => {
        if (!getState().currentUser.authenticated) {
            return;
        }
        const { items } = await loadAndTrack(dispatch, "loadAllProofDocumentVettingData", vettingItemApi.loadAllProofDocument());
        dispatch(loadProofDocumentsSuccess(items));
    };
}

export function loadAllMomentaDocumentVettingData() {
    return async (dispatch: any, getState: () => AuthState) => {
        if (!getState().currentUser.authenticated) {
            return;
        }
        const { items } = await loadAndTrack(dispatch, "loadAllMomentaDocumentVettingData", vettingItemApi.loadAllMomentaDocument());
        dispatch(loadMomentDocumentsSuccess(items));
    };
}

export function createFastTrackVettingItems() {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "createFastTrackVettingItems", vettingItemApi.createFastTrackVettingItems());
    };
}

export function saveProofDocumentVettingData(vettingItemId: number, model: ProofDocumentVettingData) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "saveProofDocumentVettingData", vettingItemApi.saveProofDocument(vettingItemId, model));
        dispatch(saveProofDocumentSuccess(model));
    };
}

export function saveMomentaDocumentVettingData(vettingItemId: number, model: MomentaDocumentVettingData) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "saveMomentaDocumentVettingData", vettingItemApi.saveMomentaDocument(vettingItemId, model));
        dispatch(saveMomentaDocumentSuccess(model));
    };
}
