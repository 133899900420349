import * as React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { FileUpload, isNullOrUndefined, truncateGuid } from "@momenta/common";
import { componentVisible } from "@momenta/common/internationalisation";
import { compose } from "recompose";
import { connect } from "react-redux";
import { DetailColumn } from "@momenta/common/companies/DetailColumn";

import { AppState } from "../model";

import { CurrentUser } from "./model";
import { GridRowHeader } from "./GridRowHeader";
import { onContractSelector } from "./selector";

interface SuperannuationDetailsEditProps {
    model: CurrentUser;
    values: CurrentUser;
    showErrors: boolean;
    onChange: (prop: string, value: string, valid: boolean) => void;
}

interface SuperannuationDetailsEditStateProps {
    onContract: boolean;
}

export const SuperannuationDetailsEditUnconnected: React.FC<SuperannuationDetailsEditProps & SuperannuationDetailsEditStateProps>
    = ({ model, values, showErrors, onChange, onContract }) => {

        const onFundNameChange = (value: string, valid: boolean) => { onChange("superannuationDetails.fundName", value, valid); };
        const onUsiCodeChange = (value: string, valid: boolean) => { onChange("superannuationDetails.usiCode", value, valid); };
        const onMembershipNameChange = (value: string, valid: boolean) => { onChange("superannuationDetails.membershipName", value, valid); };
        const onMembershipNumberChange = (value: string, valid: boolean) => { onChange("superannuationDetails.membershipNumber", value, valid); };
        const onSuperFormChange = (value: string, valid: boolean) => { onChange("superannuationDetails.superForm", value, valid); };

        const superFormNotProvided = isNullOrUndefined(model.superannuationDetails) || isNullOrUndefined(model.superannuationDetails.superForm);

        return (
            <>
                <GridRowHeader text="Superannuation Details" />
                <Grid.Row>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        {onContract === true
                            ? <DetailColumn label="Fund Name" value={values.superannuationDetails && values.superannuationDetails.fundName} />
                            : <Input.Text
                                value={values.superannuationDetails && values.superannuationDetails.fundName}
                                label="Fund Name"
                                onChange={onFundNameChange}
                                disableAutocorrect
                                showErrors={showErrors}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        {onContract === true
                            ? <DetailColumn label="SPIN/USI Code" value={values.superannuationDetails && values.superannuationDetails.usiCode} />
                            : <Input.Text
                                value={values.superannuationDetails && values.superannuationDetails.usiCode}
                                label="SPIN/USI Code"
                                onChange={onUsiCodeChange}
                                showErrors={showErrors}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        {onContract === true
                            ? <DetailColumn label="Membership Name" value={values.superannuationDetails && values.superannuationDetails.membershipName} />
                            : <Input.Text
                                value={values.superannuationDetails && values.superannuationDetails.membershipName}
                                label="Membership Name"
                                onChange={onMembershipNameChange}
                                showErrors={showErrors}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column computer={8} mobile={16} tablet={8}>
                        {onContract === true
                            ? <DetailColumn label="Membership Number" value={values.superannuationDetails && values.superannuationDetails.membershipNumber} />
                            : <Input.Text
                                value={values.superannuationDetails && values.superannuationDetails.membershipNumber}
                                label="Membership Number"
                                onChange={onMembershipNumberChange}
                                showErrors={showErrors}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={5} mobile={16} tablet={8} verticalAlign="middle">
                        {onContract === false && superFormNotProvided === true
                            ? <FileUpload
                                label="Super Form"
                                apiUrl="/api/document"
                                placeholder="Super Form"
                                value={values.superannuationDetails && values.superannuationDetails.superForm}
                                filename={values.superannuationDetails && truncateGuid(values.superannuationDetails.superForm)}
                                onFileChange={onSuperFormChange}
                                showErrors={showErrors}
                            />
                            : <div>
                                <strong>Super Form</strong>
                                <Icon fitted name="check circle" color="green" size="large" className="float-right" title="Super Form" />
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    };

const mapStateToProps = (state: AppState): SuperannuationDetailsEditStateProps => ({
    onContract: onContractSelector(state)
});

export const SuperannuationDetailsEdit = compose<SuperannuationDetailsEditProps & SuperannuationDetailsEditStateProps, SuperannuationDetailsEditProps>(
    componentVisible("superannuationDetails"),
    connect(mapStateToProps)
)(SuperannuationDetailsEditUnconnected);
