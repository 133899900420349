import * as React from "react";
import { Message } from "semantic-ui-react";
import { defaultProps } from "recompose";

import { DismissableMessageStorage } from "./DismissableMessageStorage";

interface DismissableMessageProps {
    message: JSX.Element | string;
    keyName: string;
    placeholder: string;
    save: (key: string, showMessage: boolean) => Promise<void>;
    load: (key: string) => Promise<boolean>;
}

interface DismissableMessageState {
    visible: boolean;
}

export class DismissableMessageUnconnected extends React.Component<DismissableMessageProps, DismissableMessageState> {
    public state: DismissableMessageState = {
        visible: true
    };

    public async componentDidMount() {
        const visible = await this.props.load(this.props.keyName);

        if (visible === null) {
            this.setState({ visible: true });
            return;
        }

        this.setState({ visible });
    }

    public render() {
        const { visible } = this.state;

        if (!visible) {
            return (
                <div className="dismissable-message">
                    <a onClick={this.handleShowMessage} className="dismiss-link">{this.props.placeholder}</a>
                </div>
            );
        }

        return <Message content={this.props.message} onDismiss={this.handleDismiss} className="dismissable-message" info />;
    }

    private handleDismiss = () => {
        const visible = false;
        this.setState({ visible });
        this.props.save(this.props.keyName, visible);
    }

    private handleShowMessage = () => {
        const visible = true;
        this.setState({ visible });
        this.props.save(this.props.keyName, visible);
    }
}

const dismissableMessageStorage = new DismissableMessageStorage();

export const DismissableMessage = defaultProps({
    save: dismissableMessageStorage.save.bind(dismissableMessageStorage),
    load: dismissableMessageStorage.load.bind(dismissableMessageStorage)
})(DismissableMessageUnconnected);
