import * as React from "react";
import { Button, Grid, SemanticWIDTHS, Table } from "semantic-ui-react";
import { ConfirmModal } from "@momenta/common";
import { PaginationComponent } from "@momenta/common/pagination";

import moment from "moment";

import { CreateModal } from "./CreateModal";
import { InlineEditTableRow } from "./InlineEditTableRow";

interface InlineEditTableProps<T> {
    title: string;
    titleWidth?: SemanticWIDTHS;
    buttonLabel?: string;
    buttonWidth?: SemanticWIDTHS;
    columns: ColumnDefinition<T>[];
    data: T[];
    keyProp: keyof T;
    onCreate?: (model: T) => Promise<void>;
    onEdit?: (model: T) => Promise<void>;
    onDelete?: (key: any) => Promise<void>;
    canEdit?: (model: T) => boolean;
    canDelete?: (model: T) => boolean;
    setDateEditValues?: (start: moment.Moment, end: moment.Moment) => void;
    editDisabled?: boolean;
    rowClassName?: string | ((model: T) => string);
    subheading?: string;
}

export type EditComponent<T, TValue = any> = (
    value: TValue,
    onChange: (value: TValue, valid: boolean, state?: ValuesAndValid<T>) => void,
    label: string,
    showErrors: boolean,
    getState: <TState = any>() => TState
) => React.ReactNode;

export interface ColumnDefinition<T> {
    label: string;
    property: keyof T;
    edit: EditComponent<T> | false;
    create?: EditComponent<T> | false;
    render?: (value: any, row: T) => React.ReactNode;
    default?: any;
    hidden?: boolean;
    isStartDate?: boolean;
    isEndDate?: boolean;
}

type Valid<T> = {
    [k in keyof T]?: boolean;
};

export interface ValuesAndValid<T> {
    values?: Partial<T>;
    valid?: Valid<T>;
}

interface InlineEditTableState<T> {
    editing: any;
    createOpen: boolean;
    deleteConfirm: any | false;
    loading: boolean;
    showErrors: boolean;
    total: number;
    page: number;
    values: Partial<T>;
    valid: Valid<T>;
}

export class InlineEditTable<T> extends React.Component<InlineEditTableProps<T>, InlineEditTableState<T>> {

    constructor(props: InlineEditTableProps<T>) {
        super(props);

        this.state = {
            editing: false,
            createOpen: false,
            deleteConfirm: false,
            loading: false,
            showErrors: false,
            values: {},
            valid: {},
            total: 0,
            page: 1
        };

        this.editRow = this.editRow.bind(this);
    }

    public render() {

        const { keyProp, columns, data, editDisabled } = this.props;

        const defaultButtonLabel = this.props.buttonLabel ? this.props.buttonLabel : "Create";
        const titleWidth = this.props.titleWidth ? this.props.titleWidth : 14;
        const buttonWidth = this.props.buttonWidth ? this.props.buttonWidth : 2;
        const canEdit = this.props.canEdit || (() => true);
        const canDelete = this.props.canDelete || (() => true);
        const rowClassName = (row: T): string => {
            if (this.props.rowClassName === undefined) {
                return undefined;
            }

            if (typeof this.props.rowClassName === "string") {
                return this.props.rowClassName;
            }

            return this.props.rowClassName(row);
        };

        const startColumn = this.props.columns.find(col => col.isStartDate);

        const endColumn = this.props.columns.find(col => col.isEndDate);

        const updateStartEndDateValid = (valid: boolean) => {
            this.setState(current => ({
                ...current,
                valid: {
                    ...current.valid,
                    [startColumn.property]: valid,
                    [endColumn.property]: valid
                }
            }));
        };

        return (
            <>
                <ConfirmModal
                    title="Delete"
                    open={this.state.deleteConfirm !== false}
                    onCancel={this.deleteCancel}
                    onConfirm={this.deleteConfirm}
                />
                <Grid>
                    <Grid.Column width={titleWidth}>
                        <h1>{this.props.title}</h1>
                    </Grid.Column>

                    <Grid.Column width={buttonWidth} textAlign="right">
                        {this.props.onCreate &&
                            <>
                                <Button icon="add" content={defaultButtonLabel} color="green" onClick={this.openCreate} />
                                <CreateModal
                                    columns={columns}
                                    open={this.state.createOpen}
                                    save={this.onCreate}
                                    onClose={this.closeCreate}
                                    loading={this.state.loading}
                                />
                            </>
                        }
                    </Grid.Column>
                </Grid>
                { this.props.subheading && <h3>{this.props.subheading}</h3>}
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {columns.filter(col => !col.hidden).map((col, key) => (
                                <Table.HeaderCell key={key}>{col.label}</Table.HeaderCell>
                            ))}

                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data.map((row, key) => (
                            <InlineEditTableRow
                                key={`${key}${this.state.values}`}
                                keyProp={keyProp}
                                row={row}
                                columns={columns}
                                disabled={(this.state.editing !== false && !this.isEditing(row[keyProp]))}
                                loading={this.state.loading}
                                onSaveClick={this.save}
                                onEditClick={this.props.onEdit !== null ? this.editRow : null}
                                onDeleteClick={this.deleteRow}
                                isEditing={this.isEditing(row[keyProp])}
                                showErrors={this.state.showErrors}
                                values={this.state.values}
                                updateProperty={this.updateProperty}
                                editDisabled={editDisabled || canEdit(row) === false}
                                deleteDisabled={canDelete(row) === false}
                                className={rowClassName(row)}
                                updateStartEndDateValid={updateStartEndDateValid}
                            />
                        ))}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={columns.length + 1}>
                                <PaginationComponent
                                    total={this.state.total}
                                    page={this.state.page}
                                    pageSize={10}
                                    onPageChange={undefined}
                                    basic
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </>
        );
    }

    private onCreate = async (model: T) => {
        this.setState({ loading: true });
        await this.props.onCreate(model);
        this.setState({ loading: false, createOpen: false });
    }

    private openCreate = () => this.setState({ createOpen: true });
    private closeCreate = () => this.setState({ createOpen: false });

    private save = async () => {

        if (this.formInvalid()) {
            this.setState({ showErrors: true });
            return;
        }

        this.setState({ loading: true });
        await this.props.onEdit(this.state.values as T);
        this.setState({ values: {}, valid: {}, editing: false, loading: false });
    }

    private isEditing(key: any) {
        return this.state.editing === key;
    }

    private editRow(key: any) {
        this.setState(current => {

            let values = {};
            const valid = {};

            const editing = current.editing
                ? false
                : key;

            if (editing !== false) {
                const obj = this.props.data.filter(d => d[this.props.keyProp] === key)[0] as any;
                values = { ...obj };
                if (this.props.setDateEditValues) {
                    this.props.setDateEditValues(obj.start, obj.end);
                }
            }

            return { ...current, editing, values, valid };
        });
    }

    private deleteRow = (key: any) => this.setState({ deleteConfirm: key });
    private deleteCancel = () => this.setState({ deleteConfirm: false });
    private deleteConfirm = async () => {
        const key = this.state.deleteConfirm;
        this.setState({ loading: true, deleteConfirm: false });
        await this.props.onDelete(key);
        this.setState({ loading: false });
    }

    private updateProperty = (prop: keyof T, value: any, valid: boolean, state?: ValuesAndValid<T>) => this.setState(current => {
        const values = current.values as any;
        const currentValid = current.valid as any;
        const newValues = (state && state.values) || {};
        const newValid = (state && state.valid) || {};

        return {
            ...current,
            values: {
                ...values,
                ...newValues,
                [prop]: value
            },
            valid: {
                ...currentValid,
                ...newValid,
                [prop]: valid
            }
        };
    })

    private formInvalid = () => {
        return Object.keys(this.state.valid).some(k => !this.state.valid[k]);
    };
}
