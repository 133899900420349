/* eslint-disable react/jsx-no-bind */
import React from "react";
import { State as RouterState } from "redux-little-router";
import { RegionState } from "@momenta/common/internationalisation";
import { Country } from "@momenta/common/model";

import { connect, useDispatch } from "react-redux";
import { Button, Container, Divider, Grid, Modal } from "semantic-ui-react";
import { replace } from "redux-little-router";

interface RightToWorkUsaStateProps {
    region: Country;
    jobLink?: string;
    jobName?: string;
}

export const RightToWorkUsaUnconnected: React.FC<RightToWorkUsaStateProps> = ({ region, jobLink, jobName }) => {
    const dispatch = useDispatch();

    const [authorizedToWorkInUsa, setAuthorizedToWorkInUsa] = React.useState<boolean>(undefined);
    const [requireSponorship, setRequireSponsorship] = React.useState<boolean>(undefined);
    const [closeConfirmModalOpen, setCloseConfirmModalOpen] = React.useState(false);

    React.useEffect(() => {
        if (region !== Country.Usa) {
            dispatch(replace("/"));
        }
    }, [dispatch, region]);

    const onProceedClick = () => {
        window.location.href = jobLink ? `/apply/${jobLink}?name=${jobName}` : "/account/signup";
    };

    const onCloseRightToWork = async () => {
        window.location.href = "https://momentagroup.com";
    };

    const onSetNotAuthorizedToWorkInUsaClicked = () => {
        setRequireSponsorship(undefined);
        if (authorizedToWorkInUsa === false) {
            setAuthorizedToWorkInUsa(undefined);
            return;
        }
        setAuthorizedToWorkInUsa(false);
    };

    const onSetAuthorizedToWorkInUsaClicked = () => {
        setRequireSponsorship(undefined);
        if (authorizedToWorkInUsa === true) {
            setAuthorizedToWorkInUsa(undefined);
            return;
        }
        setAuthorizedToWorkInUsa(true);
    };

    const onSetDoNotRequireSponsorshipClicked = () => {
        if (requireSponorship === false) {
            setRequireSponsorship(undefined);
            return;
        }
        setRequireSponsorship(false);
    };

    const onSetDoRequireSponsorshipClicked = () => {
        if (requireSponorship === true) {
            setRequireSponsorship(undefined);
            return;
        }
        setRequireSponsorship(true);
    };

    const unqualifiedMessage = (
        <h4>
            <strong>
                To be registered successfully on this platform you are required to be legally eligible
                to work in the United States. For other non-US opportunities
                please press Close to see our latest global vacancies/opportunities.
            </strong>
        </h4>
    );

    const showUnqualifiedMessage = authorizedToWorkInUsa === false || requireSponorship === true;

    const canProceed = authorizedToWorkInUsa === true && requireSponorship === false;

    return (
        <>
            <Container text>
                <Modal
                    open={closeConfirmModalOpen}
                    centered={false}
                    size="tiny"
                >
                    <Modal.Header>
                        Stop Registration
                    </Modal.Header>
                    <Modal.Content>
                        By closing this page, you are no longer registering with Momenta. Do you wish to continue?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative content="No" onClick={() => setCloseConfirmModalOpen(false)} />
                        <Button positive content="Yes" onClick={onCloseRightToWork} />
                    </Modal.Actions>
                </Modal>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h1>
                                Right to work in the United States
                            </h1>
                            <h5>
                                This platform is located in the United States, for successful registrations to be processed on the platform,
                                please can you complete the following questions.
                            </h5>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} verticalAlign="middle">
                            <label>Are you legally authorized to work in the United States?</label>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Button.Group size="tiny" floated="right">
                                <Button
                                    toggle
                                    color="grey"
                                    negative={authorizedToWorkInUsa === false}
                                    onClick={onSetNotAuthorizedToWorkInUsaClicked}
                                >
                                        No
                                </Button>
                                <Button.Or />
                                <Button
                                    toggle
                                    color="grey"
                                    positive={authorizedToWorkInUsa === true}
                                    onClick={onSetAuthorizedToWorkInUsaClicked}
                                >
                                        Yes
                                </Button>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                    { authorizedToWorkInUsa &&
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <label>Do you need, or will you ever need sponorship to work in the United States?</label>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button.Group size="tiny" floated="right">
                                    <Button
                                        toggle
                                        color="grey"
                                        positive={requireSponorship === false}
                                        onClick={onSetDoNotRequireSponsorshipClicked}
                                    >
                                            No
                                    </Button>
                                    <Button.Or />
                                    <Button
                                        toggle
                                        color="grey"
                                        negative={requireSponorship === true}
                                        onClick={onSetDoRequireSponsorshipClicked}
                                    >
                                            Yes
                                    </Button>
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    }
                    { showUnqualifiedMessage &&
                        <Grid.Row>
                            <Grid.Column>
                                <Divider />
                                { unqualifiedMessage }
                            </Grid.Column>
                        </Grid.Row>
                    }
                    <Grid.Row>
                        <Grid.Column>
                            { canProceed &&
                                <Button
                                    floated="right"
                                    color="green"
                                    onClick={onProceedClick}
                                >
                                    Proceed
                                </Button>
                            }
                            <Button
                                floated="right"
                                color="red"
                                onClick={() => setCloseConfirmModalOpen(true)}
                            >
                                Close
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    );
};

const mapStateToProps = (state: RegionState & RouterState): RightToWorkUsaStateProps => {
    return {
        region: state.region,
        jobLink: state.router.query.jobLink,
        jobName: state.router.query.jobName,
    };
};

export const RightToWorkUsa = connect(mapStateToProps)(RightToWorkUsaUnconnected);
