import { reducer as invoicePeriods } from "@momenta/common/invoicePeriods";
import { reducer as pagination } from "@momenta/common/pagination";
import { reducer as umbrellaCompanies } from "@momenta/common/umbrellaCompany";
import { reducer as references } from "@momenta/common/referencing";
import { globalConfigs } from "@momenta/common/globalConfig/reducer";
import { region } from "@momenta/common/internationalisation/reducer";

import { reducer as associateQualifications } from "..//qualifications";

import { currentUser } from "../currentUser/reducer";
import { timesheets } from "../timesheets/reducer";
import { createReducer } from "../hierarchicalConfiguration";
import { connectionReducer as connection } from "../connectionStatus";
import { proofDocumentsReducer as proofDocuments } from "../vetting";
import { momentaDocumentsReducer as momentaDocuments } from "../vetting";
import { reducer as companies } from "../company";
import { vatRatesReducer as vatRates } from "../vat";

const timeTypeConfiguration = createReducer("timeTypeConfiguration");
const expenseTypeConfiguration = createReducer("expenseTypeConfiguration");
const bonusTypeConfiguration = createReducer("bonusTypeConfiguration");

export const reducers = {
    currentUser,
    timesheets,
    connection,
    timeTypeConfiguration,
    expenseTypeConfiguration,
    bonusTypeConfiguration,
    invoicePeriods,
    pagination,
    proofDocuments,
    momentaDocuments,
    umbrellaCompanies,
    companies,
    vatRates,
    references,
    region,
    globalConfigs,
    associateQualifications
};
