import * as actions from "../vetting/actionTypes";

import { VettingAction } from "./actions";
import { MomentaDocumentVettingData, ProofDocumentVettingData } from "./model";

export function proofDocuments(state: ProofDocumentVettingData[] = [], action: VettingAction): ProofDocumentVettingData[] {
    switch (action.type) {
        case actions.LOAD_PROOF_DOCUMENTS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function momentaDocuments(state: MomentaDocumentVettingData[] = [], action: VettingAction): MomentaDocumentVettingData[] {
    switch (action.type) {
        case actions.LOAD_MOMENTA_DOCUMENTS_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
