import { DropdownItemProps } from "semantic-ui-react";

export const associateTitleOptions: DropdownItemProps[] = [
    { value: null, text: "" },
    { value: "Mr", text: "Mr" },
    { value: "Mrs", text: "Mrs" },
    { value: "Miss", text: "Miss" },
    { value: "Ms", text: "Ms" },
    { value: "Dr", text: "Dr" }
];
