import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";
import { Timesheet } from "@momenta/common/timesheets";
import { AuthState } from "reauthorize";
import { toast } from "@momenta/common/toasts";

import { loadTimesheetsConfiguration } from "../hierarchicalConfiguration/actions";

import * as actionTypes from "./actionTypes";
import { timesheetApi } from "./TimesheetApi";

export type TimesheetAction =
    ({ type: actionTypes.LOAD_TIMESHEETS_SUCCESS } & Payload<Timesheet[]>)
  | ({ type: actionTypes.LOAD_TIMESHEET_SUCCESS } & Payload<Timesheet>);

export function loadTimesheetSuccess(payload: Timesheet): TimesheetAction {
    return {
        type: actionTypes.LOAD_TIMESHEET_SUCCESS,
        payload
    };
}

export function loadTimesheetsSuccess(payload: Timesheet[]): TimesheetAction {
    return {
        type: actionTypes.LOAD_TIMESHEETS_SUCCESS,
        payload
    };
}

export function loadTimesheets(top: number = 20) {
    return () => {
        return async (dispatch: any) => {
            const timesheets = await loadAndTrack(dispatch, "loadTimesheets", timesheetApi.getAll(top));
            dispatch(loadTimesheetsSuccess(timesheets.items));
        };
    };
}

export function loadTopTwoTimesheets() {
    return () => {
        return async (dispatch: any, getState: () => AuthState) => {
            if (!getState().currentUser.authenticated) {
                return;
            }

            const timesheets = await loadAndTrack(dispatch, "loadTimesheets", timesheetApi.getAll(2));
            dispatch(loadTimesheetsSuccess(timesheets.items));

            dispatch(loadTimesheetsConfiguration("timeTypeConfiguration", timesheets.items));
            dispatch(loadTimesheetsConfiguration("expenseTypeConfiguration", timesheets.items));
            dispatch(loadTimesheetsConfiguration("bonusTypeConfiguration", timesheets.items));
            timesheets.items.forEach(timesheet => dispatch(loadTimesheet({ timesheetId: timesheet.id })));
        };
    };
}

export function loadTimesheet({ timesheetId }: { timesheetId: number }) {
    return async (dispatch: any) => {
        const timesheet = await loadAndTrack(dispatch, "loadTimesheet", timesheetApi.get(timesheetId));
        dispatch(loadTimesheetSuccess(timesheet));
    };
}

export function createTimesheet(model: Timesheet) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "createTimesheet", timesheetApi.create(model));
        dispatch(loadTimesheets());
    };
}

export function submitTimesheet(model: Timesheet) {
    return async (dispatch: any) => {
        const timesheet = await loadAndTrack(dispatch, "submitTimesheet", timesheetApi.save(model));
        dispatch(loadTimesheetSuccess(timesheet));
    };
}

export function saveTimesheet(model: Timesheet) {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "saveTimesheet", timesheetApi.save(model));
        dispatch(loadTimesheetSuccess(result));
        if (result.timesheetSaveSuccessful === false) {
            toast.warning("You may no longer alter this timesheet, timesheet entry has been denied.");
        }
    };
}
