import * as React from "react";
import { Card, Grid } from "semantic-ui-react";
import { isNotNullOrUndefined } from "@momenta/common";

import { Company } from "../company";

import { CompanyCard } from "./companies/CompanyCard";
import { CurrentUser } from "./model";

interface AssociateCompaniesProps {
    companies: Company[];
    currentUser: CurrentUser;
    save: (company: Company) => Promise<void>;
}

export class AssociateCompanies extends React.Component<AssociateCompaniesProps> {
    public render() {

        const companies = this.props.companies;
        const selectedCompany = this.props.currentUser.selectedCompany;

        const selectedCompanyId = isNotNullOrUndefined(selectedCompany)
            ? selectedCompany.id
            : null;

        const filteredSelectedCompany = (selectedCompanyId && companies.filter(c => c.id === selectedCompanyId)[0]) || selectedCompany;
        const filteredCompanies = companies.filter(c => c.id !== selectedCompanyId && c.showOnAssociate);

        return (
            <>
                <Grid container>
                    <Grid.Row>
                        <Grid.Column width={16} >
                            <Card.Group itemsPerRow={4} doubling stackable>
                                {filteredSelectedCompany &&
                                <>
                                    <CompanyCard
                                        key={selectedCompanyId}
                                        company={filteredSelectedCompany}
                                        isSelectedCompany={true}
                                        saveCompany={this.props.save}
                                        currentUser={this.props.currentUser}
                                    />
                                </>
                                }

                                {filteredCompanies && filteredCompanies.map(company => (
                                    <CompanyCard
                                        key={company.id}
                                        company={company}
                                        isSelectedCompany={false}
                                        saveCompany={this.props.save}
                                        currentUser={this.props.currentUser}
                                    />
                                ))}
                            </Card.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
}
