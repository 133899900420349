import * as React from "react";
import { Input } from "@neworbit/simpleui-input";
import { DropdownItemProps } from "semantic-ui-react";

import { filterNationalities, nationalities } from "./nationalities";

interface NationalityDropdownProps {
    showErrors: boolean;
    nationality: string;
    onChange: (value: string, valid: boolean) => void;
}

interface NationalityDropdownState {
    search: string;
}

export class NationalityDropdown extends React.Component<NationalityDropdownProps, NationalityDropdownState> {

    public state: NationalityDropdownState = {
        search: undefined
    };

    public render() {
        const { nationality, showErrors, onChange } = this.props;

        return (
            <Input.Dropdown
                value={nationality}
                label="Nationality"
                showErrors={showErrors}
                options={nationalities}
                search={this.onNationalitySearch}
                onChange={onChange}
                dynamicOptions
            />
        );
    }

    private onNationalitySearch = (search: string): Promise<DropdownItemProps[]> => {
        this.setState({
            search
        });

        const filteredNationalities = filterNationalities(search);

        return Promise.resolve(filteredNationalities);
    }
}
