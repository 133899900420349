import * as React from "react";
import { Moment } from "moment";
import { Grid } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";

import { componentVisible } from "@momenta/common/internationalisation";

interface DateOfBirthEditProps {
    values: { dateOfBirth?: Moment };
    showErrors: boolean;
    onChange: (value: Moment, valid: boolean) => void;
}

const DateOfBirthEditAlwaysVisible: React.FC<DateOfBirthEditProps> = ({ values, showErrors, onChange }) => (
    <Grid.Row>
        <Grid.Column>
            <Input.Date
                value={values.dateOfBirth}
                label="Date of Birth"
                required
                showErrors={showErrors}
                onChange={onChange}
            />
        </Grid.Column>
    </Grid.Row>
);

export const DateOfBirthEdit = componentVisible("dateOfBirthEnabled")(DateOfBirthEditAlwaysVisible);
