import thunk from "redux-thunk";
import { Middleware } from "redux";
import { Location, LOCATION_CHANGED } from "redux-little-router";
import { AuthPayload, AuthState, configureAuthMiddleware } from "reauthorize";
import { configureDispatchActions } from "@neworbit/redux-helpers";
import { toastMiddleware } from "@momenta/common/toasts";
import { appInsightsMiddleware } from "@momenta/common/logging/appInsightsMiddleware";
import { createEmptyState } from "@momenta/common/utils/createEmptyState";

import { unauthenticated } from "../currentUser/actions";

const dispatchActions = configureDispatchActions(
    LOCATION_CHANGED,
    action => (action.payload || {}).result,
    state => state.router.params,
    (parent, state) => {
        return state.router.result.resolveParent !== false
            && (state.router.previous === undefined || (state.router.previous.pathname !== parent.route)
            );
    }
);

const authMiddleware = configureAuthMiddleware<AuthState, { payload: Location }>({
    actionType: LOCATION_CHANGED,
    getAuthPayload: action => ((action.payload || createEmptyState<Location>()).result || {}) as AuthPayload,
    getUser: state => state.currentUser,
    unauthorizedAction: unauthenticated(),
    handleUnauthenticatedApiErrors: true
});

export const middlewares: Middleware[] = [
    appInsightsMiddleware,
    toastMiddleware,
    authMiddleware,
    dispatchActions as Middleware,
    thunk
];
