import { createSelector } from "reselect";
import { Company } from "@momenta/common/companies";

import { currentUserSelector } from "../currentUser/selector";
import { CurrentUser } from "../currentUser/model";
import { AppState } from "../model";

export const companiesSelector = (state: AppState) => state.companies;

export const associateCompaniesSelector = createSelector(
    companiesSelector,
    currentUserSelector,
    (companies: Company[], currentUser: CurrentUser) => companies.filter(c => c.associateId === currentUser.id)
);
