import moment from "moment";
import axios from "axios";

import { CompanyProfile } from "./models";

class CompanyHouseApi {

    private readonly apiUrl = "/api/companyhouse";

    public async getProfile(companyNumber: string): Promise<CompanyProfile> {
        const response = await axios.get(`${this.apiUrl}/${companyNumber}`);
        const companyProfile = response.data as CompanyProfile;
        return this.parseModel(companyProfile);
    }

    private parseModel(model: CompanyProfile) {
        return {
            ...model,
            dateOfCreation: moment(model.dateOfCreation)
        };
    }
}

export default new CompanyHouseApi();
