import { AssociateQualification } from "@momenta/common/qualifications/model";
import { Payload } from "@neworbit/redux-helpers";
import { loadAndTrack } from "redux-request-loading";

import * as actionTypes from "./actionTypes";
import { associateQualificationsApi } from "./associateQualificationsApi";

export type QualificationActions =
({ type: actionTypes.LOAD_QUALIFICATIONS_SUCCESS } & Payload<AssociateQualification[]>
| ({ type: actionTypes.SAVE_QUALIFICATION_SUCCESS } & Payload<AssociateQualification>)
| ({ type: actionTypes.DELETE_QUALIFICATION_SUCCESS } & Payload<number>));

export function loadQualificationsSuccess(payload: AssociateQualification[]) {
    return {
        type: actionTypes.LOAD_QUALIFICATIONS_SUCCESS,
        payload
    };
}

export function saveQualificationSuccess(payload: AssociateQualification) {
    return {
        type: actionTypes.SAVE_QUALIFICATION_SUCCESS,
        payload
    };
}

export function deleteQualificationSuccess(payload: number) {
    return {
        type: actionTypes.DELETE_QUALIFICATION_SUCCESS,
        payload
    };
}

export function loadAllAssociateQualifications() {
    return async (dispatch: any) => {
        const result = await loadAndTrack(dispatch, "loadAssociateQualifications", associateQualificationsApi.loadAllQualificationsForAssociate());
        dispatch(loadQualificationsSuccess(result));
    };
}

export function createAssociateQualification(newQualification: AssociateQualification) {
    return async (dispatch: any): Promise<void> => {
        const result = await loadAndTrack(dispatch, "loadAssociateQualifications", associateQualificationsApi.createQualification(newQualification));
        dispatch(saveQualificationSuccess(result));
    };
}

export function deleteAssociateQualification(id: number) {
    return async (dispatch: any): Promise<void> => {
        await loadAndTrack(dispatch, "loadAssociateQualifications", associateQualificationsApi.deleteQualification(id));
        dispatch(deleteQualificationSuccess(id));
    };
}

export function updateAssociateQualification(qualification: AssociateQualification) {
    return async (dispatch: any): Promise<void> => {
        const result =
        await loadAndTrack(dispatch, "loadAssociateQualifications", associateQualificationsApi.updateQualification(qualification.id , qualification));
        dispatch(saveQualificationSuccess(result));
    };
}
