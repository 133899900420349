/* eslint-disable max-lines */
import { toast } from "@momenta/common/toasts";
import * as React from "react";
import { Button, Form, Grid, Header, Icon, Message, Segment } from "semantic-ui-react";
import { FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { debounce } from "@neworbit/simpleui-utils";
import { Result, ValidationFunction, ValidationResult, validators } from "not-valid";
import { FileUpload, getDateInputFormat, isNotNullOrUndefined, isNullOrUndefined, Modal, truncateGuid } from "@momenta/common";
import { Company } from "@momenta/common/companies";
import { asyncResolve, AsyncResolveProps } from "@momenta/common/asyncResolve";
import { FinanceDocument, FinanceDocumentTypeEnum } from "@momenta/common/financeDocuments";
import { ValidVat } from "@momenta/common/vatDetails";

import { currentUserApi } from "../CurrentUserApi";
import { validateString } from "../../dashboard/index";
import { CompanyHouseApi, CompanyProfile } from "../../companyHouse";
import { CompanyApi } from "../../company";
import { CompanyHouseErrorModel } from "../../companyHouse/models";

export interface AsyncProps extends AsyncResolveProps {
    financeDocuments: FinanceDocument[];
}

export interface AddCompanyProps {
    company?: Company;
    save: (company: Company) => Promise<void>;
    open: boolean;
    onCloseModal: () => void;
    companyOnContract: boolean;
}

interface AddCompanyModel extends Company {
    confirmVatNameMismatch?: boolean;
}

interface AddCompanyState extends FormState<AddCompanyModel> {
    loading: boolean;
    isCompanyStatusActive: boolean;
    showCompanyStatusError: boolean;
    isCompanyChanged: boolean;
    companyDetailAvailable: boolean;
    companyHouseServiceDown: boolean;
    vatServiceDown: boolean;
    changeVatState: boolean;
}

export class AddCompanyModalUnconnected extends FormBaseComponent<AddCompanyModel, AddCompanyProps & AsyncProps, AddCompanyState> {

    private companyNumberChangedDebounced: (value: string, valid: boolean) => Promise<void>;
    private vatNumberValidationDebounced: (value: string) => ValidationResult;

    private onManagingDirectorChanged = this.getUpdate("managingDirector");
    private onManagingDirectorEmailChanged = this.getUpdate("managingDirectorEmail");
    private onSortCodeChanged = this.getUpdate("sortCode");
    private onAccountNumberChanged = this.getUpdate("accountNumber");
    private onConfirmVatNameMismatchChanged = this.getUpdate("confirmVatNameMismatch");
    private onVatNumberChanged = this.getNestedUpdate("vatDetails.vatNumber");
    private onDateRegisteredChanged = this.getNestedUpdate("vatDetails.dateRegistered");

    constructor(props: AddCompanyProps & AsyncProps & SaveDispatchProps<Company>) {
        super(props);

        this.state = this.initialState(props.company);
        this.companyNumberChangedDebounced = debounce(this.companyNumberChanged as any, 300).bind(this);
        this.vatNumberValidationDebounced = debounce(this.vatNumberValidation, 500).bind(this);
        this.confirmCompanyNameValidation = this.confirmCompanyNameValidation.bind(this);
    }

    public render() {
        const { company, open, companyOnContract } = this.props;
        const { values, showErrors, loading, isCompanyChanged, isCompanyStatusActive } = this.state;

        const showVatMismatch = this.state.values.vatDetails.vatNumber && this.state.values.vatDetails.vatCompanyNameVerified === false;
        const confirmCompanyNameMismatchMessage =
            `The vat number you have entered has a different company name to this company,
            please confirm that the vat number is correct.`;

        return (
            <Modal open={open} className="add-company-modal">
                <Modal.Header>{isNullOrUndefined(company) ? "Add New" : "Change"} Company</Modal.Header>

                <Modal.Content>
                    {this.state.vatServiceDown &&
                        <Message warning>
                            Please Note: The VAT checking system is currently offline.
                        </Message>
                    }
                    {this.state.companyHouseServiceDown &&
                        <Message warning>
                            Please Note: The Companies House checking system is currently offline, please try again later
                        </Message>
                    }
                    {companyOnContract &&
                        <Message info>
                            It may take up to 21 days before the bank account change will take effect.
                            <br />
                            Contact Momenta directly if you need the change to take effect sooner.
                        </Message>
                    }
                    {this.state.showCompanyStatusError && !companyOnContract &&
                        <Message negative>
                            Can not save company when its status is inactive
                        </Message>
                    }
                    <Form noValidate onSubmit={this.handleSubmit}>
                        {isNullOrUndefined(company) &&
                            <Message content={"Please enter your Company Number as per your Certificate of Incorporation"} warning visible />
                        }

                        <Input.Text
                            value={values.registrationNumber}
                            label="Company Number"
                            onChange={this.companyNumberChangedDebounced}
                            showErrors={this.state.showErrors}
                            disabled={companyOnContract}
                            validation={[validators.validLength({ min: 8, max: 8 })]}
                            required
                        />

                        {this.state.companyDetailAvailable &&
                            <Grid>
                                <Grid.Row columns={16}>
                                    {this.dataRow("Company Name", this.state.values.name)}
                                </Grid.Row>
                                <Grid.Row columns={16}>
                                    {this.dataRow("Company Registration Date",
                                        this.state.values.registrationDate && this.state.values.registrationDate.format("ll"))}
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Header as="h3" dividing>Company Address</Header>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {[
                                            this.state.values.address.line1,
                                            this.state.values.address.line2,
                                            this.state.values.address.city,
                                            this.state.values.address.county,
                                            this.state.values.address.postcode
                                        ].filter(s => isNotNullOrUndefined(s) && s.length > 0)
                                            .map((s, index) => (<React.Fragment key={index}> {s} <br /> </React.Fragment>))}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }

                        <Input.Text
                            value={values.managingDirector}
                            label="Managing Director"
                            onChange={this.onManagingDirectorChanged}
                            showErrors={showErrors}
                            required
                        />

                        <Input.Email
                            value={values.managingDirectorEmail}
                            label="Managing Director Email"
                            onChange={this.onManagingDirectorEmailChanged}
                            validation={[validators.validEmail()]}
                            showErrors={showErrors}
                            required
                        />

                        <Header as="h3" dividing>Company Finance</Header>
                        <Input.PhoneNumber
                            value={values.accountNumber}
                            showErrors={showErrors}
                            label="Business Bank Account Number"
                            onChange={this.onAccountNumberChanged}
                            validation={[this.stopIfEmpty, validators.validBankAccountNumber()]}
                            required
                        />

                        <Input.PhoneNumber
                            value={values.sortCode}
                            showErrors={showErrors}
                            label="Business Bank Sort Code"
                            onChange={this.onSortCodeChanged}
                            validation={[validators.requiredString(), validators.validSortCode("Please enter 6 digit sort code as numbers only")]}
                        />

                        <Message
                            content={
                                `It is your own Ltd company’s responsibility
                                to ensure that your VAT status is correct at all times
                                during your contract with Momenta.`
                            }
                            info
                            visible
                        />

                        <Input.Checkbox
                            value={this.state.changeVatState}
                            onChange={this.updateAndValidateVatNumber}
                            label={this.renderVatLabel()}
                            toggle
                        />

                        {this.isVatRegistered() &&
                            <>
                                <Input.Text
                                    value={values.vatDetails.vatNumber}
                                    showErrors={showErrors}
                                    label="VAT Number"
                                    onChange={this.onVatNumberChanged}
                                    validation={[this.stopIfEmpty, validators.validVATNumber(), this.vatNumberValidationDebounced]}
                                    required
                                />
                                <Input.Date
                                    value={values.vatDetails.dateRegistered}
                                    label="Date Registered"
                                    onChange={this.onDateRegisteredChanged}
                                    format={getDateInputFormat()}
                                    showErrors={this.state.showErrors}
                                    required
                                />

                                {showVatMismatch &&
                                    <>
                                        <Message warning visible content={confirmCompanyNameMismatchMessage} />
                                        <Input.Checkbox
                                            value={values.confirmVatNameMismatch}
                                            label="Confirm VAT number"
                                            onChange={this.onConfirmVatNameMismatchChanged}
                                            showErrors={this.state.showErrors}
                                            validation={[this.confirmCompanyNameValidation]}
                                        />
                                    </>
                                }
                            </>
                        }

                        <Header as="h3" dividing>Documents</Header>
                        {isNullOrUndefined(company) && <Message content={this.documentMessage()} visible warning />}

                        <Segment className="no-padding" basic>
                            <strong>Company Registration Document</strong>

                            {!validateString(values.registrationDocument) &&
                                <>
                                    <div>
                                        <p>Please provide a copy of LTD company registration document</p>
                                    </div>

                                </>
                            }

                            {this.showUpload(FinanceDocumentTypeEnum["Company Registration Document"])
                                ? <FileUpload
                                    apiUrl="/api/document"
                                    placeholder="Company Registration Document"
                                    value={values.registrationDocument}
                                    filename={truncateGuid(values.registrationDocument)}
                                    onFileChange={this.handleDocumentChange("registrationDocument")}
                                    showErrors={showErrors}
                                    required
                                />
                                : <Icon name="check circle" color="green" size="large" className="float-right" />
                            }

                        </Segment>
                        <Segment className="no-padding" basic>
                            <strong>PIPL Insurance Document</strong>

                            {!validateString(values.piplInsuranceDocument) &&
                                <>
                                    <div>
                                        <p>Please provide a copy of PIPL Insurance document</p>
                                    </div>
                                </>
                            }

                            {this.showUpload(FinanceDocumentTypeEnum["Pipl Insurance Document"])
                                ? <FileUpload
                                    apiUrl="/api/document"
                                    placeholder="Pipl Insurance Document"
                                    value={values.piplInsuranceDocument}
                                    filename={truncateGuid(values.piplInsuranceDocument)}
                                    onFileChange={this.handleDocumentChange("piplInsuranceDocument")}
                                    showErrors={showErrors}
                                    required
                                />
                                : <Icon name="check circle" color="green" size="large" className="float-right" />
                            }
                        </Segment>
                        {this.isVatRegistered() &&
                            <Segment className="no-padding" basic>
                                <strong>VAT Registration Document</strong>

                                {!validateString(values.vatRegistrationDocument) &&
                                    <>
                                        <div>
                                            <p>Please provide a copy of VAT registration document</p>
                                        </div>

                                    </>
                                }

                                {this.showUpload(FinanceDocumentTypeEnum["Vat Registration Document"])
                                    ? <FileUpload
                                        apiUrl="/api/document"
                                        placeholder="VAT Registration Document"
                                        value={values.vatRegistrationDocument}
                                        filename={truncateGuid(values.vatRegistrationDocument)}
                                        onFileChange={this.handleDocumentChange("vatRegistrationDocument")}
                                        showErrors={showErrors}
                                        required
                                    />
                                    : <Icon name="check circle" color="green" size="large" className="float-right" />
                                }
                            </Segment>
                        }

                        {this.isVatDeregistered() &&
                            <Segment className="no-padding" basic>
                                <strong>Vat Deregistration Document</strong>

                                {!validateString(values.vatDeregistrationDocument) &&
                                    <>
                                        <div>
                                            <p>Please provide a copy of VAT deregistration document</p>
                                        </div>

                                    </>
                                }

                                {this.showUpload(FinanceDocumentTypeEnum["Vat Deregistration Document"])
                                    ? <FileUpload
                                        apiUrl="api/document"
                                        placeholder="VAT Deregistration Document"
                                        value={values.vatDeregistrationDocument}
                                        filename={truncateGuid(values.vatDeregistrationDocument)}
                                        onFileChange={this.handleDocumentChange("vatDeregistrationDocument")}
                                        showErrors={showErrors}
                                        required
                                    />
                                    : <Icon name="check circle" color="green" size="large" className="float-right" />
                                }
                            </Segment>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon="cancel" basic content="Cancel" negative onClick={this.closeModal} />
                    <Button
                        icon="save"
                        content="Save"
                        positive
                        onClick={this.submit}
                        loading={loading}
                        disabled={isCompanyChanged && !isCompanyStatusActive || loading}
                    />

                </Modal.Actions>
            </Modal>
        );
    }

    public async vatNumberValidation(value: string) {
        const { values } = this.state;
        const { company } = this.props;

        const vatNumber = company && company.vatDetails && company.vatDetails.vatNumber;

        if (value === vatNumber) {
            return Result.Pass;
        }

        if (value === values.vatDetails.vatNumberResult) {

            if (this.state.values.vatDetails.vatNumberValid === false && this.state.vatServiceDown === false) {
                return Result.Fail("Invalid VAT number");
            }

            return Result.Stop;
        }
        let result: ValidVat;
        try {
            result = await currentUserApi.vatCompanyNameVerified(value, this.state.values.name);

            this.setState({
                vatServiceDown: false
            });
        } catch (error) {
            const serviceUnavailable = error.response && error.response.data as boolean;

            this.setState({
                vatServiceDown: serviceUnavailable
            });

            this.updateNestedProperty("vatDetails.vatVerified", false, true);
            this.updateNestedProperty("vatDetails.vatCompanyNameVerified", false, true);
            this.updateNestedProperty("vatDetails.vatNumberValid", false, true);
            this.updateNestedProperty("vatDetails.vatCompanyName", "", true);
            this.updateNestedProperty("vatDetails.vatNumberResult", value, true);
        }

        if (result) {
            this.updateNestedProperty("vatDetails.vatCompanyNameVerified", result.vatNameValid, true);
            this.updateNestedProperty("vatDetails.vatCompanyName", result.vatCompanyName, true);
            this.updateNestedProperty("vatDetails.vatNumberResult", value, true);
            this.updateNestedProperty("vatDetails.vatNumberValid", result.vatNumberValid, true);

            if (!result.vatNumberValid) {
                return Result.Fail("Invalid VAT number");
            }

            this.updateProperty("confirmVatNameMismatch", true, true);
        }

        return Result.Pass;
    }

    public confirmCompanyNameValidation(value: boolean) {
        if (!value) {
            return Result.Fail("You must confirm your VAT number");
        }

        return Result.Pass;
    }

    public onSaved = () => this.setState(this.initialState(this.props.company));

    private renderVatLabel = (): string => {
        return this.wasVatRegistered()
            ? "Has your company de-registered for VAT?"
            : "Has your company registered for VAT?";
    }

    private companyNumberChanged = async (value: string, valid: boolean) => {

        if (this.props.company && this.props.company.registrationNumber === value) {
            return;
        }

        this.updateProperty("registrationNumber", value, valid);

        if (valid) {
            try {
                const result = await CompanyHouseApi.getProfile(value);
                this.updateCompanyProfileState(result);

                this.setState({
                    companyHouseServiceDown: false
                });

            } catch (error) {
                const apiError = error.response && error.response.data as CompanyHouseErrorModel;

                this.setState({
                    companyHouseServiceDown: apiError.serviceUnavailable
                });

                if (!apiError.serviceUnavailable) {
                    toast.error("Company not found");
                }
                this.clearCompanyProfileState();
            }
        }

        this.setState({
            isCompanyChanged: true
        });
    }

    private closeModal = () => {
        this.setState(this.initialState(this.props.company));
        this.props.onCloseModal();
    }

    private updateCompanyProfileState = ({ companyName, dateOfCreation, registeredOfficeAddress, companyStatus }: CompanyProfile) => {
        this.setState(current => ({
            values: {
                ...current.values,
                name: companyName,
                registrationDate: dateOfCreation,
                address: {
                    ...this.state.values.address,
                    line1: registeredOfficeAddress.address_line_1,
                    line2: registeredOfficeAddress.address_line_2,
                    country: registeredOfficeAddress.country,
                    postcode: registeredOfficeAddress.postalCode,
                    city: registeredOfficeAddress.locality,
                    county: registeredOfficeAddress.region
                }
            },
            isCompanyStatusActive: companyStatus.toLowerCase() === "active",
            showCompanyStatusError: companyStatus.toLowerCase() !== "active",
            companyDetailAvailable: true,
        }));
    }

    private clearCompanyProfileState = () => {
        this.setState({
            values: {
                ...this.state.values,
                name: undefined,
                registrationDate: undefined,
                address: {
                    ...this.state.values.address,
                    line1: undefined,
                    line2: undefined,
                    country: undefined,
                    postcode: undefined,
                    county: undefined,
                    city: undefined
                }
            },
            isCompanyStatusActive: false,
            isCompanyChanged: false,
            companyDetailAvailable: false
        });
    }

    private dataRow = (type: string, value: string) => {
        return (
            <>
                <Grid.Column width={8}>
                    <strong>{type}</strong>
                </Grid.Column>
                <Grid.Column width={8}>
                    {value}
                </Grid.Column>
            </>
        );
    }

    private submit = async () => {
        this.setState({ loading: true });
        await this.handleSubmit();
        this.setState({ loading: false });
    }

    private updateAndValidateVatNumber = (changeVatState: boolean, valid: boolean) => {

        if (this.state.changeVatState === changeVatState) {
            return;
        }

        const wasVatRegistered = this.wasVatRegistered();

        const vatRegistered = changeVatState
            ? !wasVatRegistered
            : wasVatRegistered;

        this.setState({ changeVatState });

        this.updateNestedProperty("vatDetails.vatRegistered", vatRegistered, valid);

        this.updateNestedProperty(
            "vatDetails.vatVerified",
            !this.props.companyOnContract,
            true);

        const vatDetails = this.props.company && this.props.company.vatDetails;
        const dateRegistered = vatDetails && vatDetails.dateRegistered;

        if (vatRegistered === false) {
            this.updateNestedProperty("vatDetails.vatDeregistered", true, true);
            this.updateNestedProperty("vatDetails.deregisteredDate", null, true);
            this.updateNestedProperty("vatDetails.vatCompanyNameVerified", false, true);
            this.updateProperty("vatRegistrationDocument", null, true);
            this.updateProperty("vatDeregistrationDocument", this.getDocumentFile(FinanceDocumentTypeEnum["Vat Deregistration Document"]) || null, false);
            this.updateProperty("confirmVatNameMismatch", true, true);
        }

        if (vatRegistered) {
            this.updateNestedProperty("vatDetails.vatDeregistered", false, true);
            this.updateNestedProperty("vatDetails.vatNumber", "", true);
            this.updateNestedProperty("vatDetails.dateRegistered", dateRegistered || null, true);
            this.updateNestedProperty("vatDetails.deregisteredDate", null, true);
            this.updateProperty("vatDeregistrationDocument", null, true);
            this.updateProperty("vatRegistrationDocument", this.getDocumentFile(FinanceDocumentTypeEnum["Vat Registration Document"]) || null, true);
            this.updateProperty("confirmVatNameMismatch", false, false);
        }
    }

    private showUpload = (documentType: FinanceDocumentTypeEnum) => {
        const file = this.getDocumentFile(documentType);
        return file === undefined;
    }

    private stopIfEmpty: ValidationFunction<string> = (value: string) => {
        return value.length === 0
            ? Result.Stop
            : Result.Pass;
    }

    private isVatRegistered = () => this.state.values && this.state.values.vatDetails && this.state.values.vatDetails.vatRegistered;
    private wasVatRegistered = () => this.props.company && this.props.company.vatDetails && this.props.company.vatDetails.vatRegistered;
    private isVatDeregistered = () => this.state.values && this.state.values.vatDetails && this.state.values.vatDetails.vatDeregistered
        || this.props.company && this.props.company.vatDetails && this.props.company.vatDetails.vatDeregistered

    private handleDocumentChange = (prop: keyof Company) => {
        return (value: string, valid: boolean) => {
            if (isNullOrUndefined(value) === false) {
                valid = true;
            }

            this.updateProperty(prop, value, valid);
        };
    }

    private getDocumentFile = (documentType: FinanceDocumentTypeEnum) => {
        const financeDocument = this.props.company && this.props.financeDocuments.filter(f => f.documentType === documentType)[0];
        return financeDocument && financeDocument.path;
    }

    private initialState(company?: Company): AddCompanyState {
        if (isNullOrUndefined(company)) {
            return {
                values: {
                    id: 0,
                    name: "",
                    address: {
                        line1: "",
                        line2: "",
                        city: "",
                        county: "",
                        postcode: "",
                        country: "",
                    },
                    registrationNumber: "",
                    sortCode: "",
                    accountNumber: "",
                    umbrella: false,
                    registrationDocument: undefined,
                    piplInsuranceDocument: undefined,
                    vatRegistrationDocument: undefined,
                    financeId: "",
                    vatDetails: {
                        vatRegistered: false,
                        vatNumber: "",
                        vatNumberValid: true,
                        dateRegistered: undefined,
                        vatVerified: true,
                        vatDeregistered: false
                    },
                    associateId: 0,
                    generateInvoice: true,
                    companyStatus: "active",
                    companyStatusDetail: ""
                },
                valid: {},
                loading: false,
                isCompanyStatusActive: false,
                showCompanyStatusError: false,
                isCompanyChanged: false,
                companyDetailAvailable: false,
                showErrors: false,
                companyHouseServiceDown: false,
                vatServiceDown: false,
                changeVatState: false
            };
        }

        return {
            values: {
                ...company,
                address: {
                    ...company.address
                },
                vatDetails: {
                    ...company.vatDetails,
                },
                registrationDocument: this.getDocumentFile(FinanceDocumentTypeEnum["Company Registration Document"]),
                piplInsuranceDocument: this.getDocumentFile(FinanceDocumentTypeEnum["Pipl Insurance Document"]),
                vatRegistrationDocument: this.getDocumentFile(FinanceDocumentTypeEnum["Vat Registration Document"]),
                vatDeregistrationDocument: this.getDocumentFile(FinanceDocumentTypeEnum["Vat Deregistration Document"]),
                confirmVatNameMismatch: this.wasVatRegistered()
            },
            valid: {},
            loading: false,
            isCompanyStatusActive: false,
            showCompanyStatusError: false,
            isCompanyChanged: false,
            companyDetailAvailable: true,
            companyHouseServiceDown: false,
            vatServiceDown: false,
            changeVatState: false
        };
    }

    private documentMessage = () => {
        return (
            <p>
                If you are working through a limited company, we require a copy of the company's certificate of incorporation
                as well as a copy of their full Professional Indemnity and Public Liability Insurance schedule. Further information
                regarding PIPL Insurance can be found
                <a href="http://www.momentagroup.com/pipl-insurance/" target="_blank" rel="noopener noreferrer" className="link">here</a>
            </p>
        );
    }
}

export const AddCompanyModal = asyncResolve(
    { financeDocuments: ({ company }: AddCompanyProps) => company && CompanyApi.getActiveFinanceDocuments(company.id) },
    { financeDocuments: [] }
)(AddCompanyModalUnconnected);
