export function getCurrentTimeZone() {
    const offsetInHours = new Date().getTimezoneOffset() / 60;

    switch (offsetInHours) {
        case 5:
            return "Eastern Standard Time";
        case 6:
            return "Central Standard Time";
        case 7:
            return "Mountain Standard Time";
        case 8:
            return "Pacific Standard Time";
        case -8:
            return "W. Australia Standard Time";
        case -10:
        case -11:
            return "E. Australia Standard Time";
        case -9.5:
        case -10.5:
            return "Cen. Australia Standard Time";
        default:
            return "GMT Standard Time";
    }
}
