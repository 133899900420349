export const LOAD_UMBRELLACOMPANIES_SUCCESS = "LOAD_UMBRELLACOMPANIES_SUCCESS";
export type LOAD_UMBRELLACOMPANIES_SUCCESS = typeof LOAD_UMBRELLACOMPANIES_SUCCESS;

export const CREATE_UMBRELLACOMPANY_SUCCESS = "CREATE_UMBRELLACOMPANY_SUCCESS";
export type CREATE_UMBRELLACOMPANY_SUCCESS = typeof CREATE_UMBRELLACOMPANY_SUCCESS;

export const SAVE_UMBRELLACOMPANY_SUCCESS = "SAVE_UMBRELLACOMPANY_SUCCESS";
export type SAVE_UMBRELLACOMPANY_SUCCESS = typeof SAVE_UMBRELLACOMPANY_SUCCESS;

export const DELETE_UMBRELLACOMPANY_SUCCESS = "DELETE_UMBRELLACOMPANY_SUCCESS";
export type DELETE_UMBRELLACOMPANY_SUCCESS = typeof DELETE_UMBRELLACOMPANY_SUCCESS;

export const LOAD_UMBRELLACOMPANY_DETAIL_SUCCESS = "LOAD_UMBRELLACOMPANY_DETAIL_SUCCESS";
export type LOAD_UMBRELLACOMPANY_DETAIL_SUCCESS = typeof LOAD_UMBRELLACOMPANY_DETAIL_SUCCESS;
