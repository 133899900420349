import * as React from "react";
import { FixedActionBar } from "@momenta/common/fixedActions/FixedActionBar";

export interface FixedActionsProps {
    className?: string;
}

export interface FixedActionsComponent extends React.FC<FixedActionsProps> {
    Bar: typeof FixedActionBar;
}

const fixedActions: React.FC<FixedActionsProps> = ({ className, children }) => (
    <div className={`fixed-actions ${className}`}>
        {children}
    </div>
);

const FixedActions: FixedActionsComponent = Object.assign(
    fixedActions,
    { Bar: FixedActionBar }
);

export {
    FixedActions
};
