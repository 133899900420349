import { createSelector } from "reselect";
import { AppState, UmbrellaCompany } from "@momenta/common/umbrellaCompany";
import { PaginationState } from "@momenta/common/pagination";
import { State as RouterState } from "redux-little-router";
import { createEmptyState } from "@momenta/common/utils/createEmptyState";

export const routeIdSelector = (state: RouterState) => {
    return +state.router.params.umbrellaCompanyId;
};

export type SelectorState = AppState & PaginationState;

export const umbrellaCompaniesSelector = (state: AppState) => state.umbrellaCompanies;

export const umbrellaCompanySelector = createSelector(
    umbrellaCompaniesSelector,
    routeIdSelector,
    (umbrellaCompanies: UmbrellaCompany[], id: number) => {
        return umbrellaCompanies.filter(c => c.id === id)[0] || createEmptyState<UmbrellaCompany>();
    }
);

export const basePathSelector = (state: AppState) =>
    state.router.pathname.substring(0, state.router.pathname.indexOf("umbrellaCompanies") + "umbrellaCompanies".length);
