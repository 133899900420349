import axios, { AxiosStatic } from "axios";
import { makeRequest, PageResult } from "@momenta/common";
import { InvoicePeriod, parseInvoicePeriod } from "@momenta/common/invoicePeriods";

export class InvoicePeriodApi {

    private readonly pageSize = 20;
    private readonly apiUrl = "/api/invoiceperiod";
    private axios: AxiosStatic;

    constructor(ax: AxiosStatic) {
        this.axios = ax;
    }

    public async getAll(pageNumber: number = 1): Promise<PageResult<InvoicePeriod>> {
        const skip = (pageNumber - 1) * this.pageSize;

        const options = {
            count: true,
            skip,
            top: this.pageSize,
            orderby: "Start desc"
        };

        const request = makeRequest(`${this.apiUrl}`, options);

        const response = await this.axios.get(request);
        const { items, count } = response.data as PageResult<InvoicePeriod>;

        return {
            items: items.map(d => parseInvoicePeriod(d)),
            count
        };
    }

    public async get(invoicePeriodId: number): Promise<InvoicePeriod> {
        const response = await this.axios.get(`/api/invoiceperiod/${invoicePeriodId}`);
        const invoicePeriod = response.data as InvoicePeriod;
        return parseInvoicePeriod(invoicePeriod);
    }

    public async getInvoiceToPrint(invoicePeriodId: number): Promise<InvoicePeriod> {
        const response = await this.axios.get(`/api/invoiceperiod/${invoicePeriodId}/print`);
        const invoicePeriod = response.data as InvoicePeriod;
        return parseInvoicePeriod(invoicePeriod);
    }
}

export default new InvoicePeriodApi(axios);
