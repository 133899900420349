import * as React from "react";
import { Card, Header, SemanticCOLORS } from "semantic-ui-react";
import { Link } from "redux-little-router";
import { connect } from "react-redux";
import { toast } from "@momenta/common/toasts";
import { CdnImg } from "@momenta/common/CdnImage";

import { ConnectionState } from "../connectionStatus/model";

type SvgType = "background-screening" | "academic-employment" | "finance" | "personal-information" | "preferences" | "vacancies";

interface DashboardCardProps {
    color?: SemanticCOLORS;
    href: string;
    svg: SvgType;
    title: string;
    children?: React.ReactNode;
}

interface DashboardCardStateProps {
    online: boolean;
}

export class DashboardCardUnconnected extends React.Component<DashboardCardProps & DashboardCardStateProps> {

    public render() {
        const { children, color, href, svg, online, title } = this.props;

        const className = online
            ? "dashboard-card"
            : "dashboard-card offline";

        const props = online
            ? { as: Link, href }
            : { onClick: this.offlineMessage };

        return (
            <Card color={color} className={className} {...props}>
                <Card.Content textAlign="center">
                    <CdnImg src={`/${svg}.svg`} />
                    <Header textAlign="center">{title}</Header>
                </Card.Content>

                {children}
            </Card>
        );
    }

    private offlineMessage() {
        toast.info("Sorry you cannot do that while you are offline");
    }
}

const mapStateToProps = (state: ConnectionState): DashboardCardStateProps => ({
    online: state.connection.online
});

export const DashboardCard = connect(mapStateToProps)(DashboardCardUnconnected);
