import * as React from "react";
import { Card, Icon, Message } from "semantic-ui-react";
import { connect } from "react-redux";

import { componentVisible } from "@momenta/common/internationalisation";

import { CurrentUser, CurrentUserState } from "../currentUser/model";

import { DashboardCard } from "./DashboardCard";

interface EmploymentHistoryCardProps {
    currentUser: CurrentUser;
}

export const EmploymentHistoryCardUnconnected: React.SFC<EmploymentHistoryCardProps> = ({ currentUser }) => {

    const vettingStarted = currentUser.candidacies.some(c => c.vettingStarted === true);
    const referencingNotStarted = vettingStarted === false && currentUser.referencingComplete === false;
    const referencingComplete = currentUser.referencingComplete === true;
    const referencingIncomplete = vettingStarted && currentUser.referencingComplete === false;

    const color = (referencingNotStarted || referencingComplete) ? "green" : "red";

    return (
        <DashboardCard
            color={color}
            svg="academic-employment"
            title="Academic/Employment History"
            href="/referencing"
        >
            <Card.Content textAlign="center">
                <Card.Meta>
                    {referencingIncomplete && (
                        <Message negative>Please review and submit</Message>
                    )}
                    {(referencingComplete) && (
                        <p>Submitted <Icon name="checkmark" color="green" /></p>
                    )}
                </Card.Meta>
            </Card.Content>
        </DashboardCard>
    );
};

const mapStateToProps = (state: CurrentUserState): EmploymentHistoryCardProps => ({
    currentUser: state.currentUser
});

export const EmploymentHistoryCard = componentVisible("showEmploymentHistoryTile")(connect(mapStateToProps)(EmploymentHistoryCardUnconnected));
