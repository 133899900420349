import { getDateInputFormat } from "@momenta/common";
import { emptyOrGreaterThanZeroValidator } from "@momenta/common/validators/emptyOrGreaterThanZeroValidator";
import { Input } from "@neworbit/simpleui-input";
import * as React from "react";
import { Grid } from "semantic-ui-react";

import { CurrentUser } from "./model";

interface EditAvailabilityProps {
    values: CurrentUser;
    onChange: (prop: string, value: string, valid: boolean) => void;
    showErrors: boolean;
}

export const EditAvailability: React.FC<EditAvailabilityProps> = ({ values, showErrors, onChange }) => {
    const { availableFrom, expectedRate } = values;

    const getUpdate = (field: string) => {
        return (value: any, valid: boolean) => onChange(field, value, valid);
    };

    return (
        <Grid.Row>
            <Grid.Column computer={5} mobile={16} tablet={8}>
                <Input.Date
                    value={availableFrom}
                    label="Available From"
                    showErrors={showErrors}
                    onChange={getUpdate("availableFrom")}
                    format={getDateInputFormat()}
                />
            </Grid.Column>
            <Grid.Column computer={5} mobile={16} tablet={8}>
                <Input.Number
                    value={expectedRate}
                    label="Expected Day Rate"
                    showErrors={showErrors}
                    onChange={getUpdate("expectedRate")}
                    validation={[emptyOrGreaterThanZeroValidator()]}
                />
            </Grid.Column>
        </Grid.Row>
    );
};
