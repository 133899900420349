import * as actions from "./actiontypes";
import { Reference } from "./model";
import { ReferencingAction } from "./actions";

export function references(state: Reference[] = [], action: ReferencingAction): Reference[] {
    switch (action.type) {
        case actions.LOAD_REFERENCES_SUCCESS:
            return action.payload;
        case actions.LOAD_REFERENCE_SUCCESS:
            return [...state.filter(r => r.id !== action.payload.id), action.payload];
        case actions.DELETE_REFERENCE_SUCCESS:
            return [...state.filter(r => r.id !== action.payload)];
        default:
            return state;
    }
}
