import * as React from "react";
import { Card } from "semantic-ui-react";
import { Company } from "src/App/Associate/src/company";

import { UmbrellaCompany } from "../umbrellaCompany";

import { CompanyCardDetail } from "./CompanyCardDetail";

interface CompanyCardProps {
    company: Company | UmbrellaCompany;
    isSelectedCompany: boolean;
    openModal: () => void;
    setSelectedCompany?: (companyId: number) => Promise<void>;
}

export const CompanyCard: React.FC<CompanyCardProps> = ({ company, isSelectedCompany, openModal, setSelectedCompany }) => (
    <Card link color={isSelectedCompany ? "green" : undefined}>
        <CompanyCardDetail
            isSelectedCompany={isSelectedCompany}
            company={company}
            setSelectedCompany={setSelectedCompany}
            openModal={openModal}
        />
    </Card>
);
