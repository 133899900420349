import * as React from "react";
import { useCallback } from "react";

import { connect } from "react-redux";
import { Divider, Header, Button } from "semantic-ui-react";
import { toast } from "@momenta/common/toasts";
import { LocalText } from "@momenta/common/internationalisation";

import { CurrentUserState, CurrentUser } from "../model";
import { MomentaDocumentVettingData, ProofDocumentVettingData, VettingDataState } from "../../vetting/model";
import {
    loadAllMomentaDocumentVettingData,
    loadAllProofDocumentVettingData,
    saveMomentaDocumentVettingData,
    saveProofDocumentVettingData,
    createFastTrackVettingItems
} from "../../vetting/actions";

import { DocumentList } from "./DocumentList";

interface EditDocumentsStateProps<> {
    currentUser: CurrentUser;
    proofDocuments: ProofDocumentVettingData[];
    momentaDocuments: MomentaDocumentVettingData[];
}

interface EditDocumentsDispatchProps {
    saveProofDocument: (proofDocument: ProofDocumentVettingData) => Promise<void>;
    saveMomentaDocument: (momentaDocument: MomentaDocumentVettingData) => Promise<void>;
    createVettingItems: () => Promise<void>;
    reloadDocuments: () => Promise<void>;
}

export const EditDocumentsUnconnected: React.FC<EditDocumentsStateProps & EditDocumentsDispatchProps> =
    ({ proofDocuments, momentaDocuments, currentUser, saveProofDocument, saveMomentaDocument, createVettingItems, reloadDocuments }) => {

        const [provideFastTrackInformation, setProvideFastTrackInformation] = React.useState<boolean>(currentUser.provideFastTrackInformation);
        const hasNoDocuments = proofDocuments.length === 0 && momentaDocuments.length === 0;

        const provideTrackVettingItems = useCallback(async () => {
            await setProvideFastTrackInformation(true);
            await createVettingItems();
            await reloadDocuments();
        }, [createVettingItems, reloadDocuments]);

        return (
            <>
                <Header as="h1" dividing><LocalText keyName="backgroundScreeningDocuments" /></Header>
                <p>
                    As part of your journey with Momenta, you will be required to complete and provide documentation to
                    allow us to carry out your background screening. Any offer of employment is subject to passing the
                    vetting checks, therefore it is crucial that this stage is completed as soon as possible.
                </p>
                {provideFastTrackInformation === false &&
                    <React.Fragment>
                        <p>
                            If you would like us to start your pre-employment screening now, please confirm below.
                            Doing so will give you a better chance of being onboarded in time should you be successful
                            in an application. Priority will be given to those further through the vetting process.
                        </p>
                        <p>
                            Would you like to provide pre-employment screening information now?  &nbsp;&nbsp;&nbsp;
                            <Button size={"mini"} onClick={provideTrackVettingItems}>Yes</Button>
                        </p>
                    </React.Fragment>
                }
                {proofDocuments.length > 0 &&
                    <div>
                        <h4><strong>Documents to provide</strong></h4>
                        <LocalText keyName="documentsToProvideText" />

                        <Divider />

                        <DocumentList vettingDatas={proofDocuments} controllerName="proofdocumentvettingdata" saveDocument={saveProofDocument} />

                        <Divider />
                    </div>
                }

                {momentaDocuments.length > 0 &&
                    <div>
                        <h4><strong>Documents to download, complete and re-upload</strong></h4>

                        <Divider />

                        <DocumentList vettingDatas={momentaDocuments} controllerName="momentadocumentvettingdata" saveDocument={saveMomentaDocument} />

                        <Divider />
                    </div>
                }

                {hasNoDocuments &&
                    <p>No documents have been added yet.</p>
                }
            </>
        );
    };

const mapStateToProps = (state: VettingDataState & CurrentUserState): EditDocumentsStateProps => ({
    currentUser: state.currentUser,
    proofDocuments: state.proofDocuments,
    momentaDocuments: state.momentaDocuments
});

const mapDispatchToProps = (dispatch: any) => ({
    saveProofDocument: async (proofDocument: ProofDocumentVettingData) => {
        await dispatch(saveProofDocumentVettingData(proofDocument.id, proofDocument));
        await dispatch(loadAllProofDocumentVettingData());
        toast.success("Document uploaded");
    },
    saveMomentaDocument: async (momentaDocument: MomentaDocumentVettingData) => {
        await dispatch(saveMomentaDocumentVettingData(momentaDocument.id, momentaDocument));
        await dispatch(loadAllMomentaDocumentVettingData());
        toast.success("Document uploaded");
    },
    createVettingItems: async () => {
        await dispatch(createFastTrackVettingItems());
    },
    reloadDocuments: async () => {
        await dispatch(loadAllMomentaDocumentVettingData());
        await dispatch(loadAllProofDocumentVettingData());
    }
});

export const EditDocuments = connect(mapStateToProps, mapDispatchToProps)(EditDocumentsUnconnected);
