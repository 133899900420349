import * as React from "react";
import { Icon, Label, Menu } from "semantic-ui-react";

interface OfflineStatusProps {
    online: boolean;
}

export const OfflineStatus: React.SFC<OfflineStatusProps> = ({ online }) => !online && (
    <Menu.Item>
        <Label className="offline-status">
            <Icon.Group disabled>
                <Icon name="signal" />
                <Icon corner name="close" />
            </Icon.Group>
            Offline
        </Label>
    </Menu.Item>
);
