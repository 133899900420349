/* eslint-disable max-lines */
import { dateSortDesc, FileUpload, getDateInputFormat, isNotNullOrUndefined, YesNoOption } from "@momenta/common";
import { FixedActions } from "@momenta/common/fixedActions";
import { connect } from "react-redux";
import { Button, Form, Grid, Icon, Message } from "semantic-ui-react";
import { EditProps, FormBaseComponent, FormState, SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { toast } from "@momenta/common/toasts";
import { Link, push } from "redux-little-router";
import { Action } from "redux";
import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { ValidationFunction } from "not-valid";
import { associateTitleOptions } from "@momenta/common/associate/associateTitleOptions";
import { localTextSelector } from "@momenta/common/internationalisation/selectors";
import { DetailColumn } from "@momenta/common/companies/DetailColumn";
import { addressProvided } from "@momenta/common/address/addressProvided";
import { PreviousIdentitiesEdit } from "@momenta/common/associate/PreviousIdentitiesEdit";
import { PreviousIdentity } from "src/App/People/src/associate/model";
import { ComponentVisible } from "@momenta/common/internationalisation";
import { NationalityDropdown } from "@momenta/common/NationalityDropDown";
import { Country } from "@momenta/common/model";

import { Cv, filenameRegex } from "../currentUser/model";
import { AppState } from "../model";

import { CurrentUser, CurrentUserState } from "./model";
import { saveCurrentUser } from "./actions";
import { GridRowHeader } from "./GridRowHeader";
import { TaxDetailsEdit } from "./TaxDetailsEdit";
import { EditRelocationPreferences } from "./EditRelocationPreferences";
import { AddressesEdit } from "./AddressesEdit";
import { DateOfBirthEdit } from "./DateOfBirthEdit";
import { BloodGroupEdit } from "./BloodGroupEdit";
import { EmergencyContactEdit } from "./EditEmergencyContact";
import { EditAvailability } from "./EditAvailability";
import { onContractSelector } from "./selector";

interface EditProfileState extends FormState<CurrentUser> {
    loading: boolean;
    cv: Cv;
}

interface EditProfileStateProps extends EditProps<CurrentUser> {
    requireVisaText: string;
    phoneNumberValidation: ValidationFunction<string>;
    addressText: string;
    onContract: boolean;
    isUsa: boolean;
}

export class EditProfileUnconnected extends FormBaseComponent<CurrentUser, EditProfileStateProps, EditProfileState> {

    constructor(props: EditProfileStateProps & SaveDispatchProps<CurrentUser>) {
        super(props);
        this.state = {
            values: this.copyProfile(props.model),
            valid: {
                address: addressProvided(props.model.address)
            },
            loading: false,
            cv: this.defaultCv(props.model),
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onVisaRequiredClick = this.onVisaRequiredClick.bind(this);
    }

    public UNSAFE_componentWillReceiveProps(props: Readonly<EditProfileStateProps & SaveDispatchProps<CurrentUser>>) {
        this.setState({ values: this.copyProfile(props.model) });
    }

    public render() {
        const { values, showErrors, loading } = this.state;
        const { phoneNumberValidation } = this.props;

        const cv = values.cvs && values.cvs
            .sort(dateSortDesc(c => c.created))
            .filter(c => !c.momentaFormatted)[0];

        const fullNameMessage = "Please ensure that you provide your full name as they appear in your official documents i.e. passport";

        return (
            <FixedActions>
                <Form onSubmit={this.onSubmit} noValidate>
                    <Grid>
                        <GridRowHeader text="Personal Information" as="h1" />

                        {this.renderMessageInformation(fullNameMessage)}

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Grid>
                                    <Grid.Column computer={2} mobile={16} tablet={8}>
                                        {this.renderTitle()}
                                    </Grid.Column>

                                    <Grid.Column computer={5} mobile={16} tablet={8}>
                                        <DetailColumn label="Forename" value={values.forename || ""} />
                                    </Grid.Column>

                                    <Grid.Column computer={4} mobile={16} tablet={8}>
                                        {this.renderMiddleName()}
                                    </Grid.Column>

                                    <Grid.Column computer={5} mobile={16} tablet={8}>
                                        <DetailColumn label="Surname" value={values.surname || ""} />
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Grid>
                                    <Grid.Column computer={5} mobile={16} tablet={8}>
                                        <Input.Text
                                            value={values.linkedInAddress}
                                            label="LinkedIn Address"
                                            onChange={this.getUpdate("linkedInAddress")}
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={5} mobile={16} tablet={8}>
                                        {this.props.onContract === true
                                            ? <DetailColumn label="Nationality" value={values.nationality} />
                                            : <NationalityDropdown
                                                nationality={values.nationality}
                                                onChange={this.getUpdate("nationality")}
                                                showErrors={showErrors}
                                            />
                                        }
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>

                        <PreviousIdentitiesEdit
                            hasPreviousIdentities={values.hasPreviousIdentities}
                            onHasPreviousIdentitiesChange={this.getUpdate("hasPreviousIdentities")}
                            previousIdentities={values.previousIdentities}
                            onChange={this.updatePreviousIdentities}
                        />

                        <GridRowHeader text={this.props.addressText} />

                        <AddressesEdit onChange={this.onNestedAddressPropChange} showErrors={showErrors} valid={this.state.valid} values={values} />

                        <DateOfBirthEdit onChange={this.getUpdate("dateOfBirth")} showErrors={showErrors} values={values} />

                        <BloodGroupEdit required onChange={this.getUpdate("bloodGroup")} showErrors={showErrors} values={values} />

                        <GridRowHeader text="Contact Details" />

                        <Grid.Row>
                            <Grid.Column computer={5} mobile={16} tablet={8}>
                                <Input.PhoneNumber
                                    value={values.mobileNumber}
                                    label="Mobile Number"
                                    required
                                    showErrors={showErrors}
                                    onChange={this.getUpdate("mobileNumber")}
                                    validation={[phoneNumberValidation]}
                                />
                            </Grid.Column>

                            <Grid.Column computer={5} mobile={16} tablet={8}>
                                <Input.PhoneNumber
                                    value={values.homeNumber}
                                    label="Home Number"
                                    showErrors={showErrors}
                                    onChange={this.getUpdate("homeNumber")}
                                />
                            </Grid.Column>

                            <Grid.Column computer={6} mobile={16} tablet={8}>
                                <Input.Email
                                    value={values.email}
                                    label="Email"
                                    readOnly
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <GridRowHeader text="Availability" />
                        <EditAvailability
                            onChange={this.onUpdateChange}
                            showErrors={showErrors}
                            values={values}
                        />

                        <GridRowHeader text="Right To Work" />

                        <Grid.Row>
                            <Grid.Column computer={5} mobile={16} tablet={8}>
                                <YesNoOption
                                    label={this.props.requireVisaText}
                                    propValue={values.visaRequired}
                                    onChange={this.onVisaRequiredClick}
                                />
                            </Grid.Column>

                            {values.visaRequired && <Grid.Column computer={5} mobile={16} tablet={8}>
                                <Input.Date
                                    value={values.visaExpiryDate}
                                    label="Visa Expiry Date"
                                    showErrors={showErrors}
                                    required
                                    onChange={this.getUpdate("visaExpiryDate")}
                                    format={getDateInputFormat()}
                                />
                            </Grid.Column>}
                        </Grid.Row>

                        <EditRelocationPreferences
                            values={values}
                            showErrors={showErrors}
                            onChange={this.getUpdate("willingToRelocate")}
                        />

                        <GridRowHeader text="CV/Resume" />

                        <Grid.Row>
                            <Grid.Column computer={5} mobile={16} tablet={8}>
                                {values.cvs && cv && <Link href={`/api/cv/${this.props.model.id}/${cv.id}`} target="_blank">
                                    <Icon name="file text" size="large" />
                                    {cv.blobName.match(filenameRegex)[1]}
                                </Link>}
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column computer={5} mobile={16} tablet={8}>
                                <FileUpload
                                    value={values.newCv}
                                    apiUrl={"/api/cv"}
                                    fileTypes={[".pdf", ".doc", ".docx"]}
                                    onFileChange={this.getUpdate("newCv")}
                                    showErrors={this.state.showErrors}
                                />
                            </Grid.Column>

                        </Grid.Row>

                        <ComponentVisible keyName="showTaxDetailsOnPersonalDetails">
                            <TaxDetailsEdit
                                model={this.props.model}
                                values={values}
                                onChange={this.onUpdateChange}
                                onNestedChange={this.onNestedPropChange}
                                onYesNoChange={this.onYesNoClick}
                                showErrors={showErrors}
                            />
                        </ComponentVisible>

                        <EmergencyContactEdit
                            values={values}
                            onChange={this.onNestedPropChange}
                            showErrors={showErrors}
                        />

                    </Grid>

                    <FixedActions.Bar>
                        <Grid.Column>
                            <Button type="submit" icon="save outline" color="green" content="Save" floated="right" loading={loading} disabled={loading} />
                        </Grid.Column>
                    </FixedActions.Bar>
                </Form>
            </FixedActions>
        );
    }

    private updatePreviousIdentities = (previousIdentities: PreviousIdentity[]) => {
        this.setState({
            values: {
                ...this.state.values,
                previousIdentities
            }
        });
    }

    private copyProfile(profile: CurrentUser): CurrentUser {
        return {
            ...profile,
            address: { ...profile.address },
            emergencyContact: { ...profile.emergencyContact },
            taxDetails: { ...profile.taxDetails }
        };
    }

    private async onSubmit() {
        this.setState(({ loading: true }));
        await this.handleSubmit({ preventDefault: (): void => undefined } as any);
        this.setState({ loading: false });
    }

    private defaultCv(model: CurrentUser): Cv {
        return {
            id: 0,
            blobName: undefined,
            momentaFormatted: undefined,
            created: undefined,
            associateId: model.id
        };
    }

    private onYesNoClick = (prop: string, valid: boolean) =>
        (value: boolean) => this.updateNestedProperty(prop, value, valid)

    private onVisaRequiredClick(value: boolean) {
        this.setState(prevState => {
            return { values: { ...prevState.values, visaRequired: value } };
        });
    }

    private renderMessageInformation = (message: string) => (
        <Grid.Row>
            <Grid.Column>
                <Message content={message} info />
            </Grid.Column>
        </Grid.Row>
    )

    private onUpdateChange = (prop: any, value: any, valid: boolean) => {
        this.updateProperty(prop, value, valid);
    }

    private onNestedPropChange = (prop: any, value: any, valid: boolean) => {
        this.updateNestedProperty(prop, value, valid);
    }

    private onNestedAddressPropChange = (prop: any, value: any, valid: boolean) => {
        this.updateNestedProperty(prop, value, valid);
        this.setState(current => ({ ...current, valid: { ...current.valid, address: addressProvided(current.values.address) } }));
    }

    private renderTitle = () => {
        const { values, showErrors } = this.state;
        const title = this.props.model.title;

        if (isNotNullOrUndefined(title) && title.length > 0) {
            return <DetailColumn label="Title" value={title || ""} />;
        }
        return (
            <Input.Dropdown
                value={values.title}
                label="Title"
                showErrors={showErrors}
                options={associateTitleOptions}
                onChange={this.getUpdate("title")}
                required={this.props.isUsa === false}
            />);
    }

    private renderMiddleName = () => {
        const { values } = this.state;
        const middleName = this.props.model.middleName;

        if (isNotNullOrUndefined(middleName) && middleName.length > 0) {
            return <DetailColumn label="Middle Name" value={middleName || ""} />;
        }

        return (
            <Input.Text
                value={values.middleName}
                label="Middle Name"
                onChange={this.getUpdate("middleName")}
            />
        );
    }
}

const mapStateToProps = (state: AppState): EditProfileStateProps => ({
    model: state.currentUser,
    requireVisaText: localTextSelector(state, "requireVisaText"),
    phoneNumberValidation: localTextSelector(state, "phoneNumberValidation"),
    addressText: localTextSelector(state, "address"),
    onContract: onContractSelector(state),
    isUsa: state.region === Country.Usa
});

const mapDispatchToProps = (dispatch: ThunkDispatch<CurrentUserState, void, Action>): SaveDispatchProps<CurrentUser> => ({
    save: async (profile: CurrentUser) => {
        await dispatch(saveCurrentUser(profile));
        await dispatch(push("/"));
        toast.success("Personal Information saved");
    }
});

export const EditProfile = connect(mapStateToProps, mapDispatchToProps)(EditProfileUnconnected);
