import { BonusTypeConfiguration } from "../hierarchicalConfiguration";

export interface Bonus {
    id: number;
    bonusTypeId?: number;
    amount: number;
    bonusType: BonusTypeConfiguration;
}

export class BonusBuilder {
    private id: number;
    private bonusTypeId?: number;
    private amount: number;
    private bonusType: BonusTypeConfiguration;

    constructor() {
        this.id = 0;
        this.bonusTypeId = 0;
        this.amount = 0;
        this.bonusType = undefined;
    }

    public withId(id: number) {
        this.id = id;
        return this;
    }

    public withBonusTypeId(bonusTypeId?: number) {
        this.bonusTypeId = bonusTypeId;
        return this;
    }

    public withAmount(amount: number) {
        this.amount = amount;
        return this;
    }

    public withBonusType(bonusType: BonusTypeConfiguration) {
        this.bonusType = bonusType;
        return this;
    }

    public build() {
        return {
            id: this.id,
            bonusTypeId: this.bonusTypeId,
            amount: this.amount,
            bonusType: this.bonusType
        };
    }
}
