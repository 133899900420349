import moment from "moment";
import { Result } from "not-valid";

import { VatRate } from "../vatRates/model";

export interface ServerValidationResult {
    isValid: boolean;
    errors: [{ errorMessage: string }];
}

export interface ExpenseVatValidationProps {
    vatRates: VatRate[];
    date: moment.Moment;
    net: number;
    vat: number;
    taxAbbreviation: string;
}

export const expenseVatValidator = ({ date, net, vat, vatRates, taxAbbreviation }: ExpenseVatValidationProps) => {

    const vatRate =  vatRates
        .filter(v => v.start <= date)
        .filter(v => v.end == null || v.end >= date)
        [0];

    const absNet = Math.abs(net);
    const absVat = Math.abs(vat);
    const vatFromNet = absNet * vatRate.value;
    const fixedVatFromNet = +vatFromNet.toFixed(2);

    return absVat <= fixedVatFromNet
        ? Result.Pass
        : Result.Fail(`${taxAbbreviation} can not exceed standard ${taxAbbreviation} of ${fixedVatFromNet}`);
};
