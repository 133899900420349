import moment from "moment";
import { ReferenceConfiguration } from "@momenta/common/referencing/model";

export interface ReferencePeriod {
    start?: moment.Moment;
    end?: moment.Moment;
    gap?: boolean;
}

export const gapReferencingCalculation = (
    references: ReferencePeriod[],
    referenceConfiguration: ReferenceConfiguration
) => {

    const { nonEmploymentPeriod, referencingPeriod, contractStartDate } = referenceConfiguration;

    const contractDate = moment(contractStartDate).isValid() ? contractStartDate : moment();

    const referencingPeriodStartDate = contractDate.clone().subtract(referencingPeriod, "months");

    let gaps: ReferencePeriod[] = [{
        start: referencingPeriodStartDate,
        end: contractDate,
        gap: true
    }];

    for (const reference of references) {

        const referenceStart = reference.start;
        const referenceEnd = reference.end || contractDate;

        gaps = getNotFullyOverlappedGaps(gaps, referenceStart, referenceEnd);

        if (gaps.length === 0) {
            break;
        }

        const [gapsSplit, result] = splitGapsByReference(gaps, referenceStart, referenceEnd);
        gaps = result;

        if (gapsSplit) {
            continue;
        }

        gaps = updateGapsWithOverlappingStart(gaps, referenceStart, referenceEnd);
        gaps = updateGapsWithOverlappingEnd(gaps, referenceStart, referenceEnd);
    }

    return gaps.filter(gap => gap.end.diff(gap.start, "days") > nonEmploymentPeriod);
};

const getNotFullyOverlappedGaps = (gaps: ReferencePeriod[], referenceStart: moment.Moment, referenceEnd: moment.Moment): ReferencePeriod[] => {

    const gapsNotFullyOverlapped = gaps.filter(gap => !(referenceStart.isSameOrBefore(gap.start) && referenceEnd.isSameOrAfter(gap.end)));
    return gapsNotFullyOverlapped;
};

const splitGapsByReference = (gaps: ReferencePeriod[], referenceStart: moment.Moment, referenceEnd: moment.Moment): [boolean, ReferencePeriod[]] => {

    const gapToSplit = gaps.filter(gap => gap.start.isBefore(referenceStart) && gap.end.isAfter(referenceEnd))[0];

    if (gapToSplit === undefined) {
        return [false, gaps];
    }

    const newGaps = [];

    for (const gap of gaps) {

        if (!isSameGap(gap, gapToSplit)) {
            newGaps.push(gap);
            continue;
        }

        newGaps.push({
            gap: true,
            start: gap.start.clone(),
            end: referenceStart.clone().add(-1, "days")
        });

        newGaps.push({
            gap: true,
            start: referenceEnd.clone().add(1, "days"),
            end: gap.end.clone()
        });
    }

    return [true, newGaps];
};

const updateGapsWithOverlappingStart = (gaps: ReferencePeriod[], referenceStart: moment.Moment, referenceEnd: moment.Moment): ReferencePeriod[] => {

    const gapWithOverlappingStart = gaps.filter(gap => referenceStart.isBefore(gap.start)
        && referenceEnd.isAfter(gap.start)
        && referenceEnd.isBefore(gap.end))[0];

    if (gapWithOverlappingStart === undefined) {
        return gaps;
    }

    return gaps.map(gap => {
        if (!isSameGap(gap, gapWithOverlappingStart)) {
            return gap;
        }

        const result = {
            gap: true,
            start: referenceEnd.clone().add(1, "days"),
            end: gap.end.clone()
        };

        return result;
    });
};

const updateGapsWithOverlappingEnd = (gaps: ReferencePeriod[], referenceStart: moment.Moment, referenceEnd: moment.Moment): ReferencePeriod[] => {

    const gapWithOverlappingEnd = gaps.filter(gap => referenceStart.isAfter(gap.start)
        && referenceStart.isBefore(gap.end)
        && referenceEnd.isSameOrAfter(gap.end))[0];

    if (gapWithOverlappingEnd === undefined) {
        return gaps;
    }

    return gaps.map(gap => {
        if (!isSameGap(gap, gapWithOverlappingEnd)) {
            return gap;
        }

        const result = {
            gap: true,
            start: gap.start.clone(),
            end: referenceStart.clone().add(-1, "days")
        };

        return result;
    });
};

export const isSameGap = (gapA: ReferencePeriod, gapB: ReferencePeriod) => {

    return gapA.start.isSame(gapB.start)
        && gapA.end.isSame(gapB.end);
};
