import * as React from "react";
import { Button, ButtonProps, Grid, Header, Message } from "semantic-ui-react";
import { toast } from "@momenta/common/toasts";
import { Associate } from "src/App/People/src/associate";

import { LocalText } from "../internationalisation/LocalText";

export interface PreferencesAndConsentDetailViewProps {
    associate: Associate;
    loading: boolean;
    saveMarketingEmails: (id: number, receiveMarketingEmails: boolean) => Promise<void>;
    requestDataRights?: (message: string) => Promise<void>;
    dataRightsModal?: JSX.Element;
}

interface PreferencesAndConsentState {
    receiveMarketingEmails: boolean;
    open: boolean;
}

export class PreferencesAndConsentDetailView extends React.Component<PreferencesAndConsentDetailViewProps, PreferencesAndConsentState> {
    public state: PreferencesAndConsentState = {
        receiveMarketingEmails: this.props.associate.receiveMarketingEmails,
        open: false
    };

    public render() {
        const { associate, dataRightsModal } = this.props;

        const marketingButtonProps: ButtonProps = associate && associate.receiveMarketingEmails
            ? { content: "Unsubscribe", color: "red", basic: true }
            : { content: "Subscribe", color: "green" };

        const subscribeState = associate && associate.receiveMarketingEmails
            ? "By clicking here you will unsubscribe from all communication from Momenta"
            : "Subscribe to all further communications regarding potentially relevant roles";

        return (
            <div className="preferences-and-consent">
                <Header dividing>Preferences and Consent</Header>
                {associate.receiveMarketingEmails !== undefined &&
                    <Grid>
                        <Grid.Row verticalAlign="middle">
                            <Grid.Column computer={3} mobile={8}>
                                <Button {...marketingButtonProps} onClick={this.handleClick} loading={this.props.loading} fluid />
                            </Grid.Column>
                            <Grid.Column computer={13} mobile={8}>
                                {subscribeState}
                            </Grid.Column>
                        </Grid.Row>

                        {dataRightsModal &&
                            <Grid.Row verticalAlign="middle">
                                <Grid.Column computer={3} mobile={8}>
                                    {dataRightsModal}
                                </Grid.Column>
                                <Grid.Column computer={13} mobile={8}>
                                    <p>
                                        Click here to contact our Data Protection Team to request to exercise any of your rights
                                         which are outlined in the contractor privacy policy.
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        }

                        <Grid.Row>
                            <Grid.Column>
                                <Message info>
                                    <LocalText keyName="termsAndConditionsMessage" />
                                </Message>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </div>
        );
    }

    private handleClick = () => {
        this.setState({
            receiveMarketingEmails: !this.props.associate.receiveMarketingEmails
        }, () => this.props.saveMarketingEmails(this.props.associate.id, this.state.receiveMarketingEmails));

        toast.success("Preferences saved");
    }
}
