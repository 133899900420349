import * as React from "react";
import moment from "moment";
import { Result } from "not-valid";
import { Button, Form, Grid, List } from "semantic-ui-react";
import { Input } from "@neworbit/simpleui-input";
import { positiveNumberValidator } from "@momenta/common/validators/positiveNumberValidator";
import { ValidationState } from "@momenta/common/validationState";

import { BonusTypeConfiguration } from "../hierarchicalConfiguration";
import { dateConfigOptionSelector } from "../timesheets/dateConfigOptionSelector";

import { Bonus } from "./model";

interface BonusEntriesEditProps {
    bonus: Bonus;
    bonusValid: ValidationState;
    bonusTypeConfigurations: BonusTypeConfiguration[];
    onDelete: () => void;
    onChange: (newBonus: Bonus, valid: ValidationState) => void;
    submitted: boolean;
    showErrors: boolean;
    isAdjustment: boolean;
    timesheetStart: moment.Moment;
}

export class BonusEntriesEdit extends React.Component<BonusEntriesEditProps, {}> {
    constructor(props: BonusEntriesEditProps) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.bonusValidation = this.bonusValidation.bind(this);
        this.getBonusTypeOptionValue = this.getBonusTypeOptionValue.bind(this);
    }

    public render() {
        const { bonus, bonusTypeConfigurations, submitted, showErrors, timesheetStart } = this.props;

        const typeOptions = dateConfigOptionSelector({
            config: bonusTypeConfigurations,
            date: timesheetStart
        });

        const bonusValidation = [this.bonusValidation];

        if (!this.props.isAdjustment) {
            bonusValidation.push(positiveNumberValidator);
        }

        const getHandleChange = (prop: "bonusTypeId" | "amount") => { return (value: number, valid: boolean) => this.handleOnChange(prop, value, valid); };

        return (
            <List.Item>
                <Grid>
                    <Grid.Column mobile={16} computer={8}>
                        <Input.DropdownNumber
                            placeholder="Select type"
                            options={typeOptions}
                            label="Type"
                            onChange={getHandleChange("bonusTypeId")}
                            value={this.getBonusTypeOptionValue()}
                            disabled={submitted}
                            showErrors={showErrors}
                            dynamicOptions
                            required
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={7}>
                        <Input.Number
                            placeholder="Enter amount"
                            label="Amount"
                            onChange={getHandleChange("amount")}
                            value={bonus.amount}
                            validation={bonusValidation}
                            disabled={submitted}
                            showErrors={showErrors}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={1}>
                        <Form.Field>
                            <label className="empty-label">&nbsp;</label>
                            <Button
                                icon="trash alternate outline"
                                onClick={this.handleClick}
                                color="red"
                                floated="right"
                                disabled={submitted}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid>
            </List.Item>
        );
    }

    public bonusValidation(value: number) {
        const bonusTypes = this.props.bonusTypeConfigurations.filter(e => e.id === this.props.bonus.bonusTypeId);

        if (bonusTypes.length === 0) {
            return Result.Stop;
        }

        const maxValue = bonusTypes[0].bonusLimit;

        if (value > maxValue) {
            return Result.Fail(`Total bonus cannot exceed limit of ${maxValue}`);
        }
        return Result.Pass;
    }

    private getBonusTypeOptionValue() {
        const bonus = this.props.bonus;
        const configs = this.props.bonusTypeConfigurations;

        if (configs.some(b => b.id === bonus.bonusTypeId)) {
            return bonus.bonusTypeId;
        }

        if (bonus.bonusType) {
            const bonusType = configs.filter(b => b.typeId === bonus.bonusType.typeId)[0];

            if (bonusType) {
                this.props.bonus.bonusTypeId = bonusType.id;
                return bonusType.id;
            }
        }

        return null;
    }

    private handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();

        this.props.onDelete();
    }

    private handleOnChange<T>(prop: keyof Bonus, value: T, valid: boolean) {
        if (value === null) {
            valid = false;
        }

        this.props.onChange({
            ...this.props.bonus,
            [prop]: value,
        },
        {
            ...this.props.bonusValid,
            [prop]: valid
        });
    }
}
