import axios from "axios";
import moment from "moment";
import { PageResult } from "@momenta/common";
import { parseTimesheet, Timesheet } from "@momenta/common/timesheets";

import { timesheetStorage } from "./TimesheetStorage";

export class TimesheetApi {

    private readonly apiUrl = "/api/timesheet";

    public async getAll(top: number): Promise<PageResult<Timesheet>> {

        const response = await axios.get(`${this.apiUrl}?$orderby=Start desc&$top=${top}`);
        const { items, count } = response.data as PageResult<Timesheet>;

        return {
            items: items.map(d => this.parseModel(d)),
            count
        };
    }

    public async get(id: number): Promise<Timesheet> {
        const apiPromise = axios.get(`${this.apiUrl}/${id}`);
        const storagePromise = timesheetStorage.load(id);

        const [response, localTimesheet] = await Promise.all([apiPromise, storagePromise]);

        const apiTimesheet = response.data as Timesheet;

        const timesheetEntryAllowedHasNotChanged = apiTimesheet.timesheetEntryDenied === localTimesheet?.timesheetEntryDenied;

        if (localTimesheet && moment(localTimesheet.updated).isAfter(moment(apiTimesheet.updated)) && timesheetEntryAllowedHasNotChanged) {
            return this.parseModel(localTimesheet);
        } else {
            return this.parseModel(apiTimesheet, true);
        }
    }

    public async save(model: Timesheet): Promise<Timesheet> {
        const response = await axios.put(`${this.apiUrl}/${model.id}`, model);
        const result = this.parseModel(response.data as Timesheet);
        return result;
    }

    public async create(model: Timesheet): Promise<Timesheet> {
        const response = await axios.post(this.apiUrl, model);
        return this.parseModel(response.data as Timesheet);
    }

    public parseModel(model: Timesheet, fromServer: boolean = false): Timesheet {
        return {
            ...parseTimesheet(model),
            synced: fromServer
        };
    }
}

export const timesheetApi = new TimesheetApi();
