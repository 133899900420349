export enum BloodGroup {
    "A+" = 10,
    "O+" = 20,
    "B+" = 30,
    "AB+" = 40,
    "A-" = 50,
    "O-" = 60,
    "B-" = 70,
    "AB-" = 80
}
