import { AssociateQualification } from "@momenta/common/qualifications/model";
import axios from "axios";

export class AssociateQualificationsApi {

    private readonly apiUrl = "api/qualification"

    public async loadAllQualificationsForAssociate(): Promise<AssociateQualification[]> {
        const response = await axios.get<AssociateQualification[]>(`${this.apiUrl}/associate`);
        const qualifications = response.data;
        return qualifications;
    }

    public async deleteQualification(id: number): Promise<void> {
        await axios.delete(`${this.apiUrl}/${id}`);
    }

    public async updateQualification(id: number, associateQualification: AssociateQualification): Promise<AssociateQualification> {
        const response = await axios.put<AssociateQualification>(`${this.apiUrl}/${id}`, associateQualification);
        const qualification = response.data;
        return qualification;
    }

    public async createQualification(associateQualification: AssociateQualification): Promise<AssociateQualification> {
        const response = await axios.post<AssociateQualification>(`${this.apiUrl}`, associateQualification);
        const qualification = response.data;
        return qualification;
    }
}

export const associateQualificationsApi = new AssociateQualificationsApi();
