import * as React from "react";
import { useSelector } from "react-redux";
import { Header } from "semantic-ui-react";

import { currentUserSelector } from "../currentUser/selector";

import { ApplicationsTable } from "./ApplicationsTable";
import { ContractsTable } from "./ContractsTable";

export const ViewContracts: React.FC<{}> = () => {
    const currentUser = useSelector(currentUserSelector);

    return (
        <>
            <Header as="h1" dividing>Applications and Contracts</Header>
            <ApplicationsTable applications={currentUser.candidacies} />
            <ContractsTable contracts={currentUser.contracts} />
        </>
    );
};
