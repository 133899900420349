import * as React from "react";
import { Expense, ExpenseInputTypeEnum } from "@momenta/common/expenses/model";
import { Table } from "semantic-ui-react";
import { Currency } from "@momenta/common/internationalisation/Currency";

interface ExpenseRowPrintProps {
    expense: Expense;
}

export const ExpenseRowPrint: React.FC<ExpenseRowPrintProps> = ({ expense }) => {

    const expenseConfig = expense?.expenseType?.type;

    const isQuantity = expenseConfig.expenseInputType === ExpenseInputTypeEnum.Quantity;
    const net = isQuantity ? expense?.amount * expense?.expenseType?.rate : expense?.net;

    return (
        <Table.Row key={expense.id}>
            <Table.Cell >{expense?.date?.format("LL")}</Table.Cell>
            <Table.Cell >{expense?.expenseType?.type?.name}</Table.Cell>
            <Table.Cell >{expense?.description}</Table.Cell>
            <Table.Cell ><Currency value={net} /></Table.Cell>
            <Table.Cell ><Currency value={expense?.vat} /></Table.Cell>
        </Table.Row>
    );
};
