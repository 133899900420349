import * as React from "react";
import { Card, Icon, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { localTextSelector } from "@momenta/common/internationalisation";
import { RegionState } from "@momenta/common/internationalisation/model";
import { CandidateState } from "@momenta/common/model";

import { CurrentUser, CurrentUserState } from "../currentUser/model";

import { validate } from "./validators";
import { DashboardCard } from "./DashboardCard";

export interface PersonalInformationCardProps {
    currentUser: CurrentUser;
    superannuationDetailsRequired: boolean;
    australiaTaxFormRequired: boolean;
    bankDetailsRequired: boolean;
}

export class PersonalInformationCardUnconnected extends React.Component<PersonalInformationCardProps> {

    public render() {

        const { currentUser } = this.props;
        const isComplete = this.isProfileComplete();
        const onContract = currentUser.contracts.some(c => c.state === CandidateState["On Contract"]);
        const color = !isComplete && onContract ? "red" : "green";

        return (
            <DashboardCard
                color={color}
                href="/profile"
                title="Personal Information"
                svg="personal-information"
            >
                <Card.Content textAlign="center">
                    <Card.Meta>
                        {this.complete(isComplete, onContract)}
                    </Card.Meta>
                </Card.Content>
            </DashboardCard>
        );
    }

    private complete(isComplete: boolean, onContract: boolean) {
        if (isComplete) {
            return <p>Your profile is complete <Icon name="checkmark" color="green" /></p>;
        }

        if (onContract) {
            return (
                <Message negative>
                    Your profile is not complete, please ensure this is up to date
                </Message>
            );
        }

        return null;
    }

    private isProfileComplete() {

        let superannuationDetailsValid = true;
        let australiaTaxFormValid = true;
        let australiaBankDetailsValid = true;

        if (this.props.superannuationDetailsRequired) {
            superannuationDetailsValid = validate(this.props.currentUser.superannuationDetails,
                ["fundName", "membershipName", "membershipNumber", "usiCode", "superForm"]);
        }

        if (this.props.australiaTaxFormRequired) {
            australiaTaxFormValid = validate(this.props.currentUser, ["taxForm"]);
        }

        if (this.props.bankDetailsRequired) {
            australiaBankDetailsValid = validate(this.props.currentUser.bankDetails, ["bankAccountName", "accountNumber", "bsbCode"]);
        }

        return validate(this.props.currentUser, ["title", "surname", "forename", "id", "mobileNumber", "nationality"])
            && validate(this.props.currentUser.address, ["line1", "postcode"])
            && validate(this.props.currentUser.emergencyContact, ["relationship", "forename", "surname", "contactNumber"])
            && superannuationDetailsValid
            && australiaTaxFormValid
            && australiaBankDetailsValid;
    }
}

const mapStateToProps = (state: CurrentUserState & RegionState): PersonalInformationCardProps => ({
    currentUser: state.currentUser,
    superannuationDetailsRequired: localTextSelector(state, "superannuationDetails"),
    australiaTaxFormRequired: localTextSelector(state, "australiaTaxForm"),
    bankDetailsRequired: localTextSelector(state, "australiaBankDetails")
});

export const PersonalInformationCard = connect(mapStateToProps)(PersonalInformationCardUnconnected);
