import * as React from "react";
import { Form } from "semantic-ui-react";
import { SaveDispatchProps } from "@neworbit/simpleui-forms";
import { Input } from "@neworbit/simpleui-input";
import { getDateInputFormat } from "@momenta/common/dateFormatting/getDateInputFormat";
import { convertEnumToOptions } from "@momenta/common";
import { createValidator } from "not-valid";
import moment = require("moment");

import { AcademicReference, QualificationType, ReferenceType } from "./model";
import { CreateReference, ReferenceOwnProps, ReferenceProps } from "./CreateReference";
import { RefereeEmail } from "./RefereeEmail";

import { RefereePhoneNumber } from "./RefereePhoneNumber";

export class CreateAcademicReference extends CreateReference<AcademicReference> {

    constructor(props: ReferenceProps & ReferenceOwnProps<AcademicReference> & SaveDispatchProps<AcademicReference>) {
        super(props);
        this.state = {
            values: props.reference || this.defaultState(),
            valid: {}
        };
    }

    private courseEndsAfterItStarts() {
        return this.state.values.end >= this.state.values.start;
    }

    public render() {
        const {
            values: {
                institutionName,
                courseTitle,
                start,
                end,
                current,
                grade,
                qualificationType,
                qualificationTypeOther,
                refereeEmail,
                submitted,
                refereePhoneNumber
            }
        } = this.state;

        const qualificationTypeOptions = convertEnumToOptions(QualificationType, k => +k).filter(o => o.value !== 0 && !isFinite(o.text));

        const readOnly = this.props.disabled === true && submitted;

        const gradeText = `Please enter grade obtained, if course not completed, please list reasons for non-completion.
                    Please provide details if you are aware of any issues we may encounter when trying to obtain a reference.`;

        const futureDateCheck = createValidator<moment.Moment>(
            () => this.courseEndsAfterItStarts(),
            "Course end date must be after the start date"
        );

        return (
            <Form onSubmit={this.handleSubmit}>
                <Input.Text
                    value={institutionName}
                    label="Institution Name"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("institutionName")}
                    readOnly={readOnly}
                    required
                />
                <Input.Date
                    value={start}
                    label="Start Date"
                    onChange={this.getUpdate("start")}
                    format={getDateInputFormat()}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    required
                />
                {current === false && <Input.Date
                    value={end}
                    label="End Date"
                    onChange={this.getUpdate("end")}
                    format={getDateInputFormat()}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    validation={[futureDateCheck]}
                    required
                />}
                <Input.Checkbox
                    value={current}
                    label="To Present"
                    onChange={this.onCurrentChange}
                    readOnly={readOnly}
                />
                <Input.Text
                    value={courseTitle}
                    label="Course Title"
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("courseTitle")}
                    readOnly={readOnly}
                    required
                />
                <Input.DropdownNumber
                    value={qualificationType}
                    onChange={readOnly === false && this.getUpdate("qualificationType")}
                    label="Qualification Type"
                    options={qualificationTypeOptions}
                    showErrors={this.state.showErrors}
                    readOnly={readOnly}
                    dynamicOptions
                    required
                />
                {qualificationType === 40 &&
                    <Input.Text
                        value={qualificationTypeOther}
                        label="Other Qualification"
                        showErrors={this.state.showErrors}
                        onChange={this.getUpdate("qualificationTypeOther")}
                        readOnly={readOnly}
                        required
                    />
                }
                <Input.Textarea
                    value={grade}
                    label={gradeText}
                    showErrors={this.state.showErrors}
                    onChange={this.getUpdate("grade")}
                    readOnly={readOnly}
                />

                {this.props.showRefereeEmail &&
                    <RefereeEmail
                        refereeEmail={refereeEmail}
                        showErrors={this.state.showErrors}
                        disabled={this.props.disabled}
                        onChange={this.getUpdate("refereeEmail")}
                        onSendEmailClick={this.props.onSendEmailClick}
                    />
                }

                {this.props.showRefereePhoneNumber &&
                    <RefereePhoneNumber
                        refereePhoneNumber={refereePhoneNumber}
                        showErrors={this.state.showErrors}
                        onChange={this.getUpdate("refereePhoneNumber")}
                    />
                }

            </Form>
        );
    }

    public onCurrentChange = (value: any) => {

        if (this.state.values.current === value) {
            return;
        }

        this.setState(prevState => ({
            values: {
                ...prevState.values,
                current: value,
                end: value ? null : prevState.values.end
            },
            valid: {
                ...prevState.valid,
                end: value ? true : prevState.valid.end
            }
        }));
    }

    private defaultState(): AcademicReference {

        const { start, end } = this.props;
        const reference = this.getDefaultReferenceState(ReferenceType.Academic);
        const startDate = start && start.isValid() ? start : null;
        const endDate = end && end.isValid() ? end : null;

        return {
            ...reference,
            start: startDate,
            end: endDate,
            institutionName: "",
            courseTitle: "",
            qualificationType: undefined,
            qualificationTypeOther: "",
            grade: ""
        };
    }
}
