export const REFERENCE_QUANTITY = (referencingPeriod: number) => {
    return `
        Please enter a minimum of two employments.
        If you have only had one employment for the entire ${referencingPeriod} month period,
        we require details of your previous employment.
    `;
};

export const REFERENCE_DURATION = (referencingPeriod: number) => {
    return `You have not supplied ${referencingPeriod} months of activity, please add further details.`;
};

export const REFERENCE_GAP = "Please fill in the missing gap references highlighted in red";
