import { getConfig } from "@momenta/common";
import { saveRegion } from "@momenta/common/internationalisation";

export function initialiseRegion() {
    return async (dispatch: any) => {
        const configuration = await getConfig();
        const region = configuration.Region;

        if (!region) {
            return;
        }

        dispatch(saveRegion((region.Country)));
    };
}
