import { DropdownItemProps } from "semantic-ui-react";

export const nationalities: DropdownItemProps[] = [
    { value: "Afghan", text: "Afghan" },
    { value: "Albanian", text: "Albanian" },
    { value: "Algerian", text: "Algerian" },
    { value: "Andorran", text: "Andorran" },
    { value: "Angolan", text: "Angolan" },
    { value: "Antiguans", text: "Antiguans" },
    { value: "Argentinean", text: "Argentinean" },
    { value: "Armenian", text: "Armenian" },
    { value: "Australian", text: "Australian" },
    { value: "Austrian", text: "Austrian" },
    { value: "Azerbaijani", text: "Azerbaijani" },
    { value: "Bahamian", text: "Bahamian" },
    { value: "Bahraini", text: "Bahraini" },
    { value: "Bangladeshi", text: "Bangladeshi" },
    { value: "Barbadian", text: "Barbadian" },
    { value: "Barbudans", text: "Barbudans" },
    { value: "Batswana", text: "Batswana" },
    { value: "Belarusian", text: "Belarusian" },
    { value: "Belgian", text: "Belgian" },
    { value: "Belizean", text: "Belizean" },
    { value: "Beninese", text: "Beninese" },
    { value: "Bhutanese", text: "Bhutanese" },
    { value: "Bolivian", text: "Bolivian" },
    { value: "Bosnian", text: "Bosnian" },
    { value: "Brazilian", text: "Brazilian" },
    { value: "British", text: "British" },
    { value: "Bruneian", text: "Bruneian" },
    { value: "Bulgarian", text: "Bulgarian" },
    { value: "Burkinabe", text: "Burkinabe" },
    { value: "Burmese", text: "Burmese" },
    { value: "Burundian", text: "Burundian" },
    { value: "Cambodian", text: "Cambodian" },
    { value: "Cameroonian", text: "Cameroonian" },
    { value: "Canadian", text: "Canadian" },
    { value: "Cape Verdean", text: "Cape Verdean" },
    { value: "Central African", text: "Central African" },
    { value: "Chadian", text: "Chadian" },
    { value: "Chilean", text: "Chilean" },
    { value: "Chinese", text: "Chinese" },
    { value: "Colombian", text: "Colombian" },
    { value: "Comoran", text: "Comoran" },
    { value: "Congolese", text: "Congolese" },
    { value: "Costa Rican", text: "Costa Rican" },
    { value: "Croatian", text: "Croatian" },
    { value: "Cuban", text: "Cuban" },
    { value: "Cypriot", text: "Cypriot" },
    { value: "Czech", text: "Czech" },
    { value: "Danish", text: "Danish" },
    { value: "Djibouti", text: "Djibouti" },
    { value: "Dominican", text: "Dominican" },
    { value: "Dutch", text: "Dutch" },
    { value: "East Timorese", text: "East Timorese" },
    { value: "Ecuadorean", text: "Ecuadorean" },
    { value: "Egyptian", text: "Egyptian" },
    { value: "Emirian", text: "Emirian" },
    { value: "Equatorial Guinean", text: "Equatorial Guinean" },
    { value: "Eritrean", text: "Eritrean" },
    { value: "Estonian", text: "Estonian" },
    { value: "Ethiopian", text: "Ethiopian" },
    { value: "Fijian", text: "Fijian" },
    { value: "Filipino", text: "Filipino" },
    { value: "Finnish", text: "Finnish" },
    { value: "French", text: "French" },
    { value: "Gabonese", text: "Gabonese" },
    { value: "Gambian", text: "Gambian" },
    { value: "Georgian", text: "Georgian" },
    { value: "German", text: "German" },
    { value: "Ghanaian", text: "Ghanaian" },
    { value: "Greek", text: "Greek" },
    { value: "Grenadian", text: "Grenadian" },
    { value: "Guatemalan", text: "Guatemalan" },
    { value: "Guinea-Bissauan", text: "Guinea-Bissauan" },
    { value: "Guinean", text: "Guinean" },
    { value: "Guyanese", text: "Guyanese" },
    { value: "Haitian", text: "Haitian" },
    { value: "Herzegovinian", text: "Herzegovinian" },
    { value: "Honduran", text: "Honduran" },
    { value: "Hungarian", text: "Hungarian" },
    { value: "Icelander", text: "Icelander" },
    { value: "Indian", text: "Indian" },
    { value: "Indonesian", text: "Indonesian" },
    { value: "Iranian", text: "Iranian" },
    { value: "Iraqi", text: "Iraqi" },
    { value: "Irish", text: "Irish" },
    { value: "Israeli", text: "Israeli" },
    { value: "Italian", text: "Italian" },
    { value: "Ivorian", text: "Ivorian" },
    { value: "Jamaican", text: "Jamaican" },
    { value: "Japanese", text: "Japanese" },
    { value: "Jordanian", text: "Jordanian" },
    { value: "Kazakhstani", text: "Kazakhstani" },
    { value: "Kenyan", text: "Kenyan" },
    { value: "Kittian and Nevisian", text: "Kittian and Nevisian" },
    { value: "Kuwaiti", text: "Kuwaiti" },
    { value: "Kyrgyz", text: "Kyrgyz" },
    { value: "Laotian", text: "Laotian" },
    { value: "Latvian", text: "Latvian" },
    { value: "Lebanese", text: "Lebanese" },
    { value: "Liberian", text: "Liberian" },
    { value: "Libyan", text: "Libyan" },
    { value: "Liechtensteiner", text: "Liechtensteiner" },
    { value: "Lithuanian", text: "Lithuanian" },
    { value: "Luxembourger", text: "Luxembourger" },
    { value: "Macedonian", text: "Macedonian" },
    { value: "Malagasy", text: "Malagasy" },
    { value: "Malawian", text: "Malawian" },
    { value: "Malaysian", text: "Malaysian" },
    { value: "Maldivan", text: "Maldivan" },
    { value: "Malian", text: "Malian" },
    { value: "Maltese", text: "Maltese" },
    { value: "Marshallese", text: "Marshallese" },
    { value: "Mauritanian", text: "Mauritanian" },
    { value: "Mauritian", text: "Mauritian" },
    { value: "Mexican", text: "Mexican" },
    { value: "Micronesian", text: "Micronesian" },
    { value: "Moldovan", text: "Moldovan" },
    { value: "Monacan", text: "Monacan" },
    { value: "Mongolian", text: "Mongolian" },
    { value: "Moroccan", text: "Moroccan" },
    { value: "Mosotho", text: "Mosotho" },
    { value: "Motswana", text: "Motswana" },
    { value: "Mozambican", text: "Mozambican" },
    { value: "Namibian", text: "Namibian" },
    { value: "Nauruan", text: "Nauruan" },
    { value: "Nepalese", text: "Nepalese" },
    { value: "New Zealander", text: "New Zealander" },
    { value: "Ni-Vanuatu", text: "Ni-Vanuatu" },
    { value: "Nicaraguan", text: "Nicaraguan" },
    { value: "Nigerian", text: "Nigerian" },
    { value: "Nigerien", text: "Nigerien" },
    { value: "North Korean", text: "North Korean" },
    { value: "Northern Irish", text: "Northern Irish" },
    { value: "Norwegian", text: "Norwegian" },
    { value: "Omani", text: "Omani" },
    { value: "Pakistani", text: "Pakistani" },
    { value: "Palauan", text: "Palauan" },
    { value: "Panamanian", text: "Panamanian" },
    { value: "Papua New Guinean", text: "Papua New Guinean" },
    { value: "Paraguayan", text: "Paraguayan" },
    { value: "Peruvian", text: "Peruvian" },
    { value: "Polish", text: "Polish" },
    { value: "Portuguese", text: "Portuguese" },
    { value: "Qatari", text: "Qatari" },
    { value: "Romanian", text: "Romanian" },
    { value: "Russian", text: "Russian" },
    { value: "Rwandan", text: "Rwandan" },
    { value: "Saint Lucian", text: "Saint Lucian" },
    { value: "Salvadoran", text: "Salvadoran" },
    { value: "Samoan", text: "Samoan" },
    { value: "San Marinese", text: "San Marinese" },
    { value: "Sao Tomean", text: "Sao Tomean" },
    { value: "Saudi", text: "Saudi" },
    { value: "Scottish", text: "Scottish" },
    { value: "Senegalese", text: "Senegalese" },
    { value: "Serbian", text: "Serbian" },
    { value: "Seychellois", text: "Seychellois" },
    { value: "Sierra Leonean", text: "Sierra Leonean" },
    { value: "Singaporean", text: "Singaporean" },
    { value: "Slovakian", text: "Slovakian" },
    { value: "Slovenian", text: "Slovenian" },
    { value: "Solomon Islander", text: "Solomon Islander" },
    { value: "Somali", text: "Somali" },
    { value: "South African", text: "South African" },
    { value: "South korean", text: "South Korean" },
    { value: "Spanish", text: "Spanish" },
    { value: "Sri Lankan", text: "Sri Lankan" },
    { value: "Sudanese", text: "Sudanese" },
    { value: "Surinamer", text: "Surinamer" },
    { value: "Swazi", text: "Swazi" },
    { value: "Swedish", text: "Swedish" },
    { value: "Swiss", text: "Swiss" },
    { value: "Syrian", text: "Syrian" },
    { value: "Taiwanese", text: "Taiwanese" },
    { value: "Tajik", text: "Tajik" },
    { value: "Tanzanian", text: "Tanzanian" },
    { value: "Thai", text: "Thai" },
    { value: "Togolese", text: "Togolese" },
    { value: "Tongan", text: "Tongan" },
    { value: "Trinidadian or Tobagonian", text: "Trinidadian or Tobagonian" },
    { value: "Tunisian", text: "Tunisian" },
    { value: "Turkish", text: "Turkish" },
    { value: "Tuvaluan", text: "Tuvaluan" },
    { value: "Ugandan", text: "Ugandan" },
    { value: "Ukrainian", text: "Ukrainian" },
    { value: "United States", text: "United States" },
    { value: "Uruguayan", text: "Uruguayan" },
    { value: "Uzbekistani", text: "Uzbekistani" },
    { value: "Venezuelan", text: "Venezuelan" },
    { value: "Vietnamese", text: "Vietnamese" },
    { value: "Welsh", text: "Welsh" },
    { value: "Yemenite", text: "Yemenite" },
    { value: "Zambian", text: "Zambian" },
    { value: "Zimbabwean", text: "Zimbabwean" }
];

export function filterNationalities(searchTerm: string) {
    if (searchTerm) {
        return nationalities.filter((n: DropdownItemProps) => n.text.toString().toLowerCase().startsWith(searchTerm.toLowerCase()));
    }
    return nationalities;
}
