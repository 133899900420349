import { Location } from "redux-little-router";
import moment from "moment";
import { FinanceDocument } from "@momenta/common/financeDocuments";

import { CurrentUser } from "../../Associate/src/currentUser/model";
import { Associate } from "../../People/src/associate/model";
import { VatDetails } from "../vatDetails";
import { Address } from "../address/model";

export interface Company {
    readonly id: number;
    readonly name?: string;
    readonly address?: Address;
    readonly registrationNumber?: string;
    readonly sortCode?: string;
    readonly accountNumber?: string;
    readonly umbrella?: boolean;
    readonly registrationDocument?: string;
    readonly piplInsuranceDocument?: string;
    readonly vatRegistrationDocument?: string;
    readonly vatDeregistrationDocument?: string;
    readonly financeId?: string;
    readonly vatDetails?: VatDetails;
    readonly generateInvoice: boolean;
    readonly associateId?: number;
    readonly associate?: Associate;
    readonly registrationDate?: moment.Moment;
    readonly companyStatus?: string;
    readonly companyStatusDetail?: string;
    readonly vatRegistrationFinanceDocument?: FinanceDocument;
    readonly vatDeregistrationFinanceDocument?: FinanceDocument;
    readonly financeDocuments?: FinanceDocument[];
    readonly managingDirector?: string;
    readonly managingDirectorEmail?: string;
    readonly updatedLocally?: moment.Moment;
    readonly showOnAssociate?: boolean;
}

const parseDate = (value: any) => value == null ? value : moment(value);

export const parseCompany = (company: Company): Company => {
    let vatDetails;

    if (company != null) {

        if (company.vatDetails != null) {

            vatDetails = {
                ...company.vatDetails,
                dateRegistered: parseDate(company.vatDetails.dateRegistered),
                deregisteredDate: parseDate(company.vatDetails.deregisteredDate)
            };
        }

        company = {
            ...company,
            registrationDate: company.registrationDate && parseDate(company.registrationDate),
            updatedLocally: moment(),
            vatDetails
        };
    }

    return company;
};

export interface CompanyState {
    readonly companies: Company[];
}

export type AppState = CompanyState & { router: Location } & CurrentUser;

export class CompanyBuilder {
    private id: number;
    private name?: string;
    private address?: Address;
    private registrationNumber?: string;
    private sortCode?: string;
    private accountNumber?: string;
    private umbrella?: boolean;
    private registrationDocument: string;
    private piplInsuranceDocument: string;
    private vatRegistrationDocument: string;
    private vatDetails: VatDetails;
    private financeId: string;
    private generateInvoice: boolean;
    private associateId: number;
    private associate: Associate;
    private financeDocuments: FinanceDocument[];
    private managingDirector: string;
    private managingDirectorEmail: string;
    private showOnAssociate: boolean;

    constructor() {
        this.name = "";
        this.address = null;
        this.registrationNumber = "";
        this.sortCode = "";
        this.accountNumber = "";
        this.umbrella = false;
        this.registrationDocument = "";
        this.piplInsuranceDocument = "";
        this.vatRegistrationDocument = "";
        this.vatDetails = null;
        this.generateInvoice = false;
        this.financeId = "";
        this.associateId = 0;
        this.associate = null;
        this.financeDocuments = null;
        this.managingDirector = "";
        this.managingDirectorEmail = "";
        this.showOnAssociate = true;
    }

    public withId(id: number): CompanyBuilder {
        this.id = id;
        return this;
    }

    public withName(name: string): CompanyBuilder {
        this.name = name;
        return this;
    }
    public withAddress(address: Address): CompanyBuilder {
        this.address = address;
        return this;
    }
    public withRegistrationNumber(registrationNumber: string): CompanyBuilder {
        this.registrationNumber = registrationNumber;
        return this;
    }
    public withSortCode(sortCode: string): CompanyBuilder {
        this.sortCode = sortCode;
        return this;
    }
    public withAccountNumber(accountNumber: string): CompanyBuilder {
        this.accountNumber = accountNumber;
        return this;
    }
    public withUmbrella(umbrella: boolean): CompanyBuilder {
        this.umbrella = umbrella;
        return this;
    }

    public withRegistrationDocument(registrationDocument: string): CompanyBuilder {
        this.registrationDocument = registrationDocument;
        return this;
    }

    public withPiplInsuranceDocument(piplInsuranceDocument: string): CompanyBuilder {
        this.piplInsuranceDocument = piplInsuranceDocument;
        return this;
    }

    public withVatRegistrationDocument(vatRegistrationDocument: string): CompanyBuilder {
        this.vatRegistrationDocument = vatRegistrationDocument;
        return this;
    }

    public withVatDetails(vatDetails: VatDetails): CompanyBuilder {
        this.vatDetails = vatDetails;
        return this;
    }

    public withFinanceId(financeId: string): CompanyBuilder {
        this.financeId = financeId;
        return this;
    }

    public withGenerateInvoice(generateInvoice: boolean): CompanyBuilder {
        this.generateInvoice = generateInvoice;
        return this;
    }

    public withAssociateId(associateId: number): CompanyBuilder {
        this.associateId = associateId;
        return this;
    }

    public withAssociate(associate: Associate): CompanyBuilder {
        this.associate = associate;
        return this;
    }

    public withFinanceDocuments(financeDocuments: FinanceDocument[]): CompanyBuilder {
        this.financeDocuments = financeDocuments;
        return this;
    }

    public withManagingDirector(managingDirector: string) {
        this.managingDirector = managingDirector;
        return this;
    }

    public withManagingDirectorEmail(managingDirectorEmail: string) {
        this.managingDirectorEmail = managingDirectorEmail;
        return this;
    }

    public withShowOnAssociate(showOnAssociate: boolean) {
        this.showOnAssociate = showOnAssociate;
    }

    public build(): Company {
        return {
            id: this.id,
            name: this.name,
            address: this.address,
            registrationNumber: this.registrationNumber,
            sortCode: this.sortCode,
            accountNumber: this.accountNumber,
            umbrella: this.umbrella,
            registrationDocument: this.registrationDocument,
            piplInsuranceDocument: this.piplInsuranceDocument,
            vatRegistrationDocument: this.vatRegistrationDocument,
            vatDetails: this.vatDetails,
            financeId: this.financeId,
            generateInvoice: this.generateInvoice,
            associateId: this.associateId,
            associate: this.associate,
            financeDocuments: this.financeDocuments,
            managingDirector: this.managingDirector,
            managingDirectorEmail: this.managingDirectorEmail,
            showOnAssociate: this.showOnAssociate
        };
    }
}
