import * as React from "react";
import { Table } from "semantic-ui-react";
import { Candidate } from "src/App/People/src/candidates";
import { CandidateState, dateSortDesc } from "@momenta/common";

export interface ContractsTableProps {
    contracts: Candidate[];
}

export const ContractsTable: React.FC<ContractsTableProps> = ({ contracts }) => {

    const getStartDate = (candidate: Candidate) => {
        return candidate.contractStart?.format("L") ?? candidate.intake?.intakeDate?.format("L") ?? candidate.role?.startDate?.format("L");
    };

    const getEndDate = (candidate: Candidate) => {
        return candidate.contractEnd?.format("L") ?? candidate.intake?.endDate?.format("L") ?? candidate.role?.endDate?.format("L");
    };

    return (
        <>
            <h2>Contracts</h2>
            <Table className={ contracts.length > 0 ? "scrollable-table" : ""}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Role</Table.HeaderCell>
                        <Table.HeaderCell>Project</Table.HeaderCell>
                        <Table.HeaderCell>Client</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>

                    {contracts.length === 0 && (
                        <Table.Row>
                            <Table.Cell content={"You have no contracts"} colSpan={6} textAlign="center" disabled />
                        </Table.Row>
                    )}

                    {contracts.sort(dateSortDesc(c => c.contractStart)).map((candidate, index) => (
                        <Table.Row key={index}>
                            <Table.Cell content={candidate.role?.title} />
                            <Table.Cell content={candidate.role?.project?.name} />
                            <Table.Cell content={candidate.role?.project?.client?.name} />
                            <Table.Cell content={getStartDate(candidate)} />
                            <Table.Cell content={getEndDate(candidate)} />
                            <Table.Cell content={CandidateState[candidate?.state]} />
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
};
