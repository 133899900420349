import { Payload } from "@neworbit/redux-helpers/dist/actions";
import { loadAndTrack } from "redux-request-loading/dist/actions";
import { push } from "redux-little-router";

import * as models from "./model";
import * as actions from "./actiontypes";
import { ReferenceApi } from "./ReferenceApi";

const referenceApi = new ReferenceApi();

export type ReferencingAction =
    ({ type: actions.CREATE_ACADEMIC_REFERENCE_SUCCESS } & Payload<models.AcademicReference>)
    | ({ type: actions.DELETE_REFERENCE_SUCCESS } & Payload<number>)
    | ({ type: actions.LOAD_REFERENCE_SUCCESS } & Payload<models.Reference>)
    | ({ type: actions.LOAD_REFERENCES_SUCCESS } & Payload<models.Reference[]>);

export const createAcademicReferenceSuccess = (payload: models.AcademicReference): ReferencingAction => ({
    payload,
    type: actions.CREATE_ACADEMIC_REFERENCE_SUCCESS
});

export const loadReferencesSuccess = (payload: models.Reference[]): ReferencingAction => ({
    payload,
    type: actions.LOAD_REFERENCES_SUCCESS
});

export const loadReferenceSuccess = (payload: models.Reference): ReferencingAction => ({
    payload,
    type: actions.LOAD_REFERENCE_SUCCESS
});

export const deleteReferenceSuccess = (payload: number): ReferencingAction => ({
    payload,
    type: actions.DELETE_REFERENCE_SUCCESS
});

export function createReference(reference: models.Reference, path: string) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "createReference", referenceApi.create(reference));
        dispatch(push(path));
    };
}

export function updateReference(reference: models.Reference, path?: string) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "updateReference", referenceApi.update(reference));
        if (path) {
            dispatch(push(path));
        }
    };
}

export function loadReferences() {
    return async (dispatch: any) => {
        const references = await loadAndTrack(dispatch, "loadReferences", referenceApi.getAll());
        dispatch(loadReferencesSuccess(references));
    };
}

export function loadReference({ referenceId }: { referenceId: number }) {
    return async (dispatch: any) => {
        const reference = await loadAndTrack(dispatch, "loadReference", referenceApi.get(referenceId));
        dispatch(loadReferenceSuccess(reference));
    };
}

export function deleteReferences(referenceId: number) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "deleteReferences", referenceApi.delete(referenceId));
        dispatch(deleteReferenceSuccess(referenceId));
    };
}

export function submitReferences(referenceIds: number[]) {
    return async (dispatch: any) => {
        await loadAndTrack(dispatch, "submitReferences", referenceApi.submit(referenceIds));
    };
}
