import moment from "moment";

import { AppState } from "../model";

export const currentUserSelector = (state: AppState) => {
    return state.currentUser;
};

export const onContractSelector = (state: AppState) => state.currentUser.contracts.some(c =>
    c.contractStart && c.contractStart < moment()
    && c.contractEnd === null || c.contractEnd > moment());
