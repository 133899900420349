import { AppState } from "@momenta/common/timesheets";

import { loadConfiguration } from "../hierarchicalConfiguration";
import { loadInvoicePeriods } from "../invoicePeriods";
import { loadVatRates } from "../vat";

import { timesheetSelector } from "./selectors";
import { loadTimesheet } from "./actions";

export const routes = {
    "/timesheets": {
        "title": "Timesheets",
        "resolve": [loadInvoicePeriods],
        "ignoreParentResolve": true,
        "authorize": true,
        "/:timesheetId": {
            title: (state: AppState) => "Timesheet - " + timesheetSelector(state).start.format("L"),
            resolve: [
                loadTimesheet,
                loadConfiguration("timeTypeConfiguration"),
                loadConfiguration("expenseTypeConfiguration"),
                loadConfiguration("bonusTypeConfiguration"),
                loadVatRates
            ],
        }
    },
};
