import * as React from "react";
import { Table } from "semantic-ui-react";

import { ColumnDefinition, ValuesAndValid } from "./InlineEditTable";

export interface SharedProps<T> {
    row: T;
    isEditing: boolean;
    showErrors: boolean;
    values: Partial<T>;
    updateProperty: (prop: keyof T, value: any, valid: boolean, state?: ValuesAndValid<T>) => void;
}

export interface InlineEditTableCellProps<T> extends SharedProps<T> {
    col: ColumnDefinition<T>;
}

export function InlineEditTableCell<T>({ row, col, isEditing, values, updateProperty, showErrors }: InlineEditTableCellProps<T>) {

    if (isEditing && col.edit) {
        return (
            <Table.Cell>
                {col.edit(
                    values[col.property],
                    (v, valid, state) => updateProperty(col.property, v, valid, state),
                    undefined,
                    showErrors,
                    () => values as any)
                }
            </Table.Cell>
        );
    }

    const value = col.render
        ? col.render(row[col.property], row)
        : row[col.property] as any;

    return <Table.Cell>{value}</Table.Cell>;
}
