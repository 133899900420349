import * as React from "react";
import { TimesheetEntry } from "@momenta/common/timesheets";
import { Table } from "semantic-ui-react";
import { TimeEntryType } from "@momenta/common/hierarchicalConfiguration/model";
import { Moment } from "moment";

interface TimesheetEntryPrintProps {
    timesheetEntry: TimesheetEntry;
    timesheetDay?: Moment;
    timesheetEntryCount?: number;
    showdate?: boolean;
}

export const TimesheetEntryPrint: React.FC<TimesheetEntryPrintProps> = ({ timesheetEntry, timesheetDay, timesheetEntryCount, showdate }) => {

    const getTimeType = (entry: TimesheetEntry) => {
        const type = entry.timeType?.type?.timeEntryType;
        return type === 0 ? "Hours" : TimeEntryType[type];
    };

    return (
        <Table.Row key={timesheetEntry.id}>
            { showdate && <Table.Cell rowSpan={timesheetEntryCount}>{timesheetDay?.format("dddd Do")}</Table.Cell> }
            <Table.Cell>{timesheetEntry?.timeType?.type?.name}</Table.Cell>
            <Table.Cell>{timesheetEntry?.amount + " " + getTimeType(timesheetEntry) }</Table.Cell>
        </Table.Row>
    );
};
